import React from 'react';
import { View } from 'react-native';

import { colors, globalStyles } from '../helpers';

import { BodyText } from '.';

import { EmptyCartIcon } from '../assets';

const EmptyCart = () => {
  return (
    <View
      style={[
        globalStyles.flexColumn,
        {
          backgroundColor: colors.white,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
        },
      ]}>
      <EmptyCartIcon width={140} />
      <BodyText color='text' bold={true} fontSize={16} mt={16}>
        YOUR CART IS EMPTY
      </BodyText>
    </View>
  );
};

export default EmptyCart;

import React, { createContext, useContext } from 'react';

import sleep from '../helpers/sleep';

export const MockAuthContext = createContext<{
  emailLogin: () => void;
  logoutUser: () => void;
  sendPhoneCode: (number: string) => void;
  submitVerificationCode: (number: string, code: string) => Promise<string>;
  parseCode: () => void;
  getToken: () => Promise<string>;
}>({
  emailLogin: () => undefined,
  logoutUser: () => undefined,
  getToken: async () => '',
  sendPhoneCode: (_number: string) => undefined,
  submitVerificationCode: async (_number: string, _code: string) => '',
  parseCode: () => undefined,
});

export const useMockAuth = () => {
  const context = useContext(MockAuthContext);
  if (!context) throw new Error('useAuth must be used within WebAuthProvider.');
  return context;
};

interface Props {
  children: React.ReactNode;
}

export default class MockAuthProvider extends React.Component<Props> {
  authClient = {};

  emailLogin = () => console.info('email login sent');

  logoutUser = () => {
    this.token = '';
  };

  token = 'mock_token';

  getToken = async () => this.token;

  parseCode = () => undefined;

  sendPhoneCode = (_phoneNumber: string) => {
    console.info('phone code sent');
    return {};
  };
  submitVerificationCode = async (
    _phoneNumber: string,
    _verificationCode: string
  ) => {
    await sleep(1);

    const { pathname, origin } = window.location;
    const loginRedirect = pathname !== '/' ? pathname : '/profile';
    localStorage.setItem('loginRedirect', loginRedirect);

    const link = origin + '/login';
    window.location.href = link;

    return 'success';
  };

  render() {
    return (
      <MockAuthContext.Provider
        value={{
          emailLogin: this.emailLogin,
          logoutUser: this.logoutUser,
          getToken: this.getToken,
          sendPhoneCode: this.sendPhoneCode,
          submitVerificationCode: this.submitVerificationCode,
          parseCode: this.parseCode,
        }}>
        {this.props.children}
      </MockAuthContext.Provider>
    );
  }
}

import { cloneDeep } from 'lodash';

import { mockProducts } from './productsMock';
import { mockStores } from './storeMock';

const validId = (id: string) => {
  if (id.length !== 36) return false;
  const segments = id.split('-');
  if (segments.length !== 5) return false;
  if (segments[0].length !== 8) return false;
  if (segments[1].length !== 4) return false;
  if (segments[2].length !== 4) return false;
  if (segments[3].length !== 4) return false;
  if (segments[4].length !== 12) return false;
  return true;
};

const productCatalog = mockProducts();
const storeCatalog = mockStores();

export const mockCustomer = {
  id: '9ad35ee7-1c22-9940-80b3-a603671fe7ff',
  public_id: '0a5690f7-7dc8-4377-b9b2-0d2eb52f330b',
  created_at: '2022-09-14T22:37:47.785970Z',
  modified_at: '2022-09-15T00:38:15.336752Z',
  etag: '18bf6dd70f6017f24d3bed4224ea144df8e7eaed',
  name: '',
  email: '',
  sms_number: '',
  birthday_month: null,
  birthday_day: null,
  marketing_email_master: true,
  marketing_email_birthday: false,
  marketing_email_newsletter: false,
  ordering_sms: true,
  created_by: 'fff7d3ae-fd53-4207-cc26-d7553d437d7f',
  modified_by: 'fff7d3ae-fd53-4207-cc26-d7553d437d7f',
};

export const mockLoadUser = (config: any) => {
  if (!config.headers.Authorization) return { customer: null };
  return { customer: mockCustomer };
};

export const mockPatchUser = (config: any) => {
  if (!config.headers.Authorization) return { customer: null };

  const body = JSON.parse(config.body);
  mockCustomer.name = body.name;
  mockCustomer.email = body.email;
  return { customer: mockCustomer };
};

export const mockDeleteUser = (config: any) => {
  // if (!config.headers.Authorization) return { customer: null };

  mockCustomer.name = '';
  mockCustomer.email = '';
  pastOrders = [];
  return { status: 204 };
};

export let pastOrders: OrderResp[] = [];
export const clearMockOrders = () => (pastOrders = []);

// export let pastOrders = [
//   {
//     id: 'b51ef850-6a25-4183-8f1a-053b6156f902',
//     store: 'd186b3fb-2ccf-4cb0-b2ea-abe0becf5ee1',
//     created_at: '2022-09-24T19:02:39.891085Z',
//     coupon: 'VIP',
//     discount: 700,
//     subtotal: 700,
//     tax: 0,
//     total: 0,
//     ready_for_handoff_at: '2022-09-24T19:02:39.869662Z',
//     handoff: 'pickup',
//     pickup_identifier: 'Tester T',
//     in_progress_at: '2022-09-24T19:02:39.891085Z',
//     order_lines: [
//       {
//         // id: '3ee772da-090f-4d39-9dd3-1672b189c3a4',
//         modifiers: {
//           '07c0026f-e5bb-4b3f-99e0-6363c452d2fb': {
//             'fc4f6588-9ca4-42fc-ab37-2b91e38397d3': 1,
//           },
//           'a9200d7f-583d-47aa-9474-0f86eb815009': {
//             '9e95529f-4a11-4d3d-ba45-8a84c3c392f6': 1,
//           },
//           'd54171da-a22e-4976-a321-60134c2c5be3': {
//             'a8be53ec-b9ae-4739-b09c-0935be89e3f5': 1,
//           },
//         },
//         price: 700,
//         // product: '1816ac86-259e-4c58-9049-7ef0a83bf648',
//         product: 'ddbb0301-5c9f-406a-95ab-278a4de033a3',
//         quantity: 1,
//       },
//     ],
//     state: 'completed',
//     validation_id: 'a1f0d3c4-08b9-49e8-8d98-8644fdb3802d',
//     refund_attempted: false,
//   },
// ];

export const mockUserOrdersResponse = () => {
  const orders = cloneDeep(pastOrders);

  const products: ProductResp[] = [];
  const stores: StoreResp[] = [];

  orders.forEach((order) => {
    order.order_lines.forEach((line) => {
      const existingProduct = products.find((p) => p.id === line.product);
      if (!existingProduct) {
        const product = productCatalog.find((p) => p.id === line.product);
        if (product) products.push(product);
      }
    });
    if (!stores.find((s) => s.id === order.store)) {
      const store = storeCatalog.find((s) => s.id === order.store);
      if (store) stores.push(store);
    }
  });

  return { orders, products, stores };
};

export const mockUserCouponsResponse = () => {
  return {
    coupons: [
      {
        coupon: 'PIZZAFUND',
        coupon_description: `$5 off, always money for pizza!`,
        created_at: '2023-03-22T20:36:12.857598Z',
        id: '31d1263f-f47c-4208-869f-a3c32a3fdcca',
        modified_at: '2023-03-22T20:36:12.857628Z',
      },
      {
        coupon: '$1 PIZZA',
        coupon_description:
          'Welcome to Stellar Pizza! Order one cheese or pepperoni pizza for $1! Limit one per day.',
        created_at: '2023-03-22T20:36:12.857598Z',
        id: '31d1263f-f47c-4208-869f-a3c32a3fdccb',
        modified_at: '2023-03-22T20:36:12.857628Z',
      },
      {
        coupon: 'PIZZAFUND',
        coupon_description: 'always money for pizza',
        created_at: '2023-03-22T20:36:12.857598Z',
        id: '31d1263f-f47c-4208-869f-a3c32a3fdccc',
        modified_at: '2023-03-22T20:36:12.857628Z',
      },
    ],
    meta: {
      page: 1,
      per_page: 100,
      total_results: 1,
      total_pages: 1,
    },
  };
};

export const mockFetchOrderResponse = (url: string) => {
  const orderId = url.slice(17, 53);
  if (validId(orderId)) {
    const order = pastOrders.find((o) => o.id === orderId);
    if (order) return { order };
  }
  return sampleOrder;
};

export const mockFetchCompletedOrderResponse = (url: string) => {
  const orderId = url.slice(17, 53);
  if (validId(orderId)) {
    const order = pastOrders.find((o) => o.id === orderId);

    if (order?.state === 'awaiting_payment') {
      order.state = 'in_progress';
      order.in_progress_at = new Date().toISOString();
    }

    const products: ProductResp[] = [];
    let requiresKitchen = false;
    order?.order_lines.forEach((orderLine) => {
      const product = productCatalog.find((p) => p.id === orderLine.product);
      if (product) {
        if (product.requires_kitchen) requiresKitchen = true;
        return products.push(product);
      }
    });

    if (!requiresKitchen && !!order) {
      order.ready_for_handoff_at = new Date().toJSON();
      if (order.state === 'in_progress') order.state = 'ready_for_handoff';
    }

    const stores: StoreResp[] = [];
    const store = storeCatalog.find((s) => s.id === order?.store);
    if (store) stores.push(store);
    if (order) return { order, products, stores };
  }
};

const sampleOrder = {
  order: {
    id: 'b51ef850-6a25-4183-8f1a-053b6156f902',
    coupon: 'VIP',
    customer: '2380c314-3fbd-4d51-97b6-761d7eb6843a',
    estimated_ready_for_handoff_at: '2022-09-24T19:07:00Z',
    handoff: 'pickup',
    initial_estimated_ready_for_handoff_at: '2022-09-24T19:07:00Z',
    order_lines: [
      {
        id: '3ee772da-090f-4d39-9dd3-1672b189c3a4',
        modifiers: {},
        price: 75,
        product: '1816ac86-259e-4c58-9049-7ef0a83bf648',
        quantity: 1,
        recipient: '',
      },
    ],
    source: 'customer/web',
    store: 'd186b3fb-2ccf-4cb0-b2ea-abe0becf5ee1',
    discount: 75,
    subtotal: 75,
    tax: 0,
    total: 0,
    validation_id: 'a1f0d3c4-08b9-49e8-8d98-8644fdb3802d',
    canceled_at: null,
    completed_at: null,
    created_at: '2022-09-24T19:02:39.891085Z',
    in_progress_at: null,
    modified_at: '2022-09-24T19:02:39.891216Z',
    pickup_identifier: 'Tester T',
    products: ['1816ac86-259e-4c58-9049-7ef0a83bf648'],
    ready_for_handoff_at: '2022-09-24T19:02:39.869662Z',
    refund_attempted: false,
    state: 'ready_for_handoff',
  },
};

import { StyleSheet, Dimensions } from 'react-native';

import { colors, globalStyles, isIos } from '../../../../helpers';

const styles = StyleSheet.create({
  upsellModal: {
    display: 'flex',
    backgroundColor: colors.secondary,
    zIndex: 2,
    maxHeight: '100%',
    overflow: 'hidden',
  },
  cartRow: {
    backgroundColor: colors.secondary,
    zIndex: 2,
  },

  upsellProductRow: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    padding: 20,
    paddingBottom: 6,
  },
  hat: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: colors.secondary,
    transform: [{ rotate: '2deg' }, { translateY: 14 }, { translateX: -50 }],
    height: 20,
    width: '150%',
  },
  closeBox: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingTop: 5,
    paddingRight: 5,
    paddingLeft: 20,
    paddingBottom: 20,
    zIndex: 10,
  },
  productImage: {
    marginLeft: 30,
    width: 90,
    height: 100,
  },
  pizzaImage: {
    width: 120,
    height: 80,
    // margin: 10,
  },
  addedRow: {
    paddingRight: 20,
  },
  productScrollbox: {
    width: '100%',
  },
  addedProductBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    width: '100%',
    flexWrap: 'wrap',
    marginBottom: 12,
  },

  addedProduct: {
    width: 50,
    height: 70,
  },

  // Upsell Section
  upsellSection: {
    paddingTop: 50,
    backgroundColor: colors.body,
  },

  upsellContainer: {
    paddingBottom: isIos ? 60 : 40,
  },

  rowLabel: {
    // marginTop: 14,
    marginLeft: 14,
    color: colors.primary,
    fontSize: 22,
    fontWeight: '700',
    marginBottom: 10,
  },
  upsellRow: {
    paddingLeft: 16,
    minHeight: 180,
  },
  upsellItem: {
    width: 180,
    height: 180,
    marginRight: 10,
    backgroundColor: colors.white,
    // padding: 12,
  },
  addedItem: {
    backgroundColor: colors.secondary,
  },
  textBox: {
    padding: 5,
  },
  upsellPriceRow: {
    ...globalStyles.flexRow,
    justifyContent: 'space-between',
    // alignContent: 'flex-end',
    alignItems: 'flex-end',
    bottom: 0,
    left: 0,
    padding: 5,
    width: '100%',
    // height: '100%',
    flexGrow: 2,
  },
  upsellImage: {
    alignSelf: 'center',
    // marginBottom: 14,
    width: 80,
    height: '90%',
  },
  drinkImage: {
    width: 75,
    height: '90%',

    // marginBottom: 8,
  },
  cta: {
    marginTop: 50,
    marginBottom: 20,
    width: '95%',
    alignSelf: 'center',
  },

  // Popcorn Styles
  popcornBox: {},
  popRow: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },

  kernelColumn: {
    position: 'relative',
    minWidth: 200,
  },
  kernel: {
    ...globalStyles.flexRow,
    position: 'absolute',
    // width: 20,
    // height: 20,
  },
  kernelImage: {
    width: 40,
    height: 40,
  },
});

export default styles;

export default class Auth0 {}

export class Auth0Provider {}

export const useAuth0 = () => ({ getCredentials: () => undefined });

export interface SaveCredentialsParams {
  idToken: string;
  accessToken: string;
  tokenType: string;
  expiresIn: number;
  refreshToken?: string;
  scope?: string;
}

import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';

import { colors, globalStyles } from '../../helpers';
import { setScheduleUpsellModal } from '../../globalStore';

import { BodyText, TitleText } from '..';

import BaseModal from './BaseModal';

import { Stars, MascotBolt, Close } from '../../assets';

const ScheduleUpsellModal = () => {
  const [timeSlots, setTimeSlots] = useState<slot[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const timeSlots = generateTimeSlots(3);
    setTimeSlots(timeSlots);
  }, []);
  const clearModal = useCallback(() => {
    dispatch(setScheduleUpsellModal(false));
  }, []);

  return (
    <BaseModal onBackdropPress={clearModal}>
      <View style={styles.modalBox}>
        <View style={styles.header}>
          <Stars width={214} height={130} />
        </View>

        <Pressable style={styles.closeButton} onPress={clearModal}>
          <Close width={100} height={14} />
        </Pressable>

        <View style={styles.body}>
          <View style={styles.background} />
          <View style={styles.content}>
            <View style={styles.boltBox}>
              <MascotBolt width={100} />
            </View>

            <TitleText fontSize={24} color='primary'>
              COMING SOON
            </TitleText>

            <BodyText mt={20} color='textDark'>
              Schedule your pizza ahead of time to skip the line!
            </BodyText>

            <View style={styles.slotBox}>
              {timeSlots.map((slot, idx) => (
                <View style={styles.timeSlot} key={idx}>
                  <View
                    style={[
                      styles.slotIndicator,
                      {
                        backgroundColor:
                          slot.slices > 20 ? colors.primary : 'transparent',
                      },
                    ]}
                  />
                  <BodyText color='primary'>
                    {slot.hours} : {slot.minutes || '00'} {slot.ampm}
                  </BodyText>
                </View>
              ))}
            </View>

            <BodyText
              center={true}
              color='textDark'
              mt={20}
              ml={30}
              mr={30}
              mb={14}>
              For now keep up with our social to find when we'll be open!
            </BodyText>
            <View style={globalStyles.flexRow}>
              <BodyText
                underline={true}
                color='primary'
                link='https://www.instagram.com/eatstellarpizza/'>
                Instagram
              </BodyText>
              <BodyText
                ml={14}
                underline={true}
                color='primary'
                link='https://www.tiktok.com/@eatstellarpizza'>
                TikTok
              </BodyText>
            </View>
          </View>
        </View>
      </View>
    </BaseModal>
  );
};

export default ScheduleUpsellModal;

const styles = StyleSheet.create({
  modalBox: {
    width: 368,
    height: 441,
    borderRadius: 24,
    backgroundColor: colors.secondary,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 20,
  },
  header: {
    ...globalStyles.flexRow,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    elevation: 2,
  },
  body: {
    flex: 1,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.body,
  },
  content: {
    ...globalStyles.flexColumn,
    flex: 1,
    bottom: 88,
  },
  boltBox: {
    left: 18,
    marginBottom: 30,
  },

  slotBox: {
    ...globalStyles.flexRow,
    marginTop: 20,
  },
  timeSlot: {
    ...globalStyles.flexRow,
    width: 100,
    height: 40,
    borderRadius: 10,
    backgroundColor: colors.white,
    margin: 5,
  },
  slotIndicator: {
    position: 'absolute',
    top: 7,
    right: 7,
    width: 8,
    height: 8,
    borderRadius: 10,
    backgroundColor: colors.primary,
    borderWidth: 1,
    borderColor: colors.primary,
  },
});

type slot = {
  hours: number;
  minutes: number;
  slices: number;
  ampm: string;
};

const generateTimeSlots = (quantity = 10) => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();

  const baseMinutes = minutes >= 30 ? 60 - minutes : 30 - minutes;
  const slots: slot[] = [];

  for (let i = 0; i < quantity; i++) {
    const totalMinutes = i * 30 + baseMinutes + minutes + hours * 60;
    let slotHours = Math.floor(totalMinutes / 60);
    if (slotHours > 23) slotHours -= 23;

    const ampm = slotHours >= 12 ? 'pm' : 'am';
    if (slotHours > 12) slotHours -= 12;
    const slotMinutes = totalMinutes % 60;
    const factor = ((i * 100 + baseMinutes) * Math.random()) / 100;
    let slicesLeft = Math.max(0, factor * 30 - 4);
    if (slicesLeft < 3) slicesLeft = 0;
    const slot: slot = {
      hours: slotHours,
      minutes: slotMinutes,
      slices: (slicesLeft / 30) * 100,
      ampm,
    };
    slots.push(slot);
  }
  return slots;
};

import { StyleSheet } from 'react-native';
import { colors, globalStyles } from '../../helpers';

const styles = StyleSheet.create({
  nav: {
    ...globalStyles.flexColumn,
    width: '100%',
    height: 180,
    backgroundColor: colors.primary,
  },
  mobileNav: {
    zIndex: 100,
  },
  navSafeWrapper: {
    ...globalStyles.flexRow,
    width: '100%',
    flex: 1,
  },
  logoBox: {
    ...globalStyles.flexRow,
    backgroundColor: colors.primary,
    width: 120,
  },
  background: {
    position: 'absolute',
    top: '90%',
    width: '100%',
    backgroundColor: colors.body,
    // transform: [{ translateY: -20 }, { scaleX: Platform.OS === 'web' ? 4 : 2 }],
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },

  actionBox: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    right: 0,
    bottom: '20%',
    paddingVertical: 10,
    paddingHorizontal: 50,
  },
  mobileActionBox: {
    height: '100%',
    bottom: 0,
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  orderLink: {
    position: 'absolute',
    left: 16,
  },
  hamburgerBox: {
    position: 'absolute',
    padding: 14,
    left: 0,
  },
  line: {
    width: 27,
    height: 2.5,
    backgroundColor: colors.white,
    borderRadius: 1,
  },
  mobileMenu: {
    display: 'flex',
    // flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    position: 'absolute',
    width: '100%',
    minHeight: 70,
    backgroundColor: colors.primary,
    top: '100%',
    paddingVertical: 20,
  },
  mobileNavLink: {
    paddingVertical: 10,
    paddingHorizontal: 40,
  },
});

export default styles;

import React, { useEffect, useState, useCallback } from 'react';
import { View, Pressable, StyleSheet } from 'react-native';
import { Loader } from '@googlemaps/js-api-loader';
import { throttle } from 'lodash';

import { logError, sleep, getEnvVariable, getCoordinates } from '../../helpers';

import { truckMarker, RefreshGPS } from '../../assets';

import { StarLoader, CustomView } from '..';

import styles from './styles';

const loader = new Loader({
  apiKey: getEnvVariable('REACT_APP_GOOGLE_MAPS_KEY'),
  version: 'weekly',
  libraries: ['places'],
});

const verticalBound = 0.1;
const horizontalBound = 0.3;

interface Props {
  store: StoreType;
  setMapError?: (hasError: boolean) => void;
  disabled?: boolean;
  zoomedIn?: boolean;
}

const WebMapPreview = ({ store, setMapError, disabled, zoomedIn }: Props) => {
  const [showCenterButton, setShowCenterButton] = useState(false);
  const [map, setMap] = useState<any>(null);
  const [coordinates, setCoordinates] = useState<any>(null);
  const [appear, setAppear] = useState(false);

  const centerMap = useCallback(() => {
    map.panTo(coordinates);
    map.setZoom(15);
  }, [map, coordinates]);

  useEffect(() => {
    loader
      .load()
      .then(async (google) => {
        const mylatlong = {
          lat: store.latitude || 0,
          lng: store.longitude || 0,
          // lat: 0,
          // lng: 0,
        };

        if (!mylatlong.lat || !mylatlong.lng) {
          try {
            const coordinates = await getCoordinates(store.address);

            if (coordinates) {
              mylatlong.lat = coordinates.lat;
              mylatlong.lng = coordinates.lng;
            } else {
              const geocoder = new google.maps.Geocoder();
              const { results } = await geocoder.geocode({
                address: store.address,
              });
              const {
                geometry: { location },
              } = results[0];

              const latitude = location.lat();
              const longitude = location.lng();

              mylatlong.lat = latitude;
              mylatlong.lng = longitude;
            }
          } catch (e: any) {
            logError(e);
          }
        }

        if (!mylatlong.lat || !mylatlong) {
          if (setMapError) return setMapError(true);
        }
        setCoordinates(mylatlong);

        const bounds = {
          north: mylatlong.lat + verticalBound,
          south: mylatlong.lat - verticalBound,
          east: mylatlong.lng + horizontalBound,
          west: mylatlong.lng - horizontalBound,
        };

        const mapOptions = {
          center: mylatlong,
          restriction: {
            latLngBounds: bounds,
            strictBounds: true,
          },
          gestureHandling: disabled ? 'none' : 'cooperative',
          zoom: zoomedIn ? 17 : 15,
          tilt: zoomedIn ? 20 : 0,
          heading: zoomedIn ? 5 : 0,
          mapId: 'f0a6d4c5cd977354',
          keyboardShortcuts: false,
          disableDefaultUI: true,
        };

        await sleep(0.25);

        const element = document.getElementById(`map - ${store.id}`);
        if (!element) {
          if (setMapError) setMapError(true);
          return;
        }
        const map = new google.maps.Map(element, mapOptions);
        setMap(map);

        const checkTruckInView = () => {
          const bounds = map.getBounds();
          const inBounds = bounds?.contains(mylatlong);

          const zoom = map.getZoom();
          const zoomedOut = !!zoom && zoom < 14;
          setShowCenterButton(!inBounds || zoomedOut);
        };

        const throttledCheck = throttle(checkTruckInView, 1000);

        google.maps.event.addListener(map, 'bounds_changed', throttledCheck);
        google.maps.event.addListener(map, 'zoom_changed', throttledCheck);

        const icon = {
          url: truckMarker,
          scaledSize: new google.maps.Size(30, 34), // scaled size
          labelOrigin: new google.maps.Point(9, -12),
        };
        new google.maps.Marker({
          position: {
            lat: mylatlong.lat,
            lng: mylatlong.lng,
          },
          map,
          icon,
        });

        await sleep(0.1);
        setAppear(true);
      })
      .catch((e: any) => {
        logError(e);
      });
  }, []);

  // useEffect(() => {
  //   const key = getEnvVariable('REACT_APP_GOOGLE_GEOCODE_KEY');
  //   if (!coordinates?.lat) return;
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${key}`;
  //   const testFetch = async () => {
  //     const result = await fetch(url);
  //     console.log('RESULT -', result);
  //     const answer = await result.json();
  //     console.log('ANSWER -', answer);
  //   };
  //   testFetch();
  // }, [coordinates]);

  return (
    <View style={styles.mask}>
      <View style={styles.loadingFrame}>
        <StarLoader color='primary' />
      </View>

      <CustomView
        style={StyleSheet.absoluteFillObject}
        fadeIn={true}
        duration={800}
        appear={appear}>
        <div
          id={`map - ${store.id}`}
          data-testid='embedded-map-view'
          style={{
            position: 'absolute',
            width: '100%',
            // height: '81%',
            height: '100%',
          }}
        />
      </CustomView>

      {showCenterButton && !disabled && (
        <Pressable style={[styles.centerButtonBox]} onPress={centerMap}>
          <RefreshGPS width={40} height={40} />
        </Pressable>
      )}
    </View>
  );
};

export default WebMapPreview;

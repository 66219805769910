import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { logError } from './sentryLogger';

const isWeb = Platform.OS === 'web';

// ~~~~@@ READ STORAGE @@~~~~~
const readStorageValue = (storageValue: string) => {
  // Check if number
  try {
    const floatValue = parseFloat(storageValue);
    const floatString = floatValue.toString();
    if (floatValue && floatString === storageValue) return floatValue;
  } catch (e) {}

  // Check if object
  try {
    const objectValue = JSON.parse(storageValue);
    return objectValue;
  } catch (e) {}

  return storageValue;
};

const readWebStorage = async (key: string) => {
  const storageValue = await localStorage.getItem(key);
  if (!storageValue) return '';
  return readStorageValue(storageValue);
};

const readNativeStorage = async (key: string) => {
  try {
    const storageValue = await AsyncStorage.getItem(key);
    if (storageValue !== null) return readStorageValue(storageValue);
  } catch (e) {
    console.error('could not read native storage value');
  }
  return '';
};

export const readStorage = async (key: string) => {
  if (isWeb) return readWebStorage(key);
  else return readNativeStorage(key);
};

// ~~~~@@ WRITE STORAGE @@~~~~~
const writeWebStorage = async (
  key: string,
  value: string | number | Record<string, unknown> | Array<unknown>
) => {
  const storageValue =
    typeof value === 'string' ? value : JSON.stringify(value);
  await localStorage.setItem(key, storageValue);
};

const writeNativeStorage = async (
  key: string,
  value: string | number | Record<string, unknown> | Array<unknown>
) => {
  try {
    const storageValue =
      typeof value === 'string' ? value : JSON.stringify(value);
    await AsyncStorage.setItem(key, storageValue);
  } catch (e) {
    console.error('could not write native storage value');
  }
};

export const writeStorage = async (
  key: string,
  value: string | number | Record<string, unknown> | Array<unknown>
) => {
  if (isWeb) return writeWebStorage(key, value);
  else return writeNativeStorage(key, value);
};

// ~~~~@@ DELETE STORAGE @@~~~~~
export const wipeAllStorage = async () => {
  const keys = [
    'recentOrders',
    'cartStorage',
    'userStorage',
    'referrerId',
    'referralConfig',
    'TESTING-FORCE-MOCK',
  ];
  if (isWeb) return keys.forEach((k) => localStorage.removeItem(k));

  AsyncStorage.multiRemove(keys, (err) => {
    if (err && Array.isArray(err)) err.forEach((error) => logError(error));
  });
};

export const clearStorageItem = async (key: string) => {
  if (isWeb) return localStorage.removeItem(key);

  AsyncStorage.removeItem(key, (err) => {
    if (err) logError(err);
  });
};

import React, { useState } from 'react';
import { View, Pressable, StyleSheet } from 'react-native';
import { cloneDeep, orderBy } from 'lodash';

import { BodyText, Arrow, GrowView, Block } from '../../components';

import {
  globalStyles,
  getOperationalDate,
  getPacificTimeString,
  getPacificDateString,
  getPacificDay,
  isPacificTimeZone,
} from '../../helpers';

interface Props {
  store: StoreType;
  expandHours?: boolean;
}

const StoreHours = ({ store, expandHours }: Props) => {
  const [expanded, setExpanded] = useState(expandHours || false);
  const { events } = store;
  if (!events || !events.length) return null;

  const eventsList = orderBy(cloneDeep(events), 'openAt');

  let showToday = false;
  let showTonight = false;
  const firstEvent = eventsList[0];

  const now = getOperationalDate();

  const openAt = new Date(firstEvent.openAt);

  if (now.getDate() === openAt.getDate()) {
    showToday = true;
    const hours = now.getHours();
    if (hours < 4 || hours > 22) showTonight = true;
  } else if (store.openAt && store.closeAt) {
    showToday = true;
    eventsList.unshift({
      id: 'in-place-event',
      store: store,
      address: store.address,
      preorderAt: store.preorderAt,
      openAt: store.openAt,
      closeAt: store.closeAt,
    });
  }

  const showTimezoneText = !isPacificTimeZone();
  const timeZoneTextHeight = showTimezoneText ? 20 : 0;

  const anyPreorders = eventsList.some((e) => !!e.preorderAt);
  const maxHeight =
    eventsList.length * 25 + (anyPreorders ? 20 : 5) + timeZoneTextHeight;

  const todayText = showTonight ? 'Tonight' : 'Today';

  return (
    <View style={styles.hoursContainer}>
      <Pressable onPress={() => setExpanded((prevExpanded) => !prevExpanded)}>
        <View style={styles.hoursToggle}>
          <BodyText color='black' bold={true} mb={0}>
            Schedule
          </BodyText>
          <Arrow color='black' width={24} down={!expanded} up={expanded} />
        </View>
        <GrowView
          style={[styles.hoursBox]}
          grow={expanded}
          shrinkBack={true}
          maxHeight={maxHeight}
          duration={500}>
          {showTimezoneText && (
            <BodyText fontSize={12} color='black' mb={4}>
              Hours are shown in Pacific time
            </BodyText>
          )}
          {eventsList.slice(0, 8).map((e, idx) => {
            const eventHours = readEventHours(e);
            const isToday = showToday && idx === 0;
            return (
              <View key={e.id}>
                {idx === 0 && anyPreorders && <Block height={16} />}
                <View style={styles.hoursRow}>
                  <View style={styles.range}>
                    <BodyText
                      style={[styles.dateCell, { width: isToday ? 60 : 35 }]}
                      color={isToday ? 'black' : 'black'}
                      bold={false}>
                      {isToday ? todayText : eventHours.day}
                    </BodyText>

                    <BodyText
                      style={[styles.dateCell, { width: isToday ? 10 : 35 }]}
                      color='black'
                      bold={false}>
                      {!isToday && eventHours.date}
                    </BodyText>
                  </View>
                  {anyPreorders && (
                    <View>
                      {idx === 0 && anyPreorders && (
                        <BodyText
                          style={styles.preInfo}
                          color='darkGreen'
                          center={true}>
                          (preorders)
                        </BodyText>
                      )}
                      <BodyText
                        style={[styles.hoursCell, { width: 80 }]}
                        color='darkGreen'
                        bold={false}>
                        {eventHours.preOrderTime}
                      </BodyText>
                    </View>
                  )}

                  <View style={[styles.range]}>
                    <BodyText
                      style={styles.hoursCell}
                      color='green'
                      ml={15}
                      bold={false}>
                      {eventHours.openTime}
                    </BodyText>
                    <BodyText>-</BodyText>
                    <BodyText
                      style={styles.hoursCell}
                      color='green'
                      bold={false}>
                      {eventHours.closeTime}
                    </BodyText>
                  </View>
                </View>
              </View>
            );
          })}
        </GrowView>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  hoursContainer: {
    display: 'flex',
  },
  hoursToggle: {
    ...globalStyles.flexRow,
    alignSelf: 'flex-start',
  },
  hoursBox: {},
  hoursRow: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    // justifyContent: 'space-between',
    width: '100%',
    marginTop: 2,
    paddingHorizontal: '2%',
  },
  preInfo: {
    position: 'absolute',
    top: -20,
  },
  dateCell: {
    ...globalStyles.flexRow,
    textAlign: 'center',
    width: 40,
  },
  hoursCell: {
    ...globalStyles.flexRow,
    width: 65,
    minHeight: 10,
    textAlign: 'center',
    // borderWidth: 1,
  },
  range: {
    ...globalStyles.flexRow,
  },
});

export default StoreHours;

export const readEventHours = (event: StoreEventType) => {
  const day = getPacificDay(event.openAt);
  const date = getPacificDateString(event.openAt);

  const preOrderTime = getPacificTimeString(event.preorderAt);
  const openTime = getPacificTimeString(event.openAt);
  const closeTime = getPacificTimeString(event.closeAt);

  return { day, date, preOrderTime, openTime, closeTime };
};

import React, { useState, useCallback, useRef, createRef } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-native';
import {
  StripeProvider,
  CardField,
  useStripe,
  RetrievePaymentIntentResult,
  ConfirmPaymentResult,
} from '@stripe/stripe-react-native';

import type { Details } from '@stripe/stripe-react-native/lib/typescript/src/types/components/CardFieldInput';

import { MOCK_TESTING } from '@app/core';

import {
  sleep,
  getEnvVariable,
  colors,
  logMessage,
  logError,
} from '../../../../helpers';

import {
  readOrder,
  getOrder,
  loadCompletedOrder,
  setOrderSuccessModal,
  clearCart,
  unselectCoupon,
  throttledLoadUserItems,
} from '../../../../globalStore';

import {
  BodyText,
  Block,
  Button,
  StarLoader,
  TitleText,
  TextInput,
} from '../../..';

import SavedPaymentMethods from '../SavedPaymentMethods';

import NativeApplePay from './NativeApplePay';

import styles from '../styles';

const stripeKey = getEnvVariable('REACT_APP_STRIPE_PUBLISHABLE_KEY');

const validIntent = (
  intentPackage: RetrievePaymentIntentResult | ConfirmPaymentResult
) => {
  if (!intentPackage) return false;

  const { paymentIntent } = intentPackage;
  if (!paymentIntent) return false;

  const { created, id, canceledAt, amount } = paymentIntent;
  if (!created || !id || !amount) {
    logMessage(`Payment intent not valid - ${created} - ${id} - ${amount}`);
    return false;
  }

  if (canceledAt) {
    logMessage(`Payment intent not valid, has been canceled - ${canceledAt}`);
    return false;
  }

  return true;
};

const NativePaymentFields = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const { confirmPayment, createPaymentMethod, retrievePaymentIntent } =
    useStripe();
  const order = useSelector((state: RootState) => readOrder(state, storeId));
  const nameRef = useRef(createRef()).current as React.RefObject<any>;

  const [errorMessage, setErrorMessage] = useState('');
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [userName, setUserName] = useState('');
  const [nameError, setNameError] = useState('');

  const paymentMethods = order?.paymentMethods || [];

  const hasPaymentMethods = paymentMethods.length > 0;
  const [selectedPaymentMethod, setPaymentMethod] = useState(
    hasPaymentMethods ? paymentMethods[0].id : ''
  );

  const onCardChange = (cardDetails: Details) => {
    setCardComplete(!!cardDetails.complete);
    setErrorMessage('');
  };

  const onNameChange = (value: string) => {
    setUserName(value);
    setNameError('');
  };

  const submitPayment = useCallback(async () => {
    if (!order?.payment || !order?.id)
      return logMessage(`Cannot submit payment - ${order?.id}`);

    const orderId = order.id;

    const orderResponse: any = await dispatch(getOrder({ storeId, orderId }));

    if (
      !orderResponse ||
      !orderResponse.payload ||
      !orderResponse.payload.order
    ) {
      logError(
        `Did not find order from platform when submitting payment - ${orderId}`
      );
      return setErrorMessage('Error retrieving order, please try again');
    }

    const platformOrder = orderResponse.payload.order;
    if (
      !platformOrder.id ||
      !platformOrder.state.toLowerCase ||
      platformOrder.state.toLowerCase() !== 'awaiting_payment'
    ) {
      logError(
        `Order from platform not in correct state to submit payment - ${orderId}`
      );
      return setErrorMessage(
        'Order no longer available for payment, close cart and start again.'
      );
    }

    let billingDetails = undefined;
    let transactionPaymentMethod;

    if (selectedPaymentMethod) {
      setProcessing(true);
      transactionPaymentMethod = selectedPaymentMethod;
    } else {
      if (!userName) return setNameError('Name cannot be empty');
      setNameError('');

      if (MOCK_TESTING) return celebrateOrderSuccess();
      if (cardComplete) setProcessing(true);
      else {
        nameRef.current.focus();
        return setErrorMessage('Fill in card details to proceed');
      }

      const paymentMethodResult = await createPaymentMethod({
        paymentMethodType: 'Card',
      });

      const { paymentMethod } = paymentMethodResult;

      if (!paymentMethod?.id) {
        logMessage(`Payment method not present - ${paymentMethod?.id}`);
        return setErrorMessage('Payment not available, please refresh app.');
      }

      transactionPaymentMethod = paymentMethod.id;

      billingDetails = { name: userName };
    }

    const pendingIntent = await retrievePaymentIntent(
      order.payment.clientSecret
    );

    if (!validIntent(pendingIntent)) {
      logMessage('Unable to complete payment, no valid payment intent present');
      return setErrorMessage('Payment not available, please refresh app.');
    }

    const paymentResult = await confirmPayment(order.payment.clientSecret, {
      paymentMethodType: 'Card',
      paymentMethodData: {
        paymentMethodId: transactionPaymentMethod,
        billingDetails,
      },
    });
    const finalIntent = paymentResult.paymentIntent;

    if (
      paymentResult.error ||
      !finalIntent ||
      finalIntent.status?.toLowerCase() !== 'succeeded'
    ) {
      logError(`Error with payment -', ${paymentResult?.error?.message}`);
      setErrorMessage(
        paymentResult?.error?.toString() ||
          'There was an error submitting payment, please start again or contact support'
      );
      return setProcessing(false);
    }

    if (!validIntent(paymentResult))
      logMessage(
        `Payment went through but, but not completely valid - ${finalIntent.id}`
      );

    return celebrateOrderSuccess();

    // const getOrderResult: any = await dispatch(getOrder({ storeId, orderId }));
    // const completedOrder = getOrderResult?.payload?.order;

    // if (completedOrder) dispatch(setOrderSuccessModal(completedOrder));
    // else {
    //   setErrorMessage(
    //     'Order not confirmed, please restart and check your recent orders.'
    //   );
    //   logError(`Order not read after completing payment - ${orderId}`);
    // }
    // setProcessing(false);
    // return dispatch(clearCart(storeId));
  }, [
    cardComplete,
    userName,
    order?.payment?.clientSecret,
    selectedPaymentMethod,
  ]);

  const celebrateOrderSuccess = useCallback(async () => {
    if (!order) return;

    const orderId = order.id;

    const completedResult: any = await dispatch(
      loadCompletedOrder({ orderId, attemptsLeft: 1 })
    );

    let completedOrder;
    if (completedResult?.payload?.order)
      completedOrder = completedResult?.payload?.order;
    else {
      const result: any = await dispatch(getOrder({ storeId, orderId }));
      completedOrder = result?.payload?.order;
    }

    if (completedOrder) dispatch(setOrderSuccessModal(completedOrder));
    else {
      setErrorMessage(
        'Order not confirmed, please refresh and check your recent orders.'
      );
      logError(`Order not read after completing payment - ${orderId}`);
    }
    setProcessing(false);
    dispatch(unselectCoupon(''));
    dispatch(throttledLoadUserItems(['coupons']));
    return dispatch(clearCart(storeId));
  }, [order, dispatch, getOrder, setOrderSuccessModal, setProcessing]);

  return (
    <StripeProvider
      publishableKey={stripeKey}
      merchantIdentifier='merchant.com.StellarPizza'>
      <>
        <TitleText color='secondary' fontSize={16} mb={26} spacing={0.4}>
          CHECKOUT
        </TitleText>

        {!!order && (
          <NativeApplePay
            order={order}
            setErrorMessage={setErrorMessage}
            orderSuccess={celebrateOrderSuccess}
          />
        )}

        {!!hasPaymentMethods && (
          <SavedPaymentMethods
            paymentMethods={paymentMethods}
            selectedPaymentMethod={selectedPaymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        )}

        {!selectedPaymentMethod && (
          <>
            <TextInput
              value={userName}
              onChangeText={onNameChange}
              style={styles.fieldInput}
              label='Name on card'
              labelLeft={true}
              labelMargin={2}
            />

            {!!nameError && (
              <BodyText color='error' style={styles.nameError}>
                {nameError}
              </BodyText>
            )}

            <CardField
              ref={nameRef}
              style={styles.fieldInput}
              cardStyle={{
                backgroundColor: '#FFFFFF',
                textColor: colors.textDark,
                fontSize: 18,
              }}
              onCardChange={onCardChange}
            />
          </>
        )}

        {!!errorMessage && (
          <BodyText color='error' style={styles.errorMessage} center={true}>
            {errorMessage}
          </BodyText>
        )}

        <Block grow={true} height={20} />

        <Button
          onClick={submitPayment}
          label={!!processing ? '' : 'PAY WITH CARD'}
          style={styles.payButton}>
          {processing && <StarLoader />}
        </Button>
      </>
    </StripeProvider>
  );
};

export default NativePaymentFields;

import React from 'react';
import { connect } from 'react-redux';

import StaticMenuCategoryHeader from './StaticMenuCategoryHeader';
import ScrollingMenuCategoryHeader from './ScrollingMenuCategoryHeader';

interface Props {
  categories: CategoryType[];
  categoryScroll: number;
  clickCategory: (index: number) => void;
  isMobile: boolean;
}

const MenuCategoryHeader = (props: Props) => {
  if (props.categories?.length > 3)
    return <ScrollingMenuCategoryHeader {...props} />;

  if (props.categories?.length > 1)
    return <StaticMenuCategoryHeader {...props} />;
  return null;
};

const mapStateToProps = (state: RootState) => ({
  isMobile: state.appInfo.isMobile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenuCategoryHeader);

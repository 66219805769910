import { StyleSheet, Platform } from 'react-native';

import { colors, globalStyles, isIos } from '../../helpers';

import mobileStyles from './mobileStyles';

const isMobile = ['ios', 'android'].includes(Platform.OS);

const styles = StyleSheet.create({
  cartWrapper: {
    width: 0,
    height: '100%',
    zIndex: 100,
    elevation: 100,
  },
  cartContent: {
    width: 375,
    height: '100%',
    backgroundColor: colors.body,
  },
  emptyCart: {
    ...globalStyles.flexRow,
    width: '100%',
    height: '95%',
  },
  header: {
    ...globalStyles.flexRow,
    width: '100%',
    height: 64,
    backgroundColor: colors.secondary,
  },
  headerContent: {
    ...globalStyles.flexRow,
  },
  closeButton: {
    ...globalStyles.flexRow,
    position: 'absolute',
    width: 70,
    height: '100%',
    right: 0,
  },
  cartError: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 20,
    backgroundColor: colors.error,
  },

  cartList: {
    // flex: isMobile ? 0 : 1,
    flexGrow: 1,
    width: '100%',
    maxWidth: 650,
    // borderLeftWidth: 1,
    // borderRightWidth: 1,
    // borderColor: colors.midLight,
    display: 'flex',
    alignSelf: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingTop: 25,
  },
  innerContent: {
    ...globalStyles.flexColumn,
    flex: 1,
    width: '100%',
    paddingBottom: isIos ? 20 : 0,
  },
  cartButton: {
    position: 'relative',
    width: '91%',
    marginBottom: isMobile ? 20 : 0,
  },
});

export default { ...styles, ...mobileStyles };

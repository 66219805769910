import React, { useCallback } from 'react';
import { Pressable, View, StyleSheet } from 'react-native';

import { linkToMap, colors, globalStyles } from '../helpers';

import { BodyText } from '.';

import { RedLocationPin } from '../assets';

interface Props {
  store: StoreType;
}

const MapButton = ({ store }: Props) => {
  const getMapLink = useCallback(() => {
    if (!store || !store.open) return;
    linkToMap(store);
  }, [store]);

  return (
    <Pressable onPress={() => getMapLink()}>
      <View
        style={[
          styles.mapButton,
          {
            backgroundColor: colors.secondary,
          },
        ]}>
        <RedLocationPin width={16} height={16} />

        <BodyText ml={6} color='white'>
          View on map
        </BodyText>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  mapButton: {
    ...globalStyles.flexRow,
    marginTop: 10,
    width: 150,
    height: 40,
    borderTopWidth: 1,
    borderColor: colors.midLight,
    borderRadius: 4,
  },
});

export default MapButton;

type OptionsType = {
  showDiff?: boolean;
  trim?: boolean;
  lower?: boolean;
};

const isDaylightSavings = (UTCString: string) => {
  const timeDate = new Date(UTCString);
  const jan = new Date(timeDate.getFullYear(), 0, 1);
  const jul = new Date(timeDate.getFullYear(), 6, 1);
  const stdOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  return timeDate.getTimezoneOffset() < stdOffset;
};

export const isPacificTimeZone = () => {
  const now = new Date();
  const localString = now.toLocaleString();
  const pacificString = now.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });

  return localString === pacificString;
};

export const getPacificDay = (UTCString: string) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const offset = isDaylightSavings(UTCString) ? 420 : 480;

  const timeDate = new Date(UTCString);
  const hours = timeDate.getUTCHours();
  const minutes = timeDate.getUTCMinutes() + hours * 60;
  if (minutes < offset) shiftDayBack(timeDate);
  return days[timeDate.getUTCDay()];
};

export const getPacificDateString = (UTCString: string) => {
  const timeDate = new Date(UTCString);
  const pacificString = timeDate.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });

  const secondSlashIdx = pacificString.indexOf(
    '/',
    pacificString.indexOf('/') + 1
  );
  const dateString = pacificString.slice(0, secondSlashIdx);
  return dateString;
};

export const getPacificHours = (UTCString: string) => {
  const timeDate = new Date(UTCString);

  const pacificString = timeDate.toLocaleString('en-GB', {
    timeZone: 'America/Los_Angeles',
  });

  const commaIdx = pacificString.indexOf(',');
  const firstColonIdx = pacificString.indexOf(':');
  const hourString = pacificString.slice(commaIdx + 1, firstColonIdx).trim();
  let hours = parseInt(hourString);
  if (isNaN(hours)) hours = timeDate.getHours();
  return hours;
};

export const getPacificTimeString = (
  UTCString: string = '',
  options: OptionsType = {}
) => {
  if (!UTCString) return '';
  const { trim } = options;

  const timeDate = new Date(UTCString);

  const pacificString = timeDate.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });

  const commaIdx = pacificString.indexOf(',');
  const firstColonIdx = pacificString.indexOf(':');
  const hourString = pacificString.slice(commaIdx + 1, firstColonIdx).trim();
  let hours = parseInt(hourString);
  if (isNaN(hours)) hours = timeDate.getHours();

  const ampm = pacificString.slice(-2).toLowerCase();

  let minutes: string | number = timeDate.getMinutes();
  if (minutes < 10) {
    if (trim) minutes = '';
    else minutes = `:0${minutes}`;
  } else minutes = `:${minutes}`;
  return `${hours}${minutes}${ampm}`;
};

export const getTimeString = (
  UTCString: string = '',
  options: OptionsType = {}
) => {
  if (!UTCString) return '';

  const { showDiff, trim, lower } = options;

  const timeDate = new Date(UTCString);

  const currentTime = new Date().getTime();

  const diff = Math.ceil((timeDate.getTime() - currentTime) / 60000);
  const diffText = diff > 0 && showDiff ? ` (${diff} minutes from now)` : '';

  let hours = timeDate.getHours();
  let ampm = lower ? 'am' : 'AM';

  if (hours >= 12) {
    ampm = lower ? 'pm' : 'PM';
    if (hours > 12) hours -= 12;
  }

  if (hours === 0) hours = 12;

  let minutes: string | number = timeDate.getMinutes();

  if (minutes < 10) {
    if (trim) minutes = '';
    else minutes = `:0${minutes}`;
  } else minutes = `:${minutes}`;
  return `${hours}${minutes}${ampm}${diffText}`;
};

export const getAbsoluteWaitTime = (minutes: number) => {
  const now = Date.now();

  const readyTime = now + minutes * 60 * 1000;

  const readyDate = new Date(readyTime);
  return getTimeString(readyDate.toISOString());
};

export const getTrimTime = (time: string) =>
  getTimeString(time, { trim: true, lower: true });

export const isPastTime = (time: string) => new Date(time) < new Date();

const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];
const months = [
  'january',
  'febuary',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const getDateString = (timestamp?: string) => {
  if (!timestamp) return '';

  const dateObj = new Date(timestamp);

  const day = days[dateObj.getDay()];
  const month = months[dateObj.getMonth()];
  const date = dateObj.getDate();

  return `${day}, ${month} ${date}`;
};

export const getEventFilterDate = () => {
  const dayStart = new Date();
  const hours = dayStart.getUTCHours();
  if (hours < 11) shiftDayBack(dayStart);

  // 4am Pacific is the start of operational day for stellar
  dayStart.setUTCHours(11);
  dayStart.setMinutes(0);
  dayStart.setSeconds(0);

  return dayStart.toISOString();
};

export const shiftDayBack = (date: Date) => {
  let time = date.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  time -= oneDay;
  date.setTime(time);
};

export const getOperationalDate = () => {
  const now = new Date();
  const hours = now.getUTCHours();
  // If UTC hour is between 12am and 4am pacific time, date is one day prior. Stellar operational date goes from 4am - 4am
  if (hours > 7 && hours < 11) shiftDayBack(now);
  return now;
};

import { View, Pressable, Image, StyleSheet } from 'react-native';

import { globalStyles, colors, shadow } from '@app/core/helpers';

const styles = StyleSheet.create({
  comboBox: {
    ...globalStyles.flexColumn,
    marginTop: 10,
    width: 240,
    height: 120,
    marginHorizontal: 10,
    marginBottom: 60,
    borderRadius: 4,
  },
  comboFace: {
    ...globalStyles.flexColumn,
    width: '100%',
    height: '100%',
    paddingLeft: 2,
    backgroundColor: colors.barnRed,
    // borderWidth: 1,
    borderColor: colors.textLight,
    zIndex: 10,
  },
  comboPrice: {
    position: 'absolute',
    top: 2,
    right: 10,
    color: colors.white,
    fontSize: 16,
  },
  pizzaImage: {
    position: 'absolute',
    top: '12%',
    left: '-6%',
    width: '104%',
    height: '104%',
  },
  drinkImage: {
    position: 'absolute',
    top: '37%',
    right: '0%',
    width: 55,
    height: 102,
    // borderWidth: 1,
  },
  dippingImage: {
    position: 'absolute',
    bottom: -23,
    right: '17%',
    width: 56,
    height: 60,
  },
  rightEdge: {
    zIndex: 0,
    position: 'absolute',
    right: '-2%',
    bottom: '-1.2%',
    backgroundColor: colors.text,
    borderRadius: 2,
    borderBottomRightRadius: 4,
    ...shadow({ color: colors.text, radius: 1 }),
  },
  bottomEdge: {
    position: 'absolute',
    bottom: '-3%',
    right: '-1.2%',
    borderRadius: 2,
    borderBottomRightRadius: 4,
    ...shadow({ color: colors.text, radius: 1 }),
    backgroundColor: colors.text,
    zIndex: 0,
  },
});

export default styles;

import React from 'react';
import { useParams, useLocation } from 'react-router-native';

const withStoreId = (Component: typeof React.Component) => {
  return function WrapComponent(props: Record<string, unknown>) {
    const { pathname } = useLocation();
    const { storeId } = useParams();
    const isStoreMenu = pathname.startsWith('/store/');
    return <Component {...props} storeId={isStoreMenu ? storeId : undefined} />;
  };
};

export default withStoreId;

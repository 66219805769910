import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { View, Pressable, ScrollView } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-native';

import { getTimeString, linkToMap } from '../../../helpers';
import {
  setOrderSuccessModal,
  readStores,
  loadCompletedOrder,
  readUser,
  throttledLoadUserItems,
  unselectCoupon,
} from '../../../globalStore';

import {
  TitleText,
  BodyText,
  SocialIcon,
  ReferralSection,
  Block,
  MapButton,
  MapPreview,
} from '../..';

import BaseModal from '../BaseModal';

import {
  Stars,
  MascotBolt,
  Close,
  YellowStars,
  ExternalLink,
} from '../../../assets';
import RedLocationPin from '../../../assets/location-pin.svg';

import styles from './styles';

const OrderSuccessModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [mapError, setMapError] = useState(false);

  const orderSuccessModal = useSelector(
    (state: RootState) => state.appInfo.orderSuccessModal
  );
  const stores: StoreType[] = useSelector(readStores);
  const appConfig = useSelector((state: RootState) => state.appInfo.appConfig);
  const user = useSelector(readUser);

  const clearModal = useCallback(() => {
    dispatch(setOrderSuccessModal(null));
  }, []);

  useEffect(() => {
    if (orderSuccessModal?.id)
      dispatch(
        loadCompletedOrder({ orderId: orderSuccessModal?.id, attemptsLeft: 5 })
      );

    if (orderSuccessModal?.coupon) {
      dispatch(unselectCoupon(''));
      setTimeout(() => dispatch(throttledLoadUserItems(['coupons'])), 2000);
    }

    navigate('/');
  }, []);

  const getMapLink = useCallback(
    (store: StoreType) => {
      if (!orderSuccessModal) return;
      linkToMap(store);
    },
    [stores]
  );
  if (!orderSuccessModal) return null;

  // const handoffText = `${
  //   orderSuccessModal?.handoff || 'PICKUP'
  // } ORDER`.toUpperCase();

  const readyTime = orderSuccessModal?.readyTime || '';

  const pickupTime = useMemo(() => {
    if (!readyTime) return '';
    return getTimeString(readyTime, { showDiff: true });
  }, [readyTime]);

  const storeId: string = orderSuccessModal.storeId || '';
  const store = stores.find((s) => s.id == storeId);

  let addressLines: string[] = [];

  if (store) addressLines = store.address.split(',');

  const showReferral =
    appConfig?.registerReferralEnabled &&
    !!user.publicId &&
    !!store?.showReferrals;

  return (
    <BaseModal onBackdropPress={clearModal}>
      <ScrollView
        style={styles.modalBox}
        showsVerticalScrollIndicator={false}
        testID='order-success-modal'>
        <View style={styles.shade} />
        <View style={styles.header}>
          <Stars width={214} height={130} />
        </View>

        <Pressable
          style={styles.closeButton}
          onPress={clearModal}
          testID='success-modal-close-button'>
          <Close width={100} height={14} />
        </Pressable>

        <View style={styles.body}>
          <View style={styles.background} />
          <View style={styles.content}>
            <View style={styles.boltBox}>
              <MascotBolt width={100} />
            </View>

            <View style={styles.orderInfo}>
              <BodyText
                color='secondary'
                bold={true}
                fontSize={16}
                lineHeight={20}
                mb={8}>
                ORDER NO.{' '}
                <BodyText color='primary' uppercase={true} fontSize={18}>
                  {orderSuccessModal.id.slice(0, 4)}
                </BodyText>
              </BodyText>

              <View style={styles.nameRow}>
                <YellowStars width={50} />
                <TitleText
                  color='primary'
                  fontSize={40}
                  uppercase={true}
                  spacing={0.8}
                  ml={6}
                  center={true}>
                  {orderSuccessModal?.pickupId}
                </TitleText>
                <View style={styles.backwardsBox}>
                  <YellowStars width={50} />
                </View>
              </View>
              {/*
              <TitleText
                color='secondary'
                fontSize={16}
                uppercase={true}
                mt={8}
                spacing={0.5}>
                {handoffText}
              </TitleText> */}

              <BodyText mt={10} color='text' bold={true} spacing={0.5}>
                PICKUP AT
              </BodyText>

              <BodyText fontSize={16} mt={4} color='black'>
                {pickupTime || 'ASAP'}
              </BodyText>
              <>
                {store && (
                  <>
                    <BodyText mt={8} bold={true} color='black' fontSize={18}>
                      {store.name}
                    </BodyText>
                    {/* <BodyText mt={8} fontSize={16}>
                      {addressLines[0]}
                    </BodyText>
                    <BodyText mt={8} fontSize={16}>
                      {addressLines.slice(1).join(', ').trim()}
                    </BodyText> */}
                    {!showReferral && <Block height={20} />}
                    {!mapError ? (
                      <>
                        <View style={styles.mapBox}>
                          <MapPreview
                            store={store}
                            disabled={true}
                            zoomedIn={true}
                            setMapError={setMapError}
                          />
                        </View>
                        <Pressable
                          onPress={() => getMapLink(store)}
                          style={styles.mapLink}>
                          <BodyText
                            fontSize={16}
                            color='secondary'
                            underline={true}
                            mr={5}
                            bold={false}>
                            {store.address.split(',')[0]}
                          </BodyText>
                          <View style={{ marginTop: 2 }}>
                            <ExternalLink width={12} height={40} />
                          </View>
                        </Pressable>
                      </>
                    ) : (
                      <MapButton store={store} />
                    )}
                  </>
                )}
              </>

              {showReferral ? (
                <>
                  <View style={styles.divider} />
                  <ReferralSection />
                </>
              ) : (
                <>
                  <BodyText
                    center={true}
                    mt={20}
                    fontSize={16}
                    color='secondary'>
                    Check out our social for latest updates and upcoming truck
                    locations!
                  </BodyText>
                  <View style={styles.socialBox}>
                    {!!navigator?.share && (
                      <SocialIcon
                        name='share'
                        iconSize='48%'
                        iconColor='body'
                        background='secondary'
                        mr='8%'
                      />
                    )}

                    <SocialIcon
                      name='tiktok'
                      iconSize='54%'
                      iconColor='body'
                      background='secondary'
                      mr='8%'
                    />
                    <SocialIcon
                      name='instagram'
                      iconSize='54%'
                      iconColor='body'
                    />
                  </View>
                </>
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </BaseModal>
  );
};

export default OrderSuccessModal;

import { StyleSheet } from 'react-native';
import { globalStyles } from '../../helpers';

const styles = StyleSheet.create({
  menuItemsWrapper: {
    width: '100%',
    minWidth: 200,
  },
  categoryBox: {
    width: '100%',
    minHeight: 100,
    marginBottom: 30,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 2,
  },
  categoryName: {
    fontSize: 24,
    lineHeight: 24,
    fontWeight: '800',
    color: '#E10000',
    marginBottom: 0,
  },
  categoryDescription: {
    fontSize: 16,
    color: '#776B5F',
  },
  productGrid: {
    width: '100%',
    maxWidth: 900,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: 18,
  },
  mobileGrid: {
    maxWidth: 600,
    justifyContent: 'center',
  },
  productContainer: {
    width: '49%',
    height: 100,
    marginBottom: 12,
    ...globalStyles.noOutline,
  },
  mobileProductContainer: {
    width: '100%',
    marginBottom: 4,
  },
  productBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    paddingVertical: 20,
    paddingHorizontal: 10,
    ...globalStyles.noOutline,
    overflow: 'hidden',
  },
  outOfStockProduct: {
    opacity: 0.8,
  },
  imageBox: {
    marginRight: 14,
  },
  textBox: {
    width: '100%',
  },
  nameBox: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    maxWidth: '60%',
    zIndex: 10,
  },
  productName: {
    position: 'relative',
    fontSize: 18,
    fontWeight: '800',
    marginBottom: 5,
    letterSpacing: 0.4,
    padding: 0,
  },
  mobileProductName: {
    fontSize: 16,
    marginBottom: 2,
  },
  productDescription: {
    fontSize: 14,
    maxWidth: '80%',
  },
  stockImage: {
    position: 'absolute',
    top: 0,
    right: -50,
    transform: [{ translateY: -12 }, { rotate: '20deg' }],
  },
  productPrice: {
    position: 'absolute',
    top: 16,
    right: 15,
    fontSize: 16,
    fontWeight: '800',
    color: '#E10000',
  },
});

export default styles;

import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  radioBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 18,
  },

  firstPill: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  secondPill: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

export default styles;

import React from 'react';
import { View, ViewStyle } from 'react-native';
// @ts-ignore
import Svg, { G, Line } from 'react-native-svg';

import { colors, globalStyles } from '../helpers';

interface Props {
  width?: number;
  color?: string;
  ml?: number;
  bold?: boolean;
  fillBox?: boolean;
}

const Close = ({ width, color, ml, bold, fillBox }: Props) => {
  let fillColor = colors.midDark;
  if (color && colors[color]) fillColor = colors[color];

  let extraStyle: ViewStyle = {};

  if (ml) extraStyle.marginLeft = ml;

  if (fillBox) extraStyle = { ...extraStyle, ...globalStyles.flexRow };

  return (
    <View
      style={[
        {
          width: fillBox ? '100%' : width || 16,
          height: fillBox ? '100%' : width || 16,
        },
        extraStyle,
      ]}>
      <Svg width={width || 16} height={width || 16} viewBox='0 0 16 15'>
        <G
          id='Page-1'
          stroke='none'
          fill='none'
          fillRule='evenodd'
          strokeLinecap='square'>
          <G
            id='newClose'
            transform='translate(1.000000, 1.000000)'
            stroke={fillColor}
            strokeWidth={bold ? 4 : 2}>
            <Line x1='0.5' y1='0.5' x2='12.5' y2='12.5' id='Line'></Line>
            <Line
              x1='0.5'
              y1='0.5'
              x2='12.5'
              y2='12.5'
              id='Line-Copy'
              transform='translate(6.500000, 6.500000) scale(-1, 1) translate(-6.500000, -6.500000) '></Line>
          </G>
        </G>
      </Svg>
    </View>
  );
};

export default Close;

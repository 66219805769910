import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pressable, StyleSheet } from 'react-native';
import { useLocation, useNavigate } from 'react-router-native';
import { MOCK_TESTING } from '@app/core';

import { readUser, clearAllCarts, clearUserData } from '../globalStore';

import { BodyText } from './';

import { useAuth, wipeAllStorage, isIos } from '../helpers';

import { setLogoutRedirect } from '../pages/LoginRedirect';

import { setLoginModal } from '../globalStore';

import { clearMockOrders } from '../mocks';

import { clearGlobalMockMode } from '../App';

interface Props {
  callback?: () => void;
  isMobile?: boolean;
}

const LoginButton = ({ callback, isMobile }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { logoutUser } = useAuth();

  const user = useSelector(readUser);

  const onLogin = () => {
    if (callback) callback();
    dispatch(setLoginModal(true));
  };

  const onLogout = () => {
    wipeAllStorage();
    const { pathname } = location;
    setLogoutRedirect(pathname);
    logoutUser();
    if (MOCK_TESTING || isIos) {
      dispatch(clearUserData());
      dispatch(clearAllCarts());
      clearMockOrders();
      navigate('/logout');
      if (MOCK_TESTING && !window.Cypress) {
        clearGlobalMockMode();
      }
    }
  };

  window.globalLogoutUser = onLogout;

  if (!user.loaded) return null;

  return (
    <Pressable
      onPress={user.token ? onLogout : onLogin}
      style={styles.loginButton}>
      <BodyText color='white' fontSize={isMobile ? 15 : 14} fontWeight='700'>
        {user.token ? 'LOG OUT' : 'LOG IN'}
      </BodyText>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  loginButton: {
    // padding: 5,
  },
});

export default LoginButton;

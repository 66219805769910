import { StyleSheet, Platform } from 'react-native';
import { colors, globalStyles } from '../../helpers';

const footerProperties = {};
const styles = StyleSheet.create({
  redFiller: {
    position: 'absolute',
    width: '100%',
    height: '35%',
    backgroundColor: colors.primary,
  },
  purpleFiller: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '35%',
    backgroundColor: colors.secondary,
    zIndex: -1,
  },
  homeWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // height: '80%',
    // top: '20%',
    overflow: 'visible',
    zIndex: 10,
  },
  header: {
    width: '100%',
    height: 700,
    backgroundColor: colors.primary,
    display: 'flex',
  },
  starryNight: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 500,
  },
  background: {
    position: 'absolute',
    top: '0%',
    flex: 1,
    // minHeight: Platform.OS === 'web' ? '100%' : '300%',
    width: '100%',
    backgroundColor: colors.body,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    maxHeight: 1100,
  },
  logoBox: {
    ...globalStyles.flexRow,
    top: 136,
    // top: -90,
    // bottom: 90,
  },
  welcomeBox: {
    top: -50,
  },

  contentWrapper: {
    backgroundColor: colors.body,
  },
  openOrdersBox: {
    alignSelf: 'center',
    width: 600,
    maxWidth: '90%',
    bottom: 24,
    marginBottom: 14,
    overflow: 'hidden',
  },
  mobileOrdersBox: {
    maxWidth: '100%',
    width: '100%',
    paddingHorizontal: 10,
  },
  openOrder: {
    width: '100%',
    paddingHorizontal: 20,
    backgroundColor: colors.white,
    marginBottom: 6,
  },
  buttonText: {
    paddingVertical: 10,
  },
  orderArrowBox: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    position: 'absolute',
    right: '5%',
    height: '100%',
    top: 0,
  },
  userCouponsBox: {
    ...globalStyles.flexColumn,
    alignSelf: 'center',
    width: 700,
    maxWidth: '90%',
    // bottom: 14,
    overflow: 'hidden',
  },
  mobileCouponsBox: {
    maxWidth: '100%',
    width: '100%',
  },
  couponExtraBox: {
    width: '100%',
    paddingHorizontal: 20,
    backgroundColor: colors.secondary,
    marginBottom: 6,
  },
  // Buttons
  special: {
    borderWidth: 10,
    borderColor: 'hotpink',
  },
  buttonBox: {
    ...globalStyles.flexColumn,
    alignSelf: 'center',
    width: 460,
    maxWidth: '100%',
    paddingHorizontal: 10,
    minHeight: 200,
    marginTop: 0,
    // blurRadius: 20,
  },
  mobileButtonBox: {
    marginTop: 20,
  },
  referralButton: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    width: '100%',
    height: 70,
    borderRadius: 6,
    backgroundColor: colors.darkBlue,
    marginBottom: 10,
  },
  button: {
    ...globalStyles.flexRow,
    width: '100%',
    height: 160,
    borderRadius: 10,
    // borderBottomLeftRadius: 10,
    // borderBottomRightRadius: 10,
    marginTop: 0,
    overflow: 'hidden',
  },
  secondaryButton: {
    width: '50%',
    height: 180,
    paddingTop: 24,
    flex: 1,
  },
  buttonRow: {
    ...globalStyles.flexRow,
    marginTop: 10,
  },
  mobileButton: {
    height: 150,
    marginTop: 0,
  },
  buttonContent: {
    justifyContent: 'center',
    width: '72%',
    height: '100%',
    backgroundColor: colors.secondary,
  },
  newContent: {
    ...globalStyles.flexRow,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    // backgroundColor: colors.pink,
  },
  buttonLabel: {
    ...globalStyles.flexRow,
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    paddingTop: 12,
    paddingVertical: 10,
    lineHeight: 40,
    // backgroundColor: colors.barnRed,
  },
  profileLoader: {
    ...globalStyles.flexRow,
    position: 'absolute',
    width: '100%',
    top: 5,
    height: 20,
  },
  triangle: {
    position: 'absolute',
    right: -82,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 60,
    borderBottomWidth: 60,
    borderLeftWidth: 60,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colors.secondary,
    borderLeftColor: 'transparent',
    transform: [{ rotate: '90deg' }],
  },
  buttonImage: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-end',
    width: '36%',
    height: '100%',
    backgroundColor: colors.barnRed,
    zIndex: -1,
  },
  mobileButtonImage: {},
  newImage: {
    position: 'absolute',
    left: 0,
    // top: 0,
    width: '100%',
    height: '100%',
    borderRadius: 10,
    // overflow: 'hidden',
    zIndex: -1,
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    bottom: 0,
    height: '40%',
    backgroundColor: colors.secondary,
    overflow: 'hidden',
    width: '100%',
  },
  footer: {
    position: 'relative',
    width: '100%',
    height: 160,
  },
  mobileFooter: {
    // minHeight: 300,
    // marginTop: 70,
  },
  footerCover: {
    position: 'absolute',
    width: '100%',
    top: 0,
    height: 2000,
    backgroundColor: colors.secondary,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    // opacity: 0,
  },
  footerBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: colors.secondary,
  },
  socialBox: {
    ...globalStyles.flexRow,
    position: 'absolute',
    alignSelf: 'center',
    // height: '100%',
  },
  mobileSocialBox: {},
  socialLink: {
    ...globalStyles.flexRow,
    // marginLeft: 40,
    width: 67,
    height: 67,
    backgroundColor: colors.body,
    borderRadius: 50,
  },
  socialIcon: {
    width: '54%',
    height: '54%',
  },
  copy: {
    position: 'absolute',
    left: 20,
    bottom: 24,
  },
  puppy: {
    position: 'absolute',
    // top: 100,
    alignSelf: 'center',
    width: 140,
    zIndex: 2,
  },
  phantomBox: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 160,
    zIndex: 20,
    opacity: 0,
  },
});

export default styles;

import { Platform } from 'react-native';

import { isDev } from '../getEnvVariable';

import { initWebLogger, logWebError, logWebMessage } from './webSentryLogger';

import {
  initNativeLogger,
  logNativeMessage,
  logNativeError,
} from './nativeSentryLogger';

const isWeb = Platform.OS === 'web';

export const initSentry = () => {
  if (isDev) return;
  if (isWeb) initWebLogger();
  else initNativeLogger();
};

export const logMessage = (message: string) => {
  if (isDev) return console.info(message);
  if (!!isWeb) logWebMessage(message);
  else if (!isWeb) logNativeMessage(message);
};

export const logError = (error: Error | string) => {
  const actualError = typeof error === 'string' ? Error(error) : error;

  if (isDev) return console.error(actualError);
  if (isWeb) logWebError(actualError);
  else logNativeError(actualError);
};

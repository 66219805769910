import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-native';

import { colors, globalStyles } from '../../helpers';
import { setNewCouponModal } from '../../globalStore';

import {
  BodyText,
  TitleText,
  ReferralSection,
  Block,
  CouponTag,
  Button,
} from '..';

import BaseModal from './BaseModal';

import { Stars, MascotBolt, Close } from '../../assets';

const NewCouponModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newCouponModal = useSelector(
    (state: RootState) => state.appInfo.newCouponModal
  );

  const clearModal = useCallback(() => {
    dispatch(setNewCouponModal());
  }, []);

  return (
    <BaseModal onBackdropPress={clearModal}>
      <View style={styles.modalBox}>
        <View style={styles.header}>
          <Stars width={214} height={130} />
        </View>

        <Pressable style={styles.closeButton} onPress={clearModal}>
          <Close width={100} height={14} />
        </Pressable>

        <View style={styles.body}>
          <View style={styles.background} />
          <View style={styles.content}>
            <View style={styles.boltBox}>
              <MascotBolt width={100} />
            </View>
            <Block height={20} />
            <BodyText
              fontSize={20}
              color='secondary'
              bold={true}
              center={true}
              mb={6}>
              Incoming Coupon Detected!
            </BodyText>

            <BodyText mb={28} center={true} mr={10} ml={10}>
              Looks like you got a hot tip for some delicous pizza! You have a
              coupon offer available, proceed forward with an order to see it
              get applied.
            </BodyText>

            {!!newCouponModal && <CouponTag coupon={newCouponModal} />}

            <Block height={28} />
            <Button label='GOT IT' onClick={clearModal} />
          </View>
        </View>
      </View>
    </BaseModal>
  );
};

export default NewCouponModal;

const styles = StyleSheet.create({
  modalBox: {
    width: 368,
    height: 500,
    borderRadius: 24,
    backgroundColor: colors.secondary,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 10,
  },
  header: {
    ...globalStyles.flexRow,
    paddingLeft: 15,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    elevation: 2,
  },
  body: {
    flex: 1,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.body,
  },
  content: {
    ...globalStyles.flexColumn,
    flex: 1,
    bottom: 88,
  },
  boltBox: {
    left: 18,
    marginBottom: 10,
  },
});

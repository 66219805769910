import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetch,
  writeStorage,
  readStorage,
  clearStorageItem,
  isUUID,
} from '../helpers';

type State = {
  appConfig: AppConfigType | null;
  isMobile: boolean;
  screenSize: { width: number; height: number };
  showNameNeededModal: boolean;
  showScheduleUpsell: boolean;
  orderSuccessModal: OrderType | null;
  showLoginModal: boolean;
  updateRequiredModal: VersionType | null;
  showAccountDeletionModal: boolean;
  showReferralModal: boolean;
  referralRedeemedModal: UserCouponType | null;
  newCouponModal: CouponType | null;
  urlTags: string[];
};

const initialState: State = {
  appConfig: null,
  isMobile: false,
  screenSize: { width: 0, height: 0 },
  showNameNeededModal: false,
  showScheduleUpsell: false,
  orderSuccessModal: null,
  showLoginModal: false,
  updateRequiredModal: null,
  showAccountDeletionModal: false,
  showReferralModal: false,
  referralRedeemedModal: null,
  newCouponModal: null,
  urlTags: [],
};

const appInfoSlice = createSlice({
  name: 'appInfo',
  initialState,
  reducers: {
    setScreenSize: (state, { payload }) => {
      state.screenSize = { width: payload.width, height: payload.height };
      state.isMobile = payload.isMobile;
    },
    setNameNeededModal: (state, { payload }: { payload: boolean }) => {
      state.showNameNeededModal = payload || false;
    },
    setScheduleUpsellModal: (state, { payload }: { payload: boolean }) => {
      state.showScheduleUpsell = payload || false;
    },
    setLoginModal: (state, { payload }: { payload: boolean }) => {
      state.showLoginModal = payload || false;
    },
    setAccountDeletionModal: (state, { payload }: { payload: boolean }) => {
      state.showAccountDeletionModal = payload || false;
    },
    setReferralModal: (state, { payload }: { payload: boolean }) => {
      state.showReferralModal = payload || false;
    },
    setReferralRedeemedModal: (
      state,
      { payload }: { payload: UserCouponType | undefined }
    ) => {
      state.referralRedeemedModal = payload || null;
    },
    setNewCouponModal: (
      state,
      { payload }: { payload: CouponType | undefined }
    ) => {
      state.newCouponModal = payload || null;
    },
    setOrderSuccessModal: (
      state,
      { payload }: { payload: OrderType | null }
    ) => {
      if (!payload) {
        state.orderSuccessModal = null;
        return;
      }

      const successOrder = {
        pickupId: payload.pickupId,
        readyTime: payload.readyTime,
        storeId: payload.storeId,
        id: payload.id,
        state: payload.state,
        handoff: payload.handoff,
        coupon: payload.coupon,
      };

      state.orderSuccessModal = successOrder;
    },
    updateOrderSuccessModal: (state, { payload }: { payload: OrderType }) => {
      if (state.orderSuccessModal)
        state.orderSuccessModal.readyTime = payload.readyTime;
    },
    setUpdateRequiredModal: (state, { payload }: { payload: VersionType }) => {
      state.updateRequiredModal = payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadAppConfiguration.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.appConfig = payload;
    });
    builder.addCase(loadUrlTags.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.urlTags = payload;
    });
  },
});

export const loadUrlTags = createAsyncThunk(
  'app/loadTags',
  async (tagString: string = '', { rejectWithValue }) => {
    const tags = tagString.split(',').filter((t) => isUUID(t));
    if (tags.length) {
      const tagStorage = {
        timeStamp: Date.now(),
        tags,
      };
      writeStorage('tagStorage', tagStorage);
      return tags;
    } else {
      const tagStorage = await readStorage('tagStorage');
      if (tagStorage && tagStorage.timeStamp) {
        const timeDiff = Date.now() - tagStorage.timeStamp;
        const twelveHours = 1000 * 60 * 60 * 12;
        if (timeDiff < twelveHours) {
          return tagStorage.tags;
        } else {
          clearStorageItem('tagStorage');
          return rejectWithValue(false);
        }
      }
    }
  }
);

export const loadAppConfiguration = createAsyncThunk(
  'app/loadConfiguration',
  async () => {
    const appConfig = await fetchAppConfiguration();
    if (appConfig.referralCopyText !== undefined) return appConfig;
  }
);

const fetchAppConfiguration = async () => {
  const url = '/customer/app_configuration/';
  const res = await fetch(url);
  const { app_configuration } = res;
  const appConfig = {
    registerReferralEnabled: app_configuration.new_customer_referral_enabled,
    referralTitleText:
      app_configuration.new_customer_referral_hook_title ||
      'Get a pizza discount',
    referralCopyText:
      app_configuration.new_customer_referral_hook_message ||
      `Share with a friend and you'll both get some free pizza!  When they sign up with your referral link and complete an order, we'll send a coupon for a free pizza your way.`,
    referralShareText:
      app_configuration.new_customer_referral_share_message ||
      `You've been invited to join Stellar and get some free pizza!`,
  };
  return appConfig;
};

export default appInfoSlice;

import getEnvVariable from '../helpers/getEnvVariable';

const domain = getEnvVariable('REACT_APP_AUTH0_DOMAIN');
const clientId = getEnvVariable('REACT_APP_AUTH0_NATIVE_CLIENT_ID');
const audience = getEnvVariable('REACT_APP_AUTH0_AUDIENCE');

const nativeConfig = {
  domain,
  clientId,
  audience,
};

export default nativeConfig;

import { StyleSheet } from 'react-native';
import { colors, globalStyles } from '../../../helpers';

const styles = StyleSheet.create({
  orderBox: {
    width: 600,
    maxWidth: '100%',
    alignSelf: 'center',
    minHeight: 100,
    flex: 1,
    paddingBottom: 100,
  },
  mobileOrderBox: {
    maxHeight: '70%',
    paddingBottom: 100,
  },
  closeButton: {
    ...globalStyles.flexRow,
    position: 'absolute',
    top: 10,
    right: 10,
    width: 70,
    height: 70,
    // padding: 20,
    zIndex: 100,
  },
  section: {
    paddingVertical: 40,
    backgroundColor: colors.white,
    marginBottom: 3,
    marginTop: 3,
  },
  orderItem: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 12,
    marginBottom: 3,
    backgroundColor: colors.white,
  },
  itemQuantity: {
    ...globalStyles.flexRow,
    width: 36,
    height: 36,
    borderRadius: 25,
    backgroundColor: colors.body,
    marginRight: 10,
  },
  productBox: {
    width: '80%',
  },
  choiceList: {
    ...globalStyles.flexRow,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  infoBox: {
    backgroundColor: colors.body,
    padding: 12,
    marginHorizontal: 10,
    marginBottom: 4,
  },

  mapBox: {
    marginTop: 10,
    height: 160,
    width: '100%',
  },
  mapLink: {
    ...globalStyles.flexRow,
    backgroundColor: colors.white,
    height: 35,
    width: '100%',
  },

  priceRow: {
    ...globalStyles.flexRow,
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 6,
  },
});

export default styles;

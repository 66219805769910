import React, { createContext, useContext } from 'react';
import Auth0, {
  Auth0Provider,
  useAuth0,
  SaveCredentialsParams,
} from 'react-native-auth0';
// @ts-ignore
import credentials from './native-auth0-configuration.ts';

import getEnvVariable from '../helpers/getEnvVariable';
import { logMessage } from '../helpers/sentryLogger';

const domain = getEnvVariable('REACT_APP_AUTH0_DOMAIN');
const clientId = getEnvVariable('REACT_APP_AUTH0_NATIVE_CLIENT_ID');
const audience = getEnvVariable('REACT_APP_AUTH0_AUDIENCE');

export const NativeAuthContext = createContext<{
  emailLogin: () => void;
  logoutUser: () => void;
  sendPhoneCode: (number: string) => any;
  submitVerificationCode: (number: string, code: string) => Promise<string>;
  parseCode: () => void;
  getToken: () => Promise<string>;
}>({
  emailLogin: () => undefined,
  logoutUser: () => undefined,
  getToken: async () => '',
  sendPhoneCode: (_number: string) => undefined,
  submitVerificationCode: async (_number: string, _code: string) => '',
  parseCode: () => undefined,
});

interface Props {
  children: React.ReactNode;
  getCredentials: (scope?: string) => Promise<{ accessToken: any }>;
}

const scope = 'profile openid phone';

class NativeAuthProvider extends React.Component<Props> {
  authClient: Auth0 | null = null;

  componentDidMount(): void {
    this.loadClient();
  }

  loadClient = async () => {
    this.authClient = new Auth0(credentials);
  };

  emailLogin = () => {
    if (!this.authClient) return console.error('NO AUTH CLIENT HERE');
    this.authClient.webAuth
      .authorize({
        scope: 'openid profile email',
      })
      .then((_credentials) => {
        // setAccessToken(credentials.accessToken);
      })
      .catch((error) => console.error('oh no! -', error));
  };

  logoutUser = async () => {
    const logoutAnswer =
      await this.authClient?.credentialsManager.clearCredentials();
  };

  sendPhoneCode = async (phoneNumber: string) => {
    const formattedNumber = '+1' + phoneNumber;
    try {
      const result = await this.authClient?.auth.passwordlessWithSMS({
        phoneNumber: formattedNumber,
      });
      return {};
    } catch (e) {
      return { error: 'Sending phone code failed' };
    }

    return {};
  };

  submitVerificationCode = async (
    phoneNumber: string,
    verificationCode: string
  ) => {
    const formattedNumber = '+1' + phoneNumber;
    let success = false;
    let codeError = '';
    await this.authClient?.auth
      .loginWithSMS({
        phoneNumber: formattedNumber,
        code: verificationCode,
        audience,
        scope,
      })
      .then(async (result) => {
        const savedCredents = result as SaveCredentialsParams;
        const saved =
          this.authClient?.credentialsManager.saveCredentials(savedCredents);
        if (saved) success = true;
      })
      .catch((error) => {
        codeError = error.message || error;
      });

    if (!success && !!codeError) return codeError;
    return 'success';
  };

  getToken = async () => {
    const { accessToken } = await this.props.getCredentials(scope);

    // For some reason the hook is more dependable
    // const credentials =
    //   await this.authClient?.credentialsManager.getCredentials(
    //     'openid profile email  address phone'
    //   );

    if (accessToken) return accessToken;

    const isLoggedIn =
      await this.authClient?.credentialsManager.hasValidCredentials();

    if (!accessToken && !!isLoggedIn)
      logMessage('Did not get access token for logged in user');

    return '';
  };

  parseCode = () => undefined;

  render() {
    return (
      <NativeAuthContext.Provider
        value={{
          emailLogin: this.emailLogin,
          logoutUser: this.logoutUser,
          getToken: this.getToken,
          sendPhoneCode: this.sendPhoneCode,
          submitVerificationCode: this.submitVerificationCode,
          parseCode: this.parseCode,
        }}>
        {this.props.children}
      </NativeAuthContext.Provider>
    );
  }
}
export const useNativeAuth = () => {
  const context = useContext(NativeAuthContext);
  if (!context) throw new Error('useAuth must be used within WebAuthProvider.');
  return context;
};

const withCredentials = (Component: typeof React.Component) => {
  return function WrapComponent(props: Record<string, unknown>) {
    const { getCredentials } = useAuth0();
    return <Component {...props} getCredentials={getCredentials} />;
  };
};

const NativeProvider = withCredentials(NativeAuthProvider);

const NativeAuthWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Auth0Provider domain={domain} clientId={clientId}>
      <NativeProvider>{children}</NativeProvider>
    </Auth0Provider>
  );
};

export default NativeAuthWrapper;

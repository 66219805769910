import React from 'react';
import { View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { colors, globalStyles } from '../helpers';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const Star = ({ width, height, color }: Props) => {
  let fillColor = colors.white;
  if (color && colors[color]) fillColor = colors[color];

  return (
    <Svg
      width={width || 16}
      height={height || width || 16}
      viewBox='0 0 4 4'
      fill='none'>
      <Path
        d='M1.81657 0.146062L1.68734 0.736567C1.58312 1.21231 1.21209 1.58372 0.736842 1.68805L0.146952 1.81742C-0.0489838 1.86124 -0.0489838 2.13876 0.146952 2.18258L0.736842 2.31195C1.21209 2.41628 1.58312 2.78769 1.68734 3.26343L1.81657 3.85394C1.83742 3.95201 1.91871 4 2 4C2.08129 4 2.1605 3.95201 2.18343 3.85394L2.31266 3.26343C2.41688 2.78769 2.78791 2.41628 3.26316 2.31195L3.85305 2.18258C4.04898 2.13876 4.04898 1.86124 3.85305 1.81742L3.26316 1.68805C2.78791 1.58372 2.41688 1.21231 2.31266 0.736567L2.18343 0.146062C2.16258 0.0479918 2.08129 0 2 0C1.91871 0 1.83742 0.0500784 1.81657 0.146062Z'
        fill={fillColor}
      />
    </Svg>
  );
};

export default Star;

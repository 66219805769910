import {
  // pizzas
  cheesePizza,
  meatLoversPizza,
  pepperoniPizza,
  sausageAndPeppPizza,
  supremePizza,
  veggiePizza,
  byoPizza,
  cheesyBread,
  // dips
  ranch,
  jalapenoRanch,
  bbq,
  bleuCheese,
  buffalo,
  hotHoney,
  //drinks,
  coke,
  dietCoke,
  cokeZero,
  sprite,
  liquidDeathMountain,
  liquidDeathSparkling,
  bloodOrange,
  redBull,
  rootBeer,
  capriSun,
  drPepper,
  fanta,
  sunnyD,
  // merch,
  stellarCap,
  stellarTee,
  stellarHoodie,
  // extra,
  lactaidPills,
  // icons,
  pizzaIcon,
  drinkIcon,
  dipIcon,
  generalProductIcon,
} from '../assets';

const productImageLibrary: { [key: string]: any } = {
  // pizzas
  'cheese-pizza': cheesePizza,
  'build-your-own-cheesy-bread': cheesyBread,
  'byo-cheesy-bread': cheesyBread,
  'cheesy-bread-byo': cheesyBread,
  'pepperoni-pizza': pepperoniPizza,
  'sausage-and-pepperoni-pizza': sausageAndPeppPizza,
  'veggie-pizza': veggiePizza,
  'supreme-pizza': supremePizza,
  'meat-lovers-pizza': meatLoversPizza,
  'build-your-own-pizza': byoPizza,
  'cheesy-bread': cheesyBread,
  // dipping sauces
  ranch,
  'jalapeno-ranch': jalapenoRanch,
  'bleu-cheese': bleuCheese,
  'buffalo-sauce': buffalo,
  bbq,
  'mikes-hot-honey': hotHoney,
  // drinks
  coke,
  'diet-coke': dietCoke,
  'coke-zero': cokeZero,
  sprite,
  'liquid-death-mountain-water': liquidDeathMountain,
  'liquid-death-sparking-water': liquidDeathSparkling,
  'san-pellegrino-sparkling-orange': bloodOrange,
  'red-bull': redBull,
  'root-beer': rootBeer,
  'capri-sun': capriSun,
  'dr-pepper': drPepper,
  fanta: fanta,
  'sunny-D': sunnyD,
  // merch
  'stellar-cap': stellarCap,
  'stellar-tee': stellarTee,
  'stellar-hoodie': stellarHoodie,
  // extra
  'lactaid-caplets': lactaidPills,
};

const categoryIcons: { [key: string]: any } = {
  pizzas: pizzaIcon,
  dips: dipIcon,
  drinks: drinkIcon,
};

const getProductImage = (product: ProductType) => {
  const productImage = productImageLibrary[product.slug];
  if (productImage) return productImage;

  if (product.category)
    return categoryIcons[product.category.toLowerCase()] || generalProductIcon;

  return generalProductIcon;
};

export default getProductImage;

import { isDesktop, colors } from '../../../helpers';

export type ConfigType = {
  bold: boolean;
  fontSize: number;
  lineHeight: number;
  color: string;
  center: boolean;
  text: string[];
  backgroundColor: string;
};

interface Params {
  openStoreOrders?: FilledOrderType[];
  userCouponCount?: number;
  selectedCoupon?: UserCouponType | CouponType;
  referrerId?: string | null;
  registerReferralEnabled?: boolean;
}
const bubbleConfig = ({
  openStoreOrders,
  userCouponCount,
  selectedCoupon,
  registerReferralEnabled,
  referrerId,
}: Params) => {
  const hasOpenOrders = !!openStoreOrders && openStoreOrders?.length > 0;
  const hasReadyOrders = openStoreOrders?.some(
    (o) => o.state === 'ready_for_handoff'
  );
  const ordersCompleted =
    hasOpenOrders && openStoreOrders.every((o) => o.state === 'completed');

  const config = {
    bold: true,
    fontSize: 14,
    lineHeight: 16,
    color: 'black',
    center: true,
    text: ['LOOKING FOR SOME HOT STUFF?'],
    backgroundColor: colors.dough,
  };

  if (hasOpenOrders) {
    let text = `PREPARING YOUR ORDER${openStoreOrders.length > 1 ? 'S' : ''}`;
    if (hasReadyOrders) text = 'PIZZA TIME!';
    else if (ordersCompleted)
      text = `YOUR RECENT ORDER${openStoreOrders.length > 1 ? 'S' : ''}`;
    config.fontSize = 16;
    config.text = [text];
    config.color = 'primary';
    config.backgroundColor = colors.yellow;
  } else if (!!selectedCoupon) {
    const text = `You have a coupon applied, ${
      isDesktop ? 'click' : 'tap'
    } Order Now to get started!`;
    config.fontSize = 16;
    config.text = [text];
  } else if (userCouponCount) {
    const text1 =
      userCouponCount > 1
        ? 'You have coupons available!'
        : '  You have a coupon available!';

    const text2 = `View Coupon${userCouponCount > 1 ? 's' : ''}`;
    config.lineHeight = 20;
    config.fontSize = 16;
    config.color = 'secondary';
    config.text = [text1, text2];
  } else if (!!referrerId && registerReferralEnabled) {
    const text1 = 'You have a referral coupon waiting!';
    const text2 = 'Register Coupon';
    config.fontSize = 16;
    config.color = 'secondary';
    config.text = [text1, text2];
  }
  return config;
};

export default bubbleConfig;

import React from 'react';
import { useNavigate } from 'react-router-native';

const withNavigate = (Component: typeof React.Component) => {
  return function WrapComponent(props: Record<string, unknown>) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
};

export default withNavigate;

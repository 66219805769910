import React from 'react';
import BodyText from '../../BodyText';

interface TextProps {
  children: React.ReactNode;
}
const BoldText = ({ children }: TextProps) => {
  return (
    <BodyText fontSize={20} lineHeight={22} color='primary' bold={true}>
      {' '}
      {children}{' '}
    </BodyText>
  );
};
const descriptionLibrary = [
  <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
    A staple of Stellar pizza for a reason. Welcome to the hot & sweet life.
  </BodyText>,
  <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
    Clean and classic, you know what's good. Stay hydrated and kick up the
    flavor.
  </BodyText>,
  // <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
  //   A staple of Stellar Pizza,
  //   <BoldText>sizzling Pepperoni</BoldText>
  //   with
  //   <BoldText>sweet Hot Honey.</BoldText> Add a
  //   <BoldText> delicious Coke</BoldText>
  //   and yum.
  // </BodyText>,
  <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
    You know you want it. Decadent and delicious, we're not messing around.
  </BodyText>,
  // <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
  //   You know you want it,
  //   <BoldText>Meat Lovers Pizza</BoldText>
  //   with a rich<BoldText>Jalapeno Ranch</BoldText> dipping sauce. And dont call
  //   it a diet, having a<BoldText> Coke Zero</BoldText> is just good sense.
  // </BodyText>,
  <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
    No holding back. Here your toppings have toppings, let's live it up.
  </BodyText>,

  // <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
  //   No holding back, go big or go home. On this
  //   <BoldText>supreme pie</BoldText>
  //   your toppings have toppings. Smother in
  //   <BoldText>creamy ranch</BoldText> and pair with a delightful
  //   <BoldText> Blood Orange Pellegrino</BoldText>
  // </BodyText>,
  // <BodyText center={true} color='white' fontSize={18} lineHeight={22}>
  //   Blaze your own trail 😎.
  //   <BoldText>Take a Classic Cheese Pie, </BoldText>
  //   pour on the <BoldText>bleu cheese,</BoldText>and slam down some
  //   <BoldText>liquid death</BoldText>
  // </BodyText>,
];

interface Props {
  id: number;
}
const ComboDescription = ({ id }: Props) => {
  const description = descriptionLibrary[id];
  if (description) return description;

  return (
    <BodyText center={true} color='white' fontSize={18}>
      pizza is delicious, enough said
    </BodyText>
  );
};

export default ComboDescription;

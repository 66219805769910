export default class Stripe {}

export const useStripe = () => {
  return null;
};

export const CardField = () => {
  return null;
};

export const StripeProvider = () => {
  return null;
};

export const useApplePay = () => ({
  isApplePaySupported: null,
  presentApplePay: null,
  confirmApplePayPayment: null,
});

export const ApplePayButton = () => null;

import { NativeModules, Linking, Platform } from 'react-native';

import { logMessage, logError } from './sentryLogger';

import { iOSWebDevice, androidWebDevice } from './platform';
const { StatusBarManager } = NativeModules;

export const statusBarHeight = StatusBarManager?.HEIGHT || 0;

export const uuid = () => {
  const _p8 = (s = false) => {
    const p = (Math.random().toString(16) + '000000000').substr(2, 8);
    return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
  };
  return _p8() + _p8(true) + _p8(true) + _p8();
};

export const isUUID = (id: string) => {
  return id.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
};

export const linkToMap = (store?: StoreType) => {
  if (!store) return;

  const lat = store.latitude;
  const long = store.longitude;

  const coordinates = !!lat && !!long ? `${lat}, ${long}` : store.address;
  navigateToMap(coordinates);
};

export const eventLinkToMap = (event: StoreEventType) => {
  if (!event.address) return;
  navigateToMap(event.address);
};

const navigateToMap = (coordinates: string) => {
  const iOSDevice =
    window.navigator?.userAgent?.match(/iPhone|iPad|iPod/i) ||
    Platform.OS === 'ios';

  const mapLink = `https://maps.google.com/?q=${coordinates}`;
  const appleLink = `maps:https://maps.google.com/?q=${coordinates}`;

  const actualLink = iOSDevice ? appleLink : mapLink;

  Linking.canOpenURL(actualLink).then((supported) => {
    if (supported) Linking.openURL(actualLink);
    else {
      logMessage(`Map link not supported - ${actualLink}`);
      if (mapLink !== actualLink) Linking.openURL(mapLink);
      console.error('Linking not supported');
    }
  });
};

export const goToUrl = (link: string) => {
  if (!link) return;
  Linking.canOpenURL(link).then((supported) => {
    if (supported) Linking.openURL(link);
    else console.error('Linking not supported');
  });
};

let isOtherDeviceLogged = false;

export const getOrderSource = () => {
  if (Platform.OS === 'ios') return 'customer/native-ios';
  if (Platform.OS === 'android') return 'customer/native-android';
  if (Platform.OS === 'web') {
    if (iOSWebDevice) return 'customer/web-ios';
    if (androidWebDevice) return 'customer/web-android';
    if (!isOtherDeviceLogged) {
      logMessage('Web Device not detected - ' + window.navigator.userAgent);
      isOtherDeviceLogged = true;
    }
    return 'customer/web-other';
  }

  logError(
    `Order source not correctly detected - ${window.navigator.userAgent} - ${Platform.OS}`
  );

  return 'customer/web-other';
};

import React from 'react';
import { View, FlatList, Pressable, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

import { BodyText } from '..';

import { globalStyles, colors } from '../../helpers';

interface Props {
  categories: CategoryType[];
  categoryScroll: number;
  clickCategory: (index: number) => void;
  isMobile: boolean;
  screenWidth: number;
}

class ScrollingMenuCategoryHeader extends React.Component<Props> {
  linkRefs: Array<any> = this.props.categories.map(() => null);
  flatRef: any | null = null;

  currentScroll = 0;
  scrollSequence = 0;

  componentDidUpdate(prevProps: Props) {
    if (prevProps.categoryScroll !== this.props.categoryScroll)
      this.getOffset();
  }

  getOffset = async () => {
    this.scrollSequence += 1;
    const { categoryScroll, screenWidth } = this.props;
    let offset = null;

    if (categoryScroll === 0) offset = 0;
    else {
      await new Promise((res) => {
        this.linkRefs[categoryScroll]?.measureInWindow(
          (x: any, y: any, width: any) => {
            const end = x + width;
            if (end > screenWidth * 0.8)
              offset = this.currentScroll + (end - screenWidth * 0.8);
            else if (x < 0.3 * screenWidth) {
              if (x < 0)
                offset = this.currentScroll - (Math.abs(x) + 0.3 * screenWidth);
              else offset = this.currentScroll - (0.3 * screenWidth - x);
            }
            res(true);
          }
        );
      });
    }

    if (offset !== null) {
      this.scrollSequence += 1;
      this.scrollTo(offset, this.scrollSequence);
    }
  };

  scrollTo = (desiredOffset: number, currentSequence: number) => {
    if (this.flatRef) {
      setTimeout(() => {
        if (currentSequence === this.scrollSequence) {
          this.flatRef.scrollToOffset({
            offset: desiredOffset,
            animated: true,
          });
        }
      }, 100);
    }
  };

  onScroll = (event: any) => {
    const newScroll = event.nativeEvent?.contentOffset?.x;
    if (newScroll >= 0) this.currentScroll = newScroll;
  };

  render() {
    const { categories, categoryScroll, clickCategory, isMobile } = this.props;

    const linkPadding = isMobile ? 29 : 50;
    const linkMargin = isMobile ? 10 : 15;
    return (
      <>
        <FlatList
          data={categories}
          onScroll={this.onScroll}
          horizontal={true}
          contentContainerStyle={
            isMobile ? [] : [globalStyles.flexRow, { width: '100%' }]
          }
          style={[
            styles.flatlist,
            {
              width: isMobile ? '100%' : 'auto',
            },
          ]}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item, index }) => (
            <Pressable
              ref={(r) => (this.linkRefs[index] = r)}
              onPress={() => {
                clickCategory(index);
              }}
              style={[
                styles.scrollingLink,
                {
                  marginLeft: linkMargin,
                  marginRight:
                    index === categories.length - 1 ? 30 : linkMargin / 2,
                  paddingHorizontal: linkPadding,
                  backgroundColor:
                    index === categoryScroll ? colors.primary : colors.black,
                  minWidth: isMobile ? 100 : 150,
                },
              ]}>
              <BodyText color='white' uppercase={true}>
                {item.name}
              </BodyText>
            </Pressable>
          )}
          ref={(r) => (this.flatRef = r)}
        />
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isMobile: state.appInfo.isMobile,
  screenWidth: state.appInfo.screenSize.width,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScrollingMenuCategoryHeader);

const styles = StyleSheet.create({
  flatlist: {
    width: '100%',
    marginTop: 7,
    height: 38,
    flexShrink: 0,
    marginBottom: 5,
    // paddingLeft: 10,
    // marginRight: 100,
  },
  scrollingLink: {
    ...globalStyles.flexRow,
    height: 38,
    paddingHorizontal: 28,
    backgroundColor: colors.primary,
    borderRadius: 7,
    // marginHorizontal: 6,
  },
});

import {
  fetch,
  readStorage,
  writeStorage,
  clearStorageItem,
} from '../../helpers';

import { UserState } from '.';

export const writeUserStorage = async (userInfo: UserState) => {
  const userCouponCount = userInfo.userCoupons?.length || 0;
  const desiredUserCoupon = userInfo.selectedCoupon;
  const userStorage = { desiredUserCoupon, userCouponCount };
  await writeStorage('userStorage', userStorage);
};

export const readUserStorage = async () => {
  const userStorage = await readStorage('userStorage');
  const { desiredUserCoupon, userCouponCount } = userStorage;
  return { desiredUserCoupon };
};

export const fetchUser = async (
  user: UserType,
  secondAttempt = false
): Promise<UserType> => {
  const url = '/customer/profile/';

  const headers = { Authorization: `Bearer ${user.token}` };
  const res = await fetch(url, { headers });
  if (!res.customer && !secondAttempt) return fetchUser(user, true);

  const {
    id,
    public_id,
    name,
    sms_number,
    email,
    birthday_month,
    birthday_day,
    etag,
    marketing_email_master,
    marketing_email_birthday,
    marketing_email_newsletter,
    coupon_issued_sms,
  } = res.customer;

  const loadedUser: UserType = {
    ...user,
    id,
    publicId: public_id,
    name,
    phone: sms_number,
    email,
    birthMonth: birthday_month,
    birthDate: birthday_day,
    etag,
    marketingEmails: marketing_email_master,
    marketingEmailBirthday: marketing_email_birthday,
    marketingEmailNewsletter: marketing_email_newsletter,
    couponIssuedSMS: coupon_issued_sms,
  };

  return loadedUser;
};

export const deleteUser = async (user: UserType) => {
  const url = '/customer/profile/';
  const headers = {
    Authorization: `Bearer ${user.token}`,
    'If-Match': user.etag,
    'Content-Type': 'application/json',
  };
  const config = { method: 'DELETE', headers };
  const res = await fetch(url, config);
  return res;
};

export const patchUser = async (user: UserType) => {
  const url = '/customer/profile/';
  const headers = {
    Authorization: `Bearer ${user.token}`,
    'If-Match': user.etag,
    'Content-Type': 'application/json',
  };

  const payload = {
    birthday_day: user.birthDate,
    birthday_month: user.birthMonth,
    name: user.name,
    email: user.email,
    sms_number: user.phone,
    marketing_email_master: user.marketingEmails,
    marketing_email_birthday: user.marketingEmailBirthday,
    marketing_email_newsletter: user.marketingEmailNewsletter,
    coupon_issued_sms: user.couponIssuedSMS,
  };

  const body = JSON.stringify(payload);
  const config = { method: 'PATCH', headers, body };
  const res = await fetch(url, config);
  if (
    Array.isArray(res.name) &&
    res.name[0] === 'First and last name are required.'
  )
    return { error: res.name[0] };

  if (
    Array.isArray(res.email) &&
    res.email[0] === 'Enter a valid email address.'
  )
    return { error: res.email[0] };

  if (!res.customer || !res.customer.id) return { error: 'User not updated' };
  const { name, sms_number, birthday_month, birthday_day, etag } = res.customer;

  const updatedUser: UserType = {
    ...user,
    name,
    phone: sms_number,
    birthMonth: birthday_month,
    birthDate: birthday_day,
    etag,
  };

  return { updatedUser };
};

const isDiscarded = (order: OrderResp) => {
  if (order.state === 'awaiting_payment') return true;
  if (order.state === 'canceled') {
    if (!!order.total && !order.refund_attempted) return true;
    if (order.discount === order.subtotal && !order.total) return false;
  }

  return false;
};

const transformOrders = (
  orders: OrderResp[],
  products: ProductResp[] = [],
  stores: StoreResp[] = []
) => {
  const filledOrders: FilledOrderType[] = [];

  orders.forEach((order) => {
    if (!order.id) return;
    if (orders.length > 1 && isDiscarded(order)) return;

    const store = stores.find((s) => s.id === order.store);
    const storeInfo = {
      name: store?.name || '',
      id: store?.id || order.store,
    };

    const orderInfo = {
      id: order.id,
      state: order.state,
      customer: order.customer,
      createdAt: order.created_at,
      createdTime: new Date(order.created_at).getTime(),
      coupon: order.coupon,
      discount: order.discount,
      subtotal: order.subtotal,
      tax: order.tax,
      total: order.total,
      readyTime: order.estimated_ready_for_handoff_at,
      handoffTime: order.ready_for_handoff_at,
      completedTime: order.completed_at,
      handoff: order.handoff,
      pickupId: order.pickup_identifier,
      refundAttempted: !!order.refund_attempted,
      address: order.address,
      storeId: store?.id,
    };

    const filledProducts: FilledProduct[] = order.order_lines.map(
      (orderLine) => {
        const menuProduct = products.find((p) => p.id === orderLine.product);
        const { modifiers } = orderLine;
        const selectedChoices: { added: string[]; removed: string[] } = {
          added: [],
          removed: [],
        };

        Object.keys(modifiers).forEach((modifier) => {
          const selectedIds = Object.keys(modifiers[modifier]);
          const productModifier = menuProduct?.modifiers.find(
            (m) => m.id === modifier
          );
          if (!productModifier || productModifier.hidden) return '';
          selectedIds.forEach((choiceId) => {
            const productChoice = productModifier.choices.find(
              (c) => c.id === choiceId && !c.default
            );

            if (productChoice?.name)
              selectedChoices.added.push(productChoice.name);
          });

          productModifier.choices.forEach((c) => {
            if (
              !!c.default &&
              !selectedIds.includes(c.id) &&
              !(
                productModifier.min_choices === 1 &&
                productModifier.max_choices === 1
              )
            )
              selectedChoices.removed.push(c.name);
          });
        });

        const filledProduct = {
          name: menuProduct?.name || '',
          id: menuProduct?.id || '',
          price: orderLine.price,
          quantity: orderLine.quantity,
          selections: selectedChoices,
        };

        return filledProduct;
      }
    );

    const filledOrder: FilledOrderType = {
      ...orderInfo,
      storeInfo,
      products: filledProducts,
    };

    filledOrders.push(filledOrder);
  });

  return filledOrders.sort((a, b) => b.createdTime - a.createdTime);
};

export const fetchUserOrders = async (user: UserType) => {
  const url =
    '/customer/orders/?include[]=products.*&include[]=store.*&sort[]=-created_at';
  const headers = { Authorization: `Bearer ${user.token}` };
  const res = await fetch(url, { headers });
  if (!res.orders || !res.products || !res.stores)
    return { error: 'failed to get orders' };

  const { orders, products, stores } = res;
  const filledOrders = transformOrders(orders, products, stores);

  return { filledOrders };
};

export const fetchCompletedOrder = async (
  orderId: string,
  token: string
): Promise<FilledOrderType> => {
  const url = `/customer/orders/${orderId}/?include[]=products.*&include[]=store.*`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const res = await fetch(url, { headers });
  const { order, products, stores } = res;
  const orders = [order];
  const filledOrders = transformOrders(orders, products, stores);
  const filledOrder = filledOrders[0];
  return filledOrder;
};

const transformOrder = (orderRes: OrderResp): OrderType => ({
  id: orderRes.id,
  total: orderRes.total,
  handoff: orderRes.handoff,
  state: orderRes.state,
  storeId: orderRes.store,
  validationId: orderRes.validation_id,
  pickupId: orderRes.pickup_identifier,
  readyTime:
    orderRes.ready_for_handoff_at || orderRes.estimated_ready_for_handoff_at,
  coupon: orderRes.coupon,
});

export const fetchOrder = async (
  orderId: string,
  token: string
): Promise<OrderType> => {
  const url = `/customer/orders/${orderId}/`;
  const headers = { Authorization: `Bearer ${token}` };
  const res = await fetch(url, { headers });
  const { order } = res;
  return transformOrder(order);
};

const transformCoupon = (couponRes: UserCouponResp): UserCouponType => ({
  id: couponRes.id,
  slug: couponRes.coupon,
  description: couponRes.coupon_description,
  createdAt: couponRes.created_at,
  expiresAt: couponRes.expires_at,
});

export const fetchUserCoupons = async (user: UserType) => {
  const url = `/customer/coupons/`;
  const headers = { Authorization: `Bearer ${user.token}` };
  const res = await fetch(url, { headers });
  const { coupons } = res;
  return coupons.map(transformCoupon);
};

export const postRegisterReferral = async (
  user: UserType,
  referrerId: string
): Promise<{ coupon?: UserCouponType; error?: string }> => {
  const url = '/customer/register_referral/';
  const headers = {
    Authorization: `Bearer ${user.token}`,
    'Content-Type': 'application/json',
  };
  const payload = { referred_by: referrerId };
  const body = JSON.stringify(payload);
  const config = { method: 'POST', headers, body };
  const res = await fetch(url, config);
  clearStorageItem('referrerId');
  if (res.coupon) {
    const coupon = transformCoupon(res);
    return { coupon };
  } else return { error: 'Did not receive referral coupon' };
};

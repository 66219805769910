export const getOpenTimes = () => {
  const baseTime = new Date();
  const currentHours = baseTime.getUTCHours();

  const preorderAt = new Date();
  const openAt = new Date();
  const closeAt = new Date();

  if (currentHours >= 11 && currentHours < 13) {
    preorderAt.setUTCHours(11);
    preorderAt.setMinutes(1);
    openAt.setUTCHours(11);
    openAt.setMinutes(2);
  } else {
    preorderAt.setUTCHours(currentHours - 2);
    openAt.setUTCHours(currentHours - 1);
  }

  if (currentHours >= 10 && currentHours <= 11) {
    closeAt.setUTCHours(10);
    closeAt.setMinutes(59);
  } else closeAt.setUTCHours(currentHours + 1);

  return {
    preorder_at: preorderAt.toISOString(),
    open_at: openAt.toISOString(),
    close_at: closeAt.toISOString(),
  };
};

export const getOpeningSoonTimes = () => {
  const baseTime = new Date();
  const currentHours = baseTime.getHours();
  const currentMinutes = baseTime.getMinutes();

  const preorderAt = new Date();
  const openAt = new Date();
  const closeAt = new Date();

  if (currentHours < 4 && currentHours >= 3) {
    preorderAt.setHours(3);
    preorderAt.setMinutes(57);
    openAt.setHours(3);
    openAt.setMinutes(58);
    closeAt.setHours(3);
    closeAt.setMilliseconds(59);
  } else if (currentHours < 3) {
    preorderAt.setHours(currentHours);
    preorderAt.setMinutes(currentMinutes + 1);
    openAt.setHours(currentHours);
    openAt.setMinutes(currentMinutes + 1);
    closeAt.setHours(currentHours);
    closeAt.setMinutes(currentMinutes + 1);
  } else {
    preorderAt.setHours(currentHours + 1);
    openAt.setHours(currentHours + 2);
    closeAt.setHours(currentHours + 3);
  }

  return {
    preorder_at: preorderAt.toISOString(),
    open_at: openAt.toISOString(),
    close_at: closeAt.toISOString(),
  };
};

export const getClosedTimes = () => {
  const baseTime = new Date();
  const currentHours = baseTime.getHours();
  const currentDate = baseTime.getDate();

  const preorderAt = new Date();
  const openAt = new Date();
  const closeAt = new Date();

  preorderAt.setUTCHours(4);
  preorderAt.setMinutes(1);
  openAt.setUTCHours(11);
  openAt.setMinutes(1);
  closeAt.setUTCHours(4);
  closeAt.setMinutes(2);

  if (currentHours < 4) {
    preorderAt.setDate(currentDate - 1);
    openAt.setDate(currentDate - 1);
    closeAt.setDate(currentDate - 1);
  }

  return {
    preorder_at: preorderAt.toISOString(),
    open_at: openAt.toISOString(),
    close_at: closeAt.toISOString(),
  };
};

export const getEventTimes = (date: Date) => {
  const dateTime = date.getTime();

  const preOrderAt = new Date(dateTime);
  const openAt = new Date(dateTime);
  const closeAt = new Date(dateTime);

  const preOrderTime = 10 + Math.floor(Math.random() * 6);
  const openTime = preOrderTime + Math.ceil(Math.random() * 2);
  const closeTime = openTime + 2 + Math.floor(Math.random() * 6);

  preOrderAt.setHours(preOrderTime);
  preOrderAt.setMinutes(Math.random() > 0.5 ? 30 : 0);
  openAt.setHours(openTime);
  openAt.setMinutes(Math.random() > 0.5 ? 30 : 0);
  closeAt.setHours(closeTime);
  closeAt.setMinutes(0);

  return {
    preorder_at: Math.random() > 0.6 ? preOrderAt.toISOString() : null,
    open_at: openAt.toISOString(),
    close_at: closeAt.toISOString(),
  };
};

export const makeTime = (hours: number) => {
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(0);
  return date.toISOString();
};

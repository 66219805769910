import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../../helpers';

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    marginTop: 50,
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 26,
  },
  upsellList: {
    marginLeft: 16,
  },
  upsellItem: {
    width: 165,
    height: 165,
    marginRight: 10,
    backgroundColor: colors.body,
    // padding: 12,
  },
  textBox: {
    padding: 10,
  },
  upsellPriceRow: {
    ...globalStyles.flexRow,
    justifyContent: 'space-between',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: 10,
    width: '100%',
    // borderWidth: 1,
  },
  productImage: {
    alignSelf: 'center',
    marginBottom: 14,
    width: 65,
    height: 75,
  },
  drinkImage: {
    height: 80,
    marginBottom: 8,
  },
});

export default styles;

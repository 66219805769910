import { Platform } from 'react-native';
import { MOCK_TESTING } from '@app/core';
import { getVersion } from 'react-native-device-info';

import { logError, logMessage } from '../sentryLogger';
import { readStorage, writeStorage } from '../storage';
import getEnvVariable from '../getEnvVariable';

import { setUpdateRequired } from '../../pages/Backdrop';

import mockFetch from './mockFetch';

export type ConfigType = {
  method?: string;
  headers?: {
    Authorization?: string;
    'If-Match'?: string;
    'Content-Type'?: string;
  };
  body?: string;
  timeout?: number;
  isRetry?: boolean;
};

const checkIosAppVersion = (minVersion: string) => {
  const currentVersion = getVersion();
  const currentSplit = currentVersion.split('.');
  const minSplit = minVersion.split('.');

  let outdated = false;

  for (const x in minSplit) {
    if (!minSplit[x] || !currentSplit[x]) break;
    const minNumber = parseInt(minSplit[x]);
    const currentNumber = parseInt(currentSplit[x]);
    if (minNumber > currentNumber) {
      outdated = true;
      break;
    }
    if (currentNumber > minNumber) break;
  }

  if (outdated) setUpdateRequired({ current: currentVersion, min: minVersion });
};

const baseUrl = getEnvVariable('REACT_APP_STELLAR_API_URL');

const shouldLogout = async () => {
  const lastLogoutString = await readStorage('lastAutomaticLogout');
  let lastLogout = 0;
  try {
    lastLogout = parseInt(lastLogoutString);
  } catch (e) {}

  const now = Date.now();
  const diff = now - lastLogout;
  const oneMinute = 1000 * 60;
  if (diff < oneMinute) return false;

  writeStorage('lastAutomaticLogout', now);
  return true;
};

type ResolveType = (value: unknown) => void;
let authConfirmed = false;
let authPending = false;
const pendingRequests: ResolveType[] = [];

const timeoutFetch = async (url: string, config: ConfigType = {}) => {
  let isFirstAuthRequest = false;
  let requestPromise: any;

  // Extra logic for queueing auth requests until first 200 returned, for fresh logins only
  if (config.headers?.Authorization && !authConfirmed && !!window.isNewUser) {
    if (!authPending) {
      authPending = true;
      isFirstAuthRequest = true;
    } else {
      let requestResolve: ResolveType | null = null;
      requestPromise = new Promise((res, rej) => {
        requestResolve = res;
        setTimeout(() => {
          rej(false);
        }, 5000);
      });
      if (requestResolve) pendingRequests.push(requestResolve);
    }
  }

  if (requestPromise) {
    const answer = await requestPromise;
    if (!answer) {
      logMessage(`Auth request not completed ${url}`);
      return null;
    }
  }

  return Promise.race([
    fetch(url, config).then(async (res) => {
      if (Platform.OS === 'ios') {
        const minimumIosVersion = res.headers.get('minimum-ios-app-version');
        if (minimumIosVersion) checkIosAppVersion(minimumIosVersion);
      }

      let json: any = {};
      try {
        json = await res.json();
      } catch (e) {}

      if (isFirstAuthRequest) {
        if (res.status === 200) {
          pendingRequests.forEach((r) => r(true));
          authConfirmed = true;
        } else {
          const nextRequest = pendingRequests.shift();
          if (nextRequest) nextRequest(true);
          authPending = false;
        }
      }

      if (res.status === 401) {
        const willLogout = await shouldLogout();
        if (willLogout && !!window?.globalLogoutUser) window.globalLogoutUser();
        logError(json.detail || 'Auth not provided');
      }

      if (res.status === 204) json.status = 204;
      return json;
    }),
    new Promise(
      (res, rej) =>
        setTimeout(
          () => res({ timeoutError: `Fetch Timeout - ${url}` }),
          config.timeout
        )
      // setTimeout(() => rej(new Error(`Fetch timeout - ${url}`)), config.timeout)
    ),
  ]);
};
const customFetch = async (
  url: string,
  config: ConfigType = {}
): Promise<any> => {
  if (!config.method) config.method = 'GET';

  if (MOCK_TESTING) return mockFetch(url, config);

  if (config.timeout === undefined) config.timeout = 7000;
  const fetchUrl = baseUrl + url;

  try {
    const res = await timeoutFetch(fetchUrl, config);
    if (res.timeoutError) {
      logMessage(res.timeoutError);
      const isGet = config.method === 'GET';
      if (!!isGet && !config.isRetry) {
        logMessage(`Retrying fetch - ${url}`);
        config.isRetry = true;
        return customFetch(url, config);
      }
    }
    return res;
  } catch (e: any) {
    if (!e.name || e.name !== 'TypeError') logError(e);
  }
};

export default customFetch;

import { Platform } from 'react-native';
import colors from './colors';

const baseThemeColor = colors.primary;

const isWeb = Platform.OS === 'web';

const createTheme = () => {
  const themeColor = document.createElement('meta');
  themeColor.name = 'theme-color';
  document.head.appendChild(themeColor);
  themeColor.content = baseThemeColor;
  return themeColor;
};

const getTheme = () => {
  if (!isWeb) return;
  const themeColor: HTMLMetaElement =
    document.querySelector('meta[name="theme-color"]') || createTheme();
  return themeColor;
};

export const setThemeColor = (color: string) => {
  const themeColor = getTheme();
  if (themeColor) themeColor.content = colors[color] || baseThemeColor;
};

export const resetThemeColor = () => {
  const themeColor = getTheme();
  if (themeColor) themeColor.content = baseThemeColor;
};

import { combineReducers, configureStore } from '@reduxjs/toolkit';

import storeSlice from './storeSlice';
import cartSlice from './cartSlice';
import appInfoSlice from './appInfoSlice';
import userSlice from './userSlice';

// ~~~@@ DISPATCH ACTIONS @@~~~

// store actions
export const {
  setActiveProduct,
  clearActiveProduct,
  setActiveCombo,
  setGenericProduct,
  clearGenericProduct,
  updateStoreStatus,
} = storeSlice.actions;
export { loadStores, loadMenu, loadStore, loadGenericMenu } from './storeSlice';

// cart actions
export const {
  toggleCart,
  addItemToCart,
  updateCartItem,
  removeCartItem,
  inputCoupon,
  applyCoupon,
  removeCartCoupon,
  clearCart,
  clearAllCarts,
  checkCartMenus,
} = cartSlice.actions;
export { loadCarts, validateCart, createOrder, emptyCart } from './cartSlice';

// app actions
export const {
  setScreenSize,
  setNameNeededModal,
  setScheduleUpsellModal,
  setOrderSuccessModal,
  updateOrderSuccessModal,
  setLoginModal,
  setAccountDeletionModal,
  setUpdateRequiredModal,
  setReferralModal,
  setReferralRedeemedModal,
  setNewCouponModal,
} = appInfoSlice.actions;
export { loadAppConfiguration, loadUrlTags } from './appInfoSlice';

// user actions
export {
  setUserTokenAndLoad,
  loadUser,
  updateUser,
  removeUser,
  loadUserOrders,
  loadUserCoupons,
  loadUserItems,
  throttledLoadUserItems,
  loadCompletedOrder,
  getOrder,
  unselectCoupon,
  registerReferral,
} from './userSlice';
export const {
  setUserToken,
  setUserUpdating,
  clearUserError,
  clearUserData,
  selectCoupon,
  setReferrerId,
} = userSlice.actions;

// ~~~@@ STATE FIELDS @@~~~
export const readStores = (state: RootState) => state.storeInfo.stores;

export const readStoreMenu = (state: RootState, storeId: string) => {
  const { storeMenus } = state.storeInfo;
  const activeMenu = storeMenus[storeId];
  return activeMenu;
};

export const readGenericMenu = (state: RootState) =>
  state.storeInfo.genericMenu;

export const readActiveProduct = (state: RootState) => {
  const { activeProduct } = state.storeInfo;
  return activeProduct;
};

export const readGenericProduct = (state: RootState) =>
  state.storeInfo.genericProduct;

export const readCart = (state: RootState, storeId: string) => {
  const { storeCarts } = state.cartInfo;
  return storeCarts[storeId];
};

export const readStoreCoupon = (state: RootState, storeId: string) => {
  const { storeCarts } = state.cartInfo;
  const storeCart = storeCarts[storeId];
  return storeCart.appliedCoupon;
};

export const readOrder = (state: RootState, storeId: string) => {
  const { storeCarts } = state.cartInfo;
  return storeCarts[storeId].order;
};

export const readUser = (state: RootState) => state.userInfo.user;
export const readUserError = (state: RootState) => state.userInfo.error;
export const readUserOrders = (state: RootState) => state.userInfo.userOrders;
export const readSingleUserOrder = (state: RootState, orderId: string) => {
  const { userOrders } = state.userInfo;
  const order = userOrders.find((o) => o.id === orderId);
  return order;
};

export const rootReducer = combineReducers({
  storeInfo: storeSlice.reducer,
  cartInfo: cartSlice.reducer,
  appInfo: appInfoSlice.reducer,
  userInfo: userSlice.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

// ~~~@@ CONFIGURE STORE REDUCERS @@~~~
let store = setupStore();

export const refreshStore = () => {
  store = setupStore();
};

export default store;

import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../helpers';

const styles = StyleSheet.create({
  pageWrapper: {
    ...globalStyles.pageWrapper,
    width: '80%',
    alignSelf: 'center',
    minWidth: 700,
    top: 0,
    paddingTop: 40,
    paddingBottom: 100,
  },
  mobileWrapper: {
    width: '100%',
    minWidth: 300,
    paddingTop: 34,
  },
  borderLine: {
    marginTop: 20,
    left: '5%',
    width: '90%',
    height: 2,
    borderBottomWidth: 1,
    borderBottomColor: colors.midLight,
  },
});

export default styles;

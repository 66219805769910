import React from 'react';
import { View, Pressable, Image } from 'react-native';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-native';

import { getProductPrice, getProductImage } from '../../../helpers';

import { removeCartItem, setActiveProduct } from '../../../globalStore';
import { BodyText, Close } from '../../../components';

import { ErrorIcon } from '../../../assets';

import styles from './styles';

interface Props {
  item: ProductType;
  itemIdx: number;
  stockError: boolean;
}

const CartItem = ({ item, itemIdx, stockError }: Props) => {
  const dispatch = useDispatch();
  const { storeId } = useParams();

  const isFirst = itemIdx === 0;

  const removedChoices = item.modifiers.flatMap((m) =>
    m.hidden
      ? []
      : m.choices.filter(
          (c) =>
            !c.selected &&
            !!c.default &&
            !(m.maxChoices === 1 && m.minChoices === 1)
        )
  );
  const selectedChoices = item.modifiers.flatMap((m) =>
    m.hidden ? [] : m.choices.filter((c) => !c.default && !!c.selected)
  );

  const isDip = item.category === 'Dips';
  const isPizza = item.category === 'Pizzas';

  return (
    <Pressable
      style={[
        styles.cartItem,
        isFirst && styles.firstItem,
        stockError && styles.errorItem,
      ]}
      onPress={() => dispatch(setActiveProduct(item))}
      testID='cart-item'>
      <View style={styles.itemQuantity}>
        <BodyText bold={true} color='black'>
          {item.quantity}
        </BodyText>
        <Close width={6} ml={1} bold={true} color='black' />
      </View>
      <View style={styles.imageBox}>
        <Image
          source={getProductImage(item)}
          resizeMode='contain'
          style={[styles.productImage, !isPizza && styles.otherImage]}
        />
      </View>
      <View style={styles.productBox}>
        <BodyText
          bold={true}
          color={stockError ? 'white' : 'primary'}
          uppercase={true}>
          {item.name}
        </BodyText>
        <View style={styles.choiceList}>
          {selectedChoices.map((c, cIdx) => (
            <BodyText color={stockError ? 'white' : 'midDark'} key={cIdx}>
              {c.name}
              {(cIdx !== selectedChoices.length - 1 ||
                removedChoices.length > 0) &&
                ', '}
            </BodyText>
          ))}
          {removedChoices.map((c, cIdx) => (
            <BodyText color='textDark' mb={4} key={cIdx}>
              No {c.name}
              {cIdx !== removedChoices.length - 1 && ',  '}
            </BodyText>
          ))}
        </View>
        {stockError ? (
          <View style={styles.stockErrorBadge}>
            <ErrorIcon width={12} />
            <BodyText color='white' ml={8}>
              Product is sold out.
            </BodyText>
          </View>
        ) : (
          <BodyText color='midDark' fontWeight='bold' spacing={0.4}>
            {getProductPrice(item, { trim: false })}
          </BodyText>
        )}
      </View>
      <Pressable
        style={styles.deleteItemButton}
        onPress={() =>
          item.cartItemId &&
          dispatch(removeCartItem({ storeId, cartItemId: item.cartItemId }))
        }>
        <Close fillBox={true} width={12} />
      </Pressable>
    </Pressable>
  );
};

export default CartItem;

import { StyleSheet } from 'react-native';
import { colors, globalStyles } from '../../helpers';

const styles = StyleSheet.create({
  socialLink: {
    ...globalStyles.flexRow,
    width: 52,
    height: 52,
    backgroundColor: colors.body,
    borderRadius: 100,
  },
});

export default styles;

import React from 'react';
import { View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { colors, globalStyles } from '../helpers';

interface Props {
  width?: number;
  color?: string;
  ml?: number;
  bold?: boolean;
  fillBox?: boolean;
}

const LongArrow = ({ width, color }: Props) => {
  let fillColor = colors.midDark;
  if (color && colors[color]) fillColor = colors[color];

  return (
    <Svg
      width={width || 16}
      height={width || 16}
      viewBox='0 0 16 16'
      fill='none'>
      <Path
        d='M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z'
        fill={fillColor}
      />
    </Svg>
  );
};

export default LongArrow;

import { Platform } from 'react-native';

import { isHexColor, hexToRgb, colornameToHex } from './colors';

type ShadowConfigType = {
  color?: string;
  radius?: number;
  opacity?: number;
  offset?: { width?: number; height?: number };
};

const getShadow = (shadowConfig: ShadowConfigType = {}) => {
  let hexColor = '#000';
  let rgbColor = { r: 0, g: 0, b: 0 };

  if (shadowConfig.color) {
    hexColor = colornameToHex(shadowConfig.color.toLowerCase()) || hexColor;
    if (isHexColor(shadowConfig.color)) hexColor = shadowConfig.color;
    rgbColor = hexToRgb(hexColor) || rgbColor;
  }
  const shadowColor = hexColor;
  const shadowRadius = shadowConfig.radius || 1;
  const shadowOpacity = shadowConfig.opacity || 1;
  const xOffset = shadowConfig.offset?.width || 0;
  const yOffset = shadowConfig.offset?.height || 0;

  const colorString = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${shadowOpacity})`;

  return Platform.select({
    ios: {
      shadowColor,
      shadowRadius,
      shadowOpacity,
      shadowOffset: { width: xOffset, height: yOffset },
    },
    android: {
      elevation: 1,
    },
    web: {
      boxShadow: `${xOffset}px ${yOffset}px ${shadowRadius}px ${colorString}`,
    },
  });
};

export default getShadow;

import React from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';

import { readUserOrders } from '../../../globalStore';

import { Link, BodyText, TitleText, SpinLoader } from '../../../components';

import { formatPrice, getDateString, getTimeString } from '../../../helpers';

import { RightArrow } from '../../../assets';

import styles from './styles';

const showInProgress = (order: OrderType) => {
  const createdTime = new Date(order.createdAt || '').getTime();
  const timeDiff = Date.now() - createdTime;
  const oneDay = 1000 * 60 * 60 * 24;
  const oldOrder = timeDiff > oneDay;
  return order.state === 'in_progress' && !oldOrder;
};

const readyForPickup = (order: OrderType) =>
  order.state === 'ready_for_handoff';

const Orders = () => {
  const userOrders = useSelector(readUserOrders);

  return (
    <View style={styles.ordersWrapper}>
      {!userOrders.length && <SpinLoader fullSize={true} minSize={400} />}
      {userOrders.map((order) => (
        <Link to={`/profile/orders/${order.id}`} key={order.id}>
          <View style={styles.orderBox}>
            {showInProgress(order) || readyForPickup(order) ? (
              <View style={styles.progressPreview}>
                <TitleText fontSize={18} color='primary' mb={4}>
                  ORDER #{order.id.slice(0, 4).toUpperCase()}
                </TitleText>
                <BodyText bold={true} fontSize={15}>
                  {readyForPickup(order) ? (
                    <BodyText color='darkBlue'>READY FOR PICKUP</BodyText>
                  ) : (
                    <BodyText color='blue'>IN PROGRESS</BodyText>
                  )}

                  <BodyText color='textDark' ml={8}>
                    •{'  '}
                    {readyForPickup(order)
                      ? `READY AT ${getTimeString(
                          order.handoffTime || order.readyTime
                        )}`
                      : `PICKUP ETA ${getTimeString(order.readyTime)}`}
                  </BodyText>
                </BodyText>
              </View>
            ) : (
              <BodyText
                bold={true}
                color='textLight'
                mb={6}
                uppercase={true}
                fontSize={14}
                spacing={0.4}>
                {getDateString(order.createdAt)}
              </BodyText>
            )}

            {order.products.map((p) => (
              <BodyText
                color='textDark'
                bold={true}
                fontSize={16}
                mb={4}
                key={p.id}>
                {p.quantity}x {p.name}
              </BodyText>
            ))}
            <BodyText mt={2} fontSize={16} color='textDark'>
              {formatPrice(order.total, { inDollars: true, trim: true })}
            </BodyText>

            <View style={styles.arrowBox}>
              <RightArrow width={28} height={28} />
            </View>
          </View>
        </Link>
      ))}
    </View>
  );
};

export default Orders;

import React from 'react';
import { View, Pressable } from 'react-native';
import { connect } from 'react-redux';
import { isEqual, sumBy } from 'lodash';

import { setLoginModal } from '../../../globalStore';

import { globalStyles, withNavigate, readStorage } from '../../../helpers';

import { BodyText, Arrow, Block, CustomView } from '../../../components';

import { MaroonMascotBolt } from '../../../assets';

import bubbleConfig, { ConfigType } from './bubbleConfig';

import styles from './styles';

interface Props {
  interact: () => void;
  userCoupons: UserCouponType[];
  selectedCoupon: UserCouponType;
  referrerId: string | null;
  openStoreOrders: FilledOrderType[];
  registerReferralEnabled: boolean;
  // Actions
  setLoginModal: (open: boolean) => void;
  navigate: (to: string) => void;
}

interface State {
  textCount: number;
  config: ConfigType | null;
}

class PizzaPal extends React.Component<Props, State> {
  state: State = {
    textCount: 0,
    config: null,
  };

  lastTextTime = 0;
  loadingText = false;
  textTimer = setTimeout(() => undefined);
  nextText: string[] = [];

  componentDidMount(): void {
    this.loadConfig();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!isEqual(prevProps, this.props)) this.loadConfig();
  }

  loadConfig = async () => {
    const {
      openStoreOrders,
      userCoupons,
      selectedCoupon,
      referrerId,
      registerReferralEnabled,
    } = this.props;

    const userStorage = await readStorage('userStorage');
    const { userCouponCount } = userStorage;

    // TODO UNCOMMENT TO TEST TEXT ANIMATION
    // if (!this.state.config) {
    //   const config = bubbleConfig({ openStoreOrders, userCouponCount });
    //   this.nextText = config.text;
    //   this.setState({ config }, this.loadText);
    //   return;
    // }

    const config = bubbleConfig({
      openStoreOrders,
      userCouponCount: userCoupons ? userCoupons.length : userCouponCount,
      selectedCoupon,
      referrerId,
      registerReferralEnabled,
    });
    this.setState({ config });

    // TODO UNCOMMENT TO TEST TEXT ANIMATION
    // if (!isEqual(this.nextText, config.text)) {
    //   this.nextText = config.text;
    //   clearTimeout(this.textTimer);
    //   const now = Date.now();
    //   const minTime = 1500;
    //   const timeDiff = now - this.lastTextTime;
    //   const timer = timeDiff < minTime ? minTime - timeDiff : 10;
    //   this.textTimer = setTimeout(() => {
    //     if (!isEqual(config, this.state.config))
    //       this.setState({ config, textCount: 0 }, this.loadText);
    //   }, timer);
    //   return;
    // }
  };

  // TODO UNCOMMENT TO TEST TEXT ANIMATION
  // loadText = () => {
  //   this.lastTextTime = Date.now();
  //   if (this.loadingText) return;
  //   this.loadingText = true;
  //   this.showText();
  // };

  // TODO UNCOMMENT TO TEST TEXT ANIMATION
  // showText = () => {
  //   const { config, textCount } = this.state;
  //   const texts = config?.text || [];
  //   const textLength = sumBy(texts, (t) => t.length);
  //   if (textCount >= textLength) return (this.loadingText = false);

  //   this.setState({ textCount: textCount + 1 }, () =>
  //     setTimeout(this.showText, 20)
  //   );
  // };

  bubbleAction = () => {
    const {
      userCoupons,
      referrerId,
      registerReferralEnabled,
      openStoreOrders,
    } = this.props;
    const hasOpenOrders = openStoreOrders.length > 0;
    const hasUserCoupons = !!userCoupons && userCoupons.length > 0;

    if (!!referrerId && !!registerReferralEnabled) {
      this.props.setLoginModal(true);
    } else if (!hasOpenOrders && !!hasUserCoupons)
      this.props.navigate('/profile/coupons');
  };

  render() {
    const { interact } = this.props;
    const { config, textCount } = this.state;

    const textDone = true;

    // TODO UNCOMMENT TO TEST TEXT ANIMATION
    // let remainingTextCount = 0;
    // let textDOne = false;
    // if (config.text[1] && textCount > config.text[0].length) {
    //   remainingTextCount = textCount - config.text[0].length;
    //   if (remainingTextCount >= config.text[1].length) textDone = true;
    // }

    return (
      <View>
        <Pressable style={styles.boltBox} onPress={interact}>
          <MaroonMascotBolt width={88} height={88} />
        </Pressable>

        {!!config ? (
          <View style={styles.chatBox}>
            <View
              style={[
                styles.chatArrow,
                {
                  borderBottomColor: config.backgroundColor,
                },
              ]}
            />
            <Pressable
              onPress={this.bubbleAction}
              style={[
                styles.chatBubble,
                {
                  backgroundColor: config.backgroundColor,
                },
              ]}>
              <BodyText
                bold={config.bold}
                fontSize={config.fontSize}
                color={config.color}
                center={config.center}>
                {config.text[0]}
              </BodyText>
              {/* TODO UNCOMMENT TO TEST TEXT ANIMATION */}
              {/* <View>
              <BodyText
                bold={config.bold}
                fontSize={config.fontSize}
                color={config.color}
                // center={config.center}
                style={{ opacity: 0 }}>
                {config.text[0]}
              </BodyText>
              <BodyText
                bold={config.bold}
                fontSize={config.fontSize}
                color={config.color}
                center={false}
                style={{ position: 'absolute' }}>
                {config.text[0].slice(0, textCount)}
              </BodyText>
            </View> */}
              {!!config.text[1] && (
                <View
                  style={[
                    globalStyles.flexRow,
                    {
                      alignSelf: 'center',
                      marginTop: 4,
                    },
                  ]}>
                  <BodyText
                    lineHeight={config.lineHeight}
                    fontSize={config.fontSize}
                    bold={config.bold}
                    color={config.color}
                    center={false}>
                    {config.text[1]}
                  </BodyText>
                  {/* TODO UNCOMMENT TO TEST TEXT ANIMATION */}
                  {/* <View>
                  <BodyText
                    lineHeight={config.lineHeight}
                    fontSize={config.fontSize}
                    bold={config.bold}
                    color={config.color}
                    center={config.center}
                    style={{ opacity: 0 }}>
                    {config.text[1]}
                  </BodyText>
                  <BodyText
                    lineHeight={config.lineHeight}
                    fontSize={config.fontSize}
                    bold={config.bold}
                    color={config.color}
                    center={false}
                    style={{ position: 'absolute' }}>
                    {config.text[1].slice(0, remainingTextCount)}
                  </BodyText>
                </View> */}
                  <CustomView
                    fadeIn={true}
                    duration={60}
                    style={{
                      ...globalStyles.flexRow,
                      height: 26,
                      top: 1,
                      opacity: textDone ? 1 : 0,
                    }}>
                    <Arrow color='secondary' width={26} height={40} />
                  </CustomView>
                </View>
              )}
            </Pressable>
          </View>
        ) : (
          <Block height={90} />
        )}
      </View>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  userCoupons: state.userInfo.userCoupons,
  registerReferralEnabled: state.appInfo.appConfig?.registerReferralEnabled,
  referrerId: state.userInfo.referrerId,
  selectedCoupon: state.userInfo.selectedCoupon,
});

const mapDispatchToProps = {
  setLoginModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigate(PizzaPal));

import React from 'react';
import {
  Pressable,
  StyleSheet,
  ViewStyle,
  useWindowDimensions,
} from 'react-native';
import * as animatable from 'react-native-animatable';
import Modal from 'react-native-modal';

interface BackdropProps {
  onBackdropPress: () => void;
}

const CustomBackdrop = ({ onBackdropPress }: BackdropProps) => {
  const { width, height } = useWindowDimensions();
  return (
    <Pressable
      style={[styles.modalBackdrop, { width, height }]}
      onPress={onBackdropPress}
      testID='modal-backdrop'></Pressable>
  );
};

const BaseModal = (props: BaseModalProps) => {
  return (
    <Modal
      isVisible={true}
      onBackdropPress={props.onBackdropPress}
      customBackdrop={
        <CustomBackdrop onBackdropPress={props.onBackdropPress} />
      }
      animationIn={props.animationIn || 'zoomIn'}
      animationOut={props.animationOut || 'zoomOut'}
      animationInTiming={props.animationInTiming || 300}
      animationOutTiming={props.animationOutTiming || 200}
      testID={props.testID}
      style={{ margin: 0 }}>
      {props.children}
    </Modal>
  );
};

export default BaseModal;

const styles = StyleSheet.create({
  modalBackdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(48,32,26,.8)',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

interface BaseModalProps {
  onBackdropPress: () => void;
  animationIn?: AnimationSpeed;
  animationInTiming?: number;
  animationOut?: AnimationSpeed;
  animationOutTiming?: number;
  testID?: string;
  children: React.ReactNode;
}

type AnimationSpeed =
  | 'bounce'
  | 'flash'
  | 'jello'
  | 'pulse'
  | 'rotate'
  | 'rubberBand'
  | 'shake'
  | 'swing'
  | 'tada'
  | 'wobble'
  | 'bounceIn'
  | 'bounceInDown'
  | 'bounceInUp'
  | 'bounceInLeft'
  | 'bounceInRight'
  | 'bounceOut'
  | 'bounceOutDown'
  | 'bounceOutUp'
  | 'bounceOutLeft'
  | 'bounceOutRight'
  | 'fadeIn'
  | 'fadeInDown'
  | 'fadeInDownBig'
  | 'fadeInUp'
  | 'fadeInUpBig'
  | 'fadeInLeft'
  | 'fadeInLeftBig'
  | 'fadeInRight'
  | 'fadeInRightBig'
  | 'fadeOut'
  | 'fadeOutDown'
  | 'fadeOutDownBig'
  | 'fadeOutUp'
  | 'fadeOutUpBig'
  | 'fadeOutLeft'
  | 'fadeOutLeftBig'
  | 'fadeOutRight'
  | 'fadeOutRightBig'
  | 'flipInX'
  | 'flipInY'
  | 'flipOutX'
  | 'flipOutY'
  | 'lightSpeedIn'
  | 'lightSpeedOut'
  | 'slideInDown'
  | 'slideInUp'
  | 'slideInLeft'
  | 'slideInRight'
  | 'slideOutDown'
  | 'slideOutUp'
  | 'slideOutLeft'
  | 'slideOutRight'
  | 'zoomIn'
  | 'zoomInDown'
  | 'zoomInUp'
  | 'zoomInLeft'
  | 'zoomInRight'
  | 'zoomOut'
  | 'zoomOutDown'
  | 'zoomOutUp'
  | 'zoomOutLeft'
  | 'zoomOutRight'
  | animatable.CustomAnimation<
      import('react-native').TextStyle &
        ViewStyle &
        import('react-native').ImageStyle
    >;

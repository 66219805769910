import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../../helpers';

const styles = StyleSheet.create({
  paymentWrapper: {
    ...globalStyles.flexColumn,
    width: '100%',
    maxWidth: 650,
    alignSelf: 'center',
    flex: 1,
    paddingTop: 25,
    paddingBottom: 50,
    paddingHorizontal: '5%',
    backgroundColor: colors.white,
  },
  fieldInput: {
    borderWidth: 1,
    borderColor: colors.midLight,
    color: colors.textDark,
    fontSize: 18,
    width: '100%',
    borderRadius: 4,
    padding: 10,
    minHeight: 50,
    marginBottom: 20,
    ...globalStyles.noOutline,
  },
  cardLabel: {
    alignSelf: 'flex-start',
    color: colors.textDark,
  },
  nameError: {
    position: 'relative',
    alignSelf: 'flex-start',
    bottom: 14,
  },
  errorMessage: {
    alignSelf: 'flex-start',
  },
  payButton: { maxWidth: 800 },

  // Payment Methods
  methodsWrapper: {},
  paymentMethod: {
    ...globalStyles.flexRow,
    // width: 220,
    paddingHorizontal: 28,
    paddingVertical: 14,
    borderWidth: 1,
    borderColor: colors.midDark,
    marginBottom: 18,
    borderRadius: 5,
    ...globalStyles.noOutline,
  },
  selectedMethod: {
    borderColor: colors.primary,
  },
  indicator: {
    width: 10,
    height: 10,
    marginLeft: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.secondary,
  },
  activeIndicator: {
    backgroundColor: colors.primary,
    borderColor: colors.primary,
  },
});

export default styles;

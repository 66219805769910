import { StyleSheet, Platform } from 'react-native';

const ios = Platform.OS === 'ios';

const mobileStyles = StyleSheet.create({
  mobileBox: {
    bottom: 0,
    width: '100%',
    maxWidth: '100%',
    // maxHeight: '84%',
    // minHeight: '40%',
    maxHeight: ios ? '89%' : '89%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  mobileProductName: {
    fontSize: 25,
    lineHeight: 25,
    maxWidth: '70%',
  },
  mobileClose: {
    right: 10,
  },
  mobileSection: {
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 20,
  },
  mobileHeader: {
    paddingHorizontal: 8,
    paddingVertical: 10,
    maxHeight: 145,
  },
  mobileOptionsBox: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    minHeight: 250,
    // maxHeight: 250,
  },
});

export default mobileStyles;

import React, { useEffect, useRef } from 'react';
import { View, ViewStyle, Animated, Easing, StyleSheet } from 'react-native';

import { globalStyles, colors, animate } from '../helpers';

interface Props {
  fullSize?: boolean;
  minSize?: number;
}

const SpinLoader = ({ fullSize, minSize }: Props) => {
  const spinAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      animate(spinAnim, {
        toValue: 1,
        duration: 1500,
        easing: Easing.linear,
      })
    ).start();
  }, []);

  const extraWrapperStyle: ViewStyle = {};

  const spin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  if (fullSize) {
    extraWrapperStyle.width = '100%';
    extraWrapperStyle.height = '100%';
  }

  if (minSize) {
    extraWrapperStyle.minWidth = minSize;
    extraWrapperStyle.minHeight = minSize;
  }

  return (
    <View style={[styles.wrapper, extraWrapperStyle]}>
      <Animated.View
        style={[styles.spinner, { transform: [{ rotate: spin }] }]}
      />
    </View>
  );
};

export default SpinLoader;

const styles = StyleSheet.create({
  wrapper: {
    ...globalStyles.flexRow,
  },
  spinner: {
    width: 80,
    height: 80,
    borderRadius: 50,
    borderTopWidth: 2,
    borderBottomWidth: 3,
    borderTopColor: colors.primary,
    borderBottomColor: colors.primary,
    backgroundColor: 'rgba( 225, 0, 0, .4)',
  },
});

import { readStorage, writeStorage } from '../../helpers';

const writeRecentOrders = async (recentOrders: FilledOrderType[]) => {
  await writeStorage('recentOrders', recentOrders.slice(0, 3));
};

const readRecentOrders = async (): Promise<FilledOrderType[]> => {
  const storageOrders = (await readStorage('recentOrders')) || [];

  const recentOrders = storageOrders.filter((o: FilledOrderType) => {
    const now = new Date().getTime();
    const timeDiff = now - o.createdTime;
    const duration = 1000 * 60 * 60 * 4;
    return timeDiff < duration;
  });

  return recentOrders;
};

export const getOpenOrders = async (
  globalOrders: FilledOrderType[],
  userId?: string
) => {
  const recentOrders = await readRecentOrders();

  const uniqueRecentOrders = recentOrders.filter(
    (order) =>
      !globalOrders.find((o) => o.id === order.id) &&
      !(userId && userId !== order.customer)
  );

  const fullOrders = [...uniqueRecentOrders, ...globalOrders];

  const openOrders = fullOrders
    ?.filter((o) => {
      const now = new Date().getTime();
      const timeDiff = now - o.createdTime;
      const oneWeek = 1000 * 60 * 60 * 24 * 7;
      const tenMinutes = 1000 * 60 * 10;

      if (o.state === 'in_progress' && timeDiff < oneWeek) return true;
      if (o.state === 'ready_for_handoff' && o.handoffTime) {
        const handoffTime = new Date(o.handoffTime).getTime();
        if (now - handoffTime < tenMinutes) return true;
      }
      if (o.state === 'completed' && o.completedTime) {
        const completedTime = new Date(o.completedTime).getTime();
        if (now - completedTime < tenMinutes) return true;
      }

      const hiddenState = ['awaiting_payment', 'canceled'].includes(o.state);
      if (timeDiff < tenMinutes && !hiddenState) return true;
    })
    .sort((a, b) => b.createdTime - a.createdTime)
    .slice(0, 6);

  writeRecentOrders(openOrders);
  return openOrders;
};

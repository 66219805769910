import React, { useRef, useEffect } from 'react';
import { Pressable, View, StyleSheet, Animated } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-native';

import { readCart, toggleCart } from '../../globalStore';
import { colors, globalStyles, animate } from '../../helpers';

import { BodyText } from '../../components';
import CustomView from '../../components/CustomView';

import Cart from '../../assets/cart.svg';

const CartIcon = () => {
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => readCart(state, storeId));
  const isMobile = useSelector((state: RootState) => state.appInfo.isMobile);

  const previousQuantity = useRef(0);

  const cartEmpty = !cart?.products || cart?.products?.length <= 0;

  const sizeAnim = useRef(new Animated.Value(1)).current;
  const pillAnim = useRef(new Animated.Value(0.6)).current;

  useEffect(() => {
    if (!cart?.products) return;
    const quantity = cart?.products.reduce((s, a) => (s += a.quantity || 0), 0);

    if (quantity > previousQuantity.current) {
      const firstItem = quantity === 1 && previousQuantity.current === 0;
      const boostSize = firstItem ? 1.45 : 1.3;
      const boostTime = firstItem ? 540 : 420;

      const largeStretch = isMobile ? 1.3 : 1.4;
      const smallStretch = isMobile ? 1.2 : 1.3;
      const stretchSize = firstItem ? largeStretch : smallStretch;

      Animated.parallel([
        Animated.sequence([
          animate(pillAnim, {
            toValue: stretchSize,
            duration: boostTime,
          }),
          animate(pillAnim, {
            toValue: 1,
            duration: boostTime * 0.5,
          }),
        ]),
        Animated.sequence([
          animate(sizeAnim, {
            toValue: boostSize,
            duration: boostTime,
          }),
          animate(sizeAnim, {
            toValue: 1,
            duration: boostTime * 0.5,
          }),
        ]),
      ]).start();
    }
    previousQuantity.current = quantity;
  }, [cart]);

  const quantity = cart?.products.reduce((s, a) => (s += a.quantity || 0), 0);

  return (
    <Pressable onPress={() => dispatch(toggleCart())} testID='cart-icon'>
      <CustomView fadeIn={true} style={styles.wrapper}>
        {!cartEmpty && (
          <Animated.View
            style={[styles.cartPill, { transform: [{ scale: pillAnim }] }]}
          />
        )}
        <Animated.View
          style={[styles.cartWrapper, { transform: [{ scale: sizeAnim }] }]}>
          {!cartEmpty && (
            <View style={styles.count}>
              <BodyText fontSize={19} lineHeight={23} bold={true} color='white'>
                {quantity}
              </BodyText>
            </View>
          )}

          <View style={styles.cartBox}>
            <Cart width={20} height={20} />
          </View>
        </Animated.View>
      </CustomView>
    </Pressable>
  );
};

export default CartIcon;

const styles = StyleSheet.create({
  wrapper: {
    ...globalStyles.flexRow,
    justifyContent: 'center',
    marginLeft: 22,
    marginRight: 14,
    width: 65,
  },
  cartPill: {
    position: 'absolute',
    width: '120%',
    height: '150%',
    backgroundColor: colors.secondary,
    borderRadius: 20,
    zIndex: -1,
  },
  cartWrapper: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-end',
    width: '100%',
    height: 24,
  },
  count: {
    ...globalStyles.flexRow,
    width: '50%',
    paddingRight: 0,
    paddingBottom: 2,
  },
  cartBox: {
    width: '46%',
  },
});

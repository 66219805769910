import React from 'react';
import { View, Platform } from 'react-native';

import WebPaymentFields from './WebPaymentFields';
import NativePaymentFields from './NativePaymentFields';

import styles from './styles';

export default class PaymentDetails extends React.Component {
  render() {
    const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

    return (
      <View style={styles.paymentWrapper}>
        {isNative ? <NativePaymentFields /> : <WebPaymentFields />}
      </View>
    );
  }
}

import React from 'react';
import { View, Animated } from 'react-native';

import { animate } from '../../helpers';

import { Star } from '..';

import styles from './styles';

const starQuantity = 20;

type ShootingStarType = {
  size: number;
  duration: number;
  origin: { x: number; y: number };
  destination: { x: number; y: number };
};

interface Props {
  couponSize: { width: number; height: number };
}

interface State {
  starsLoaded: boolean;
}
export default class NewCouponStars extends React.Component<Props, State> {
  state: State = { starsLoaded: false };
  shootingStars: ShootingStarType[] = [];
  starAnims = [...Array(starQuantity)].map(() => new Animated.Value(0));
  starTimers = [...Array(starQuantity)].map(() =>
    setTimeout(() => undefined, 1000)
  );

  componentDidMount() {
    this.loadStars();
  }

  componentDidUpdate() {
    this.loadStars();
  }

  loadStars = () => {
    if (this.props.couponSize.width > 0 && this.shootingStars.length === 0) {
      this.shootingStars = [...Array(starQuantity)].map((_, idx) =>
        this.createShootingStar(idx, true)
      );

      this.setState({ starsLoaded: true });

      this.starAnims.forEach((s, idx) => {
        const timer = idx * 200;
        this.starTimers[idx] = setTimeout(() => this.moveStar(idx), timer);
      });
    }
  };

  componentWillUnmount(): void {
    this.starTimers.forEach(clearTimeout);
  }

  createShootingStar = (idx: number, initial?: boolean) => {
    const { couponSize } = this.props;
    const deltas = [
      [1, 1],
      [1, -1],
      [-1, -1],
      [-1, 1],
    ];
    const delta = deltas[idx % 4];
    const size = Math.floor(6 + Math.random() * 7);
    const duration = size * 350;

    let remainingX = 0.5 * couponSize.width;
    let remainingY = 0.5 * couponSize.height;

    const origin = {
      x: (0.1 * remainingX + Math.random() * 0.7 * remainingX) * delta[0],
      y: initial
        ? 0.93 * remainingY * delta[1]
        : (0.85 * remainingY + Math.random() * 0.1 * remainingY) * delta[1],
    };

    remainingX -= Math.abs(origin.x);
    remainingY -= Math.abs(origin.y);

    const destination = {
      x: origin.x + Math.random() * (0.4 * remainingX * delta[0]),
      // origin.x +
      // (0.05 * remainingX + Math.random() * 0.5 * remainingX) * delta[0],
      y: origin.y + (remainingY + 5 + Math.random() * 10) * delta[1],
    };

    return { size, duration, destination, origin };
  };

  moveStar = (idx: number) => {
    const anim = this.starAnims[idx];
    anim.setValue(0);
    const star = this.shootingStars[idx];
    animate(anim, {
      toValue: 1,
      duration: star.duration,
    }).start(() => {
      this.shootingStars[idx] = this.createShootingStar(idx);
      const timer = 50 + Math.random() * 1000;
      this.starTimers[idx] = setTimeout(() => this.moveStar(idx), timer);
    });
  };

  render() {
    return (
      <View style={styles.testingStars}>
        {this.shootingStars.map((star, idx) => {
          const anim = this.starAnims[idx];

          const translateX = anim.interpolate({
            inputRange: [0, 1],
            outputRange: [star.origin.x, star.destination.x],
          });

          const translateY = anim.interpolate({
            inputRange: [0, 1],
            outputRange: [star.origin.y, star.destination.y],
          });

          const opacity = anim.interpolate({
            inputRange: [0, 0.2, 0.9, 1],
            outputRange: [0, 1, 1, 0],
          });
          return (
            <Animated.View
              style={[
                styles.starBox,
                {
                  opacity,
                  transform: [{ translateX }, { translateY }],
                },
              ]}
              key={idx}>
              <Star width={star.size} height={star.size} color='barnRed' />
            </Animated.View>
          );
        })}
      </View>
    );
  }
}

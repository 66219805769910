import React, { useEffect, useRef, useState } from 'react';
import { ViewProps, ViewStyle, Animated } from 'react-native';
import { animate } from '../helpers';

interface Props extends ViewProps {
  grow: boolean;
  shrinkBack?: boolean;
  fullWidth?: boolean;
  initialHeight?: number;
  maxHeight?: number;
  duration?: number;
  shrinkDuration?: number;
  children: React.ReactNode;
}

const GrowView = (props: Props) => {
  const {
    children,
    fullWidth,
    maxHeight,
    duration,
    initialHeight,
    grow,
    shrinkBack,
    shrinkDuration,
  } = props;

  const [grown, setGrown] = useState(false);

  const maxHeightAnim = useRef(new Animated.Value(initialHeight || 0)).current;

  useEffect(() => {
    if (grow && !grown) {
      animate(maxHeightAnim, {
        toValue: maxHeight || 100,
        duration: duration || 700,
      }).start(() => setGrown(true));
    } else if (!grow && grown && shrinkBack) {
      const actualDuration =
        shrinkDuration !== undefined ? shrinkDuration : duration || 400;
      animate(maxHeightAnim, {
        toValue: initialHeight || 0,
        duration: actualDuration,
      }).start(() => {
        setGrown(false);
      });
    }
  }, [grow, grown]);

  const extraStyle: ViewStyle = {};
  if (fullWidth) extraStyle.width = '100%';

  return (
    <Animated.View
      {...props}
      style={[
        props.style,
        extraStyle,
        { maxHeight: maxHeightAnim, overflow: 'hidden' },
      ]}>
      {children}
    </Animated.View>
  );
};

export default GrowView;

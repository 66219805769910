// export const updateQueryParam = (
//   param: string,
//   newval: string,
//   search: any
// ) => {
//   const regex = new RegExp('([?;&])' + param + '[^&;]*[;&]?');
//   const query = search.replace(regex, '$1').replace(/&$/, '');

//   return (
//     (query.length > 2 ? query + '&' : '?') +
//     (newval ? param + '=' + newval : '')
//   );
// };

export const readQueryParam = (param: string) => {
  if (!window || !window.location) return '';
  const { search } = window.location;
  if (!search) return '';
  const urlParams = new URLSearchParams(search);
  const value = urlParams.get(param);
  return value;
};

export const removeQueryParam = (param: string) => {
  const { search } = window.location;
  const urlParams = new URLSearchParams(search);
  urlParams.delete(param);
  const newSearch = urlParams.toString();

  let newUrl = window.location.origin + window.location.pathname;
  if (newSearch) newUrl += '?' + newSearch;
  window.history.replaceState(null, '', newUrl);
};

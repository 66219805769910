import { StyleSheet } from 'react-native';
import { colors } from '../../helpers';

const styles = StyleSheet.create({
  pill: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: 60,
    borderRadius: 50,
    backgroundColor: colors.offWhite,
  },
  soldOut: {
    position: 'absolute',
    right: -10,
    top: -5,
    paddingVertical: 2,
    paddingHorizontal: 8,
    // borderRadius: 6,
    fontSize: 12,
    transform: [{ rotate: '16deg' }],
    // backgroundColor: colors.yellow,
  },
});

export default styles;

import React, { useCallback, useState, useRef, createRef } from 'react';
import { ScrollView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import {
  readGenericMenu,
  setActiveProduct,
  setActiveCombo,
} from '../../globalStore';

import {
  MenuItems,
  ImageMenuItems,
  MenuCategoryHeader,
  CustomView,
  Block,
} from '../../components';

import styles from './styles';

const GenericMenu = () => {
  const dispatch = useDispatch();
  const menu = useSelector(readGenericMenu);
  const isMobile = useSelector((state: RootState) => state.appInfo.isMobile);

  const [scrollY, setScrollY] = useState(0);
  const [categoryScroll, setCategoryScroll] = useState(0);
  const [categoryLocations, setCategoryLocations] = useState<number[]>([]);
  const [menuView, setMenuView] = useState<0 | 1>(0);

  const scrollRef: React.RefObject<any> = useRef(createRef());

  const checkScroll = useCallback((e: any) => {
    const scrollY = e.nativeEvent.contentOffset.y;
    setScrollY(scrollY);
  }, []);

  const updateCategoryLocation = useCallback(
    (idx: number, location: number) => {
      setCategoryLocations((prevLocations) => {
        const newLocations = [...prevLocations];
        newLocations[idx] = location;
        return newLocations;
      });
    },
    []
  );

  const clickCategory = useCallback(
    (categoryIdx: number) => {
      if (!categoryLocations) return;
      if (categoryIdx === 0) return scrollRef.current.scrollTo({ y: 0 });
      const scrollLocation = categoryLocations[categoryIdx];
      if (scrollLocation !== null)
        scrollRef.current.scrollTo({ y: scrollLocation });
    },
    [categoryLocations]
  );

  const clickProduct = useCallback((product: ProductType) => {
    dispatch(setActiveProduct(product));
  }, []);

  const clickCombo = (combo: ComboType) => {
    dispatch(setActiveCombo(combo));
  };

  if (!menu) return null;

  const { categories, productLibrary } = menu;

  return (
    <>
      <Block height={10} />
      <MenuCategoryHeader
        categories={categories}
        categoryScroll={categoryScroll}
        clickCategory={clickCategory}
      />

      <ScrollView
        onScroll={checkScroll}
        scrollEventThrottle={16}
        ref={scrollRef}>
        <CustomView
          style={[styles.pageWrapper, isMobile && styles.mobileWrapper]}>
          {menuView === 0 ? (
            <ImageMenuItems
              categories={categories}
              productLibrary={productLibrary}
              clickProduct={clickProduct}
              clickCombo={clickCombo}
              scrollY={scrollY}
              setCategoryScroll={(categoryIdx: number) =>
                setCategoryScroll(categoryIdx)
              }
              updateCategoryLocation={updateCategoryLocation}
              menuView={menuView}
              setMenuView={setMenuView}
            />
          ) : (
            <MenuItems
              categories={categories}
              productLibrary={productLibrary}
              clickProduct={clickProduct}
              clickCombo={clickCombo}
              scrollY={scrollY}
              setCategoryScroll={(categoryIdx) =>
                setCategoryScroll(categoryIdx)
              }
              updateCategoryLocation={updateCategoryLocation}
              menuView={menuView}
              setMenuView={setMenuView}
            />
          )}
        </CustomView>
      </ScrollView>
    </>
  );
};

export default GenericMenu;

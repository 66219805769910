import React, { useCallback, useRef } from 'react';
import { View, Pressable, Image, Animated, Easing } from 'react-native';

import { BodyText, TitleText } from '../../';

import {
  convertMenuPrice,
  getProductImage,
  animate,
  colors,
} from '../../../helpers';

import { YellowSoldOut } from '../../../assets';

import styles from './styles';

interface Props {
  product: ProductType;
  selectProduct: (product: ProductType) => void;
}

let pressTimer = setTimeout(() => undefined);

const ProductButton = ({ product, selectProduct }: Props) => {
  const pressAnim = useRef(new Animated.Value(0)).current;

  const onPressIn = useCallback(() => {
    pressTimer = setTimeout(() => {
      animate(pressAnim, {
        toValue: 1,
        duration: 140,
        easing: Easing.out(Easing.ease),
      }).start();
    }, 100);
  }, []);

  const onPressOut = useCallback(() => {
    clearTimeout(pressTimer);
    animate(pressAnim, { toValue: 0, duration: 100 }).start();
  }, []);

  const onPress = useCallback(() => {
    selectProduct(product);
  }, []);

  const productImage = getProductImage(product);
  const isPizza = product.category === 'Pizzas';
  const isDrink = product.category === 'Drinks';
  const isDip = product.category === 'Dips';

  const faceScale = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.99],
  });

  const shift = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 3],
  });

  const downShift = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 2.5],
  });

  const shrink = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['3%', '2.9%'],
  });

  const size = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['99.2%', '96.5%'],
  });

  const shiftUp = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -2],
  });

  const shiftDownLeft = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0],
  });

  const shiftLeft = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -2],
  });

  let nameSize = 34;
  let nameHeight = 30;
  if (!isPizza && product.name.length > 15) {
    nameSize = 28;
    nameHeight = 28;
  }

  return (
    <View style={[styles.buttonBox, !isPizza && styles.sideButton]}>
      <Pressable
        style={[
          styles.button,
          // @ts-ignore WebkitUserSelect needed for web to suppress long press context menu on safari
          { WebkitUserSelect: 'none', userSelect: 'none' },
        ]}
        onPressIn={onPressIn}
        onPressOut={onPressOut}
        onPress={onPress}
        onLongPress={onPress}
        disabled={!product.inStock}
        testID={`${product.category?.toLowerCase() || 'item'}-menu-item`}>
        {!product.inStock && (
          <View style={styles.stockImage}>
            <YellowSoldOut width={70} height={70} />
          </View>
        )}
        <Animated.View
          style={[
            styles.face,
            {
              opacity: product.inStock ? 1 : 1,
              backgroundColor: product.inStock ? colors.secondary : colors.grey,
              transform: [
                { translateX: shift },
                { translateY: downShift },
                { scale: faceScale },
              ],
            },
          ]}>
          <View style={styles.priceTag}>
            <BodyText
              style={styles.productPrice}
              color={product.inStock ? 'white' : 'textDark'}
              testID='menu-item-product-price'>
              {convertMenuPrice(product)}
            </BodyText>
          </View>
          <View style={[styles.imageBox, !isPizza && styles.otherImageBox]}>
            <Image
              source={productImage}
              resizeMode={isPizza ? 'contain' : 'contain'}
              style={[
                isPizza && styles.pizzaImage,
                isDip && styles.dipImage,
                isDrink && styles.drinkImage,
                !(isPizza || isDip || isDrink) && styles.otherImage,
              ]}
            />
          </View>

          <View style={[styles.textBox, !isPizza && styles.sideText]}>
            <TitleText
              fontSize={nameSize}
              lineHeight={nameHeight}
              color={product.inStock ? 'white' : 'textDark'}
              center={true}
              secondary={true}
              // bold={true}
              mt={10}
              testID='menu-item-product-name'>
              {product.name.toUpperCase()}
            </TitleText>
            <BodyText
              fontSize={14}
              color='white'
              center={true}
              hoverColor='text'>
              {product.inStock ? product.description : ''}
            </BodyText>
          </View>
        </Animated.View>
        <Animated.View
          style={[
            styles.rightEdge,
            {
              width: shrink,
              height: size,
              transform: [
                { rotate: '00deg' },
                { skewY: '45deg' },
                { translateX: shiftLeft },
              ],
            },
          ]}
        />
        <Animated.View
          style={[
            styles.bottomEdge,
            {
              height: shrink,
              width: size,
              transform: [
                { rotate: '0deg' },
                { skewX: '45deg' },
                { translateY: shiftUp },
                { translateX: shiftDownLeft },
                { scaleX: 1.01 },
              ],
            },
          ]}
        />
      </Pressable>
    </View>
  );
};

export default ProductButton;

import React, { useCallback } from 'react';
import { Pressable } from 'react-native';

import { LinkProps, useNavigate } from 'react-router-native';

interface Props extends LinkProps {
  children?: React.ReactNode;
}

const NewLink = ({ to, children, style }: Props) => {
  const navigate = useNavigate();

  const goto = useCallback(() => {
    navigate(to);
  }, [to]);

  return (
    <Pressable onPress={goto} style={style}>
      {children}
    </Pressable>
  );
};

export default NewLink;

import React from 'react';
import {
  View,
  ScrollView,
  Animated,
  Pressable,
  SafeAreaView,
  Platform,
} from 'react-native';
import { connect } from 'react-redux';
import { isEqual, debounce, throttle } from 'lodash';

import {
  withStoreId,
  calculatePrice,
  addFocusListener,
  logError,
  animate,
} from '../../helpers';

import {
  toggleCart,
  readCart,
  validateCart,
  createOrder,
  clearCart,
  setLoginModal,
  setOrderSuccessModal,
  loadUserOrders,
  loadCarts,
  checkCartMenus,
  updateStoreStatus,
  inputCoupon,
  loadMenu,
  throttledLoadUserItems,
} from '../../globalStore';

import {
  BodyText,
  TitleText,
  EmptyCart,
  Button,
  Block,
} from '../../components';

import CartItem from './CartItem';
import Upsell from './Upsell';
import OrderDetails from './OrderDetails';
import ContactDetails from './ContactDetails';
import PaymentDetails from './PaymentDetails';

import { CartIcon, Close, ErrorIcon } from '../../assets';
import styles from './styles';

const isNative = Platform.OS === 'ios' || Platform.OS === 'android';
const ContentView = isNative ? View : SafeAreaView;

interface Props {
  storeId?: string;
  readCart: (storeId: string) => CartType;
  validateCart: (storeId: string) => Promise<void>;
  createOrder: (storeId: string) => Promise<{ error?: { message: string } }>;
  displayCart: boolean;
  toggleCart: () => void;
  clearCart: (storeId: string) => void;
  isMobile: boolean;
  user: UserType;
  setLoginModal: (needed: boolean) => void;
  setOrderSuccessModal: (order: OrderType) => void;
  loadUserOrders: () => void;
  cart: CartType | null;
  screenSize: { height: number };
  loadCarts: (payload: { storeId?: string }) => void;
  stores: StoreType[];
  checkCartMenus: (stores: StoreType[]) => void;
  updateStoreStatus: ({
    storeId,
    waitTime,
  }: {
    storeId: string;
    waitTime: number;
  }) => void;
  inputCoupon: ({
    storeId,
    coupon,
  }: {
    storeId: string;
    coupon: CouponType;
  }) => void;
  loadMenu: (storeId: string) => void;
  throttledLoadUserItems: (payload: UserItemsPayload) => void;
}

interface State {
  widthAnim: Animated.Value;
  leftAnim: Animated.Value;
  paymentStep: boolean;
  submitting: boolean;
  awaitingName: boolean;
  localCoupon: string;
  lastError: number;
  showNameError: boolean;
}

class Cart extends React.Component<Props, State> {
  state: State = {
    widthAnim: new Animated.Value(0),
    leftAnim: new Animated.Value(1400),
    paymentStep: false,
    submitting: false,
    awaitingName: false,
    localCoupon: '',
    lastError: 0,
    showNameError: false,
  };

  contentOpacity = new Animated.Value(0);

  scrollRef: ScrollView | null = null;
  mountedTime = 0;
  needsValidation = false;
  focusListenerId = 0;

  lastValidateTime = Date.now();

  lastOrderFetch = Date.now();

  componentDidMount() {
    const { storeId } = this.props;
    this.mountedTime = Date.now();
    this.loadCarts({ storeId });
    this.fadeInContent();
    this.focusListenerId = addFocusListener(this.focusUp);
  }

  fadeInContent = () => {
    animate(this.contentOpacity, {
      toValue: 1,
      duration: 400,
      delay: 1400,
    }).start(() => this.contentOpacity.setValue(1));
    setTimeout(() => this.contentOpacity.setValue(1), 2000);
  };

  onFocus = () => {
    const { cart, storeId, user } = this.props;
    this.loadCarts({ storeId });
    const now = Date.now();
    if (now - this.lastValidateTime > 10000)
      if (cart && storeId && user?.token) this.validateCart(storeId);

    const diff = Date.now() - this.lastOrderFetch;
    if (diff > 60000 * 3) {
      this.lastOrderFetch = Date.now();
      this.props.loadUserOrders();
    }
  };

  focusUp = throttle(this.onFocus, 1000, {
    leading: true,
  });

  loadCarts = throttle(this.props.loadCarts, 1000, {
    leading: true,
  });

  fakeValidate = (storeId: string) => {
    const { paymentStep } = this.state;
    if (!paymentStep && storeId) {
      this.lastValidateTime = Date.now();
      return this.props.validateCart(storeId);
    }
  };

  validateCart = debounce(this.fakeValidate, 1000, {
    leading: true,
    trailing: true,
  });

  // validateCart = debounce(this.props.validateCart, 1000, {
  //   leading: true,
  //   trailing: true,
  // });

  loadMenu = throttle(this.props.loadMenu, 10000, {
    leading: true,
    trailing: true,
  });

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { storeId, user } = this.props;

    if (!storeId && !!prevProps.storeId && !!this.props.displayCart)
      this.props.toggleCart();

    if (
      !!this.props.cart?.stockErrors.length &&
      !isEqual(prevProps.cart?.stockErrors, this.props.cart?.stockErrors) &&
      !!storeId
    )
      this.loadMenu(storeId);

    if (!prevProps.displayCart && !!this.props.displayCart) {
      const now = Date.now();
      if (now - this.mountedTime < 800) this.quickShow();
      else this.showCart();
    } else if (prevProps.displayCart && !this.props.displayCart)
      this.hideCart();

    const loggedIn = !!user && !!user.token;

    const couponChange =
      (prevProps.cart?.couponInput !== this.props.cart?.couponInput &&
        this.props.cart?.couponInput) ||
      this.props.cart?.appliedCoupon?.slug !==
        prevProps.cart?.appliedCoupon?.slug ||
      (!!this.props.cart?.pricing?.coupon &&
        this.props.cart.pricing.coupon.slug !==
          prevProps.cart?.pricing?.coupon?.slug);

    const cartChanges =
      (this.props.cart &&
        this.props.cart?.products.length > 0 &&
        !isEqual(prevProps.cart?.products, this.props.cart?.products)) ||
      !!couponChange;

    if (storeId && loggedIn && (cartChanges || this.needsValidation)) {
      this.needsValidation = false;
      setTimeout(() => {
        this.validateCart(storeId);
      }, 10);
    }

    if (!this.needsValidation)
      this.needsValidation = !!cartChanges && !loggedIn;

    if (cartChanges && this.state.paymentStep)
      this.setState({ paymentStep: false });

    const storeChanges = !isEqual(prevProps.stores, this.props.stores);
    if (storeChanges) this.props.checkCartMenus(this.props.stores);

    if (this.props.cart) {
      const waitTime = this.props.cart.waitTime || 0;

      let storeClosed = this.props.cart.validateErrors.some(
        (e) => e === 'The store is closed for new orders.'
      )
        ? true
        : undefined;

      if (this.props.cart.validated) storeClosed = false;
      const payload = { waitTime, storeClosed };

      if (storeId) this.props.updateStoreStatus({ storeId, ...payload });
    }
  }

  quickShow = () => {
    this.state.leftAnim.setValue(0);
    this.state.widthAnim.setValue(375);
  };

  showCart = () => {
    this.props.throttledLoadUserItems(['coupons']);
    this.contentOpacity.setValue(1);
    this.scrollRef?.scrollTo({ y: 0 });
    Animated.parallel([
      animate(this.state.leftAnim, {
        toValue: 0,
        duration: 300,
      }),
      animate(this.state.widthAnim, {
        toValue: 375,
        duration: 300,
      }),
    ]).start();
  };

  hideCart = () => {
    this.setState({
      paymentStep: false,
      submitting: false,
    });
    Animated.parallel([
      animate(this.state.leftAnim, {
        toValue: 1400,
        duration: 300,
      }),
      animate(this.state.widthAnim, {
        toValue: 0,
        duration: 300,
      }),
    ]).start();
  };

  updateCoupon = (coupon: string) => this.setState({ localCoupon: coupon });

  enterCoupon = async () => {
    const { storeId } = this.props;
    if (!storeId) return;
    const { localCoupon } = this.state;
    const coupon = { slug: localCoupon };
    this.props.inputCoupon({ storeId, coupon });
    await this.validateCart(storeId);
    if (!this.props.cart?.validated) {
      this.setState({ lastError: Date.now() });
    }
  };

  errorScroll = () => {
    const { cart } = this.props;
    if (!cart) return;

    const { fieldErrors, orderErrors } = cart;
    let isCouponError = false;

    if (fieldErrors.length == 1 && fieldErrors[0].field === 'coupon')
      isCouponError = true;

    if (
      orderErrors.length === 1 &&
      orderErrors[0] ===
        'A specific coupon is required to place an order at this store.'
    )
      isCouponError = true;

    this.setState({ lastError: Date.now() });

    if (!isCouponError) this.scrollRef?.scrollTo({ y: 0 });
  };

  submitAction = async () => {
    const { user, storeId } = this.props;
    const loggedIn = !!user && !!user.token;

    if (!loggedIn) return this.props.setLoginModal(true);

    if (!this.props.cart)
      return logError('Submit action with cart not available');

    this.setState({ submitting: true });

    if (
      !this.props.cart.fieldErrors.length &&
      !this.props.cart.stockErrors.length &&
      storeId
    ) {
      this.enterCoupon();

      await this.validateCart(storeId);

      if (!this.props.cart.validated) {
        this.errorScroll();
        return this.setState({ submitting: false, showNameError: true });
      }

      const createResponse = await this.props.createOrder(storeId);
      const errorResponse = createResponse?.error?.message === 'Rejected';

      if (
        !!errorResponse ||
        !this.props.cart?.validated ||
        !this.props.cart?.order ||
        !this.props.cart?.order.id
      ) {
        this.errorScroll();

        this.validateCart(storeId);
        this.setState({ submitting: false });
        return;
      }

      const { order } = this.props.cart;

      if (order?.state === 'awaiting_payment') {
        this.setState({ paymentStep: true, submitting: false });
      } else {
        if (
          order.state === 'in_progress' ||
          order.state === 'ready_for_handoff'
        ) {
          this.props.setOrderSuccessModal(order);
          this.setState({ localCoupon: '' });
        } else {
          const error = Error(`Order state not expected - ${order.state}`);
          logError(error);
        }
        this.setState({ submitting: false });
        // const orderId = order?.id;
        this.props.clearCart(storeId);
        this.setState({ localCoupon: '' });
      }
    } else {
      if (storeId) await this.validateCart(storeId);
      this.setState({ submitting: false });
      this.errorScroll();
    }
  };

  render() {
    const { toggleCart, isMobile, user, cart, storeId, screenSize, stores } =
      this.props;

    const {
      widthAnim,
      leftAnim,
      paymentStep,
      submitting,
      localCoupon,
      lastError,
      showNameError,
    } = this.state;

    const cartEmpty = !cart || !cart.products || cart.products.length === 0;

    const loggedIn = !!user && !!user.token;

    const calculatedSubtotal =
      cart?.products?.reduce(
        (sum, p) => (sum += p.inStock ? calculatePrice(p) : 0),
        0
      ) || 0;

    const storeClosed =
      !!cart &&
      cart.validateErrors.some(
        (e) => e === 'The store is closed for new orders.'
      );

    const couponRequired =
      !!cart &&
      cart.orderErrors[0] ===
        'A specific coupon is required to place an order at this store.';

    let filteredValidateErrors = cart?.validateErrors || [];

    if (!showNameError)
      filteredValidateErrors = filteredValidateErrors.filter(
        (e) => e !== 'Customer name must be set.'
      );

    const fullOrderErrors = [
      ...filteredValidateErrors,
      ...(cart?.orderErrors || []),
    ];

    const activeStore = stores.find((s) => s.id === storeId);

    const ctaLabel =
      cart?.pricing?.total === 0 || activeStore?.deferPayment
        ? 'PLACE ORDER'
        : 'CONTINUE TO PAYMENT';

    return (
      <Animated.View
        style={[
          styles.cartWrapper,
          isMobile && styles.mobileCartWrapper,
          {
            width: isMobile ? '100%' : widthAnim,
            height: screenSize.height || '100%',
            transform: [{ translateX: leftAnim }],
          },
        ]}>
        <ContentView
          style={[styles.cartContent, isMobile && styles.mobileCartContent]}
          testID='cart-view'>
          <View style={[styles.header, isMobile && styles.mobileHeader]}>
            <View style={styles.headerContent}>
              <CartIcon width={16} height={16} />
              <BodyText
                color='white'
                bold={true}
                fontSize={16}
                lineHeight={20}
                ml={8}
                spacing={1}>
                CART
              </BodyText>
            </View>
            <Pressable
              style={[styles.closeButton, isMobile && styles.mobileCloseButton]}
              onPress={() => toggleCart()}>
              <Close width={16} height={16} />
            </Pressable>
          </View>

          {!cartEmpty &&
            fullOrderErrors.map((e) => (
              <View style={styles.cartError} key={e}>
                <ErrorIcon width={14} />
                <BodyText color='white' ml={10} style={{ width: '90%' }}>
                  {e}
                </BodyText>
              </View>
            ))}

          {cartEmpty ? (
            <View style={styles.emptyCart}>
              <EmptyCart />
            </View>
          ) : paymentStep ? (
            <PaymentDetails />
          ) : (
            <ScrollView
              contentContainerStyle={[
                styles.cartList,
                isMobile && styles.mobileCartList,
              ]}
              testID='cart-scrolling-content'
              ref={(r) => (this.scrollRef = r)}>
              <TitleText color='secondary' fontSize={16} mb={14} spacing={0.4}>
                ITEMS
              </TitleText>

              {cart?.products.map((cartItem, idx) => (
                <CartItem
                  item={cartItem}
                  itemIdx={idx}
                  stockError={cart.stockErrors.includes(idx)}
                  key={cartItem.id + idx}
                />
              ))}

              <Upsell />

              <Animated.View
                style={[{ opacity: this.contentOpacity }, styles.innerContent]}
                testID='cart-inner-content'>
                {!!loggedIn && !!storeId && (
                  <ContactDetails
                    validateCart={() => this.validateCart(storeId)}
                  />
                )}

                {!!loggedIn && !!storeId && (
                  <OrderDetails
                    localCoupon={localCoupon}
                    updateCoupon={this.updateCoupon}
                    enterCoupon={this.enterCoupon}
                    storeId={storeId}
                    pricing={cart.pricing}
                    appliedCoupon={cart.appliedCoupon}
                    calculatedSubtotal={calculatedSubtotal}
                    waitTime={cart.waitTime}
                    fieldErrors={cart.fieldErrors}
                    cartErrors={[...cart.validateErrors, ...cart.orderErrors]}
                    storeClosed={storeClosed}
                    couponRequired={couponRequired}
                    lastError={lastError}
                  />
                )}

                <Block grow={true} height={50} />
                <Button
                  onClick={this.submitAction}
                  submitting={submitting}
                  label={ctaLabel}
                  style={styles.cartButton}
                  testID='cart-view-submit-button'></Button>
                <Block height={20} />
              </Animated.View>
            </ScrollView>
          )}
        </ContentView>
      </Animated.View>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isMobile: state.appInfo.isMobile,
  readCart: (storeId: string) => readCart(state, storeId),
  displayCart: state.cartInfo.displayCart,
  user: state.userInfo.user,
  screenSize: state.appInfo.screenSize,
  stores: state.storeInfo.stores,
});

const mapDispatchToProps = {
  loadCarts,
  toggleCart,
  validateCart,
  createOrder,
  clearCart,
  setLoginModal,
  setOrderSuccessModal,
  loadUserOrders,
  checkCartMenus,
  updateStoreStatus,
  inputCoupon,
  loadMenu,
  throttledLoadUserItems,
};

class CartWrapper extends React.Component<Props> {
  render() {
    const { readCart, storeId } = this.props;
    const cart = storeId ? readCart(storeId) : null;
    return <Cart {...this.props} cart={cart} />;
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStoreId(CartWrapper));

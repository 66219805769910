/* eslint-disable camelcase */
import Stripe from '@stripe/stripe-js';

// import { StripePaymentMethodCard } from '~/core/typings/cart';

export function formatCardPaymentMethod(
  paymentMethod: Stripe.PaymentMethod
): StripePaymentMethodCard {
  const { id, card } = paymentMethod;
  const { last4, brand, exp_month, exp_year } =
    card as Stripe.PaymentMethod.Card;

  return { id, last4, brand, expires: `${exp_month}/${exp_year}` };
}

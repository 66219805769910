import { Platform } from 'react-native';
import { MOCK_TESTING } from '../mockModes';

import {
  useNativeAuth,
  NativeAuthContext,
} from '../providers/NativeAuthProvider';
import { useWebAuth, WebAuthContext } from '../providers/WebAuthProvider';
import { useMockAuth, MockAuthContext } from '../providers/MockAuthProvider';

const setAuthContext = () =>
  MOCK_TESTING
    ? MockAuthContext
    : Platform.OS === 'web'
    ? WebAuthContext
    : NativeAuthContext;

export let AuthContext = setAuthContext();

const setAuth = () =>
  MOCK_TESTING
    ? useMockAuth
    : Platform.OS === 'web'
    ? useWebAuth
    : useNativeAuth;
export let useAuth = setAuth();

export const mockAuth = (mock = true) => {
  if (mock) {
    AuthContext = MockAuthContext;
    useAuth = useMockAuth;
  } else {
    AuthContext = setAuthContext();
    useAuth = setAuth();
  }
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

import { logError, logMessage, uuid } from '../../helpers';

import { refreshUser, unselectCoupon } from '../userSlice';

import {
  getExistingItem,
  getUpsellItems,
  postValidation,
  postCreateOrder,
  saveCartState,
  readStorageCarts,
  removeStorageOrder,
  readCartOpen,
  saveCartOpen,
  removeUrlCoupon,
  fetchOrderInfo,
} from './actions';

export const emptyCart = (): CartType => ({
  products: [],
  upsellIds: [],
  fieldErrors: [],
  stockErrors: [],
  validateErrors: [],
  orderErrors: [],
  menuId: '',
  appliedCoupon: null,
});

type State = {
  storeCarts: { [id: string]: CartType };
  displayCart: boolean;
};

const initialState: State = {
  storeCarts: {},
  displayCart: false,
};

const cartSlice = createSlice({
  name: 'cartInfo',
  initialState,
  reducers: {
    toggleCart: (state) => {
      const displayCart = !state.displayCart;
      saveCartOpen(displayCart);
      state.displayCart = displayCart;
    },
    checkCartMenus: (state, { payload }: { payload: StoreType[] }) => {
      const stores = payload;
      Object.keys(state.storeCarts).forEach((storeId) => {
        const store = stores.find((s) => s.id === storeId);
        if (!store) return;

        const storeCart = state.storeCarts[storeId];
        if (store.menuId !== storeCart.menuId && storeCart.menuId) {
          state.storeCarts[storeId] = emptyCart();
          saveCartState(state.storeCarts);
        }
      });
    },
    addItemToCart: (state, { payload }: { payload: AddItemPayload }) => {
      const { storeId, product, storeMenu } = payload;
      const storeCart = cloneDeep(state.storeCarts[storeId]) || emptyCart();

      if (!storeCart.menuId) storeCart.menuId = storeMenu.id;
      const existingItem = getExistingItem(product, storeCart.products);

      product.cartItemId = uuid();

      if (existingItem)
        existingItem.quantity =
          (existingItem.quantity || 0) + (product.quantity || 1);
      else storeCart.products.push(product);

      storeCart.upsellIds = getUpsellItems(storeCart, storeMenu);

      state.storeCarts[storeId] = storeCart;
      saveCartState(state.storeCarts);
    },
    updateCartItem: (state, { payload }: { payload: UpdateItemPayload }) => {
      const { storeId, product } = payload;
      const storeCart = cloneDeep(state.storeCarts[storeId]);
      const productIdx = storeCart.products.findIndex(
        (p) => p.cartItemId === product.cartItemId
      );
      storeCart.products[productIdx] = product;
      state.storeCarts[storeId] = storeCart;
      saveCartState(state.storeCarts);
    },
    removeCartItem: (state, { payload }: { payload: RemoveItemPayload }) => {
      const { storeId, cartItemId, singleQuantity } = payload;

      const storeCart = cloneDeep(state.storeCarts[storeId]);

      const itemIdx = storeCart.products.findIndex(
        (p) => p.cartItemId === cartItemId
      );

      const item = storeCart.products[itemIdx];
      if (item?.quantity && item.quantity > 1 && singleQuantity) {
        item.quantity -= 1;
      } else storeCart.products.splice(itemIdx, 1);

      const cartEmpty =
        !storeCart || !storeCart.products || storeCart.products.length === 0;
      if (cartEmpty) {
        storeCart.fieldErrors = [];
        storeCart.stockErrors = [];
        storeCart.validateErrors = [];
        storeCart.orderErrors = [];
        storeCart.validated = false;
      }

      state.storeCarts[storeId] = storeCart;
      saveCartState(state.storeCarts);
    },
    inputCoupon: (state, { payload }: CouponPayload) => {
      const { storeId, coupon } = payload;
      const storeCart = cloneDeep(state.storeCarts[storeId]) || emptyCart();
      storeCart.couponInput = coupon ? coupon.slug.toUpperCase() : '';
      storeCart.removedCoupon = null;
      state.storeCarts[storeId] = storeCart;
    },
    applyCoupon: (state, { payload }: CouponPayload) => {
      const { storeId, coupon } = payload;
      const storeCart = cloneDeep(state.storeCarts[storeId]) || emptyCart();
      storeCart.appliedCoupon = coupon;
      storeCart.removedCoupon = null;
      state.storeCarts[storeId] = storeCart;
      saveCartState(state.storeCarts);
    },
    removeCartCoupon: (state, { payload }: { payload: RemovePayload }) => {
      const { storeId } = payload;
      const storeCart = cloneDeep(state.storeCarts[storeId]);
      if (!storeCart) return;
      storeCart.couponInput = '';
      storeCart.removedCoupon = storeCart.appliedCoupon;
      storeCart.appliedCoupon = null;
      if (storeCart.pricing) storeCart.pricing.coupon = null;
      state.storeCarts[storeId] = storeCart;
      removeUrlCoupon();
      saveCartState(state.storeCarts);
    },
    clearCart: (state, { payload }: { payload: string }) => {
      const storeId = payload;
      state.storeCarts[storeId] = emptyCart();
      state.displayCart = false;
      saveCartState(state.storeCarts);
      saveCartOpen(false);
    },
    clearAllCarts: (state) => {
      state.storeCarts = {};
      saveCartState(state.storeCarts);
    },
  },
  extraReducers(builder) {
    builder.addCase(
      validateCart.fulfilled,
      (state, { payload }: { payload: ValidatePayload | undefined }) => {
        if (!payload) return;
        const pricing = payload?.pricing;
        const validationId = payload?.validationId;
        const storeId = payload?.storeId;

        if (!storeId || !pricing) return;
        const storeCart = cloneDeep(state.storeCarts[storeId]);
        if (!storeCart) return;
        storeCart.pricing = pricing;
        storeCart.waitTime = payload?.waitTime;
        storeCart.validationId = validationId;
        storeCart.couponInput = '';
        if (pricing.coupon?.slug) {
          if (storeCart.appliedCoupon?.slug === pricing.coupon.slug)
            storeCart.appliedCoupon.description = pricing.coupon?.description;
          else if (
            !storeCart.appliedCoupon?.slug &&
            pricing.coupon?.slug !== storeCart.removedCoupon?.slug
          ) {
            storeCart.appliedCoupon = pricing.coupon;
          }
        }
        storeCart.fieldErrors = [];
        storeCart.stockErrors = [];
        storeCart.validateErrors = [];
        storeCart.validated = true;
        state.storeCarts[storeId] = storeCart;

        if (storeCart.appliedCoupon)
          storeCart.orderErrors = storeCart.orderErrors.filter(
            (e) =>
              e !==
              'A specific coupon is required to place an order at this store.'
          );

        saveCartState(state.storeCarts);
      }
    );
    builder.addCase(validateCart.rejected, (state, { payload }: any) => {
      if (!payload) return;
      const {
        storeId,
        fieldErrors,
        stockErrors,
        validateErrors,
        orderErrors,
        appError,
      } = payload;

      if (appError) {
        logMessage(appError);
        return;
      }

      if (storeId) {
        const storeCart = cloneDeep(state.storeCarts[storeId]);
        storeCart.fieldErrors = fieldErrors || [];
        storeCart.stockErrors = stockErrors || [];
        storeCart.validateErrors = validateErrors || [];
        storeCart.orderErrors = orderErrors || [];
        storeCart.couponInput = '';

        const couponError = storeCart.fieldErrors.find(
          (e) => e.field === 'coupon'
        );
        if (
          couponError?.message === 'Coupon is not valid.' ||
          storeCart.validateErrors.includes(
            'A specific coupon is required to place an order at this store.'
          )
        ) {
          storeCart.appliedCoupon = null;
        }
        storeCart.validated = false;
        storeCart.pricing = undefined;
        state.storeCarts[storeId] = storeCart;
        saveCartState(state.storeCarts);
      }
    });
    builder.addCase(createOrder.fulfilled, (state, { payload }: any) => {
      if (!payload) return;
      const { order, storeId } = payload;
      if (storeId) {
        const storeCart = cloneDeep(state.storeCarts[storeId]);
        storeCart.order = order;
        storeCart.orderErrors = [];
        state.storeCarts[storeId] = storeCart;
        saveCartState(state.storeCarts);
        return;
      }
    });
    builder.addCase(createOrder.rejected, (state, { payload }: any) => {
      const { appError, storeId } = payload;
      if (storeId) {
        const storeCart = cloneDeep(state.storeCarts[storeId]);
        storeCart.orderErrors = [appError];

        const couponError =
          appError ===
          'A specific coupon is required to place an order at this store.';

        if (couponError) {
          storeCart.couponInput = '';
          storeCart.appliedCoupon = null;
          if (storeCart.pricing) {
            storeCart.pricing.coupon = null;
          }
        }

        state.storeCarts[storeId] = storeCart;

        return;
      }
    });
    builder.addCase(loadCarts.fulfilled, (state, { payload }: any) => {
      if (!payload) return;
      const { storeCarts, displayCart } = payload;
      if (!storeCarts) return;

      state.storeCarts = storeCarts;
      state.displayCart = !!displayCart;
    });
  },
});

export const loadCarts = createAsyncThunk(
  'cart/loadCarts',
  async (payload: { storeId: string }, { getState }) => {
    const { storeId } = payload;
    const storeState = getState() as RootState;
    const storageCarts = await readStorageCarts();
    const storeCarts = cloneDeep(storeState.cartInfo.storeCarts);

    let displayCart = storeState.cartInfo.displayCart;

    Object.keys(storageCarts).forEach((storeId) => {
      const storageCart = storageCarts[storeId];
      const storeCart = storeCarts[storeId] || emptyCart();

      storageCart.products.forEach((p) => {
        if (!p.cartItemId) p.cartItemId = uuid();
      });
      if (!storageCart.products.length) return;

      if (
        storeCart.menuId &&
        storageCart.menuId &&
        storageCart.products.length > 0 &&
        storeCart.menuId !== storageCart.menuId
      )
        return;

      storeCart.products = storageCart.products;
      storeCart.upsellIds = storageCart.upsellIds;
      if (!storeCart.menuId) storeCart.menuId = storageCart.menuId;
      if (
        !storeCart.pricing?.coupon?.slug &&
        !storeCart.appliedCoupon?.slug &&
        storageCart.coupon?.slug
      )
        storeCart.appliedCoupon = storageCart.coupon;

      if (!storeCart.order && storageCart.order) {
        if (
          existingOrderValid({
            ...storeCart,
            pricing: storeCart.pricing || storageCart.order.cartState?.pricing,
            order: storageCart.order,
          })
        )
          storeCart.order = storageCart.order;
        else removeStorageOrder(storeId);
      }

      storeCarts[storeId] = storeCart;
    });

    let cartEmpty = false;
    if (storeId) {
      const activeCart = storeCarts[storeId];
      if (
        !activeCart ||
        !activeCart.products ||
        activeCart.products.length === 0
      )
        cartEmpty = true;
    }

    const cartOpen = await readCartOpen();
    displayCart = !!cartOpen && !cartEmpty;
    return { storeCarts, displayCart };
  }
);

export const validateCart = createAsyncThunk(
  'cart/validateCart',
  async (storeId: string, { getState, rejectWithValue, dispatch }) => {
    const storeState = getState() as RootState;
    const { user } = storeState.userInfo;
    if (!user.loaded || !user.token) {
      logMessage('User not authenticated when validating');
      return rejectWithValue({ appError: 'no user present', storeId });
    }

    const storeCart = storeState.cartInfo.storeCarts[storeId];
    if (!storeCart)
      return rejectWithValue({
        appError: 'cart not found for store -',
        storeId,
      });

    if (user.token) {
      const validationResults = await postValidation(storeCart, storeId, user);
      const fieldErrors = validationResults.fieldErrors || [];
      const stockErrors = validationResults.stockErrors || [];
      const validateErrors = validationResults.validateErrors || [];
      const orderErrors = validationResults.orderErrors || [];
      let hasCouponError = false;
      const couponError = fieldErrors.find((e) => e.field === 'coupon');
      if (
        !!couponError?.message ||
        couponError?.message === 'Coupon is not valid.' ||
        couponError?.message ===
          'This coupon has already been used. Please use a different coupon or remove the coupon to continue.' ||
        validateErrors.includes(
          'A specific coupon is required to place an order at this store.'
        )
      ) {
        hasCouponError = true;
      }

      // if (hasCouponError) dispatch(unselectCoupon(storeId));

      if (
        !isEmpty(fieldErrors) ||
        !isEmpty(stockErrors) ||
        !isEmpty(validateErrors) ||
        !isEmpty(orderErrors)
      ) {
        return rejectWithValue({
          fieldErrors,
          stockErrors,
          orderErrors,
          validateErrors,
          hasCouponError,
          storeId,
        });
      }

      const { pricing, validationId } = validationResults;
      const waitTime = validationResults.waitTime || 0;
      if (pricing && validationId)
        return { pricing, waitTime, validationId, storeId };

      return rejectWithValue({ storeId, orderErrors: ['cart not validated'] });
    }
  }
);

export const createOrder = createAsyncThunk(
  'cart/createOrder',
  async (storeId: string, { getState, rejectWithValue, dispatch }) => {
    const globalState = getState() as RootState;
    const { user } = globalState.userInfo;
    const { urlTags } = globalState.appInfo;
    const storeCart = globalState.cartInfo.storeCarts[storeId];

    if (!storeCart)
      return rejectWithValue({
        appError: 'cart not found for store -',
        storeId,
      });

    if (!user.token) {
      logError('User not authenticated to create order');
      return rejectWithValue({ appError: 'user not present' });
    }

    if (storeCart.order && user.token) {
      if (existingOrderValid(storeCart)) {
        try {
          const orderInfo = await fetchOrderInfo(
            storeCart.order.id,
            user.token
          );
          if (orderInfo?.state === 'awaiting_payment')
            return { order: storeCart.order, storeId };
          else if (orderInfo?.state)
            logMessage(
              `Existing order is not in awaiting payment - ${storeCart.order.id}`
            );
          else
            logMessage(`Could not retrieve order info - ${storeCart.order.id}`);
        } catch (e) {
          const error = e as Error;
          logError(error);
        }
      }
    }
    if (user.token) {
      const response = await postCreateOrder(storeCart, storeId, user, urlTags);
      const { order, error } = response;
      if (error === 'First and last name are required.')
        dispatch(refreshUser());

      if (error) return rejectWithValue({ appError: error, storeId });
      if (!order) return rejectWithValue({ appError: 'order not created' });

      const cartState = createCartState(storeCart);
      if (cartState) order.cartState = cartState;
      return { order, storeId };
    }
  }
);

const createCartState = (cart: CartType) => {
  if (!cart.pricing || !cart.products || !cart.menuId) return;
  return {
    products: [...cart.products],
    pricing: { ...cart.pricing },
    menuId: cart.menuId || '',
  };
};

const existingOrderValid = (cart: CartType) => {
  const newCartState = createCartState(cart);
  if (!newCartState || !cart.order?.createdAt || !cart.order?.cartState)
    return false;

  const pastOrderTime = new Date(cart.order.createdAt).getTime();
  const now = Date.now();
  const timeDiff = now - pastOrderTime;
  const eightMins = 1000 * 60 * 8;
  if (timeDiff > eightMins) return false;

  return isEqual(newCartState, cart.order.cartState);
};

export default cartSlice;

import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    elevation: 10,
  },
  copy: {
    marginTop: 15,
    fontSize: 24,
    color: 'white',
  },
  logo: {
    transform: [{ scale: 1 }],
    width: 820,
    height: 540,
  },
  puppy: {
    width: 200,
    height: 200,
    marginVertical: 30,
  },
});

export default styles;

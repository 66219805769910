import React from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-native';

const withRouter = (Component: typeof React.Component) => {
  return function WrapComponent(props: Record<string, unknown>) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();

    const { storeId } = params;
    return (
      <Component
        {...props}
        navigate={navigate}
        params={params}
        location={location}
        storeId={storeId}
      />
    );
  };
};

export default withRouter;

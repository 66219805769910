import React from 'react';
import { Platform } from 'react-native';

import WebMapPreview from './WebMapPreview';
import NativeMapPreview from './NativeMapPreview';

interface Props {
  store: StoreType;
  setMapError?: (hasError: boolean) => void;
  disabled?: boolean;
  zoomedIn?: boolean;
}

const MapPreview = ({ store, setMapError, disabled, zoomedIn }: Props) => {
  const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

  if (isNative)
    return (
      <NativeMapPreview
        store={store}
        setMapError={setMapError}
        disabled={disabled}
        zoomedIn={zoomedIn}
      />
    );
  return (
    <WebMapPreview
      store={store}
      setMapError={setMapError}
      disabled={disabled}
      zoomedIn={zoomedIn}
    />
  );
};

export default MapPreview;

import { Platform } from 'react-native';
import { shuffle, cloneDeep, isEqual } from 'lodash';

import {
  fetch,
  getOrderLines,
  removeQueryParam,
  readStorage,
  writeStorage,
  getOrderSource,
  logError,
  flattenObjectErrors,
} from '../../helpers';

import { formatCardPaymentMethod } from './formatPaymentMethods';

type StoreCarts = { [id: string]: CartType };
type StorageCarts = { [id: string]: StorageCartType };

let saveCount = 0;
export const removeStorageOrder = async (storeId: string) => {
  saveCount += 1;
  const storageCarts = await readStorageCarts();
  delete storageCarts[storeId].order;
  const updatedAt = new Date().getTime();
  writeStorage('cartStorage', { bareStoreCarts: storageCarts, updatedAt });
};

export const saveCartState = async (storeCarts: StoreCarts) => {
  const carts = cloneDeep(storeCarts);
  saveCartsToStorage(carts);
};

export const saveCartsToStorage = async (storeCarts: StoreCarts) => {
  saveCount += 1;
  const currentSaveCount = saveCount;
  const bareStoreCarts: StorageCarts = {};

  const storageCarts = await readStorageCarts();

  Object.keys(storeCarts).forEach((storeId) => {
    const cart = storeCarts[storeId];
    const storageCart = storageCarts[storeId];

    let coupon = cart.appliedCoupon;
    if (cart.pricing?.coupon?.slug) coupon = cart.pricing.coupon;
    bareStoreCarts[storeId] = {
      products: cart.products,
      upsellIds: cart.upsellIds,
      menuId: cart.menuId,
      coupon: coupon || null,
      // order: cart?.order || storageCart?.order || undefined,
    };

    const order = cart?.order || storageCart?.order || undefined;
    if (order) bareStoreCarts[storeId].order = order;
  });

  const updatedAt = new Date().getTime();
  await writeStorage('cartStorage', { bareStoreCarts, updatedAt });

  // Double check that carts were stored properly
  const storedCarts = await readStorageCarts();
  const cartsSavedCorrectly = isEqual(
    JSON.parse(JSON.stringify(bareStoreCarts)),
    storedCarts
  );

  if (!cartsSavedCorrectly && currentSaveCount === saveCount) {
    logError(
      `Carts unable to be saved to storage - ${
        Object.keys(bareStoreCarts).length
      } - ${Object.keys(storedCarts).length}`
    );
  }
};

export const readStorageCarts = async (): Promise<StorageCarts> => {
  const cartStorage = await readStorage('cartStorage');
  if (!cartStorage) return {};

  const { bareStoreCarts, updatedAt } = cartStorage;

  const now = new Date().getTime();

  const oneWeek = 1000 * 60 * 60 * 24 * 7;

  const diff = now - updatedAt;
  if (diff > oneWeek) return {};

  return bareStoreCarts;
};

export const saveCartOpen = async (open: boolean) => {
  await writeStorage('displayCart', open ? 'open' : 'closed');
};

export const readCartOpen = async () => {
  const storageDisplay = await readStorage('displayCart');
  const loginRedirect = (await readStorage('loginRedirect')) || '/';

  const { pathname } = window.location;

  const storePath =
    pathname.startsWith('/store/') ||
    (pathname === '/login' && loginRedirect.startsWith('/store/'));

  const displayCart = storageDisplay === 'open' && !!storePath;
  return !!displayCart;
};

const sameModifiers = (products: ProductType[]) => {
  const firstProduct = products[0];
  if (!firstProduct) return false;

  let matchingModifiers = true;
  for (let i = 1; i < products.length; i++) {
    const currentProduct = products[i];
    if (currentProduct.modifiers.length !== firstProduct.modifiers.length) {
      matchingModifiers = false;
      break;
    }

    for (let m = 0; m < firstProduct.modifiers.length; m++) {
      const firstModifier = firstProduct.modifiers[m];
      const currentModifier = currentProduct.modifiers.find(
        (cm) => cm.id === firstModifier.id
      );
      if (!currentModifier) {
        matchingModifiers = false;
        break;
      }

      for (let c = 0; c < firstModifier.choices.length; c++) {
        const firstChoice = firstModifier.choices[c];
        const currentChoice = currentModifier.choices.find(
          (cc) => cc.id == firstChoice.id
        );
        if (!currentChoice || firstChoice.selected !== currentChoice.selected) {
          matchingModifiers = false;
          break;
        }
      }
    }
  }
  return matchingModifiers;
};

export const getExistingItem = (
  product: ProductType,
  cart: ProductType[]
): null | ProductType => {
  const existingItems = cart.filter((i) => i.id === product.id);

  let existingItem = null;

  if (existingItems.length > 0) {
    existingItems.forEach((item) => {
      const sameOptions = sameModifiers([item, product]);
      if (sameOptions) {
        existingItem = item;
      }
    });
  }

  return existingItem;
};

const UPSELL_CATEGORIES = ['salads', 'drinks', 'dips'];

export const getUpsellItems = (cart: CartType, storeMenu: MenuType) => {
  const cartProductIds = cart.products.map((p) => p.id);

  const upsellPool = shuffle(
    storeMenu.categories
      .flatMap((c) =>
        UPSELL_CATEGORIES.includes(c.name.toLowerCase()) ? c.products : []
      )
      .filter(
        (id) =>
          !cartProductIds.includes(id) && !!storeMenu.productLibrary[id].inStock
      )
  );
  return upsellPool.slice(0, 5);
};

type ValidationResponse = {
  pricing?: PricingType;
  waitTime?: number;
  validationId?: string;
  fieldErrors?: FieldErrorType[];
  stockErrors?: string[];
  orderErrors?: string[];
  validateErrors?: string[];
};

export const removeUrlCoupon = () => {
  const isWeb = Platform.OS === 'web';
  if (!isWeb) return;
  removeQueryParam('coupon');
};

export const postValidation = async (
  cart: CartType,
  storeId: string,
  user: UserType
): Promise<ValidationResponse> => {
  const url = '/customer/orders/validate/';
  const headers = {
    Authorization: `Bearer ${user.token}`,
    'Content-Type': 'application/json',
  };
  const orderLines = getOrderLines(cart);

  const coupon = cart.appliedCoupon?.slug || cart.couponInput;

  const orderSource = getOrderSource();

  const payload = {
    store: storeId,
    subtotal: orderLines.reduce((sum, x) => sum + x.price, 0),
    handoff: 'pickup',
    source: orderSource,
    order_lines: orderLines,
    customer: user.id,
    coupon,
  };

  const body = JSON.stringify(payload);
  const config = { method: 'POST', headers, body };
  const res = await fetch(url, config);

  const isValid = !!res.validation_id;

  // TODO return multiple errors at once
  if (typeof res.coupon !== 'string' && Array.isArray(res.coupon)) {
    removeUrlCoupon();
    return {
      fieldErrors: [{ field: 'coupon', message: res.coupon[0] }],
    };
  }

  if (Array.isArray(res.store) && !isValid)
    return { validateErrors: [res.store[0]] };

  if (
    Array.isArray(res.subtotal) &&
    res.subtotal[0].startsWith('Subtotal may not be greater than')
  )
    return {
      validateErrors: [res.subtotal[0]],
    };

  if (
    Array.isArray(res.customer) &&
    res.customer[0] === 'First and last name are required.'
  )
    return { validateErrors: [res.customer[0]] };
  if (
    Array.isArray(res.customer) &&
    res.customer[0] === 'Customer name must be set.'
  )
    return { validateErrors: [res.customer[0]] };

  if (!res.validation_id && !res.total && !!res.order_lines) {
    const stockErrors = res.order_lines
      .map((ol: OrderLineResp, idx: number) => {
        if (ol.product && ol.product[0] === 'Product is out of stock.')
          return idx;

        if (
          ol.modifiers &&
          Object.keys(ol.modifiers).some((id) => {
            const mod = ol.modifiers[id];
            if (Array.isArray(mod) && mod[0]) {
              const value = mod[0];
              if (value.startsWith('Number of choices must be')) return true;
            }
          })
        )
          return idx;
      })
      .filter((id: number) => id >= 0);

    return { stockErrors };
  }

  if (!res.total === undefined && !isValid) {
    logError(`Unknown validation error - ${JSON.stringify(res)}`);
    return { validateErrors: flattenObjectErrors(res) };
  }
  const pricingCoupon = res.coupon
    ? {
        slug: res.coupon,
        description: res.coupon_description || '',
      }
    : null;

  const pricing = {
    subtotal: res.subtotal,
    tax: res.tax,
    total: res.total,
    discount: res.discount,
    coupon: pricingCoupon,
  };

  const waitTime = res.estimated_wait_time_minutes;
  const validationId = res.validation_id;
  return { pricing, waitTime, validationId };
};

const transformOrder = (orderRes: OrderResp): OrderType => ({
  id: orderRes.id,
  total: orderRes.total,
  handoff: orderRes.handoff,
  state: orderRes.state,
  storeId: orderRes.store,
  validationId: orderRes.validation_id,
  pickupId: orderRes.pickup_identifier,
  readyTime:
    orderRes.ready_for_handoff_at || orderRes.estimated_ready_for_handoff_at,
  createdAt: orderRes.created_at,
});

export const postCreateOrder = async (
  cart: CartType,
  storeId: string,
  user: UserType,
  urlTags: string[]
): Promise<{ order?: OrderType; error?: string }> => {
  if (!user.token) return { error: 'no user token' };
  const url = '/customer/orders/';
  const headers = {
    Authorization: `Bearer ${user.token}`,
    'Content-Type': 'application/json',
  };

  const orderLines = getOrderLines(cart);
  const coupon = cart.pricing?.coupon?.slug || cart.appliedCoupon?.slug;
  const orderSource = getOrderSource();

  const payload = {
    store: storeId,
    subtotal: orderLines.reduce((sum, x) => sum + x.price, 0),
    handoff: 'pickup',
    source: orderSource,
    order_lines: orderLines,
    customer: user.id,
    validation_id: cart.validationId,
    coupon,
    tags: urlTags,
  };

  const body = JSON.stringify(payload);
  const config = { method: 'POST', headers, body };
  const orderRes = await fetch(url, config);
  if (
    orderRes.customer &&
    orderRes.customer[0] === 'First and last name are required.'
  )
    return { error: orderRes.customer[0] };

  if (
    orderRes.store &&
    orderRes.store[0] ===
      'A specific coupon is required to place an order at this store.'
  )
    return { error: orderRes.store[0] };

  if (
    orderRes.total &&
    orderRes.total[0] &&
    orderRes.total[0].startsWith('Order total must be greater than $0.50.')
  )
    return { error: orderRes.total[0] };

  const order: OrderType = transformOrder(orderRes);

  const orderValid = !!order.validationId && !!order.id && orderRes.total >= 0;
  if (!orderValid) return { error: 'Unable to create order, try again.' };

  if (order.state === 'awaiting_payment') {
    const payment = await fetchPayment(order.id, user.token);
    order.payment = payment;

    let paymentMethods: StripePaymentMethodCard[] = [];

    try {
      paymentMethods = await fetchPaymentMethods(user.token);
      order.paymentMethods = paymentMethods;
    } catch (e) {
      order.paymentMethods = [];
    }

    return { order };
  } else {
    return { order };
  }
};

const fetchPaymentMethods = async (token: string) => {
  const url = '/customer/payment_methods/';

  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const res = await fetch(url, { headers });
  const cards = res.data || [];

  const paymentMethods = cards.map(formatCardPaymentMethod);
  return paymentMethods;
};

const fetchPayment = async (
  orderId: string,
  token: string
): Promise<PaymentType> => {
  const url = '/customer/payments/';
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  const payload = { order: orderId };
  const body = JSON.stringify(payload);
  const config = { method: 'POST', headers, body };
  const res = await fetch(url, config);
  const payment = {
    id: res.id,
    amount: res.amount,
    orderId: res.order,
    state: res.state,
    stripeCustomerId: res.stripe_customer_id,
    clientSecret: res.payment_intent_client_secret,
    keySecret: res.ephemeral_key_secret,
  };

  return payment;
};

export const fetchOrderInfo = async (
  orderId: string,
  token: string
): Promise<OrderType | null> => {
  const url = `/customer/orders/${orderId}/`;
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const res = await fetch(url, { headers });
  const { order } = res;
  if (!order) return null;
  const transformedOrder: OrderType = transformOrder(order);
  return transformedOrder;
};

import React, { useCallback } from 'react';
import { Pressable, ViewStyle } from 'react-native';
import { LinkProps, useNavigate, useLocation } from 'react-router-native';

import { BodyText } from '.';

import { colors } from '../helpers';

interface Props extends LinkProps {
  label: string;
  labelColor?: string;
  activeColor?: string;
  underlineActive?: boolean;
  fontSize?: number;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  style?: ViewStyle;
  activeChildren?: boolean;
  altRoutes?: string[];
}

const NewLink = ({
  to,
  label,
  labelColor,
  activeColor,
  underlineActive,
  fontSize,
  mt,
  mb,
  mr,
  ml,
  style,
  activeChildren,
  altRoutes,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  let isActive = activeChildren
    ? location.pathname.startsWith(to)
    : location.pathname === to;

  if (
    !!altRoutes &&
    altRoutes?.length > 0 &&
    altRoutes?.some((r) => location.pathname === r)
  )
    isActive = true;

  const goto = useCallback(() => {
    if (location.pathname === to) return;
    navigate(to);
  }, [to, isActive, location.pathname]);

  const linkColor = isActive
    ? activeColor || colors.primary
    : labelColor || colors.textDark;

  return (
    // @ts-ignore
    <Pressable
      onPress={goto}
      style={[
        style,
        {
          marginTop: mt,
          marginBottom: mb,
          marginRight: mr,
          marginLeft: ml,
          borderBottomWidth: underlineActive && isActive ? 2 : 0,
          borderBottomColor: linkColor,
        },
      ]}>
      <BodyText color={linkColor} bold={true} fontSize={fontSize}>
        {label}
      </BodyText>
    </Pressable>
  );
};

export default NewLink;

import React from 'react';
import { Pressable, View, Animated, ScrollView, Image } from 'react-native';
import { connect } from 'react-redux';
import Modal from 'react-native-modal';
import { cloneDeep } from 'lodash';

import {
  setActiveCombo,
  addItemToCart,
  readStoreMenu,
} from '../../../globalStore';

import {
  withRouter,
  formatPrice,
  setThemeColor,
  logError,
  isIos,
  isWeb,
  getProductImage,
  animate,
} from '../../../helpers';

import { BodyText } from '../..';

import ComboDescription from './ComboDescription';

import Close from '../../../assets/close.svg';

import styles from './styles';

const readProducts = (activeCombo: ComboType, activeMenu: MenuType | null) => {
  // let menuModifiers: ModifierType[] = [];
  // if (activeMenu) {
  //   const menuProduct = activeMenu?.productLibrary[activeProduct.id];
  //   if (menuProduct) menuModifiers = menuProduct.modifiers;
  // }

  // const localProducts = Object.values(activeCombo).map(cloneDeep);
  const localProducts = cloneDeep([
    activeCombo.pizza,
    activeCombo.dipping,
    activeCombo.drink,
  ]);

  localProducts.forEach((localProduct) => {
    localProduct.modifiers.forEach((m) => {
      m.choices.forEach((c) => {
        if (c.default) c.selected = true;
      });
    });
    localProduct.quantity = 1;
  });

  return localProducts;
};

interface Props {
  activeCombo: ComboType | null;
  setActiveCombo: (payload: { combo: ComboType } | null) => void;
  addItemToCart: (payload: {
    storeId: string;
    product: ProductType;
    storeMenu: MenuType;
  }) => void;
  readStoreMenu: (storeId: string) => MenuType;
  isMobile: boolean;
  screenSize: { width: number; height: number };
  storeId?: string;
  navigate: (to: string) => void;
}

interface State {
  modalVisible: boolean;
  localProducts: ProductType[];
  selectionError: string;
  showDisabled: boolean;
}

class ComboModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (!props.activeCombo) return;
    const { storeId } = props;

    const activeMenu = storeId ? props.readStoreMenu(storeId) : null;
    const localProducts = readProducts(props.activeCombo, activeMenu);

    this.state = {
      localProducts,
      modalVisible: false,
      selectionError: '',
      showDisabled: false,
    };
  }

  scrollRef: ScrollView | null = null;

  slideAnim = new Animated.Value(800);
  pizzaAnim = new Animated.Value(0);
  dipAnim = new Animated.Value(0);
  drinkAnim = new Animated.Value(0);

  componentDidMount() {
    this.setState({ modalVisible: true });
    this.appear();

    if (!!window && window.addEventListener)
      window.addEventListener('keydown', this.escape);
  }

  componentWillUnmount(): void {
    if (!!window && window.removeEventListener)
      window.removeEventListener('keydown', this.escape);
  }

  appear = () => {
    const { isMobile } = this.props;
    Animated.stagger(250, [
      animate(this.pizzaAnim, {
        toValue: 1,
        duration: 800,
        delay: isMobile ? 200 : 0,
      }),
      animate(this.dipAnim, {
        toValue: 1,
        duration: 600,
      }),
      animate(this.drinkAnim, {
        toValue: 1,
        duration: 500,
      }),
    ]).start();
  };

  escape = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.state.modalVisible) this.closeModal();
  };

  closeModal = () => {
    setThemeColor('primary');
    this.setState({ modalVisible: false });
  };

  clearProduct = () => this.props.setActiveCombo(null);

  selectionDisabled = () => {
    this.setState({ showDisabled: true });
    this.scrollRef?.scrollToEnd({ animated: true });
  };

  addToCart = () => {
    const { storeId } = this.props;
    const { localProducts } = this.state;
    if (!storeId || !localProducts) return;

    // const selectionErrors = localProduct.modifiers
    //   .map(getSelectionError)
    //   .filter(Boolean);
    // if (selectionErrors.length > 0)
    //   return this.setState({ selectionError: selectionErrors[0] });

    const storeMenu = this.props.readStoreMenu(storeId);
    localProducts.forEach((product) => {
      const payload = { storeId, product, storeMenu };
      this.props.addItemToCart(payload);
    });

    this.closeModal();
  };

  selectStore = () => {
    // const { localProducts } = this.state;
    // if (!localProduct)
    //   return logError('No local product present in MenuItemModal');

    // this.props.setGenericProduct(localProduct);
    this.props.navigate(`/stores`);
    this.closeModal();
  };

  render() {
    const { isMobile, screenSize, activeCombo } = this.props;
    const { modalVisible, localProducts, selectionError, showDisabled } =
      this.state;

    const priceTotal = localProducts.reduce((acc, prod) => acc + prod.price, 0);
    const price = formatPrice(priceTotal);

    const webMobile = isMobile && isWeb;
    const nativeMobile = isMobile && !isWeb;

    if (!activeCombo) return null;

    const pizzaImage = getProductImage(activeCombo.pizza);
    const dippingImage = getProductImage(activeCombo.dipping);
    const drinkImage = getProductImage(activeCombo.drink);

    let buttonLabel = 'ADD TO CART';
    let buttonAction = this.addToCart;
    const isGeneric = localProducts.some((p) => !!p.genericItem);
    if (isGeneric) {
      buttonLabel = 'SELECT STORE';
      buttonAction = this.selectStore;
    }

    return (
      <Modal
        isVisible={modalVisible}
        backdropColor='rgba(48,32,26,.8)'
        onBackdropPress={this.closeModal}
        onModalHide={this.clearProduct}
        animationIn={isMobile ? 'slideInUp' : 'fadeIn'}
        animationOut={isMobile ? 'slideOutDown' : 'fadeOut'}
        animationInTiming={isMobile ? 500 : 400}
        animationOutTiming={isMobile ? 900 : 300}
        deviceHeight={screenSize.height}
        deviceWidth={screenSize.width}
        style={{
          margin: 0,
          display: 'flex',
          justifyContent: isMobile ? 'flex-end' : 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={[styles.modalBox, isMobile && styles.mobileBox]}
          testID='menu-item-modal'>
          {!nativeMobile && (
            <Pressable
              style={[styles.closeButton, webMobile && styles.mobileClose]}
              onPress={this.closeModal}>
              <Close width={100} height={14} />
            </Pressable>
          )}

          <ScrollView
            ref={(r) => (this.scrollRef = r)}
            contentContainerStyle={{ marginBottom: 0, paddingBottom: 40 }}>
            <View style={[styles.section, isMobile && styles.mobileHeader]}>
              <BodyText
                color='white'
                fontSize={20}
                style={{
                  position: 'relative',
                  marginLeft: 'auto',
                  top: webMobile ? 10 : 0,
                }}
                testID='item-modal-product-price'>
                {price}
              </BodyText>
            </View>

            <View style={styles.showCase}>
              <View style={styles.imageBox}>
                <Animated.Image
                  source={pizzaImage}
                  resizeMode='contain'
                  style={[
                    styles.pizzaImage,
                    {
                      transform: [
                        {
                          scale: this.pizzaAnim.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0.92, 1],
                          }),
                        },
                        {
                          rotate: this.pizzaAnim.interpolate({
                            inputRange: [0, 1],
                            outputRange: ['3deg', '0deg'],
                          }),
                        },
                      ],
                    },
                  ]}
                />
                <Animated.Image
                  source={drinkImage}
                  resizeMode='contain'
                  style={[
                    styles.drinkImage,
                    {
                      opacity: this.drinkAnim,
                      transform: [
                        {
                          translateX: this.drinkAnim.interpolate({
                            inputRange: [0, 1],
                            outputRange: [10, 0],
                          }),
                        },
                      ],
                    },
                  ]}
                />
                <Animated.Image
                  source={dippingImage}
                  resizeMode='contain'
                  style={[
                    styles.dippingImage,
                    {
                      opacity: this.dipAnim,
                      transform: [
                        {
                          translateX: this.dipAnim.interpolate({
                            inputRange: [0, 1],
                            outputRange: [10, 0],
                          }),
                        },
                      ],
                    },
                  ]}
                />
              </View>
              <ComboDescription id={activeCombo.id} />

              <View style={styles.productList}>
                {localProducts.map((product) => (
                  <BodyText
                    color='white'
                    fontSize={20}
                    bold={true}
                    key={product.name}>
                    •{'  '}
                    {product.name}
                  </BodyText>
                ))}
              </View>
            </View>

            <Pressable
              style={[styles.addButton]}
              onPress={buttonAction}
              testID='menu-item-modal-submit-button'>
              <BodyText color='white' fontSize={16} fontWeight='700'>
                {buttonLabel}
              </BodyText>
              {!isGeneric && (
                <BodyText
                  color='white'
                  fontSize={16}
                  fontWeight='800'
                  style={{ position: 'absolute', right: '10%' }}>
                  {price}
                </BodyText>
              )}
            </Pressable>
          </ScrollView>
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  activeCombo: state.storeInfo.activeCombo,
  isMobile: state.appInfo.isMobile,
  screenSize: state.appInfo.screenSize,
  readStoreMenu: (storeId: string) => readStoreMenu(state, storeId),
});

const mapDispatchToProps = {
  setActiveCombo,
  addItemToCart,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComboModal));

import React, { useCallback } from 'react';
import { colors } from '../../helpers';

import { Text, TextStyle, TextProps, Linking } from 'react-native';

interface Props extends TextProps {
  style?: TextStyle | Array<TextStyle | false>;
  color?: string;
  uppercase?: boolean;
  hovered?: boolean;
  hoverColor?: string;
  fontSize?: number;
  lineHeight?: number;
  disabled?: boolean;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  spacing?: number;
  center?: boolean;
  link?: string;
  testId?: string;
  secondary?: boolean;
  bold?: boolean;
  children: React.ReactNode;
}

const TitleText = ({
  style,
  color,
  children,
  uppercase,
  hovered,
  hoverColor,
  fontSize,
  lineHeight,
  disabled,
  mt,
  mb,
  mr,
  ml,
  spacing,
  center,
  onLayout,
  onTextLayout,
  link,
  testID,
  secondary,
  bold,
}: Props) => {
  let fontFamily = 'Noka';
  if (secondary && bold) fontFamily = 'Platform-Regular';
  else if (secondary) fontFamily = 'Platform-Medium';
  const extraStyle: TextStyle = { fontFamily };

  if (color) extraStyle.color = colors[color] || color;
  if (hovered && hoverColor) extraStyle.color = colors[hoverColor];
  if (fontSize) {
    extraStyle.fontSize = fontSize;
    extraStyle.lineHeight = fontSize;
  }
  if (disabled) {
    // extraStyle.textDecorationLine = 'line-through';
    extraStyle.color = colors.text;
  }
  if (lineHeight) extraStyle.lineHeight = lineHeight;

  if (mt) extraStyle.marginTop = mt;
  if (mb) extraStyle.marginBottom = mb;
  if (mr) extraStyle.marginRight = mr;
  if (ml) extraStyle.marginLeft = ml;
  // extraStyle.fontWeight = '800';

  if (center) extraStyle.textAlign = 'center';

  if (spacing) extraStyle.letterSpacing = spacing;
  const text =
    children && typeof children === 'string' && uppercase
      ? children.toUpperCase()
      : children;

  const goToUrl = useCallback(() => {
    if (!link) return;
    Linking.canOpenURL(link).then((supported) => {
      if (supported) Linking.openURL(link);
      else console.error('Linking not supported');
    });
  }, [link]);

  const textPress = link ? goToUrl : undefined;

  return (
    <Text
      onPress={textPress}
      onLayout={onLayout}
      onTextLayout={onTextLayout}
      style={[style, extraStyle]}
      testID={testID}>
      {text}
    </Text>
  );
};

export default TitleText;

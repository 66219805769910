import React, { useCallback, useState } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-native-modal';

import { colors, globalStyles } from '../../helpers';
import { setNameNeededModal, readUser, updateUser } from '../../globalStore';

import { Button, BodyText, TextInput } from '..';

import { Stars, MascotBolt, Close } from '../../assets';

const NameNeededModal = () => {
  const dispatch = useDispatch();
  const user = useSelector(readUser);

  const [localUser, setLocalUser] = useState<UserType>({
    name: user.name,
    phone: user.phone,
  });

  const [nameError, setNameError] = useState(false);

  const clearModal = useCallback(() => {
    dispatch(setNameNeededModal(false));
  }, []);

  const updateField = useCallback((field: 'name' | 'phone', value: string) => {
    if (field === 'name') setNameError(false);
    setLocalUser((user) => {
      const newUser = { ...user };
      if (field === 'phone') newUser.phone = value.replace(/[^0-9\-()]/g, '');
      else newUser[field] = value;
      return newUser;
    });
  }, []);

  const saveUser = useCallback(async () => {
    const updatedUser = {
      ...user,
      ...localUser,
    };

    const response: any = await dispatch(updateUser(updatedUser));
    const error = response.payload?.error || '';
    if (error === 'First and last name are required.') setNameError(true);
    else clearModal();
  }, [user, localUser]);

  return (
    <Modal
      isVisible={true}
      backdropColor={'rgba(48,32,26,.5)'}
      onBackdropPress={clearModal}
      animationIn='zoomIn'
      animationOut='zoomOut'
      animationInTiming={300}
      animationOutTiming={200}
      style={{ margin: 0 }}>
      <View style={styles.modalBox}>
        <View style={styles.header}>
          <Stars width={214} height={130} />
        </View>

        <Pressable style={styles.closeButton} onPress={clearModal}>
          <Close width={100} height={14} />
        </Pressable>

        <View style={styles.body}>
          <View style={styles.background} />
          <View style={styles.content}>
            <View style={styles.boltBox}>
              <MascotBolt width={100} />
            </View>

            <View style={styles.labelRow}>
              <BodyText color='textDark' bold={true} mr={4}>
                NAME
              </BodyText>
              <BodyText color='primary' fontSize={12} bold={true}>
                (REQUIRED)
              </BodyText>
            </View>
            <TextInput
              value={localUser.name}
              onChangeText={(value) => updateField('name', value)}
              style={[
                styles.inputBox,
                { borderColor: nameError ? colors.error : colors.midLight },
              ]}
              focusStyle={styles.focusedInput}
              placeholder='Jane Dough'
              placeholderTextColor={colors.midLight}
            />

            <View style={styles.labelRow}>
              <BodyText
                color='textDark'
                fontSize={12}
                mb={20}
                mt={2}
                lineHeight={16}>
                Enter your{' '}
                <BodyText
                  color={nameError ? 'error' : 'textDark'}
                  fontSize={nameError ? 14 : 12}>
                  first and last name
                </BodyText>{' '}
                so we can identify your orders during pickup.
              </BodyText>
            </View>

            <View style={styles.labelRow}>
              <BodyText color='textDark' bold={true}>
                PHONE NUMBER
              </BodyText>
            </View>

            <TextInput
              value={localUser.phone}
              onChangeText={(value) => updateField('phone', value)}
              style={styles.inputBox}
              focusStyle={styles.focusedInput}
              placeholder='555 555 5555'
              placeholderTextColor={colors.midLight}
            />

            <BodyText
              color='textDark'
              fontSize={12}
              mb={20}
              mt={2}
              lineHeight={16}>
              Enter your phone number if you'd like to receive SMS order
              notifications for each order places. Notifications can be disabled
              from your profile within the app. Message and data rates may
              apply.
            </BodyText>

            <Button
              color='secondary'
              onClick={saveUser}
              label='SAVE'
              style={styles.button}
            />

            <View style={styles.disclosureRow}>
              <BodyText
                color='textDark'
                bold={true}
                fontSize={12}
                link='https://www.eatstellarpizza.com/terms/'>
                Terms of Service
              </BodyText>
              <BodyText
                color='textDark'
                bold={true}
                fontSize={12}
                link='https://www.eatstellarpizza.com/privacy/'>
                Privacy Policy
              </BodyText>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default NameNeededModal;

const styles = StyleSheet.create({
  modalBox: {
    width: 368,
    height: 571,
    borderRadius: 24,
    backgroundColor: colors.secondary,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 20,
  },
  header: {
    ...globalStyles.flexRow,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    elevation: 2,
  },
  body: {
    flex: 1,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.body,
  },
  content: {
    ...globalStyles.flexColumn,
    flex: 1,
    bottom: 88,
  },
  boltBox: {
    left: 18,
    marginBottom: 30,
  },
  labelRow: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    width: '100%',
    marginBottom: 6,
  },
  inputBox: {
    color: colors.textDark,
    borderWidth: 1,
    borderColor: colors.midLight,
    backgroundColor: colors.white,
    paddingVertical: 10,
    paddingHorizontal: 16,
    fontSize: 16,
    lineHeight: 24,
    borderRadius: 4,
    opacity: 0.9,
    width: '100%',
  },
  focusedInput: {
    opacity: 1,
    ...globalStyles.noOutline,
    borderColor: colors.secondary,
  },
  button: {
    width: '100%',
  },
  disclosureRow: {
    ...globalStyles.flexRow,
    justifyContent: 'space-around',
    marginTop: 10,
    width: '80%',
  },
});

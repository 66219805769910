import React from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, { Defs, Rect, LinearGradient, Stop } from 'react-native-svg';

const fromColor = 'rgba(255, 255,255,1)';
const toColor = 'rgba(245,240,240, 0)';

interface Props {
  children?: React.ReactNode;
}
const Gradient = ({ children }: Props) => {
  return (
    <View
      style={{
        flex: 1,
        position: 'absolute',
        zIndex: 100,
        bottom: 0,
        left: 0,
        // borderWidth: 4,
        width: '100%',
        height: '4%',
        transform: [{ rotate: '180deg' }],
      }}>
      <Svg width='100%' height='100%' style={StyleSheet.absoluteFillObject}>
        <Defs>
          <LinearGradient id='grad' x1='0%' y1='0%' x2='0%' y2='70%'>
            <Stop offset='0' stopColor={fromColor} />
            <Stop offset='1' stopColor={toColor} stopOpacity='0' />
          </LinearGradient>
        </Defs>
        <Rect width='100%' height='100%' fill='url(#grad)' />
      </Svg>
      {children}
    </View>
  );
};

export default Gradient;

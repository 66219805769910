import React from 'react';
import { Pressable, View, ScrollView, Image } from 'react-native';
import { connect } from 'react-redux';
import Modal from 'react-native-modal';
import { cloneDeep } from 'lodash';

import {
  clearActiveProduct,
  setGenericProduct,
  addItemToCart,
  updateCartItem,
  readStoreMenu,
  readCart,
} from '../../../globalStore';

import {
  withRouter,
  getProductPrice,
  getProductImage,
  setThemeColor,
  logError,
  isIos,
  isWeb,
  colors,
} from '../../../helpers';

import {
  BodyText,
  TitleText,
  RadioButton,
  ChoicesSelection,
  Counter,
  Gradient,
} from '../..';

import UpsellModal from './UpsellModal';

import Close from '../../../assets/close.svg';

import styles from './styles';

const readProduct = (
  activeProduct: ProductType,
  activeMenu: MenuType | null
) => {
  let menuModifiers: ModifierType[] = [];
  if (activeMenu) {
    const menuProduct = activeMenu?.productLibrary[activeProduct.id];
    if (menuProduct) menuModifiers = menuProduct.modifiers;
  }

  const localProduct: ProductType = cloneDeep(activeProduct);

  if (localProduct.cartItemId)
    localProduct.modifiers.forEach((m) => {
      const menuModifier = menuModifiers.find((mm) => mm.id === m.id);
      m.choices.forEach((c) => {
        if (!menuModifier) return;
        const menuChoice = menuModifier.choices.find((mc) => mc.id === c.id);
        if (menuChoice) {
          c.inStock = menuChoice.inStock;
          if (!menuChoice.inStock) c.selected = false;
        }
      });
    });
  else if (localProduct.copiedItem) {
  } else
    localProduct.modifiers.forEach((m) => {
      m.choices.forEach((c) => {
        if (c.default) c.selected = true;
      });
    });

  if (!localProduct.quantity) localProduct.quantity = 1;
  return localProduct;
};

const getSelectionError = (modifier: ModifierType) => {
  let selectedCount = 0;
  modifier.choices.forEach((c) => {
    if (c.selected) selectedCount += 1;
  });

  let selectionError = '';
  if (selectedCount < modifier.minChoices)
    selectionError = `You must select at least ${modifier.minChoices} option from ${modifier.name}`;
  else if (selectedCount > modifier.maxChoices)
    selectionError = `You cannot select more than ${modifier.maxChoices} options from ${modifier.name}`;

  const stockErrors = modifier.choices.some((c) => !c.inStock && c.selected);

  if (stockErrors)
    selectionError = 'Please remove sold out ingredients to continue';
  return selectionError;
};

interface Props {
  activeProduct: ProductType | null;
  setGenericProduct: (product: ProductType) => void;
  clearActiveProduct: () => void;
  addItemToCart: (payload: {
    storeId: string;
    product: ProductType;
    storeMenu: MenuType;
  }) => void;
  updateCartItem: (payload: { storeId: string; product: ProductType }) => void;
  readStoreMenu: (storeId: string) => MenuType;
  readCart: (storeId: string) => CartType;
  isMobile: boolean;
  screenSize: { width: number; height: number };
  storeId?: string;
  navigate: (to: string) => void;
}

interface State {
  modalVisible: boolean;
  localProduct: ProductType | null;
  selectionError: string;
  showDisabled: boolean;
  showUpsell: boolean;
}

class MenuItemModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    if (!props.activeProduct) return;
    const { storeId } = props;

    const activeMenu = storeId ? props.readStoreMenu(storeId) : null;
    const localProduct = readProduct(props.activeProduct, activeMenu);

    this.state = {
      localProduct,
      modalVisible: false,
      selectionError: '',
      showDisabled: false,
      showUpsell: false,
    };
  }

  scrollRef: ScrollView | null = null;

  componentDidMount() {
    this.setState({ modalVisible: true });

    if (!!window && window.addEventListener)
      window.addEventListener('keydown', this.escape);
  }

  componentWillUnmount(): void {
    if (!!window && window.removeEventListener)
      window.removeEventListener('keydown', this.escape);
  }

  escape = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && this.state.modalVisible) this.closeModal();
  };

  closeModal = () => {
    const { activeProduct } = this.props;
    const themeColor = activeProduct?.cartItemId ? 'secondary' : 'primary';
    setThemeColor(themeColor);
    this.setState({ modalVisible: false });
  };

  clearProduct = () => this.props.clearActiveProduct();

  selectionDisabled = () => {
    this.setState({ showDisabled: true });
    this.scrollRef?.scrollToEnd({ animated: true });
  };

  selectChoice = (modifierId: string, choiceId: string) => {
    const { localProduct } = this.state;
    if (localProduct?.genericItem) return this.selectionDisabled();

    const modifier = localProduct?.modifiers.find((m) => m.id === modifierId);
    if (!modifier) return;

    modifier.choices.forEach((c) => {
      if (c.id === choiceId) c.selected = true;
      else c.selected = false;
    });
    this.setState({ localProduct });
  };

  toggleChoice = (modifierId: string, choiceId: string) => {
    const { localProduct } = this.state;
    if (localProduct?.genericItem) return this.selectionDisabled();

    const modifier = localProduct?.modifiers.find((m) => m.id === modifierId);

    if (
      modifier?.choices.length === 1 &&
      !!modifier.choices[0].default &&
      modifier.minChoices === 1 &&
      modifier.maxChoices === 1
    )
      return;

    if (!modifier) return console.error('choice not found');
    const choice = modifier.choices.find((c) => c.id === choiceId);
    if (!choice) return console.error('choice not found');
    choice.selected = choice.selected ? false : true;
    const selectionError = getSelectionError(modifier);
    this.setState({ localProduct, selectionError });
  };

  decreaseQuantity = () => {
    const { localProduct } = this.state;
    if (!localProduct || !localProduct.quantity) return;
    if (localProduct.quantity > 1) localProduct.quantity -= 1;
    this.setState({ localProduct });
  };

  increaseQuantity = () => {
    const { localProduct } = this.state;
    if (!localProduct || !localProduct.quantity) return;
    localProduct.quantity += 1;
    this.setState({ localProduct });
  };

  shouldShowUpsell = (product: ProductType) => {
    if (product.category !== 'Pizzas') return false;

    const { storeId, readCart } = this.props;
    if (!storeId) return false;
    const cart = readCart(storeId);

    const hasDrinks = cart?.products.some(
      (p) => p.category?.toLowerCase() === 'drinks'
    );
    const hasDips = cart?.products.some(
      (p) => p.category?.toLowerCase() === 'dips'
    );
    return !hasDrinks || !hasDips;
  };

  addToCart = () => {
    const { storeId, readCart } = this.props;
    const { localProduct } = this.state;
    if (!storeId || !localProduct) return;

    const selectionErrors = localProduct.modifiers
      .map(getSelectionError)
      .filter(Boolean);
    if (selectionErrors.length > 0)
      return this.setState({ selectionError: selectionErrors[0] });

    const activeMenu = this.props.readStoreMenu(storeId);
    const payload = { storeId, product: localProduct, storeMenu: activeMenu };
    this.props.addItemToCart(payload);

    if (this.shouldShowUpsell(localProduct))
      return this.setState({ showUpsell: true });

    this.closeModal();
  };

  updateCartItem = () => {
    const { storeId } = this.props;
    const { localProduct } = this.state;
    if (!storeId || !localProduct) return;
    const payload = { storeId, product: localProduct };
    this.props.updateCartItem(payload);
    this.closeModal();
  };

  selectStore = () => {
    const { localProduct } = this.state;
    if (!localProduct)
      return logError('No local product present in MenuItemModal');

    this.props.setGenericProduct(localProduct);
    this.props.navigate(`/stores?genericProduct=${localProduct.id}`);
    this.closeModal();
  };

  render() {
    const { isMobile, screenSize, storeId, readCart } = this.props;
    const {
      modalVisible,
      localProduct,
      selectionError,
      showDisabled,
      showUpsell,
    } = this.state;
    const price = getProductPrice(localProduct);
    const productImage = localProduct ? getProductImage(localProduct) : null;

    const modifiers = localProduct?.modifiers || [];

    const hasModifiers = modifiers.filter((m) => !m.hidden).length > 0;

    const webMobile = isMobile && isWeb;
    const nativeMobile = isMobile && !isWeb;

    let buttonLabel = 'ADD TO CART';
    if (localProduct?.cartItemId) buttonLabel = 'UPDATE ITEM';
    else if (localProduct?.genericItem) buttonLabel = 'SELECT STORE';

    let buttonAction = this.addToCart;
    if (localProduct?.cartItemId) buttonAction = this.updateCartItem;
    else if (localProduct?.genericItem) buttonAction = this.selectStore;

    const isPizza = localProduct?.category === 'Pizzas';
    const isDip = localProduct?.category === 'Dips';
    const isDrink = localProduct?.category === 'Drinks';
    const isMerch = localProduct?.category === 'Merch';

    const cart = storeId ? readCart(storeId) : undefined;

    return (
      <Modal
        isVisible={modalVisible}
        backdropColor='rgba(48,32,26,.8)'
        onBackdropPress={this.closeModal}
        onModalHide={this.clearProduct}
        animationIn={isMobile ? 'slideInUp' : 'fadeIn'}
        animationOut={isMobile ? 'slideOutDown' : 'fadeOut'}
        animationInTiming={isMobile ? 500 : 400}
        animationOutTiming={isMobile ? 900 : 300}
        deviceHeight={screenSize.height}
        deviceWidth={screenSize.width}
        style={{
          margin: 0,
          display: 'flex',
          justifyContent: isMobile ? 'flex-end' : 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}>
        <View
          style={[styles.modalBox, isMobile && styles.mobileBox]}
          testID='menu-item-modal'>
          <Pressable
            style={[styles.closeButton, isMobile && styles.mobileClose]}
            onPress={this.closeModal}>
            <Close width={50} height={14} />
          </Pressable>
          <View style={styles.bottomBackground} />

          {showUpsell ? (
            <UpsellModal
              originalProduct={localProduct}
              closeModal={this.closeModal}
              cartItems={cart?.products}
            />
          ) : (
            <ScrollView
              ref={(r) => (this.scrollRef = r)}
              alwaysBounceHorizontal={false}
              style={{ backgroundColor: colors.white }}
              alwaysBounceVertical={true}>
              {isPizza && (
                <>
                  <View style={styles.pizzaTop} />
                  <View style={styles.imageRow}>
                    <Image
                      source={productImage}
                      resizeMode='contain'
                      style={[
                        styles.productImage,
                        isPizza && styles.pizzaImage,
                      ]}
                    />
                  </View>
                </>
              )}

              <View
                style={[
                  styles.section,
                  isMobile && styles.mobileHeader,
                  { minHeight: isMerch ? 160 : 150 },
                ]}>
                <View style={styles.detailRow}>
                  <TitleText
                    color='textLight'
                    fontSize={16}
                    lineHeight={0}
                    style={{ borderWidth: 0, marginTop: 5 }}>
                    {localProduct?.category?.toUpperCase()}
                  </TitleText>
                  <BodyText
                    color='primary'
                    fontWeight='800'
                    fontSize={18}
                    lineHeight={24}
                    style={{
                      position: 'relative',
                      top: webMobile ? 0 : 0,
                      borderWidth: 0,
                    }}
                    testID='item-modal-product-price'>
                    {price}
                  </BodyText>
                </View>

                <View style={styles.titleRow}>
                  <TitleText
                    color='secondary'
                    uppercase={true}
                    style={[
                      styles.productName,
                      isMobile && styles.mobileProductName,
                    ]}
                    testID='item-modal-product-name'>
                    {localProduct?.name}
                  </TitleText>
                </View>
                <BodyText color='midDark' fontSize={16} ml={0}>
                  {localProduct?.description}
                </BodyText>
                {!isPizza && (
                  <Image
                    source={productImage}
                    resizeMode='contain'
                    style={[styles.sideImage, isMerch && styles.merchImage]}
                  />
                )}
              </View>

              {hasModifiers && !localProduct?.genericItem ? (
                <View
                  style={[
                    styles.optionsBox,
                    isMobile && styles.mobileOptionsBox,
                  ]}>
                  <View style={styles.optionsContent}>
                    {/* {!!showDisabled && (
                    <CustomView style={styles.disabledMessage}>
                      <BodyText color='white' fontSize={14} bold={true}>
                        Select a store to customize
                      </BodyText>
                    </CustomView>
                  )} */}
                    {localProduct?.modifiers.map(
                      (m) =>
                        m.hidden === false && (
                          <View key={m.id}>
                            <BodyText
                              color='textDark'
                              fontWeight='700'
                              uppercase={true}
                              mb={14}
                              ml={20}>
                              {m.name}
                            </BodyText>
                            {m.choices.length === 2 && m.maxChoices === 1 ? (
                              <RadioButton
                                choices={m.choices}
                                selectChoice={(choiceId) =>
                                  this.selectChoice(m.id, choiceId)
                                }
                              />
                            ) : (
                              <ChoicesSelection
                                choices={m.choices}
                                toggleChoice={(choiceId) =>
                                  this.toggleChoice(m.id, choiceId)
                                }
                              />
                            )}
                          </View>
                        )
                    )}
                  </View>
                </View>
              ) : (
                <View style={styles.emptyLine} />
              )}

              <View
                style={[
                  styles.section,
                  isMobile && styles.mobileSection,
                  { paddingTop: isMobile ? 0 : 5, paddingBottom: 60 },
                ]}>
                <BodyText
                  center={true}
                  style={[
                    styles.errorMessage,
                    !!selectionError && styles.showErrorMessage,
                  ]}>
                  {selectionError}
                </BodyText>
                {!localProduct?.genericItem && (
                  <View style={[styles.detailRow, { marginBottom: 20 }]}>
                    <BodyText fontWeight='700' fontSize={16} color='textDark'>
                      QUANTITY
                    </BodyText>
                    <Counter
                      increaseCount={this.increaseQuantity}
                      decreaseCount={this.decreaseQuantity}
                      count={localProduct?.quantity || 0}
                    />
                  </View>
                )}

                {localProduct?.genericItem && (
                  <BodyText
                    color='secondary'
                    center={true}
                    mt={10}
                    mb={10}
                    bold={true}
                    fontSize={showDisabled ? 18 : 16}>
                    Ready to order?
                  </BodyText>
                )}
                <Pressable
                  style={[styles.addButton]}
                  onPress={buttonAction}
                  testID='menu-item-modal-submit-button'>
                  <BodyText color='white' fontSize={16} fontWeight='700'>
                    {buttonLabel}
                  </BodyText>
                  {!localProduct?.genericItem && (
                    <BodyText
                      color='white'
                      fontSize={16}
                      fontWeight='800'
                      style={{ position: 'absolute', right: '10%' }}>
                      {price}
                    </BodyText>
                  )}
                </Pressable>
              </View>
            </ScrollView>
          )}

          {!!isIos && <Gradient />}
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  activeProduct: state.storeInfo.activeProduct,
  isMobile: state.appInfo.isMobile,
  screenSize: state.appInfo.screenSize,
  readStoreMenu: (storeId: string) => readStoreMenu(state, storeId),
  readCart: (storeId: string) => readCart(state, storeId),
});

const mapDispatchToProps = {
  clearActiveProduct,
  setGenericProduct,
  addItemToCart,
  updateCartItem,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MenuItemModal));

import React, { useEffect, useCallback, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useSelector } from 'react-redux';
import { useParams, useOutletContext } from 'react-router-native';

import { readSingleUserOrder } from '../../../globalStore';

import {
  getTimeString,
  getDateString,
  formatPrice,
  linkToMap,
} from '../../../helpers';

import {
  TitleText,
  BodyText,
  Close,
  Link,
  SpinLoader,
  MapButton,
  MapPreview,
} from '../../../components';

import { ExternalLink } from '../../../assets';

import styles from './styles';

const OrderSummary = () => {
  const { orderId } = useParams();
  const order = useSelector((state: RootState) =>
    readSingleUserOrder(state, orderId)
  );
  const [mapError, setMapError] = useState(false);

  const storeId = order?.storeInfo.id || order?.storeId;

  const store = useSelector((state: RootState) =>
    state.storeInfo.stores.find((s) => s.id === storeId)
  );

  const getMapLink = useCallback(
    (storeId: string) => {
      linkToMap(store);
    },
    [store]
  );

  const scrollUp = useOutletContext();

  useEffect(() => {
    scrollUp(false);
  }, []);

  const createdTime = new Date(order?.createdAt || '').getTime();
  const timeDiff = Date.now() - createdTime;
  const oneDay = 1000 * 60 * 60 * 24;
  const oldOrder = timeDiff > oneDay;
  const orderState = order?.state.replace('handoff', 'pickup');
  const inProgress = orderState === 'in_progress';
  const showInProgress = !!inProgress && !oldOrder;
  const readyForPickup = orderState === 'ready_for_pickup';
  const highlightState = showInProgress || readyForPickup;
  let displayState =
    orderState && !order?.refundAttempted ? orderState.replace(/_/g, ' ') : '';

  if (inProgress && oldOrder) displayState = 'Pending';

  const isMobile = useSelector((state: RootState) => state.appInfo.isMobile);
  const showMapLocation = inProgress || !oldOrder;

  const addressLines = order?.address.split(',') || [];
  return (
    <View style={[styles.orderBox, isMobile && styles.mobileOrderBox]}>
      {!!order && (
        <>
          <Link to='/profile/orders' style={styles.closeButton}>
            <Close fillBox={true} />
          </Link>
          <View style={styles.section}>
            <TitleText
              fontSize={24}
              color='primary'
              center={true}
              uppercase={true}>
              <TitleText uppercase={true}>{order.pickupId}</TitleText>
            </TitleText>
            <TitleText
              fontSize={24}
              color='primary'
              center={true}
              uppercase={true}>
              ORDER #
              <TitleText uppercase={true}>{order.id.slice(0, 4)}</TitleText>
            </TitleText>

            <BodyText
              uppercase={true}
              center={true}
              color='textLight'
              bold={true}>
              {getDateString(order.createdAt)} •{' '}
              {getTimeString(order.createdAt)}
            </BodyText>

            <BodyText
              uppercase={true}
              center={true}
              bold={true}
              fontSize={highlightState ? 16 : 14}
              color={
                highlightState
                  ? readyForPickup
                    ? 'darkBlue'
                    : 'blue'
                  : 'textLight'
              }
              mt={highlightState ? 8 : 2}>
              {displayState}
              {!!showInProgress && (
                <BodyText ml={4} color='textDark'>
                  • PICKUP ETA {getTimeString(order.readyTime)}
                </BodyText>
              )}
              {!!readyForPickup && !!order.handoffTime && (
                <BodyText ml={4} color='textLight'>
                  • PICKUP TIME {getTimeString(order.handoffTime)}
                </BodyText>
              )}
              {order.refundAttempted && (
                <BodyText color='green'>REFUNDED</BodyText>
              )}
            </BodyText>
          </View>

          {order.products.map((product) => (
            <View style={styles.orderItem} key={product.id}>
              <View style={styles.itemQuantity}>
                <BodyText bold={true} color='black'>
                  {product.quantity}
                </BodyText>
                <Close width={6} ml={1} bold={true} color='black' />
              </View>
              <View style={styles.productBox}>
                <BodyText bold={true} color='primary' uppercase={true}>
                  {product.name}
                </BodyText>
                <View style={styles.choiceList}>
                  <BodyText>
                    {product.selections.added.map((c, cIdx) => (
                      <BodyText color='midDark' key={cIdx}>
                        {c}
                        {(cIdx !== product.selections.added.length - 1 ||
                          product.selections.removed.length > 0) &&
                          ', '}
                      </BodyText>
                    ))}
                    {product.selections.removed.map((c, cIdx) => (
                      <BodyText color='textDark' key={cIdx}>
                        No {c}
                        {cIdx !== product.selections.removed.length - 1 && ', '}
                      </BodyText>
                    ))}
                  </BodyText>
                </View>

                <BodyText color='midDark' fontWeight='bold'>
                  {formatPrice(product.price, { trim: true })}
                </BodyText>
              </View>
            </View>
          ))}

          <View style={styles.section}>
            <View style={styles.infoBox}>
              <BodyText color='textLight' fontSize={12} bold={true} mb={4}>
                {!store?.open && 'GENERAL '}LOCATION
              </BodyText>
              <BodyText bold={true}>{order.storeInfo.name}</BodyText>
              <BodyText>{addressLines[0].trim()}</BodyText>
              <BodyText>{addressLines.slice(1).join(', ').trim()}</BodyText>

              {showMapLocation && !!store && (
                <>
                  {!mapError ? (
                    <>
                      <View style={styles.mapBox}>
                        <MapPreview
                          store={store}
                          // disabled={true}
                          zoomedIn={true}
                          setMapError={setMapError}
                        />
                      </View>
                      <Pressable
                        onPress={() => getMapLink(store.id)}
                        style={styles.mapLink}>
                        <BodyText
                          fontSize={16}
                          color='secondary'
                          underline={true}
                          mr={5}
                          bold={false}>
                          {store.address.split(',')[0]}
                        </BodyText>
                        <View style={{ marginTop: 2 }}>
                          <ExternalLink width={12} height={40} />
                        </View>
                      </Pressable>
                    </>
                  ) : (
                    <MapButton store={store} />
                  )}
                </>
              )}
            </View>

            {order.coupon && (
              <View style={styles.infoBox}>
                <BodyText color='textLight' bold={true}>
                  COUPON:{' '}
                  <BodyText bold={true} color='textDark'>
                    {order.coupon}
                  </BodyText>
                </BodyText>
              </View>
            )}

            <View style={styles.infoBox}>
              <View style={styles.priceRow}>
                <BodyText color='textLight' bold={true}>
                  SUBTOTAL
                </BodyText>
                <BodyText color='textDark' bold={true}>
                  {formatPrice(order.subtotal)}
                </BodyText>
              </View>
              {!!order.discount && (
                <View style={styles.priceRow}>
                  <BodyText color='textLight' bold={true}>
                    DISCOUNT
                  </BodyText>
                  <BodyText color='primary' bold={true}>
                    {order.discount ? formatPrice(order.discount) : '-'}
                  </BodyText>
                </View>
              )}
              <View style={styles.priceRow}>
                <BodyText color='textLight' bold={true}>
                  SALES TAX
                </BodyText>
                <BodyText color='textDark' bold={true}>
                  {order.tax ? formatPrice(order.tax) : '-'}
                </BodyText>
              </View>
            </View>

            <View style={styles.infoBox}>
              <View style={styles.priceRow}>
                <BodyText color='textLight' bold={true} fontSize={16}>
                  TOTAL
                </BodyText>
                <BodyText color='textDark' bold={true}>
                  {order.total ? formatPrice(order.total) : '$0'}
                </BodyText>
              </View>
            </View>
          </View>
        </>
      )}
      {!order && <SpinLoader fullSize={true} minSize={400} />}
    </View>
  );
};

export default OrderSummary;

import React from 'react';
import { View, Pressable } from 'react-native';

import { BodyText } from '../..';

import styles from './styles';

interface Props {
  paymentMethods: StripePaymentMethodCard[];
  selectedPaymentMethod: string;
  setPaymentMethod: (id: string) => void;
}

const WebPaymentMethods = ({
  paymentMethods,
  selectedPaymentMethod,
  setPaymentMethod,
}: Props) => {
  return (
    <View style={styles.methodsWrapper}>
      {paymentMethods.map((m) => {
        const selected = m.id === selectedPaymentMethod;
        return (
          <Pressable
            onPress={() => setPaymentMethod(m.id)}
            style={[styles.paymentMethod, selected && styles.selectedMethod]}
            key={m.id}>
            <BodyText color='textDark'>
              {m.brand.toUpperCase()} ending in {m.last4}
            </BodyText>
            <View
              style={[styles.indicator, selected && styles.activeIndicator]}
            />
          </Pressable>
        );
      })}

      <Pressable
        onPress={() => setPaymentMethod('')}
        style={[
          styles.paymentMethod,
          !selectedPaymentMethod && styles.selectedMethod,
        ]}>
        <BodyText color='textDark'>Add a new card</BodyText>
      </Pressable>
    </View>
  );
};

export default WebPaymentMethods;

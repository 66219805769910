import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../../helpers';

const styles = StyleSheet.create({
  profileWrapper: { flexGrow: 1, minHeight: 700 },
  contentWrapper: {
    flexGrow: 1,
    width: 600,
    maxWidth: '90%',
    alignSelf: 'center',
    paddingBottom: 200,
  },
  titleRow: {
    ...globalStyles.flexRow,
    flexDirection: 'column',
    marginBottom: 10,
  },
  labelRow: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    marginTop: 30,
    marginBottom: 10,
  },
  inputBox: {
    color: colors.textDark,
    borderWidth: 1,
    borderColor: colors.midLight,
    backgroundColor: colors.white,
    paddingVertical: 10,
    paddingHorizontal: 16,
    fontSize: 16,
    lineHeight: 24,
    borderRadius: 4,
    ...globalStyles.noOutline,
  },
  disabledInput: {
    color: colors.textDark,
    borderWidth: 1,
    borderColor: colors.midLight,
    paddingVertical: 10,
    paddingHorizontal: 16,
    fontSize: 16,
    lineHeight: 24,
    borderRadius: 4,
    opacity: 0.7,
  },
  errorInput: {
    borderColor: colors.error,
  },
  focusedInput: {
    borderColor: colors.secondary,
  },
  toggleRow: {
    ...globalStyles.flexRow,
    justifyContent: 'space-between',
    marginTop: 10,
  },
  divider: {
    borderBottomColor: colors.midLight,
    borderBottomWidth: 1,
    marginBottom: 20,
  },
  updateButton: {
    ...globalStyles.flexRow,
    backgroundColor: colors.secondary,
    borderRadius: 50,
    paddingVertical: 20,
    marginBottom: 80,
    marginTop: 30,
  },
});

export default styles;

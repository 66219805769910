import React, { useCallback } from 'react';
import { colors } from '../../helpers';

import { Text, TextStyle, Linking } from 'react-native';

interface Props {
  style?: TextStyle | Array<TextStyle | false>;
  color?: string;
  uppercase?: boolean;
  hovered?: boolean;
  hoverColor?: string;
  fontSize?: number;
  fontWeight?: FontWeight;
  lineHeight?: number;
  disabled?: boolean;
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  bold?: boolean;
  opacity?: number;
  spacing?: number;
  link?: string;
  center?: boolean;
  underline?: boolean;
  strike?: boolean;
  onPress?: () => void;
  numberOfLines?: number;
  testID?: string;
  children: React.ReactNode;
  textRef?: any;
}

const BodyText = ({
  style,
  color,
  children,
  uppercase,
  hovered,
  hoverColor,
  fontSize,
  fontWeight,
  lineHeight,
  disabled,
  mt,
  mb,
  mr,
  ml,
  bold,
  opacity,
  spacing,
  link,
  center,
  underline,
  strike,
  onPress,
  numberOfLines,
  testID,
  textRef,
}: Props) => {
  const extraStyle: TextStyle = {
    fontFamily: 'MierA-Book',
    // color: colors.textDark,
  };

  if (color) extraStyle.color = colors[color] || color;

  let hasStyleColor = !!style && 'color' in style;
  if (Array.isArray(style))
    hasStyleColor = style.some((s) => !!s && 'color' in s);

  if (!color && !hasStyleColor) extraStyle.color = colors.textDark;

  if (hovered && hoverColor) extraStyle.color = colors[hoverColor];
  if (fontSize) {
    extraStyle.fontSize = fontSize;
    extraStyle.lineHeight = fontSize * 1.1;
  }
  if (lineHeight) extraStyle.lineHeight = lineHeight;

  if (bold) extraStyle.fontWeight = '700';
  if (fontWeight) extraStyle.fontWeight = fontWeight;
  if (disabled) {
    // extraStyle.textDecorationLine = 'line-through';
    extraStyle.color = colors.text;
  }

  if (mt) extraStyle.marginTop = mt;
  if (mb) extraStyle.marginBottom = mb;
  if (mr) extraStyle.marginRight = mr;
  if (ml) extraStyle.marginLeft = ml;

  if (spacing) extraStyle.letterSpacing = spacing;

  if (center) {
    extraStyle.textAlign = 'center';
    extraStyle.alignSelf = 'center';
  }

  if (underline) extraStyle.textDecorationLine = 'underline';
  if (strike) extraStyle.textDecorationLine = 'line-through';

  if (typeof opacity !== 'undefined') extraStyle.opacity = opacity;

  const text =
    children && typeof children === 'string' && uppercase
      ? children.toUpperCase()
      : Array.isArray(children) && uppercase
      ? children.map((c) => (typeof c === 'string' ? c.toUpperCase() : c))
      : children;

  const goToUrl = useCallback(() => {
    if (!link) return;
    Linking.canOpenURL(link).then((supported) => {
      if (supported) Linking.openURL(link);
      else console.error('Linking not supported');
    });
  }, [link]);

  const textPress = onPress ? onPress : link ? goToUrl : undefined;

  return (
    <Text
      onPress={textPress}
      style={[style, extraStyle]}
      numberOfLines={numberOfLines}
      testID={testID}
      ref={textRef}>
      {text}
    </Text>
  );
};

export default BodyText;

import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../../helpers';

import mobileStyles from './mobileStyles';

const maxContentWidth = 500;

const styles = StyleSheet.create({
  modalBox: {
    paddingHorizontal: 10,
    width: 500,
    maxWidth: '80%',
    maxHeight: '80%',
    backgroundColor: colors.secondary,
  },
  closeButton: {
    position: 'absolute',
    right: -20,
    top: -20,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    elevation: 2,
  },
  section: {
    paddingVertical: 20,
    paddingHorizontal: 40,
    width: '100%',
    maxWidth: maxContentWidth * 1.2,
    alignSelf: 'center',
  },
  showCase: {
    ...globalStyles.flexColumn,
    width: '100%',
    paddingHorizontal: 14,
    paddingTop: 10,
    paddingBottom: 40,
  },
  imageBox: {
    height: 180,
    width: 300,
    maxWidth: '90%',
    marginBottom: 40,
    // borderWidth: 2,
    // borderColor: 'hotpink',
  },
  pizzaImage: {
    position: 'absolute',
    top: '1%',
    left: '-7%',
    width: 320,
    height: 200,
  },
  drinkImage: {
    position: 'absolute',
    bottom: '-18%',
    right: '-14%',
    width: 100,
    height: 140,
  },
  dippingImage: {
    position: 'absolute',
    bottom: '-16%',
    right: '10%',
    width: 65,
    height: 77,
  },
  productList: {
    width: '100%',
    marginTop: 40,
  },
  addButton: {
    ...globalStyles.flexRow,
    justifyContent: 'center',
    width: '100%',
    height: 60,
    backgroundColor: colors.primary,
    borderRadius: 100,
    paddingHorizontal: 30,
    userSelect: 'none',
  },
  storesClosedDisplay: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    paddingVertical: 20,
  },
});

export default { ...styles, ...mobileStyles };

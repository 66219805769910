import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../../helpers';

const styles = StyleSheet.create({
  ordersWrapper: {
    flexGrow: 1,
    width: 600,
    maxWidth: '100%',
    minHeight: 700,
    alignSelf: 'center',
    paddingBottom: 200,
  },
  orderBox: {
    width: '100%',
    paddingVertical: 24,
    paddingHorizontal: 20,
    backgroundColor: colors.white,
    marginBottom: 28,
  },
  progressPreview: {
    marginBottom: 8,
  },
  arrowBox: {
    ...globalStyles.flexRow,
    position: 'absolute',
    right: '1%',
    height: '100%',
    top: 0,
  },
});

export default styles;

import { StyleSheet } from 'react-native';

import { statusBarHeight } from '../../helpers';

const mobileStyles = StyleSheet.create({
  mobileCartWrapper: {
    position: 'absolute',
  },
  mobileHeader: {
    height: 64 + statusBarHeight,
    paddingTop: statusBarHeight,
  },
  mobileCartContent: {
    width: '100%',
    minWidth: 300,
  },
  mobileCartList: {},
  mobileCloseButton: {
    top: statusBarHeight,
  },
});

export default mobileStyles;

import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../../helpers';

const styles = StyleSheet.create({
  infoBox: {
    position: 'relative',
    width: '52%',
    elevation: 10,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column-reverse',
    alignContent: 'center',
    alignItems: 'center',
    marginHorizontal: 'auto',
    overflow: 'hidden',
  },
  mobileInfoBox: {
    width: '100%',
  },
  infoRow: {
    width: '100%',
    height: '100%',
    maxWidth: 450,
    display: 'flex',
    paddingHorizontal: '3%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  mobileRow: {},
  headerButton: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    // maxWidth: '47%',
    // width: '50%',
    flex: 1,
    // minWidth: 140,
    borderWidth: 1.4,
    height: 45,
    borderColor: colors.dimMaroon,
    backgroundColor: colors.body,
    borderRadius: 4,
    overflow: 'hidden',
  },
  mobileHeaderButton: {
    paddingHorizontal: 5,
  },
  spaceBlock: {
    width: 25,
    maxWidth: '2.5%',
  },
  detailBox: {
    // marginLeft: 6,
  },
  detailHeader: {
    fontSize: 12,
    color: colors.textDark,
    fontWeight: '600',
  },
  pillBox: {
    ...globalStyles.flexRow,
    backgroundColor: colors.primary,
    maxWidth: '47%',
    borderWidth: 0,
  },
  pillInfo: {
    color: colors.white,
    fontSize: 15,
    fontWeight: '800',
  },
});

export default styles;

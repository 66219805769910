import React, { useEffect, useCallback, useState } from 'react';
import { View, Pressable, Share, StyleSheet, Alert } from 'react-native';
import { useSelector } from 'react-redux';

import { readUser } from '../globalStore';

import {
  globalStyles,
  colors,
  logError,
  logMessage,
  isWeb,
  isIos,
  getEnvVariable,
} from '../helpers';

import { BodyText } from '.';

import IconSvg from './SocialIcon/IconSvg';

let copyTimer = setTimeout(() => undefined);

interface Props {}

const ReferralSection = ({}: Props) => {
  const user = useSelector(readUser);
  const appConfig = useSelector((state: RootState) => state.appInfo.appConfig);
  const referralBase = getEnvVariable('REACT_APP_REFERRAL_BASE');
  const referralLink = referralBase + user.publicId;
  const [linkCopied, setLinkCopied] = useState(false);

  useEffect(() => {
    return () => setLinkCopied(false);
  }, []);

  const referralCopy = appConfig?.referralCopyText;

  const shareCopy = appConfig?.referralShareText;

  const webShare = useCallback(async () => {
    try {
      await navigator.share({
        title: 'Stellar Pizza',
        text: shareCopy,
        url: referralLink,
      });
      logMessage(`Successful referral share from web app - ${user.publicId}`);
    } catch (e: any) {
      const isAbortError = !!e.toString && e.toString().includes('AbortError');
      if (!isAbortError) logError(e);
    }
  }, [referralLink, shareCopy, user.publicId]);

  const nativeShare = useCallback(async () => {
    try {
      const result = await Share.share({
        title: 'Stellar Pizza',
        message: shareCopy,
        url: referralLink,
      });
      if (result.action === 'sharedAction')
        logMessage(
          `Successful referral share from native app - ${user.publicId}`
        );
    } catch (error: any) {
      logError(`Error with native share - ${error.message}`);
    }
  }, [shareCopy, referralLink, user.publicId]);

  const canShare = isIos || !!navigator?.share;

  const webCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      logMessage(`Referral link copied from web app - ${user.publicId}`);
      setLinkCopied(true);
      copyTimer = setTimeout(() => setLinkCopied(false), 4000);
    } catch (e: any) {
      logError(e);
    }
  }, [referralLink]);

  return (
    <View style={styles.referralBox}>
      <BodyText fontSize={24} color='black' bold={true} center={true}>
        {appConfig?.referralTitleText}
      </BodyText>

      <BodyText mt={10} center={true}>
        {referralCopy}
      </BodyText>

      <View style={styles.buttonBox}>
        {canShare && (
          <Pressable
            onPress={isWeb ? webShare : nativeShare}
            style={styles.actionButton}>
            <IconSvg name='share' size={16} color='white' />
            <BodyText color='white' ml={8}>
              SHARE
            </BodyText>
          </Pressable>
        )}

        {isWeb && (
          <Pressable onPress={webCopy} style={styles.actionButton}>
            {linkCopied ? (
              <BodyText color='pink' bold={true}>
                Link Copied!
              </BodyText>
            ) : (
              <>
                <IconSvg name='link' size={14} color='white' />
                <BodyText color='white' ml={6}>
                  COPY LINK
                </BodyText>
              </>
            )}
          </Pressable>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  referralBox: {
    ...globalStyles.flexColumn,
    width: '100%',
  },
  buttonBox: {
    ...globalStyles.flexRow,
    marginTop: 30,
    width: 250,
  },
  actionButton: {
    ...globalStyles.flexRow,
    marginHorizontal: 5,
    flex: 1,
    height: 45,
    backgroundColor: colors.secondary,
    borderRadius: 6,
  },
});

export default ReferralSection;

import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../../helpers';

const styles = StyleSheet.create({
  couponContainer: {
    ...globalStyles.flexColumn,
    flexGrow: 1,
    minHeight: 700,
    width: 600,
    maxWidth: '100%',
    alignSelf: 'center',
    // overflow: 'hidden',
    paddingBottom: 200,
  },
  referralButton: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    width: '100%',
    height: 70,
    borderRadius: 6,
    backgroundColor: colors.darkBlue,
    marginBottom: 10,
  },
});

export default styles;

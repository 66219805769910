import { StyleSheet } from 'react-native';

import { colors } from '../../helpers';

const styles = StyleSheet.create({
  counterBox: {
    width: 120,
    height: 50,
    borderWidth: 2,
    borderRadius: 40,
    borderColor: colors.body,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  buttonBox: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    fontSize: 16,
    marginBottom: 2,
    // lineHeight: 16,
    // width: 40,
    // height: '100%',
    // display: 'flex',
    // alignContent: 'center',
    // alignItems: 'center',
    // justifyContent: 'center',
    userSelect: 'none',
    // borderWidth: 2,
  },
});

export default styles;

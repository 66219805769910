import React from 'react';
import { View, Pressable } from 'react-native';
import { connect } from 'react-redux';
import { useOutletContext } from 'react-router-native';

import {
  TitleText,
  TextInput,
  BodyText,
  StarLoader,
  SpinLoader,
  Toggle,
} from '../../../components';

import {
  readUser,
  readUserError,
  updateUser,
  setUserUpdating,
  clearUserError,
  setAccountDeletionModal,
} from '../../../globalStore';

import styles from './styles';

interface Props {
  user: UserType;
  userError: string;
  updateUser: (user: UserType) => void;
  setUserUpdating: () => void;
  clearUserError: () => void;
  setAccountDeletionModal: (show: boolean) => void;
  isMobile: boolean;
  scrollUp: (animated?: boolean) => void;
}

type FieldType = 'name' | 'email';
type ToggleFieldType =
  | 'marketingEmails'
  | 'marketingEmailBirthday'
  | 'marketingEmailNewsletter'
  | 'couponIssuedSMS';

interface State {
  localUser: UserType | null;
  updated: boolean;
}

class Profile extends React.Component<Props, State> {
  state: State = { localUser: null, updated: false };

  updatedTimer: ReturnType<typeof setTimeout> | null = null;

  componentDidMount() {
    this.props.clearUserError();
    if (this.props.user.name !== undefined && !this.state.localUser)
      this.setLocalUser();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!this.state.localUser && this.props.user.name !== undefined)
      this.setLocalUser();

    if (prevProps.user.updating && !this.props.user.updating)
      this.showUserUpdated();
  }

  componentWillUnmount(): void {
    if (this.updatedTimer) clearTimeout(this.updatedTimer);
  }

  setLocalUser = () => {
    const { user } = this.props;

    const localUser = {
      name: user.name || '',
      phone: user.phone?.slice(0) || '',
      email: user.email || '',
      marketingEmails: user.marketingEmails,
      marketingEmailBirthday: user.marketingEmailBirthday,
      marketingEmailNewsletter: user.marketingEmailNewsletter,
      couponIssuedSMS: user.couponIssuedSMS,
    };

    this.setState({ localUser });
  };

  updateField = (field: FieldType, value: string) => {
    const localUser = { ...this.state.localUser };
    // if (field === 'phone') localUser.phone = value.replace(/[^0-9\-()]/g, '');
    localUser[field] = value;
    this.setState({ localUser });
  };

  toggleField = (field: ToggleFieldType) => {
    const localUser: UserType = { ...this.state.localUser };
    localUser[field] = !localUser[field];
    if (field === 'marketingEmails') {
      localUser.marketingEmailBirthday = localUser.marketingEmails;
      localUser.marketingEmailNewsletter = localUser.marketingEmails;
    }
    this.setState({ localUser });
  };

  saveUser = () => {
    this.props.setUserUpdating();
    const updatedUser = {
      ...this.props.user,
      ...this.state.localUser,
    };
    this.props.updateUser(updatedUser);
  };

  showUserUpdated = () => {
    if (this.props.userError) return;
    this.props.scrollUp(true);
    if (this.updatedTimer) clearTimeout(this.updatedTimer);
    this.setState({ updated: true });
    this.updatedTimer = setTimeout(
      () => this.setState({ updated: false }),
      2400
    );
  };

  render() {
    const { userError, isMobile, setAccountDeletionModal } = this.props;
    const { localUser, updated } = this.state;

    const userNameError =
      userError === 'First and last name are required.'
        ? 'first and last name are required'
        : '';

    const emailError = userError === 'Enter a valid email address.';

    return (
      <View style={styles.profileWrapper} testID='profile-page-wrapper'>
        {!localUser && <SpinLoader fullSize={true} minSize={400} />}
        {!!localUser && (
          <View style={styles.contentWrapper} testID='content-wrapper'>
            <View style={styles.titleRow}>
              {!isMobile && (
                <TitleText color='secondary' uppercase={true} fontSize={18}>
                  Your Profile
                </TitleText>
              )}

              <BodyText color='blue' opacity={updated ? 1 : 0}>
                Profile updated successfully.
              </BodyText>
              {!userNameError && !emailError && !!userError && (
                <BodyText color='error'>{userError}</BodyText>
              )}
            </View>

            <TextInput
              value={localUser.phone}
              onChangeText={() => undefined}
              style={styles.disabledInput}
              label='phone number'
              labelUppercase={true}
              labelBold={true}
              disabled={true}
              autoComplete='tel'
              textContentType='telephoneNumber'
              keyboardType='numeric'
            />

            <BodyText color='text' ml={2} mt={4}>
              Your phone number is used to send a message when your orders are
              ready.
            </BodyText>

            <View style={styles.labelRow}>
              <BodyText color='textDark' bold={true}>
                NAME
              </BodyText>
              {userNameError ? (
                <BodyText ml={5} color='error'>
                  ({userNameError})
                </BodyText>
              ) : (
                <BodyText
                  ml={5}
                  bold={true}
                  color={localUser.name ? 'textDark' : 'primary'}>
                  (required)
                </BodyText>
              )}
            </View>

            <TextInput
              value={localUser.name}
              onChangeText={(value) => this.updateField('name', value)}
              style={styles.inputBox}
              hasError={!!userNameError}
              focusStyle={styles.focusedInput}
              autoComplete='name'
              textContentType='name'
              autoCorrect={false}
              testID='profile-name-input'
            />
            <BodyText color='text' ml={2}>
              Your name will be used to identify your orders.
            </BodyText>

            <View style={styles.labelRow}>
              <BodyText color='textDark' bold={true}>
                EMAIL
              </BodyText>
              {!!emailError && (
                <BodyText color='error' bold={true} ml={5}>
                  ({userError})
                </BodyText>
              )}
            </View>

            <TextInput
              value={localUser.email}
              onChangeText={(value) => this.updateField('email', value)}
              style={styles.inputBox}
              focusStyle={styles.focusedInput}
              hasError={!!emailError}
              autoComplete='email'
              textContentType='emailAddress'
              keyboardType='email-address'
              autoCapitalize='none'
              autoCorrect={false}
              testID='profile-email-input'
            />

            <BodyText color='text' mb={15} ml={2}>
              Enter your email to receive a receipt for your orders.
            </BodyText>

            <View style={styles.toggleRow}>
              <BodyText color='textDark' bold={true}>
                TEXT ALERTS FOR COUPONS
              </BodyText>
              <Toggle
                value={!!localUser.couponIssuedSMS}
                toggleValue={() => this.toggleField('couponIssuedSMS')}
              />
            </View>

            <BodyText color='text' mb={15} ml={2}>
              Enable/Disable text alerts when a coupon is issued to you.
            </BodyText>

            {/* <View style={styles.toggleRow}>
                  <BodyText color='textDark' bold={true}>
                    EMAILS
                  </BodyText>
                  <Toggle
                    value={!!localUser.marketingEmails}
                    toggleValue={() => this.toggleField('marketingEmails')}
                  />
                </View>
                <View style={styles.divider} />
                <View style={styles.toggleRow}>
                  <BodyText color='textDark' bold={true}>
                    BIRTHDAY
                  </BodyText>
                  <Toggle
                    value={!!localUser.marketingEmailBirthday}
                    toggleValue={() =>
                      this.toggleField('marketingEmailBirthday')
                    }
                  />
                </View>
                <View style={styles.toggleRow}>
                  <BodyText color='textDark' bold={true}>
                    NEWSLETTER
                  </BodyText>
                  <Toggle
                    value={!!localUser.marketingEmailNewsletter}
                    toggleValue={() =>
                      this.toggleField('marketingEmailNewsletter')
                    }
                  />
                </View> */}

            <Pressable style={styles.updateButton} onPress={this.saveUser}>
              <BodyText
                color={this.props.user.updating ? 'secondary' : 'white'}
                bold={true}
                fontSize={16}>
                UPDATE PROFILE
              </BodyText>
              {!!this.props.user.updating && (
                <View style={{ position: 'absolute' }}>
                  <StarLoader />
                </View>
              )}
            </Pressable>

            <BodyText
              onPress={() => setAccountDeletionModal(true)}
              color='primary'
              center={true}
              mb={80}
              fontSize={18}>
              Delete Account
            </BodyText>
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: readUser(state),
  userError: readUserError(state),
  isMobile: state.appInfo.isMobile,
});

const mapDispatchToProps = {
  updateUser,
  setUserUpdating,
  clearUserError,
  setAccountDeletionModal,
};

const withContext = (Component: typeof React.Component) => {
  return function WrapComponent(props: Record<string, unknown>) {
    const scrollUp = useOutletContext();
    return <Component {...props} scrollUp={scrollUp} />;
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withContext(Profile));

import React, { useRef, useCallback } from 'react';
import { View, Pressable, Image, Animated, Easing } from 'react-native';
import { useSelector } from 'react-redux';

import { readStoreMenu } from '../../globalStore';

import { getProductImage, animate, formatPrice } from '../../helpers';

import { BodyText } from '..';

import styles from './styles';

interface Props {
  combo: {
    pizza: ProductType;
    dipping: ProductType;
    drink: ProductType;
  };
  clickCombo: () => void;
  productLibrary: LibraryType;
}

let pressTimer = setTimeout(() => undefined);

const ComboBox2 = ({ combo, clickCombo, productLibrary }: Props) => {
  const pressAnim = useRef(new Animated.Value(0)).current;

  const onPressIn = useCallback(() => {
    pressTimer = setTimeout(() => {
      animate(pressAnim, {
        toValue: 0.4,
        duration: 120,
        easing: Easing.out(Easing.ease),
      }).start();
    }, 80);
  }, []);

  const onPressOut = useCallback(() => {
    clearTimeout(pressTimer);
    animate(pressAnim, { toValue: 0, duration: 100 }).start();
    // Animated.sequence([
    //   animate(pressAnim, {
    //     toValue: .9,
    //     duration: 200,
    //     easing: Easing.in(Easing.ease),
    //   }),
    //   animate(pressAnim, {
    //     toValue: 0,
    //     duration: 200,
    //     easing: Easing.out(Easing.ease),
    //   }),
    // ]).start();
  }, []);
  const pizzaImage = getProductImage(combo.pizza);
  const dippingImage = getProductImage(combo.dipping);
  const drinkImage = getProductImage(combo.drink);

  const priceTotal =
    combo.pizza.price + combo.dipping.price + combo.drink.price;
  const price = formatPrice(priceTotal);

  const faceScale = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.99],
  });

  const shift = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 4],
  });

  const downShift = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 3.5],
  });

  const shrink = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['3%', '2%'],
  });

  const size = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['99.5%', '94%'],
  });

  const shiftUp = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -2],
  });

  const shiftDownLeft = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0],
  });

  const shiftLeft = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -2],
  });

  const thinMint = pressAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 0],
  });

  return (
    <Pressable
      style={styles.comboBox}
      onPress={clickCombo}
      onPressIn={onPressIn}
      onPressOut={onPressOut}>
      <Animated.View
        style={[
          styles.comboFace,
          {
            // borderLeftWidth: 1,
            // borderTopWidth: 1,
            borderRadius: 3,
            transform: [
              { translateX: shift },
              { translateY: downShift },
              { scale: faceScale },
            ],
          },
        ]}>
        <BodyText style={styles.comboPrice}>{price}</BodyText>
        <Image
          source={pizzaImage}
          style={styles.pizzaImage}
          resizeMode='contain'
        />
        <Image
          source={drinkImage}
          style={styles.drinkImage}
          resizeMode='contain'
        />
        <Image
          source={dippingImage}
          style={styles.dippingImage}
          resizeMode='contain'
        />
      </Animated.View>
      <Animated.View
        style={[
          styles.rightEdge,
          {
            width: shrink,
            height: size,
            transform: [
              { rotate: '00deg' },
              { skewY: '45deg' },
              { translateX: shiftLeft },
            ],
          },
        ]}
      />
      <Animated.View
        style={[
          styles.bottomEdge,
          {
            height: shrink,
            width: size,
            transform: [
              { rotate: '0deg' },
              { skewX: '45deg' },
              { translateY: shiftUp },
              { translateX: shiftDownLeft },
            ],
          },
        ]}
      />
    </Pressable>
  );
};

export default ComboBox2;

import * as React from 'react';
import { Dimensions, AppState, View } from 'react-native';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { MOCK_TESTING } from '@app/core';

import {
  addFocusListener,
  clearStorageItem,
  readStorage,
  AuthContext,
  withRouter,
  readQueryParam,
  removeQueryParam,
  isWeb,
} from '../../helpers';

import { LoginButton } from '..';

import {
  setScreenSize,
  loadStores,
  loadGenericMenu,
  setUserToken,
  setUserTokenAndLoad,
  loadUser,
  loadUserItems,
  updateOrderSuccessModal,
  registerReferral,
  loadAppConfiguration,
  setReferrerId,
  selectCoupon,
  setNewCouponModal,
  loadUrlTags,
} from '../../globalStore';

export let goHome: () => void;
export let loadUserInfo: () => void;

interface Props {
  user: UserType;
  userOrders: FilledOrderType[];
  orderSuccessModal: OrderType | null;
  // Dispatch actions
  setScreenSize: ({
    width,
    height,
    isMobile,
  }: {
    width: number;
    height: number;
    isMobile: boolean;
  }) => void;
  setUserToken: (token: string) => void;
  setUserTokenAndLoad: (token: string) => void;
  loadStores: () => void;
  loadGenericMenu: () => void;
  loadUser: () => void;
  loadUserItems: (payload: UserItemsPayload) => void;
  updateOrderSuccessModal: (order: OrderType) => void;
  navigate: (to: string) => void;
  registerReferral: (referrerId: string) => void;
  loadAppConfiguration: () => void;
  setReferrerId: (referrerId: string) => void;
  selectCoupon: (coupon: CouponType) => void;
  setNewCouponModal: (coupon: CouponType) => void;
  loadUrlTags: (tags: string) => void;
}

class AppInfo extends React.Component<Props> {
  declare context: React.ContextType<typeof AuthContext>;
  orderInterval: ReturnType<typeof setInterval> | null = null;

  componentDidMount() {
    this.resize();
    this.readUrl();
    loadUserInfo = () => this.initiateData();
    goHome = () => this.props.navigate('/');
    this.initiateData();
    if (window?.addEventListener)
      window.addEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.userOrders, this.props.userOrders))
      this.checkUserOrders();
  }

  readUrl = () => {
    if (!isWeb) return;
    const couponCode = readQueryParam('coupon');
    removeQueryParam('coupon');
    const tags = readQueryParam('tags') || '';
    removeQueryParam('tags');
    this.props.loadUrlTags(tags);

    if (couponCode) {
      const slug = couponCode.toUpperCase();
      this.props.selectCoupon({ slug });
      this.props.setNewCouponModal({ slug });
    }
  };

  initiateData = async () => {
    const {
      loadStores,
      loadGenericMenu,
      registerReferral,
      loadAppConfiguration,
      loadUserItems,
      setReferrerId,
    } = this.props;

    loadStores();
    loadGenericMenu();
    loadAppConfiguration();

    const storageReferrerId = await readStorage('referrerId');
    const queryReferrerId = readQueryParam('referred_by');
    const referrerId = queryReferrerId || storageReferrerId;

    const token = await this.loadUserToken();
    if (token) {
      if (referrerId) registerReferral(referrerId);
      loadUserItems(['orders', 'coupons']);
    } else {
      if (referrerId) setReferrerId(referrerId);
      clearStorageItem('recentOrders');
    }

    addFocusListener(this.checkUserToken);
  };

  checkUserToken = async () => {
    const { user, setUserToken } = this.props;
    const token = await this.context.getToken();
    if (token !== user.token) {
      setUserToken(token);
    }
  };

  loadUserToken = async () => {
    const { setUserToken, setUserTokenAndLoad } = this.props;
    let token = '';

    try {
      if (MOCK_TESTING) token = 'mock_token';
      else token = await this.context.getToken();
      if (token) setUserTokenAndLoad(token);
      else setUserToken('');
    } catch (e) {
      setUserToken('');
    }
    return token;
  };

  pollUserItems = () => this.props.loadUserItems(['orders']);

  checkUserOrders = () => {
    const { userOrders, orderSuccessModal, updateOrderSuccessModal } =
      this.props;

    if (this.orderInterval) clearInterval(this.orderInterval);

    const currentState = AppState.currentState;
    const appActive = currentState === 'active';

    const fiveMins = 60000 * 5;
    let openOrders = false;
    let upSoon = false;

    const now = Date.now();
    userOrders.forEach((o) => {
      if (o.state === 'in_progress') {
        openOrders = true;
        if (o.readyTime) {
          const readyTime = new Date(o.readyTime).getTime();
          if (readyTime - now < fiveMins) upSoon = true;
        }
      }
    });

    if (upSoon) setTimeout(this.pollUserItems, 60000);
    else if (!appActive) setTimeout(this.pollUserItems, fiveMins * 2);
    else this.orderInterval = setInterval(this.pollUserItems, fiveMins);

    if (orderSuccessModal?.id) {
      const successOrder = userOrders.find(
        (o) => o.id === orderSuccessModal.id
      );
      if (successOrder) updateOrderSuccessModal(successOrder);
    }
  };

  resize = () => {
    const windowSize = Dimensions.get('window');
    const { width, height } = windowSize;
    const isMobile = width < 1100;
    this.props.setScreenSize({ width, height, isMobile });
  };

  render() {
    return (
      <View
        style={{
          display: 'none',
          opacity: 0,
          position: 'absolute',
          zIndex: -100,
          top: -1000,
        }}>
        <LoginButton />
      </View>
    );
  }
}

AppInfo.contextType = AuthContext;

const mapStateToProps = (state: RootState) => ({
  user: state.userInfo.user,
  userOrders: state.userInfo.userOrders,
  orderSuccessModal: state.appInfo.orderSuccessModal,
});

const mapDispatchToProps = {
  setScreenSize,
  loadStores,
  loadGenericMenu,
  setUserToken,
  setUserTokenAndLoad,
  loadUser,
  loadUserItems,
  updateOrderSuccessModal,
  registerReferral,
  loadAppConfiguration,
  setReferrerId,
  selectCoupon,
  setNewCouponModal,
  loadUrlTags,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppInfo));

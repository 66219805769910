import { Platform } from 'react-native';

export const isIos = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';

export const iOSWebDevice =
  window.navigator.userAgent.match(/iPhone|iPad|iPod/i);
export const androidWebDevice = window.navigator.userAgent.match(/Android/i);

export const isDesktop = !(
  iOSWebDevice ||
  isIos ||
  androidWebDevice ||
  isAndroid
);

import React from 'react';
import { StyleSheet, View, Platform } from 'react-native';

import { colors, globalStyles, logError } from '../helpers';

import { goHome } from './AppInfo';

import { BodyText } from '.';

import { Logo } from '../assets';

const isWeb = Platform.OS === 'web';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error: unknown) {
    return { hasError: !!error };
  }

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo): void {
    if (!isWeb) logError(error);
  }

  goHome = () => {
    if (isWeb) window.location.href = window.location.origin;
    else {
      goHome();
      this.setState({ hasError: false });
    }
  };
  render() {
    if (this.state.hasError)
      return (
        <View style={styles.wrapper}>
          <Logo width={200} height={200} />
          <BodyText color='white' fontSize={16}>
            woops something went wrong.
          </BodyText>
          <BodyText style={styles.homeButton} onPress={this.goHome}>
            Return Home
          </BodyText>
        </View>
      );
    return <>{this.props.children}</>;
  }
}

const styles = StyleSheet.create({
  wrapper: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: colors.primary,
  },
  homeButton: {
    backgroundColor: colors.secondary,
    color: colors.white,
    padding: 15,
    borderRadius: 5,
    fontSize: 18,
    marginTop: 50,
  },
});

import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Pressable, View, Animated } from 'react-native';

import { colors, animate } from '../../helpers';

import { BodyText, Block } from '../../components';

import { getOpeningTime } from './OpeningStoreBox';
import StoreHours from './StoreHours';

import styles from './styles';

interface Props {
  store: StoreType;
  firstStore: boolean;
  lastStore: boolean;
  expandHours?: boolean;
}

const ClosedStoreBox = ({
  store,
  firstStore,
  lastStore,
  expandHours,
}: Props) => {
  const closedAnim = useRef(new Animated.Value(1)).current;
  const closedTimer = useRef(setTimeout(() => undefined));

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    return () => clearTimeout(closedTimer.current);
  }, []);

  const onPress = useCallback(() => {
    if (closedTimer) clearTimeout(closedTimer.current);
    Animated.sequence([
      animate(closedAnim, { toValue: 1.08, duration: 400 }),
      animate(closedAnim, { toValue: 1.04, duration: 250 }),
    ]).start();
    setClicked(true);
    closedTimer.current = setTimeout(() => setClicked(false), 3500);
  }, []);

  const openingTime = getOpeningTime(store);

  return (
    <Pressable
      onPress={onPress}
      style={{ maxWidth: '94%' }}
      testID='closed-store-container'>
      <View
        style={[
          styles.storeBox,
          styles.closedStoreBox,
          {
            borderTopRightRadius: firstStore ? 8 : 0,
            borderTopLeftRadius: firstStore ? 8 : 0,
            borderBottomLeftRadius: lastStore ? 8 : 0,
            borderBottomRightRadius: lastStore ? 8 : 0,
            borderBottomWidth: !lastStore ? 1 : 0,
            backgroundColor: colors.grey,
          },
        ]}
        testID='closed-store-box'
        key={store.id}>
        <BodyText style={styles.label} color='dimMaroon'>
          {store.name}
        </BodyText>
        <BodyText style={[styles.sublabel, { marginTop: 8, marginBottom: 6 }]}>
          {store.address}
        </BodyText>

        <Block height={8} />
        <View style={styles.infoRow}>
          <Animated.View
            style={[
              styles.pill,
              styles.closed,
              { transform: [{ scale: closedAnim }], right: 2 },
            ]}>
            <BodyText color='white' fontSize={12}>
              {openingTime || 'Closed'}
            </BodyText>
          </Animated.View>
          {clicked && (
            <BodyText ml={10}>Currently closed, check back later!</BodyText>
          )}
        </View>
        <Block height={14} />
        <StoreHours store={store} expandHours={expandHours} />
        {!!store.events?.length && <Block height={20} />}
      </View>
    </Pressable>
  );
};

export default ClosedStoreBox;

import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import {
  useStripe,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';

import type { PaymentRequest } from '@stripe/stripe-js';

import { BodyText } from '../../../../components';

interface Props {
  order: OrderType;
  setErrorMessage: (error: string) => void;
  orderSuccess: () => void;
}
const WebPaymentRequestField = ({
  order,
  setErrorMessage,
  orderSuccess,
}: Props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null
  );

  useEffect(() => {
    if (!stripe || !order.total) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Stellar Pizza',
        amount: order.total,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then((canMakePaymentRes) => {
      if (canMakePaymentRes) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      if (!order.payment?.clientSecret)
        return setErrorMessage('Not able to process this request');

      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          order.payment?.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        setErrorMessage('Payment failed, please try another credit card');
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow.
        if (paymentIntent?.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(
            order.payment.clientSecret
          );
          if (error) {
            setErrorMessage('Payment failed, please try another credit card');
          } else {
            // The payment has succeeded.
            // triggerConfirmationSuccess(order);
            orderSuccess();
          }
        } else {
          // The payment has succeeded.
          // triggerConfirmationSuccess(order);
          orderSuccess();
        }
      }
    });
  }, [
    stripe,
    setErrorMessage,
    order,
    order.payment?.clientSecret,
    // triggerConfirmationSuccess,
  ]);

  if (paymentRequest)
    return (
      <View style={{ width: 220 }}>
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: { paymentRequestButton: { height: '50px' } },
          }}
        />
        <BodyText color='textDark' mt={44} mb={14}>
          Or pay with card
        </BodyText>
      </View>
    );
  return null;
};

export default WebPaymentRequestField;

import React from 'react';

import { StyleSheet, Pressable, ViewStyle } from 'react-native';

import { globalStyles, colors } from '../helpers';

import { BodyText, StarLoader } from '.';

interface Props {
  onClick: () => void;
  label: string;
  color?: string;
  style?: ViewStyle;
  children?: React.ReactNode;
  submitting?: boolean;
  testID?: string;
  mt?: number;
  mb?: number;
  width?: DimensionValue;
  center?: boolean;
}

const Button = ({
  onClick,
  label,
  color,
  style,
  submitting,
  testID,
  mt,
  mb,
  children,
  width,
  center,
}: Props) => {
  let buttonColor = colors.primary;
  if (color && colors[color]) buttonColor = colors[color];

  const extraStyle: ViewStyle = { backgroundColor: buttonColor };

  if (mt) extraStyle.marginTop = mt;
  if (mb) extraStyle.marginBottom = mt;
  if (width) extraStyle.width = width;
  if (center) extraStyle.alignSelf = 'center';

  return (
    <Pressable
      style={[styles.wrapper, style, extraStyle]}
      disabled={!!submitting}
      onPress={onClick}
      testID={testID}>
      {!!label && !submitting && (
        <BodyText color='white' fontSize={16} fontWeight='700' spacing={0.4}>
          {label}
        </BodyText>
      )}
      {!!submitting && <StarLoader />}
      {children}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...globalStyles.flexRow,
    justifyContent: 'center',
    width: '100%',
    height: 56,
    borderRadius: 100,
    paddingHorizontal: 30,
    userSelect: 'none',
  },
});

export default Button;

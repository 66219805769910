import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../helpers';

const styles = StyleSheet.create({
  mapWrapper: {},
  mask: {
    position: 'absolute',
    // width: '100%',
    // height: 120,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    // borderWidth: 1,
    borderColor: colors.midLight,
    borderTopWidth: 0,
    // borderBottomRightRadius: 10,
    // borderBottomLeftRadius: 10,
  },
  loadingFrame: {
    ...globalStyles.flexRow,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  mapBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  centerButtonBox: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  mapCover: {
    ...globalStyles.flexRow,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.textDark,
  },
});

export default styles;

const mockStoreConfig = [
  {
    id: 'a9d21c66-001c-4e4d-8849-5a8cf3efca3a',
    address: '123 Fake St, Hawthorne, CA 90250',
    mobile: false,
  },
  {
    id: 'a9d21c77-003c-4e4d-8849-5a8cf3efca3a',
    address: '223 Fake St, Hawthorne, CA 90250',
    mobile: true,
  },
  {
    id: 'fdd21c77-003c-4e4d-8849-5a8cf3efcb9b',
    address: '323 Fake St, Hawthorne, CA 90250',
    mobile: false,
  },
  {
    id: 'd186b3fb-2ccf-4cb0-b2ea-abe0becf5ee1',
    address: '12700 Yukon Ave, Hawthorne, CA 90250',
    mobile: false,
    opening: true,
  },
  {
    id: 'd186b3fb-2ccf-4cee-b2eb-abe0becf5ee1',
    address: '12706 Yukon Ave, Hawthorne, CA 90250',
    mobile: false,
    closed: true,
  },
  {
    id: 'd186b3fb-2ccf-4cee-b2eb-abe0ffff5ee5',
    address: '12708 Yukon Ave, Hawthorne, CA 90250',
    mobile: false,
    closed: true,
  },
];

export default mockStoreConfig;

import React from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';

import {
  readUserError,
  updateUser,
  setUserUpdating,
  loadUser,
} from '../../../globalStore';

import { logMessage } from '../../../helpers';

import { BodyText, TitleText, TextInput } from '../..';

import styles from './styles';

type FieldType = 'name' | 'email';

interface Props {
  user: UserType;
  userError: string;
  updateUser: (user: UserType) => void;
  setUserUpdating: () => void;
  validateCart: () => void;
  loadUser: () => void;
}

interface State {
  localUser: UserType | null;
  localError: string;
}

class ContactDetails extends React.Component<Props, State> {
  state: State = { localUser: null, localError: '' };

  userTimer: ReturnType<typeof setTimeout> | null = null;
  userInterval: ReturnType<typeof setInterval> | null = null;
  intervalCount = 0;

  componentDidMount() {
    if (this.props.user.name !== undefined) this.setLocalUser();
    this.userInterval = setInterval(() => this.checkUser(), 4000);
  }

  componentDidUpdate(prevProps: Props) {
    const nameChange = this.props.user.name !== prevProps.user.name;
    if (!this.state.localUser && this.props.user.name !== undefined)
      this.setLocalUser();
    else if (nameChange) this.setLocalUser();
  }

  checkUser = () => {
    const { localUser } = this.state;
    this.intervalCount += 1;
    if (!localUser && !!this.props.user.token) {
      logMessage(`Fetching user from cart, attempt #${this.intervalCount}`);
      this.props.loadUser();
    } else if ((this.intervalCount > 5 || !!localUser) && !!this.userInterval)
      return clearInterval(this.userInterval);
  };

  setLocalUser = () => {
    const localUser = {
      name: this.props.user.name || '',
      email: this.props.user.email || '',
    };
    this.setState({ localUser });
  };

  updateUser = (field: FieldType, value: string) => {
    const localUser = { ...this.state.localUser };
    localUser[field] = value;

    this.setState({ localUser }, () => {
      if (this.userTimer) clearTimeout(this.userTimer);
      this.userTimer = setTimeout(this.saveUser, 1000);
    });
  };

  saveUser = async () => {
    const { user } = this.props;
    const { localUser } = this.state;
    const updatedUser = {
      ...user,
      ...localUser,
    };

    const nameLength = updatedUser.name?.split(' ').length;
    if (!nameLength || nameLength <= 1)
      return this.setState({ localError: 'First and last name are required' });

    const userChanges =
      !!updatedUser.name &&
      (updatedUser.name !== user.name || updatedUser.email !== user.email);

    if (!userChanges) return;

    this.props.setUserUpdating();
    await this.props.updateUser(updatedUser);
    this.props.validateCart();
    this.setState({ localError: '' });
  };

  render() {
    const { userError } = this.props;
    const { localUser, localError } = this.state;

    const userNameError =
      userError === 'First and last name are required.' || !!localError;

    const emailError = userError === 'Enter a valid email address.';

    if (!localUser) return null;
    return (
      <View style={styles.wrapper}>
        <TitleText
          center={true}
          color='secondary'
          fontSize={16}
          mt={60}
          mb={14}
          spacing={0.4}>
          CONTACT
        </TitleText>
        <View style={styles.contactBox}>
          <BodyText bold={true} color='textDark' mb={10}>
            NAME{' '}
            {userNameError ? (
              <BodyText ml={4} color='error'>
                ({localError || userError})
              </BodyText>
            ) : (
              <BodyText color={localUser.name ? 'textDark' : 'primary'}>
                (required)
              </BodyText>
            )}
          </BodyText>
          <TextInput
            value={localUser.name}
            onChangeText={(value) => this.updateUser('name', value)}
            focusStyle={styles.focusedInput}
            hasError={!!userNameError}
            style={styles.inputBox}
            autoComplete='name'
            textContentType='name'
            autoCorrect={false}
          />
          <View style={{ marginBottom: 16 }}>
            <BodyText color='text' ml={2}>
              Your name will be used to identify your order.
            </BodyText>
          </View>

          <BodyText bold={true} color='textDark' mb={10}>
            EMAIL{' '}
            {!!emailError && (
              <BodyText ml={3} color='error'>
                ({userError})
              </BodyText>
            )}
          </BodyText>
          <TextInput
            value={localUser.email}
            onChangeText={(value) => this.updateUser('email', value)}
            style={styles.inputBox}
            hasError={!!emailError}
            focusStyle={styles.focusedInput}
            autoCorrect={false}
            autoComplete='email'
            textContentType='emailAddress'
            keyboardType='email-address'
            autoCapitalize='none'
          />
          <BodyText color='text' ml={2}>
            Enter your email to receive a receipt of your order.
          </BodyText>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.userInfo.user,
  userError: readUserError(state),
});

const mapDispatchToProps = { updateUser, setUserUpdating, loadUser };

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { View, Animated, Pressable, useWindowDimensions } from 'react-native';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-native';

import { readUser, readUserOrders } from '../../globalStore';

import { animate, isIos, statusBarHeight } from '../../helpers';

import { Link, LoginButton, BodyText, NavLink, Block } from '..';

import CartIcon from './CartIcon';

import { Logo, Close } from '../../assets';

import styles from './styles';

const Nav = () => {
  const [navOpacity, setNavOpacity] = useState(1);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { width } = useWindowDimensions();

  const location = useLocation();
  const { storeId } = useParams();

  useEffect(() => {
    setShowMobileMenu(false);
    if (window?.removeEventListener)
      window.removeEventListener('click', toggleMobileMenu);
  }, [location.pathname]);

  const isMobile = width < 1200;

  const user = useSelector(readUser);
  const userOrders = useSelector(readUserOrders);
  const userCoupons = useSelector(
    (state: RootState) => state.userInfo.userCoupons
  );

  const isHome = location.pathname === '/';
  const coverPage = !!isHome;

  const isStoreMenu = location.pathname.startsWith('/store/');

  const isProfile = location.pathname?.startsWith('/profile');

  const smallHeight = 64 + statusBarHeight;
  const maxHeight = 350;

  const navHeight = useRef(
    new Animated.Value(coverPage ? maxHeight : smallHeight)
  ).current;
  const logoScale = useRef(new Animated.Value(coverPage ? 2.4 : 1)).current;

  const toggleMobileMenu = useCallback(() => {
    setShowMobileMenu((prevState) => {
      if (!prevState && !!window?.addEventListener)
        window.addEventListener('click', toggleMobileMenu);
      else if (window?.removeEventListener)
        window.removeEventListener('click', toggleMobileMenu);
      return !prevState;
    });
  }, [setShowMobileMenu]);

  useEffect(() => {
    setTimeout(() => setNavOpacity(1), 1000);
  }, []);

  useEffect(() => {
    if (!coverPage)
      Animated.parallel([
        animate(navHeight, {
          toValue: smallHeight,
          duration: 400,
        }),
        animate(logoScale, {
          toValue: 1,
          duration: 400,
        }),
      ]).start();
    else
      Animated.parallel([
        animate(navHeight, {
          toValue: maxHeight,
          duration: 400,
        }),
        animate(logoScale, {
          toValue: 1.8,
          duration: 400,
        }),
      ]).start();
  }, [coverPage]);

  // const webMobile = isMobile && Platform.OS === 'web';

  if (isHome) return null;

  const hasUserOrders = userOrders.length > 0;

  return (
    <Animated.View
      style={[
        styles.nav,
        isMobile && !coverPage && styles.mobileNav,
        { opacity: navOpacity, height: navHeight },
      ]}>
      {/* {!!coverPage && (
        <View
          style={[
            styles.background,
            {
              borderTopRightRadius: width / 2,
              borderTopLeftRadius: width / 2,
              height: height * 2,
              transform: [{ translateY: -2 }, { scaleX: isMobile ? 2 : 4 }],
            },
          ]}
        />
      )} */}

      {isIos && <Block height={statusBarHeight} />}

      <View style={styles.navSafeWrapper}>
        {!isMobile && !!isProfile && (
          <Link to='/stores' style={styles.orderLink}>
            <BodyText color='white' fontSize={16} fontWeight='700' mr={20}>
              ORDER NOW
            </BodyText>
          </Link>
        )}
        {!!isMobile && !isHome && (
          <Pressable
            style={styles.hamburgerBox}
            onPress={toggleMobileMenu}
            testID='mobile-hamburger-toggle'>
            {!!showMobileMenu ? (
              <Close width={24} height={24} />
            ) : (
              <>
                <View style={[styles.line, { marginBottom: 5.8 }]} />
                <View style={[styles.line, { marginBottom: 5.8 }]} />
                <View style={styles.line} />
              </>
            )}
          </Pressable>
        )}

        <Animated.View
          style={[
            styles.logoBox,
            { bottom: coverPage ? 20 : 0, transform: [{ scale: logoScale }] },
          ]}>
          <Link to='/'>
            <Logo width={200} height={40} />
          </Link>
        </Animated.View>

        {!isHome && (
          <View style={[styles.actionBox, isMobile && styles.mobileActionBox]}>
            {!isMobile && (
              <>
                {!isHome && (
                  <Link to='/'>
                    <BodyText
                      color='white'
                      fontSize={14}
                      fontWeight='700'
                      mr={20}>
                      HOME
                    </BodyText>
                  </Link>
                )}
                {!!user.token && (
                  <NavLink
                    to={hasUserOrders ? '/profile/orders' : '/profile'}
                    label={hasUserOrders ? 'ORDERS' : 'PROFILE'}
                    mr={20}
                    fontSize={14}
                    labelColor='white'
                    activeColor='secondary'
                  />
                )}
                <LoginButton />
              </>
            )}

            {storeId && isStoreMenu && <CartIcon />}
          </View>
        )}

        {showMobileMenu && (
          <View style={styles.mobileMenu} testID='mobile-nav-menu'>
            {!!user.token && (
              <>
                <NavLink
                  to='/'
                  label='HOME'
                  mb={15}
                  fontSize={15}
                  labelColor='white'
                  activeColor='secondary'
                  style={styles.mobileNavLink}
                />
                <NavLink
                  to='/stores'
                  label='ORDER NOW'
                  altRoutes={['/store']}
                  mb={15}
                  fontSize={15}
                  labelColor='white'
                  activeColor='secondary'
                  style={styles.mobileNavLink}
                />
                <NavLink
                  to={hasUserOrders ? '/profile/orders' : '/profile'}
                  label={hasUserOrders ? 'PROFILE / ORDERS' : 'PROFILE'}
                  altRoutes={['/profile/orders', '/profile']}
                  mb={15}
                  fontSize={15}
                  labelColor='white'
                  activeColor='secondary'
                  style={styles.mobileNavLink}
                />
                {!!userCoupons && userCoupons.length > 0 && (
                  <NavLink
                    to='/profile/coupons'
                    label='COUPONS'
                    mb={15}
                    fontSize={15}
                    labelColor='white'
                    activeColor='secondary'
                    style={styles.mobileNavLink}
                  />
                )}
              </>
            )}
            <Block height={15} />
            <LoginButton
              callback={() => setShowMobileMenu(false)}
              isMobile={true}
            />
          </View>
        )}
      </View>
    </Animated.View>
  );
};

export default Nav;

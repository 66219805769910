import React, { useCallback } from 'react';
import { FlatList, View, Pressable, Image } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-native';
import { cloneDeep } from 'lodash';

import { readStoreMenu, readCart, addItemToCart } from '../../../globalStore';
import { getProductPrice, getProductImage } from '../../../helpers';

import { BodyText, TitleText } from '../../../components';

import { CircleAdd } from '../../../assets';

import styles from './styles';

const Upsell = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const activeMenu = useSelector((state: RootState) =>
    readStoreMenu(state, storeId)
  );
  const productLibrary = activeMenu?.productLibrary || {};

  const cart = useSelector((state: RootState) => readCart(state, storeId));

  const upsellIds = cart?.upsellIds || [];

  const addItem = useCallback(
    (product: ProductType) => {
      const newProduct = cloneDeep(product);
      newProduct.quantity = 1;
      const payload = { storeId, product: newProduct, storeMenu: activeMenu };
      dispatch(addItemToCart(payload));
    },
    [storeId, activeMenu]
  );

  const renderItem = ({ item }: { item: string }) => {
    const product = productLibrary[item];
    if (!product) return null;
    const productImage = getProductImage(product);
    const shortName = product.name.length < 10;
    const isDrink = product?.category === 'Drinks';

    return (
      <Pressable style={styles.upsellItem} onPress={() => addItem(product)}>
        <View style={styles.textBox}>
          <BodyText color='text' uppercase={true}>
            {product.category}
          </BodyText>
          <BodyText
            color='primary'
            bold={true}
            uppercase={true}
            fontSize={shortName ? 16 : 14}
            lineHeight={shortName ? 16 : 16}>
            {product.name}
          </BodyText>
        </View>
        <View style={styles.upsellPriceRow}>
          <BodyText color='midDark' bold={true}>
            {getProductPrice(product, { trim: true })}
          </BodyText>
          <Image
            source={productImage}
            style={[styles.productImage, isDrink && styles.drinkImage]}
            resizeMode='contain'
          />
          <CircleAdd width={30} height={30} />
        </View>
      </Pressable>
    );
  };

  if (!upsellIds || upsellIds.length === 0) return null;
  return (
    <View style={styles.wrapper}>
      <View style={styles.titleRow}>
        <TitleText color='secondary' fontSize={18}>
          PEOPLE ALSO ORDERED
        </TitleText>
      </View>
      <FlatList
        data={upsellIds}
        renderItem={renderItem}
        horizontal={true}
        style={styles.upsellList}
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item) => item}
      />
    </View>
  );
};

export default Upsell;

import { StyleSheet } from 'react-native';
import { colors, globalStyles } from '../../../helpers';

const styles = StyleSheet.create({
  scrollContainer: {},
  modalBox: {
    width: 368,
    minHeight: 300,
    maxHeight: '93%',
    borderRadius: 24,
    backgroundColor: colors.body,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 20,
    flexGrow: 0,
  },
  header: {
    ...globalStyles.flexRow,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 16,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    elevation: 2,
  },
  body: {
    flex: 1,
  },
  shade: {
    position: 'absolute',
    top: -5,
    left: 0,
    width: '100%',
    height: '40%',
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.secondary,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.body,
  },
  content: {
    ...globalStyles.flexColumn,
    flex: 1,
    paddingTop: 54,
  },
  boltBox: {
    position: 'absolute',
    top: -92,
    transform: [{ translateX: 18 }],
  },
  orderInfo: {
    ...globalStyles.flexColumn,
    width: '100%',
    paddingBottom: 20,
  },
  nameRow: {
    ...globalStyles.flexRow,
    // marginBottom: 24,
  },
  backwardsBox: {
    transform: [{ rotateY: '180deg' }],
  },
  mapBox: {
    marginTop: 10,
    height: 100,
    width: '100%',
  },
  mapLink: {
    ...globalStyles.flexRow,
    backgroundColor: colors.white,
    height: 35,
    width: '100%',
  },
  divider: {
    borderTopWidth: 1,
    borderColor: colors.midLight,
    width: '90%',
    height: 1,
    marginVertical: 25,
  },

  socialBox: {
    ...globalStyles.flexRow,
    width: '80%',
    marginTop: 20,
    marginBottom: 10,
  },
});

export default styles;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { readActiveProduct } from '../../globalStore';

import { setThemeColor } from '../../helpers';

import MenuItemModal from './MenuItemModal';
import ComboModal from './ComboModal';

import OrderSuccessModal from './OrderSuccessModal';
import NameNeededModal from './NameNeededModal';
import ScheduleUpsellModal from './ScheduleUpsellModal';
import UpdateRequiredModal from './UpdateRequiredModal';
import LoginModal from './LoginModal';
import AccountDeletionModal from './AccountDeletionModal';
import ReferralModal from './ReferralModal';
import ReferralRedeemedModal from './ReferralRedeemedModal';
import NewCouponModal from './NewCouponModal';

const Modals = () => {
  const activeProduct = useSelector(readActiveProduct);
  const activeCombo = useSelector(
    (state: RootState) => state.storeInfo.activeCombo
  );

  const appInfo = useSelector((state: RootState) => state.appInfo);
  const {
    orderSuccessModal,
    showLoginModal,
    showNameNeededModal,
    showScheduleUpsell,
    updateRequiredModal,
    showAccountDeletionModal,
    showReferralModal,
    referralRedeemedModal,
    newCouponModal,
  } = appInfo;

  const displayCart = useSelector(
    (state: RootState) => state.cartInfo.displayCart
  );

  useEffect(() => {
    const showModal =
      !!activeProduct ||
      !!activeCombo ||
      !!orderSuccessModal ||
      !!showLoginModal ||
      !!showScheduleUpsell ||
      !!updateRequiredModal ||
      !!showAccountDeletionModal ||
      !!showReferralModal ||
      !!referralRedeemedModal ||
      !!newCouponModal;
    let themeColor = '';

    if (displayCart && !showModal) themeColor = 'secondary';
    else if (displayCart && showModal) themeColor = 'modalSecondaryOverlay';
    else if (showModal) themeColor = 'modalPrimaryOverlay';

    setThemeColor(themeColor);
  }, [
    displayCart,
    activeProduct,
    activeCombo,
    orderSuccessModal,
    showLoginModal,
    showScheduleUpsell,
    updateRequiredModal,
    showAccountDeletionModal,
    showReferralModal,
    referralRedeemedModal,
    newCouponModal,
  ]);

  if (updateRequiredModal) return <UpdateRequiredModal />;
  if (activeProduct) return <MenuItemModal />;
  if (activeCombo) return <ComboModal />;
  if (orderSuccessModal) return <OrderSuccessModal />;
  if (showLoginModal) return <LoginModal />;
  if (showNameNeededModal) return <NameNeededModal />;
  if (showScheduleUpsell) return <ScheduleUpsellModal />;
  if (showAccountDeletionModal) return <AccountDeletionModal />;
  if (showReferralModal) return <ReferralModal />;
  if (referralRedeemedModal) return <ReferralRedeemedModal />;
  if (newCouponModal) return <NewCouponModal />;
  return null;
};

export default Modals;

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { View, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { colors, formatPrice, getAbsoluteWaitTime } from '../../../helpers';

import { unselectCoupon, selectCoupon } from '../../../globalStore';

import {
  TitleText,
  BodyText,
  TextInput,
  StarLoader,
  Close,
  CouponList,
  GrowView,
  MapPreview,
  MapButton,
} from '../../';

import styles from './styles';

interface Props {
  storeId: string;
  pricing?: PricingType;
  waitTime?: number;
  fieldErrors: FieldErrorType[];
  cartErrors: string[];
  calculatedSubtotal: number;
  storeClosed: boolean;
  couponRequired: boolean;
  localCoupon: string;
  updateCoupon: (coupon: string) => void;
  enterCoupon: () => void;
  appliedCoupon: CouponType;
  lastError: number;
}

const OrderDetails = ({
  storeId,
  pricing,
  fieldErrors,
  cartErrors,
  waitTime,
  calculatedSubtotal,
  storeClosed,
  couponRequired,
  localCoupon,
  updateCoupon,
  enterCoupon,
  appliedCoupon,
  lastError,
}: Props) => {
  const dispatch = useDispatch();
  const stores = useSelector((state: RootState) => state.storeInfo.stores);
  const orderStore = stores.find((s) => s.id === storeId);

  const availableUserCoupons = useSelector((state: RootState) => {
    // const coupons = state.userInfo.userCoupons;
    // const usedCoupon = coupons.find((c) => c.slug === appliedCoupon?.slug);
    // if (usedCoupon) return coupons.filter((c) => c.id !== usedCoupon.id);
    return state.userInfo.userCoupons || [];
  });

  const selectedCoupon = useSelector(
    (state: RootState) => state.userInfo.selectedCoupon
  );

  const [applying, setApplying] = useState<boolean>(false);
  const [localCouponError, setLocalCouponError] = useState<string>('');

  const [mapError, setMapError] = useState(false);

  useEffect(() => {
    const now = Date.now();
    const diff = now - lastError;
    if (diff < 1000) setApplying(false);
  }, [lastError]);

  useEffect(() => {
    let couponError = '';
    const couponFieldError = fieldErrors.find((e) => e.field === 'coupon');
    if (couponFieldError) couponError = couponFieldError.message;
    else couponError = cartErrors.find((e) => e.includes('coupon')) || '';

    if ((appliedCoupon || couponError) && applying) {
      setApplying(false);
      updateCoupon('');
    }

    setLocalCouponError(couponError);
  }, [pricing, applying, setApplying, fieldErrors, cartErrors, lastError]);

  useEffect(() => {
    if (appliedCoupon) {
      setLocalCouponError('');
    }
  }, [appliedCoupon]);

  const onCouponChange = useCallback(
    (value: string) => {
      setLocalCouponError('');

      updateCoupon(value.toUpperCase());
    },
    [updateCoupon]
  );

  const clickUserCoupon = useCallback((coupon: UserCouponType) => {
    setLocalCouponError('');
    updateCoupon('');
    dispatch(selectCoupon(coupon));
  }, []);

  const clickApply = useCallback(() => {
    if (storeClosed) return;
    if (pricing?.coupon || appliedCoupon || selectedCoupon) {
      return dispatch(unselectCoupon(storeId));
    }
    setApplying(true);
    enterCoupon();
  }, [localCoupon, pricing]);

  if (!orderStore) return null;

  const address = orderStore.address.split(',').map((s) => s.trim());

  const subtotal = pricing?.subtotal || calculatedSubtotal;

  const actualWaitTime = waitTime || orderStore.waitTime || 15;

  return (
    <>
      <TitleText color='secondary' fontSize={16} mt={60} mb={14} spacing={0.4}>
        PICKUP ORDER
      </TitleText>
      <View style={styles.infoBox}>
        {actualWaitTime > 50 ? (
          <>
            <BodyText color='text' bold={true} fontSize={14} mb={8}>
              PICKUP TIME
            </BodyText>
            <BodyText fontSize={18}>
              Ready at {getAbsoluteWaitTime(actualWaitTime)}
            </BodyText>
          </>
        ) : (
          <>
            <BodyText color='text' bold={true} fontSize={14} mb={8}>
              TIME: ASAP
            </BodyText>
            <BodyText fontSize={18}>Ready in {actualWaitTime} mins</BodyText>
          </>
        )}
      </View>
      <View style={styles.infoBox}>
        <BodyText color='text' bold={true} fontSize={14} mb={8}>
          LOCATION
        </BodyText>
        <BodyText
          bold={true}
          color='black'
          fontSize={20}
          mb={8}
          testID='cart-order-details-store-name'>
          {orderStore.name}
        </BodyText>
        <BodyText
          fontSize={18}
          mb={6}
          testID='cart-order-details-store-address'>
          {address[0]}
        </BodyText>
        <BodyText fontSize={18}>{address.slice(1).join(', ').trim()}</BodyText>

        {!mapError ? (
          <View style={styles.mapBox}>
            <MapPreview
              store={orderStore}
              setMapError={setMapError}
              disabled={true}
            />
          </View>
        ) : (
          <MapButton store={orderStore} />
        )}
      </View>
      <TitleText color='secondary' fontSize={16} mt={40} mb={16}>
        SUMMARY
      </TitleText>
      <View style={styles.infoBox}>
        {couponRequired && (
          <View style={styles.couponInfo}>
            <BodyText center={true} fontSize={14} color='primary'>
              (required)
            </BodyText>
          </View>
        )}

        {!appliedCoupon?.slug && (
          <View style={styles.couponBox}>
            <TextInput
              value={appliedCoupon?.slug || localCoupon}
              disabled={!!appliedCoupon}
              onChangeText={onCouponChange}
              style={[
                styles.couponInput,
                !!appliedCoupon && styles.activeCoupon,
              ]}
              focusStyle={{ borderColor: colors.secondary }}
              placeholder='COUPON CODE'
              placeholderTextColor={colors.midLight}
              onKeyPress={(e) => e.nativeEvent.key === 'Enter' && clickApply()}
              autoCorrect={false}
            />
            <Pressable
              onPress={clickApply}
              disabled={!localCoupon && !appliedCoupon}
              style={[
                styles.couponButton,
                !!localCoupon && styles.activeButton,
                !!appliedCoupon && styles.removeButton,
              ]}>
              {!!appliedCoupon ? (
                <Close color='white' />
              ) : applying ? (
                <StarLoader small={true} />
              ) : (
                <BodyText color='white' bold={true}>
                  APPLY
                </BodyText>
              )}
            </Pressable>
          </View>
        )}

        {/* <BodyText center={true} color='secondary' mt={4}>
          {appliedCoupon?.description || pricing?.coupon?.description}
        </BodyText> */}
        {!!localCouponError && (
          <BodyText color='primary' style={styles.couponError}>
            {localCouponError}
          </BodyText>
        )}

        {(availableUserCoupons.length > 0 || !!appliedCoupon) && (
          <CouponList
            coupons={availableUserCoupons}
            appliedCoupon={appliedCoupon}
            clickCoupon={clickUserCoupon}
            couponError={localCouponError}
            storeId={storeId}
          />
        )}
      </View>
      <View style={styles.infoBox}>
        <View style={styles.infoRow}>
          <BodyText color='text' bold={true} fontSize={16} mb={10}>
            SUBTOTAL
          </BodyText>
          <BodyText color='midDark' bold={true} spacing={0.4} fontSize={16}>
            {subtotal ? formatPrice(subtotal, { inDollars: true }) : '--'}
          </BodyText>
        </View>
        {!!pricing?.discount && (
          <GrowView style={styles.infoRow} grow={true} duration={400}>
            <BodyText color='text' bold={true} mb={10} fontSize={16}>
              DISCOUNT
            </BodyText>
            <BodyText color='primary' bold={true} spacing={0.4} fontSize={16}>
              -{formatPrice(pricing.discount, { inDollars: true })}
            </BodyText>
          </GrowView>
        )}

        <View style={styles.infoRow}>
          <BodyText color='text' bold={true} fontSize={16}>
            SALES TAX
          </BodyText>
          <BodyText color='midDark' bold={true} spacing={0.4} fontSize={16}>
            {pricing?.tax
              ? formatPrice(pricing?.tax, { inDollars: true })
              : '--'}
          </BodyText>
        </View>

        {!!orderStore.deferPayment && (
          <View style={styles.infoRow}>
            <BodyText color='text' bold={true} fontSize={16} mt={10}>
              TOTAL
            </BodyText>
            <BodyText color='midDark' bold={true} spacing={0.4} fontSize={16}>
              {pricing?.total
                ? formatPrice(pricing?.total, { inDollars: true })
                : '$0'}
            </BodyText>
          </View>
        )}
      </View>

      <View style={styles.infoBox}>
        <View style={styles.infoRow}>
          <BodyText
            color={orderStore.deferPayment ? 'textDark' : 'black'}
            bold={true}
            fontSize={18}>
            {orderStore.deferPayment ? 'DUE NOW' : 'TOTAL'}
          </BodyText>
          <BodyText
            color={orderStore.deferPayment ? 'textDark' : 'black'}
            bold={true}
            fontSize={18}
            spacing={0.4}>
            {pricing?.total && !orderStore.deferPayment
              ? formatPrice(pricing?.total, { inDollars: true })
              : '$0'}
          </BodyText>
        </View>
      </View>
    </>
  );
};

export default OrderDetails;

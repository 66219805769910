import React from 'react';
import { View, FlatList, Pressable, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

import { BodyText } from '..';

import { globalStyles, colors } from '../../helpers';

interface Props {
  categories: CategoryType[];
  categoryScroll: number;
  clickCategory: (index: number) => void;
  isMobile: boolean;
}

class MenuCategoryHeader extends React.Component<Props> {
  render() {
    const { categories, categoryScroll, clickCategory, isMobile } = this.props;

    const linkMargin = isMobile ? 5 : 15;
    return (
      <>
        <View style={[styles.wrapper]}>
          {categories.map((c, idx) => (
            <Pressable
              style={[
                styles.categoryLink,
                {
                  marginLeft: idx === 0 ? 0 : linkMargin,
                  backgroundColor:
                    idx === categoryScroll ? colors.primary : colors.black,
                },
              ]}
              onPress={() => {
                clickCategory(idx);
              }}
              key={c.name}>
              <BodyText
                color={idx === categoryScroll ? 'white' : 'white'}
                uppercase={true}>
                {c.name}
              </BodyText>
            </Pressable>
          ))}
        </View>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isMobile: state.appInfo.isMobile,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenuCategoryHeader);

const styles = StyleSheet.create({
  wrapper: {
    ...globalStyles.flexRow,
    position: 'relative',
    alignSelf: 'center',
    marginVertical: 7,
    paddingHorizontal: '3%',
    width: '100%',
  },
  categoryLink: {
    ...globalStyles.flexRow,
    height: 38,
    width: '100%',
    flex: 1,
    minWidth: 80,
    maxWidth: 200,
    backgroundColor: colors.primary,
    borderRadius: 7,
  },
});

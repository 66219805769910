import { StyleSheet } from 'react-native';

import { colors, globalStyles, isIos } from '../../../helpers';

const styles = StyleSheet.create({
  cartItem: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderColor: colors.grey,
    width: '100%',
    minHeight: 40,
    padding: 12,
  },
  firstItem: {
    borderTopWidth: 1,
  },
  errorItem: {
    backgroundColor: colors.black,
  },
  itemQuantity: {
    ...globalStyles.flexRow,
    width: 36,
    height: 36,
    borderRadius: 25,
    backgroundColor: colors.body,
    marginRight: 5,
  },
  imageBox: {
    ...globalStyles.flexRow,
    marginRight: 5,
    width: 50,
  },
  productImage: {
    width: 50,
    height: 40,
  },
  otherImage: {
    width: 35,
  },
  productBox: {
    width: '70%',
  },
  deleteItemButton: {
    position: 'absolute',
    right: -10,
    height: isIos ? '130%' : '100%',
    width: 70,
  },
  choiceList: {
    ...globalStyles.flexRow,
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    minHeight: 4,
  },
  stockErrorBadge: {
    ...globalStyles.flexRow,
    alignSelf: 'flex-start',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 7,
    marginTop: 6,
    backgroundColor: colors.primary,
  },
});

export default styles;

import React, { useCallback, useState } from 'react';
import { Pressable, View } from 'react-native';

import {
  linkToMap,
  getPacificTimeString,
  isPastTime,
  getAbsoluteWaitTime,
} from '../../helpers';

import { BodyText, MapPreview } from '../../components';

import StoreHours from './StoreHours';

import { ExternalLink, BrbBolt, GreyLocationPin } from '../../assets';

import styles from './styles';

interface Props {
  store: StoreType;
  setStore: (storeId: string) => void;
  expandHours?: boolean;
}

const OpenStoreBox = ({ store, setStore, expandHours }: Props) => {
  const [mapError, setMapError] = useState(false);

  const hoursString = getHoursString(store.openAt, store.closeAt);
  const preorderString = getPreorderString(store.preorderAt, store.openAt);

  return (
    <View style={styles.storeContainer} testID='open-store-container'>
      <Pressable onPress={() => setStore(store.id)} testID='open-store'>
        <View style={[styles.storeBox]}>
          <View style={styles.storeDetailsBox}>
            <View style={styles.innerBox}>
              <BodyText style={[styles.label]} mb={4} testID='open-store-name'>
                {store.name}
              </BodyText>

              {!!hoursString && (
                <BodyText color='green' ml={0} mb={6} fontSize={15} bold={true}>
                  {hoursString}
                </BodyText>
              )}

              {!!store.open && store.waitTime > 0 ? (
                <BodyText
                  color='green'
                  ml={0}
                  mt={0}
                  mb={4}
                  fontSize={15}
                  bold={true}>
                  {store.waitTime > 50
                    ? `Pickup time - ${getAbsoluteWaitTime(store.waitTime)}`
                    : `Current wait time - ${store.waitTime} mins`}
                </BodyText>
              ) : preorderString ? (
                <BodyText color='green' mb={4} fontSize={16} bold={true}>
                  {preorderString}
                </BodyText>
              ) : null}

              {!store.open && (
                <BodyText fontSize={14} bold={true}>
                  (temporarily paused)
                </BodyText>
              )}

              {/* <View style={[styles.pill, styles.open]}>
                  <BodyText color='white' fontSize={12}>
                    Open
                  </BodyText>
                </View> */}
            </View>

            {!!store.open ? (
              <Pressable
                style={styles.orderButton}
                onPress={() => setStore(store.id)}>
                <BodyText
                  bold={true}
                  color='white'
                  fontSize={14}
                  lineHeight={20}
                  spacing={0.4}>
                  ORDER
                </BodyText>
              </Pressable>
            ) : (
              <View style={{ maxHeight: 75 }}>
                <BrbBolt width={100} height={100} />
              </View>
            )}
          </View>
          <StoreHours store={store} expandHours={expandHours} />
        </View>
      </Pressable>

      <View>
        {!mapError ? (
          <>
            <View style={[styles.mapView]}>
              <MapPreview store={store} setMapError={setMapError} />
            </View>
            <Pressable onPress={() => linkToMap(store)} style={styles.mapLink}>
              <BodyText
                fontSize={16}
                color='secondary'
                underline={true}
                mr={5}
                bold={false}>
                {store.address.split(',')[0]}
              </BodyText>
              <View style={{ marginTop: 2 }}>
                <ExternalLink width={12} height={40} />
              </View>
            </Pressable>
          </>
        ) : (
          <Pressable onPress={() => linkToMap(store)} style={styles.mapButton}>
            <View style={styles.mapLink}>
              <GreyLocationPin width={16} height={16} />
              <BodyText ml={6} color='textDark'>
                {store.address.split(',')[0]}
              </BodyText>
            </View>
          </Pressable>
        )}
      </View>
    </View>
  );
};

export default OpenStoreBox;

const getTrimTime = (time: string) =>
  getPacificTimeString(time, { trim: true });

const getHoursString = (openAt: string, closeAt: string) => {
  if (openAt && closeAt)
    return `Open ${getTrimTime(openAt)} - ${getTrimTime(closeAt)}`
      .replaceAll('12am', 'Midnight')
      .replaceAll('12pm', 'Noon');

  if (openAt)
    return `${isPastTime(openAt) ? 'Opened' : 'Opening'} at ${getTrimTime(
      openAt
    )}`;

  if (closeAt)
    return `${
      isPastTime(closeAt) ? 'Scheduled close' : 'Closing'
    } at ${getTrimTime(closeAt)}`;

  return '';
};

const getPreorderString = (preorderAt: string, openAt: string) => {
  if (!preorderAt || isPastTime(openAt)) return '';
  return `Pre-orders ${
    isPastTime(preorderAt) ? 'began' : 'begin'
  } at ${getTrimTime(preorderAt)}`;
};

import React from 'react';
import { View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

import { colors, globalStyles } from '../helpers';

interface Props {
  width?: number;
  height?: number;
  color?: string;
  down?: boolean;
  up?: boolean;
}

const Arrow = ({ width, height, color, down, up }: Props) => {
  let fillColor = colors.midDark;
  if (color && colors[color]) fillColor = colors[color];

  let rotation = '0deg';
  if (down) rotation = '90deg';
  else if (up) rotation = '-90deg';

  return (
    <View style={{ transform: [{ rotate: rotation }] }}>
      <Svg
        width={width || 16}
        height={height || width || 16}
        viewBox='0 0 24 24'
        fill='none'>
        <Path
          d='M9.99984 6L8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6Z'
          fill={fillColor}
        />
      </Svg>
    </View>
  );
};

export default Arrow;

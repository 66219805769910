import React, { useState } from 'react';
import { View, ScrollView, Pressable, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-native';
import { groupBy, orderBy } from 'lodash';
import Svg, { Defs, Rect, LinearGradient, Stop } from 'react-native-svg';

import { colors, isPacificTimeZone } from '../../../helpers';

import { TitleText, BodyText, CustomView } from '../../../components';

import { readEventHours } from '../StoreHours';

import EventPill from './EventPill';

import styles from './styles';

const extractDate = (event: StoreEventType) => {
  if (!event.openAt && !event.preorderAt) return '';
  const { day, date } = readEventHours(event);
  return `${day},${date}`;
};

interface ScheduleProps {
  fullPage?: boolean;
}
const UpcomingSchedule = ({ fullPage }: ScheduleProps) => {
  const navigate = useNavigate();
  const { events } = useSelector((state: RootState) => state.storeInfo);

  const now = new Date();

  const futureEvents = events.filter((e) => {
    const eventCloseDate = new Date(e.closeAt);
    return eventCloseDate > now;
  });

  const [eventRowWidth, setRowWidth] = useState(0);

  const groupedEvents = groupBy(futureEvents, extractDate);

  const orderedGroupedEvents = orderBy(
    Object.entries(groupedEvents),
    (entry: any) => {
      const dateString = entry[0].split(',')[1];
      const [month, date] = dateString.split('/');
      return parseInt(month) * 100 + parseInt(date);
    }
  );

  if (!futureEvents?.length) return null;

  const ContainerView = fullPage ? ScrollView : View;

  return (
    <CustomView
      style={[styles.eventWrapper, fullPage && styles.fullPageWrapper]}
      fadeIn={true}>
      <ContainerView
        style={[styles.eventContainer, fullPage && styles.fullPageContainer]}>
        <TitleText color='secondary' fontSize={22} center={true}>
          UPCOMING SCHEDULE
        </TitleText>
        <BodyText center={true} color='secondary' mb={25}>
          (tap event for location)
        </BodyText>

        {!isPacificTimeZone() && (
          <BodyText color='black' center={true} mb={20}>
            Events are displayed in Pacific time
          </BodyText>
        )}

        <View style={styles.eventHeader}>
          <View style={styles.line}>
            <Svg
              width='100%'
              height='100%'
              style={StyleSheet.absoluteFillObject}>
              <Defs>
                <LinearGradient id='grad' x1='0%' y1='0%' x2='100%' y2='0%'>
                  <Stop offset='0' stopColor={colors.yellow} stopOpacity='.6' />
                  <Stop
                    offset='1'
                    stopColor={colors.secondary}
                    stopOpacity='1'
                  />
                </LinearGradient>
              </Defs>
              <Rect width='100%' height='100%' fill='url(#grad)' />
            </Svg>
          </View>
          <View style={styles.headerContent}>
            <View style={{ paddingHorizontal: 5 }}>
              <BodyText bold={true} color='secondary'>
                Morning
              </BodyText>
            </View>
            <View style={{ paddingHorizontal: 5 }}>
              <BodyText bold={true} color='secondary'>
                Evening
              </BodyText>
            </View>
            <View style={{ paddingHorizontal: 5 }}>
              <BodyText bold={true} color='secondary'>
                Late Night
              </BodyText>
            </View>
          </View>
        </View>

        {orderedGroupedEvents.map(([date, dayEvents], idx) => (
          <View
            style={[
              styles.dayRow,
              {
                borderBottomWidth:
                  idx === Object.keys(groupedEvents).length - 1 ? 0 : 1,
              },
            ]}
            key={date}>
            <View style={styles.dateBox}>
              <BodyText style={styles.date} mr={6}>
                {date.split(',')[0]}
              </BodyText>
              <BodyText style={styles.date}>{date.split(',')[1]}</BodyText>
            </View>
            <View style={styles.eventsContainer}>
              {orderBy(dayEvents, 'openAt').map((e: StoreEventType) => {
                return (
                  <EventPill
                    event={e}
                    rowWidth={eventRowWidth}
                    setRowWidth={idx === 0 ? setRowWidth : undefined}
                    backgroundColor={
                      idx % 2 !== 0 ? colors.secondary : colors.primary
                    }
                    key={e.id}
                  />
                );
              })}
            </View>
          </View>
        ))}
        <View />

        {fullPage && (
          <Pressable
            onPress={() => navigate('/stores')}
            style={styles.storeButton}>
            <BodyText color='white' fontSize={18} bold={false}>
              Select Store
            </BodyText>
          </Pressable>
        )}
      </ContainerView>
    </CustomView>
  );
};

export default UpcomingSchedule;

import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {
  ScrollView,
  View,
  Image,
  FlatList,
  Animated,
  Easing,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-native';
import { sample, shuffle, cloneDeep } from 'lodash';

import {
  readStoreMenu,
  addItemToCart,
  removeCartItem,
  readCart,
} from '../../../../globalStore';

import { getProductImage, animate, formatPrice } from '../../../../helpers';

import {
  BodyText,
  TitleText,
  Block,
  Button,
  CustomView,
  GrowView,
} from '../../..';

import UpsellItem from './UpsellItem';
import PopcornAdd from './PopcornAdd';

import styles from './styles';

const drinkTitles = ['Wash it down', `Who's thirsty`, 'Looking Parched'];
const dipTitles = ['Dip it up', 'Flavor boost'];

interface Props {
  originalProduct: ProductType | null;
  closeModal: () => void;
  cartItems?: ProductType[];
}

const UpsellModal = ({ originalProduct, closeModal, cartItems }: Props) => {
  const dispatch = useDispatch();
  const { storeId } = useParams();

  const appearAnim = useRef(new Animated.Value(0)).current;
  const addAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    animate(appearAnim, {
      toValue: 1,
      duration: 600,
      easing: Easing.quad,
    }).start();
  }, []);

  const activeMenu = useSelector((state: RootState) =>
    readStoreMenu(state, storeId)
  );

  const cart = useSelector((state: RootState) => readCart(state, storeId));

  const productLibrary = activeMenu?.productLibrary || {};
  const isPizza = originalProduct?.category === 'Pizzas';

  const [drinkTitle, dipTitle] = useMemo(
    () => [sample(drinkTitles), sample(dipTitles)],
    []
  );

  const [addedProducts, setAddedProducts] = useState<ProductType[]>([]);

  // const [showDrinks, showDips] = useMemo(
  //   () => [
  //     !cartItems?.some((p) => p.category?.toLowerCase() === 'drinks'),
  //     !cartItems?.some((p) => p.category?.toLowerCase() === 'dips'),
  //   ],
  //   []
  // );

  const [drinks, dips] = useMemo(() => {
    const drinkCategory = activeMenu.categories.find(
      (c) => c.slug === 'drinks'
    );
    const dipCategory = activeMenu.categories.find(
      (c) => c.slug === 'dipping-sauces'
    );

    const drinks = shuffle(drinkCategory?.products).slice(0, 20);
    const dips = shuffle(dipCategory?.products).slice(0, 20);
    return [drinks, dips];
  }, []);

  const showFlair = useCallback(() => {
    animate(addAnim, {
      toValue: 1,
      duration: 1000,
      easing: Easing.ease,
    }).start(() => addAnim.setValue(0));
  }, []);

  const addItem = useCallback(
    async (product: ProductType) => {
      showFlair();

      const newProduct = cloneDeep(product);
      newProduct.quantity = 1;
      newProduct;
      const payload = {
        storeId,
        product: newProduct,
        storeMenu: activeMenu,
      };
      const result = await dispatch(addItemToCart(payload));
      const addedProduct = cloneDeep(product);
      addedProduct.cartItemId = result.payload.product.cartItemId;

      setAddedProducts((prevAddedProducts) => [
        ...prevAddedProducts,
        addedProduct,
      ]);
    },
    [storeId, activeMenu]
  );

  const removeItem = useCallback(
    (product: ProductType) => {
      const addedProduct = addedProducts.find((p) => p.id === product.id);
      if (addedProduct) {
        const cartItem = cart.products.find((p) => p.id === product.id);
        if (cartItem?.cartItemId) {
          const payload = {
            storeId,
            cartItemId: cartItem.cartItemId,
            singleQuantity: true,
          };
          dispatch(removeCartItem(payload));
        }
      }

      setAddedProducts((prevAddedProducts) =>
        prevAddedProducts.filter((p) => p.id !== product.id)
      );
    },
    [storeId, addedProducts]
  );

  const grow = appearAnim.interpolate({
    inputRange: [0, 0.6, 1],
    outputRange: [0.94, 1.04, 1],
  });

  let addedPrice = originalProduct?.price || 0;

  addedProducts.forEach((p) => (addedPrice += p.price));

  return (
    <>
      <PopcornAdd addedProducts={addedProducts} />

      <View style={[styles.upsellModal]}>
        <CustomView
          fadeIn={true}
          style={{
            maxHeight: '100%',
          }}>
          {!!originalProduct && (
            <View style={styles.cartRow}>
              <View style={styles.upsellProductRow}>
                <View>
                  <TitleText color='white' fontSize={24}>
                    Added to cart!
                  </TitleText>

                  {!!addedPrice && (
                    <TitleText fontSize={18} color='white'>
                      {formatPrice(addedPrice)}
                    </TitleText>
                  )}
                </View>
                <Animated.Image
                  source={getProductImage(originalProduct)}
                  resizeMode='contain'
                  style={[
                    styles.productImage,
                    isPizza && styles.pizzaImage,
                    {
                      transform: [{ scale: grow }],
                    },
                  ]}
                />
              </View>
              <GrowView
                maxHeight={180}
                duration={1400}
                grow={!!addedProducts.length}
                shrinkBack={true}
                shrinkDuration={0}
                style={styles.addedRow}>
                <ScrollView
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  contentContainerStyle={styles.productScrollbox}>
                  <View style={styles.addedProductBox}>
                    {addedProducts.map((p) => (
                      <Image
                        source={getProductImage(p)}
                        resizeMode='contain'
                        style={styles.addedProduct}
                        key={p.id}
                      />
                    ))}
                  </View>
                </ScrollView>
              </GrowView>
              <View style={styles.hat} />
            </View>
          )}

          <ScrollView
            style={styles.upsellSection}
            alwaysBounceVertical={true}
            contentContainerStyle={styles.upsellContainer}>
            {/* <Button
              mt={40}
              mb={40}
              width='80%'
              center={true}
              onClick={() => {
                setAddedProducts([]);
                dispatch(clearCart(storeId));
              }}
              label='clear'
            /> */}

            {!!drinks.length && (
              <>
                <BodyText style={styles.rowLabel}>{drinkTitle}</BodyText>

                <FlatList
                  style={styles.upsellRow}
                  data={drinks}
                  renderItem={(props) => (
                    <UpsellItem
                      {...props}
                      addItem={addItem}
                      removeItem={removeItem}
                      productLibrary={productLibrary}
                      added={!!addedProducts.find((p) => p.id === props.item)}
                      inCart={cart.products.find((p) => p.id === props.item)}
                    />
                  )}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  keyExtractor={(item) => item}
                />
              </>
            )}

            {!!drinks.length && !!dips.length && <Block height={40} />}

            {dips.length > 0 && (
              <>
                <BodyText style={styles.rowLabel}>{dipTitle}</BodyText>

                <FlatList
                  data={dips}
                  renderItem={(props) => (
                    <UpsellItem
                      {...props}
                      addItem={addItem}
                      removeItem={removeItem}
                      productLibrary={productLibrary}
                      added={!!addedProducts.find((p) => p.id === props.item)}
                      inCart={cart.products.find((p) => p.id === props.item)}
                    />
                  )}
                  horizontal={true}
                  style={styles.upsellRow}
                  showsHorizontalScrollIndicator={false}
                  keyExtractor={(item) => item}
                />
              </>
            )}

            <Button
              label={!addedProducts.length ? 'Skip' : 'All Set'}
              onClick={closeModal}
              style={styles.cta}
              testID='upsell-modal-cta'
            />
          </ScrollView>
        </CustomView>
      </View>
    </>
  );
};

export default UpsellModal;

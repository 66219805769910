import { getEnvVariable } from '.';

const requestCache: { [address: string]: { lat: number; lng: number } } = {};
const getCoordinates = async (address: string) => {
  try {
    if (requestCache[address]) return requestCache[address];
    const key = getEnvVariable('REACT_APP_GOOGLE_GEOCODE_KEY');
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`;
    const result = await fetch(url);
    const { results } = await result.json();
    if (!results || !results[0]) return null;

    const {
      geometry: { location },
    } = results[0];

    const latitude = location.lat;
    const longitude = location.lng;

    if (!latitude || !longitude) return null;

    const coordinates = { lat: latitude, lng: longitude };

    requestCache[address] = coordinates;
    return coordinates;
  } catch (e) {
    return null;
  }
};

export default getCoordinates;

import React, { useEffect, useRef } from 'react';
import { View, ViewStyle, Animated, StyleSheet } from 'react-native';

import { globalStyles, animate } from '../helpers';

import { Star } from '../components';

interface Props {
  small?: boolean;
  color?: string;
}

const StarLoader = ({ small, color }: Props) => {
  const leftAnim = useRef(new Animated.Value(0)).current;
  const midAnim = useRef(new Animated.Value(0)).current;
  const rightAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.stagger(300, [
      Animated.loop(
        Animated.sequence([
          animate(leftAnim, {
            toValue: 1,
            duration: 700,
          }),
          animate(leftAnim, {
            toValue: 0,
            duration: 700,
          }),
        ])
      ),
      Animated.loop(
        Animated.sequence([
          animate(midAnim, {
            toValue: 1,
            duration: 700,
          }),
          animate(midAnim, {
            toValue: 0,
            duration: 700,
          }),
        ])
      ),
      Animated.loop(
        Animated.sequence([
          animate(rightAnim, {
            toValue: 1,
            duration: 700,
          }),
          animate(rightAnim, {
            toValue: 0,
            duration: 700,
          }),
        ])
      ),
    ]).start();
  }, []);

  const extraStyle: ViewStyle = {};

  if (small) extraStyle.transform = [{ scale: 0.5 }];
  return (
    <View style={[styles.wrapper, extraStyle]}>
      <Animated.View style={{ opacity: leftAnim }}>
        <Star color={color} width={16} height={16} />
      </Animated.View>
      <Animated.View style={{ opacity: midAnim }}>
        <Star color={color} width={16} height={16} />
      </Animated.View>
      <Animated.View style={{ opacity: rightAnim }}>
        <Star color={color} width={16} height={16} />
      </Animated.View>
    </View>
  );
};

export default StarLoader;

const styles = StyleSheet.create({
  wrapper: {
    ...globalStyles.flexRow,
    justifyContent: 'space-around',
    width: 100,
    height: 40,
  },
});

import { Platform } from 'react-native';

import { isReview } from './helpers/getEnvVariable';
import { readStorage } from './helpers/storage';

// ~~~@@ TESTING FLAGS @@~~~
let forceMock = false;
export let JEST_TEST = false;

// Force mock testing if jest test
if (typeof process !== 'undefined' && process?.env?.JEST_WORKER_ID) {
  forceMock = true;
  JEST_TEST = true;
}

try {
  if (Platform.OS === 'web') {
    const { origin } = window;
    // Check local storage for manaul mock mode
    if (
      !origin.includes('order.eatstellarpizza') &&
      localStorage.getItem('TESTING-FORCE-MOCK') === 'YES'
    )
      forceMock = true;

    // Force mock mode on review branch since it cannot authenticate at present
    // https://serve.atlassian.net/browse/APP-212

    if (isReview) forceMock = true;
  }

  // Force mock testing if cypress test
  if (window.Cypress) forceMock = true;

  // forceMock = true;
} catch (e) {}

export const forceMockMode = (on = true) => {
  MOCK_TESTING = on;
};

export let MOCK_TESTING = forceMock;
export const STOCK_TESTING = false;
export const STORE_TESTING = false;

const comboLibrary = [
  {
    id: 0,
    pizza: 'pepperoni-pizza',
    dipping: 'mikes-hot-honey',
    drink: 'coke',
  },
  {
    id: 1,
    pizza: 'cheese-pizza',
    dipping: 'ranch',
    // dipping: 'bleu-cheese',
    drink: 'liquid-death-mountain-water',
  },
  {
    id: 2,
    pizza: 'sausage-and-pepperoni-pizza',
    dipping: 'jalapeno-ranch',
    drink: 'coke-zero',
    // drink: 'sprite',
  },
  {
    id: 3,
    pizza: 'supreme-pizza',
    dipping: 'ranch',
    // drink: 'coke-zero',
    drink: 'fanta',
    // description: `Go big or go home,  we've got toppings for your toppings, smothered in Ranch.  Balanced with a delightful Sparkling Orange Pellegrino`,
  },
];

const getCombos = (productLibary: LibraryType) => {
  const products = Object.values(productLibary);

  const combos: ComboType[] = [];

  comboLibrary.forEach((c) => {
    const pizza = products.find((p) => p.slug === c.pizza);
    const dipping = products.find((p) => p.slug === c.dipping);
    const drink = products.find((p) => p.slug === c.drink);
    if (!pizza?.inStock || !dipping?.inStock || !drink?.inStock) return;
    combos.push({ pizza, dipping, drink, id: c.id });
  });

  return combos;
};

export default getCombos;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import auth0 from 'auth0-js';

import getEnvVariable from '../helpers/getEnvVariable';

const domain = getEnvVariable('REACT_APP_AUTH0_DOMAIN');
const clientID = getEnvVariable('REACT_APP_AUTH0_WEB_CLIENT_ID');
const audience = getEnvVariable('REACT_APP_AUTH0_AUDIENCE');

const scope = 'profile openid offline_access';
const webAuth = new auth0.WebAuth({
  domain,
  clientID,
  redirectUri: window.location.origin + '/login',
  audience,
  scope,
  responseType: 'token',
  responseMode: '',
});

export const WebAuthContext = createContext<{
  emailLogin: () => void;
  logoutUser: () => void;
  sendPhoneCode: (number: string) => any;
  submitVerificationCode: (
    number: string,
    code: string,
    route?: string
  ) => Promise<string>;
  parseCode: () => void;
  getToken: () => Promise<string>;
}>({
  emailLogin: () => undefined,
  logoutUser: () => undefined,
  sendPhoneCode: (number: string) => undefined,
  submitVerificationCode: async (
    number: string,
    code: string,
    route?: string
  ) => '',
  parseCode: () => undefined,
  getToken: async () => '',
});

interface Props {
  children: React.ReactNode;
}

const WebAuthProvider = ({ children }: Props) => {
  const { loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // getToken();
  }, []);

  const logoutUser = useCallback(() => {
    logout({ returnTo: origin + '/logout' });
  }, []);

  const getToken = useCallback(async () => {
    let token = '';

    try {
      token = await getAccessTokenSilently({ timeoutInSeconds: 8 });
    } catch (e) {
      // console.error(e);
    }
    return token;
  }, []);
  const emailLogin = useCallback(() => {
    loginWithRedirect();
  }, []);

  const sendPhoneCode = useCallback((phoneNumber: string) => {
    const formattedNumber = '+1' + phoneNumber;

    try {
      webAuth.passwordlessStart(
        { connection: 'sms', send: 'code', phoneNumber: formattedNumber },
        () => undefined
      );
      return {};
    } catch (e) {
      return { error: 'Sending phone code failed' };
    }
  }, []);

  const submitVerificationCode = useCallback(
    async (
      phoneNumber: string,
      verificationCode: string,
      desiredRoute?: string
    ): Promise<string> => {
      const formattedNumber = '+1' + phoneNumber;

      const { pathname } = window.location;
      const loginRedirect = desiredRoute
        ? desiredRoute
        : pathname !== '/'
        ? pathname
        : '/profile';
      localStorage.setItem('loginRedirect', loginRedirect);

      let errorCode = '';
      let submitResolve: (value?: any) => void = (value: any) => undefined;
      const submitPromise = new Promise((res) => {
        submitResolve = res;
      });

      webAuth.passwordlessLogin(
        {
          connection: 'sms',
          phoneNumber: formattedNumber,
          verificationCode,
        },
        (result) => {
          errorCode = result?.code || '';
          submitResolve();
        }
      );

      await submitPromise;
      if (errorCode) return errorCode;
      return 'success';
    },
    []
  );

  const parseCode = useCallback(() => {
    // const params = new URL(window.location.href).searchParams;
    // const code = params.get('code') || '';
    // const state = params.get('state') || '';
    // webAuth.parseHash((err, authResult) => {
    //   // if (err) console.error('ERROR PARSING AUTH CODE -', err);
    //   console.info('TESTING AUTH CODE -', err, authResult);
    //   // if (authResult?.accessToken)
    //   //   webAuth.client.userInfo(authResult?.accessToken, (err, user) => {
    //   //     if (err) console.error('ERROR GETTING USER INFO -', err);
    //   //   });
    // });
  }, []);

  return (
    <WebAuthContext.Provider
      value={{
        emailLogin,
        logoutUser,
        sendPhoneCode,
        submitVerificationCode,
        parseCode,
        getToken,
      }}>
      {children}
    </WebAuthContext.Provider>
  );
};

export const useWebAuth = () => {
  const context = useContext(WebAuthContext);
  if (!context) throw new Error('useAuth must be used within WebAuthProvider.');
  return context;
};

const WebAuthWrapper = ({ children }: Props) => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientID}
      redirectUri={window.location.origin + '/login'}
      onRedirectCallback={(test) => console.info('WHAAT CALLBACK --', test)}
      audience={audience}
      scope={scope}
      useRefreshTokens={true}
      useCookiesForTransactions={true}>
      <WebAuthProvider>{children}</WebAuthProvider>
    </Auth0Provider>
  );
};
export default WebAuthWrapper;

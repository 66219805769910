import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useSelector } from 'react-redux';

import { colors, globalStyles, goToUrl } from '../../helpers';

import { TitleText, BodyText, LongArrow } from '..';

import BaseModal from './BaseModal';

import { SaucerPie, Logo } from '../../assets';

const UpdateRequiredModal = () => {
  const updateRequiredModal = useSelector(
    (state: RootState) => state.appInfo.updateRequiredModal
  );

  return (
    <BaseModal onBackdropPress={() => undefined}>
      <View style={styles.modalBox}>
        <View style={styles.header}>
          <Logo width={200} height={80} />
        </View>

        <View style={styles.body}>
          <View style={styles.background} />
          <View style={styles.content}>
            <BodyText mt={10} center={true} color='textDark' fontSize={18}>
              We made some changes you dont want to miss out on!
            </BodyText>

            {!!updateRequiredModal?.min && !!updateRequiredModal?.current && (
              <View style={styles.versionBox}>
                <BodyText fontSize={20} lineHeight={24} color='secondary'>
                  {updateRequiredModal.current}
                </BodyText>
                <View style={styles.arrowBox}>
                  <LongArrow width={14} color='barnRed' />
                </View>
                <BodyText fontSize={20} lineHeight={24} color='secondary'>
                  {updateRequiredModal.min}
                </BodyText>
              </View>
            )}

            <Pressable
              style={styles.updateButton}
              onPress={() =>
                goToUrl(
                  'https://apps.apple.com/us/app/stellar-pizza/id1583634431'
                )
              }>
              <TitleText fontSize={24} color='white'>
                UPDATE APP
              </TitleText>
            </Pressable>

            <View style={styles.saucer}>
              <SaucerPie width={200} height={100} />
            </View>
          </View>
        </View>
      </View>
    </BaseModal>
  );
};

export default UpdateRequiredModal;

const styles = StyleSheet.create({
  modalBox: {
    width: '100%',
    height: '100%',
    // borderRadius: 24,
    backgroundColor: colors.primary,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingTop: 100,
    paddingHorizontal: 20,
  },
  header: {
    ...globalStyles.flexRow,
    height: '20%',
  },
  body: {
    flex: 1,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.body,
  },
  content: {
    ...globalStyles.flexColumn,
    flex: 1,
    top: '10%',
    paddingHorizontal: '5%',
  },
  versionBox: {
    ...globalStyles.flexRow,
    marginTop: '20%',
  },
  arrowBox: {
    transform: [{ rotate: '180deg' }],
    marginHorizontal: 10,
  },
  updateButton: {
    marginTop: 10,
    borderRadius: 10,
    backgroundColor: colors.primary,
    paddingVertical: 24,
    paddingHorizontal: 64,
  },
  saucer: {
    position: 'absolute',
    bottom: 100,
  },
});

import { StyleSheet, Platform } from 'react-native';
import { colors, shadow } from '@app/core/helpers';

const ios = Platform.OS === 'ios';

const mobileStyles = StyleSheet.create({
  mobileBox: {
    bottom: 0,
    width: '100%',
    maxWidth: '100%',
    // maxHeight: '84%',
    // minHeight: '40%',
    maxHeight: ios ? '82%' : '90%',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    // display: 'flex',
  },
  mobileProductName: {
    fontSize: 25,
    lineHeight: 25,
  },
  mobileClose: {
    right: 10,
  },
  mobileSection: {
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 20,
  },
  mobileHeader: {
    paddingHorizontal: 14,
    paddingVertical: 20,
    maxHeight: 175,
    backgroundColor: colors.white,
    ...shadow({ color: colors.white, radius: 2 }),
  },
  mobileOptionsBox: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    minHeight: 250,
    // maxHeight: 250,
  },
});

export default mobileStyles;

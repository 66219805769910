import * as Sentry from '@sentry/react';

import { isDev, environment } from '../getEnvVariable';

export const initWebLogger = () => {
  Sentry.init({
    dsn: 'https://dcb86d1693514de0b7bd1e77b98c417e@o467382.ingest.sentry.io/4504204718964736',
    environment,
    enabled: !isDev,
    // integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: isDev ? 1.0 : 0.1,
    beforeSend: function (event) {
      const { exception } = event;
      if (!exception) return event;

      const level = event.level || '';
      // const url = event.request?.url || '';
      const firstException = (exception?.values || [])[0];
      const type = firstException.type || '';
      const value = firstException.value || '';
      const frames = (firstException.stacktrace || {}).frames || [];
      let lastFrame = frames[frames.length - 1] || {};
      if (!lastFrame.filename?.includes(window.location.origin))
        lastFrame = frames[frames.length - 2] || {};
      const lastFunc = lastFrame.function || '';
      event.fingerprint = [level, lastFunc, type, value];
      return event;
    },
  });
};

export const logWebError = (error: any) => {
  Sentry.captureException(error);
};

export const logWebMessage = (message: string) => {
  Sentry.captureMessage(message);
};

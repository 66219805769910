const origin = window.location?.origin || '';

const appEnv = process.env.REACT_APP_ENV || '';

const isProd =
  appEnv === 'production' ||
  (!!origin && origin.includes('order.eatstellarpizza.com'));

export const isDev = appEnv === 'development' || origin.includes('localhost');

const isStaging =
  appEnv === 'staging' || origin?.includes('order-staging.eatstellarpizza.com');

export const isReview =
  origin.includes('review-app') &&
  origin.endsWith('.dev.aws.eatstellarpizza.com');

export const environment = isProd
  ? 'production'
  : isStaging
  ? 'staging'
  : isReview
  ? 'review'
  : 'development';

type VariableType =
  | 'REACT_APP_ENV'
  | 'REACT_APP_STELLAR_API_URL'
  | 'REACT_APP_STRIPE_PUBLISHABLE_KEY'
  | 'REACT_APP_AUTH0_WEB_CLIENT_ID'
  | 'REACT_APP_AUTH0_NATIVE_CLIENT_ID'
  | 'REACT_APP_AUTH0_DOMAIN'
  | 'REACT_APP_AUTH0_AUDIENCE'
  | 'REACT_APP_INSTAGRAM_URL'
  | 'REACT_APP_TIKTOK_URL'
  | 'REACT_APP_REFERRAL_BASE'
  | 'REACT_APP_GOOGLE_MAPS_KEY'
  | 'REACT_APP_GOOGLE_GEOCODE_KEY';

const stagingVariables = {
  REACT_APP_ENV: 'staging',
  REACT_APP_STELLAR_API_URL: 'https://platform-staging.eatstellarpizza.com',
  REACT_APP_STRIPE_PUBLISHABLE_KEY:
    'pk_test_tMxvVPLgHFuma9md8lX6Q6oS00uvfwGr4Q',
  REACT_APP_AUTH0_WEB_CLIENT_ID: 'skagbh3eVF3D99AamDiNZYWmcvfwyfg3',
  REACT_APP_AUTH0_NATIVE_CLIENT_ID: '7VGf3kGaQ0UgK9yYqIFg3lU4kHRxW6jd',
  REACT_APP_AUTH0_DOMAIN: 'auth.order-staging.eatstellarpizza.com',
  REACT_APP_AUTH0_AUDIENCE: 'https://platform-staging.serveautomation.com/',
  REACT_APP_INSTAGRAM_URL: 'https://www.instagram.com/eatstellarpizza/',
  REACT_APP_TIKTOK_URL: 'https://www.tiktok.com/@eatstellarpizza',
  REACT_APP_REFERRAL_BASE: origin.includes('localhost')
    ? 'http://localhost:3200/?referred_by='
    : 'https://order-staging.eatstellarpizza.com/?referred_by=',
  REACT_APP_GOOGLE_MAPS_KEY: 'AIzaSyAGTBS6IwuOgld3tRWi6fEDuANUliQFy9o',
  REACT_APP_GOOGLE_GEOCODE_KEY: 'AIzaSyCLUWW1MjC1gH7LIn6lNoWx2HXvvq6hg_k',
};

const prodVariables = {
  REACT_APP_ENV: 'production',
  REACT_APP_STELLAR_API_URL: 'https://platform.eatstellarpizza.com',
  REACT_APP_STRIPE_PUBLISHABLE_KEY:
    'pk_live_QHhZvlQ5JPlvVFYKi1uxIwAm00lt5h2b9C',
  REACT_APP_AUTH0_WEB_CLIENT_ID: 'xdm3I86KBKpa0DfV3KDeoCLTyMS3kqDh',
  REACT_APP_AUTH0_NATIVE_CLIENT_ID: 'bjotQeYhnU0PAFabZprrnFGwc5sgzYl2',
  REACT_APP_AUTH0_DOMAIN: 'auth.order.eatstellarpizza.com',
  REACT_APP_AUTH0_AUDIENCE: 'https://platform.serveautomation.com/',
  REACT_APP_INSTAGRAM_URL: 'http://bit.ly/3DYAwfr',
  REACT_APP_TIKTOK_URL: 'http://bit.ly/3EmFcNH',
  REACT_APP_REFERRAL_BASE: 'https://order.eatstellarpizza.com/?referred_by=',
  REACT_APP_GOOGLE_MAPS_KEY: 'AIzaSyBgbOO2QvFm7JyK3Rh37xzmIfabpQASQjQ',
  REACT_APP_GOOGLE_GEOCODE_KEY: 'AIzaSyCh_yrJkAVHCoAcbH14aPqlAHa5RtkjbIY',
};

const getEnvVariable = (variable: VariableType) => {
  const variables = isProd ? { ...prodVariables } : { ...stagingVariables };
  return variables[variable] || '';
};

export default getEnvVariable;

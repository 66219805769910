import React from 'react';
import { View } from 'react-native';

import { PillBox } from '..';

import styles from './styles';

interface Props {
  choices: ChoiceType[];
  selectChoice: (choiceId: string) => void;
  disabled?: boolean;
}

const RadioButton = ({ choices, selectChoice, disabled }: Props) => {
  return (
    <View style={styles.radioBox}>
      {choices.map((c, idx) => (
        <PillBox
          choice={c}
          onPress={selectChoice}
          disabled={disabled}
          extraStyle={idx === 0 ? styles.firstPill : styles.secondPill}
          isRadio={true}
          key={c.id}
        />
      ))}
    </View>
  );
};

export default RadioButton;

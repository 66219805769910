import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  createRef,
} from 'react';
import {
  Pressable,
  View,
  Platform,
  NativeSyntheticEvent,
  LayoutRectangle,
  TextStyle,
  Animated,
  Image,
  FlatList,
} from 'react-native';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { STOCK_TESTING } from '@app/core';

import {
  convertMenuPrice,
  colors,
  animate,
  getProductImage,
  getCombos,
} from '../../helpers';

import { TitleText, BodyText, MenuToggle, ComboBox } from '..';

import { YellowSoldOut } from '../../assets';

import styles from './styles';

const isWeb = Platform.OS === 'web';

type WebLayoutChangeEvent = NativeSyntheticEvent<{
  layout: LayoutRectangle;
  target?: {
    style: TextStyle;
    childNodes: Node[];
  };
}>;

interface Props {
  categories: CategoryType[];
  productLibrary: LibraryType;
  clickProduct: (product: ProductType) => void;
  clickCombo: (combo: ComboType) => void;
  scrollY?: number;
  setCategoryScroll: (categoryIdx: number) => void;
  updateCategoryLocation: (categoryIdx: number, location: number) => void;
  menuView: 0 | 1;
  setMenuView: (menuView: 0 | 1) => void;
}

const MenuItems = ({
  categories,
  productLibrary,
  clickProduct,
  clickCombo,
  scrollY,
  setCategoryScroll,
  updateCategoryLocation,
  menuView,
  setMenuView,
}: Props) => {
  const {
    isMobile,
    screenSize: { height },
  } = useSelector((state: RootState) => state.appInfo);
  const [visible, setVisible] = useState(false);

  const menuOpacity = useRef(new Animated.Value(0)).current;

  const categoryRefs: React.RefObject<any>[] = categories.map(() =>
    useRef(createRef())
  );

  useEffect(() => {
    if (categories.length > 0 && !visible) {
      animate(menuOpacity, { toValue: 1, duration: 1000 }).start();
      setVisible(true);
    }
  }, [categories]);

  useEffect(() => {
    const checkCategories = async () => {
      const refPlacements = categoryRefs.map(() => ({ py: 0, height: 0 }));

      const placementPromises = categoryRefs.map((ref, idx) => {
        return new Promise((res) => {
          ref?.current?.measure(
            (fx: any, fy: any, width: any, height: any, px: any, py: any) => {
              refPlacements[idx] = { py, height };
              res(true);
            }
          );
        });
      });

      await Promise.all(placementPromises);

      let newActiveIdx: number | null = null;
      refPlacements.forEach((placement, idx) => {
        if (newActiveIdx !== null) return;
        const top = placement.py;
        const bottom = top + height;
        if (top < height * 0.8 && bottom > height * 1.1) newActiveIdx = idx;
        // if (placement >= 0.1 * height && placement < 0.8 * height)
        // newActiveIdx = idx;
      });

      if (newActiveIdx !== null) setCategoryScroll(newActiveIdx);
    };

    checkCategories();
  }, [scrollY]);

  const measureName = useCallback((event: WebLayoutChangeEvent) => {
    const target = event.nativeEvent.target;
    if (!target) return;
    const range = document.createRange();
    target.style.maxWidth = '100%';
    const text = target.childNodes[0];
    range.setStartBefore(text);
    range.setEndAfter(text);
    const clientRect = range.getBoundingClientRect();
    target.style.maxWidth = clientRect.width + 3;
  }, []);

  const combos = getCombos(productLibrary);

  return (
    <Animated.View
      style={[
        styles.menuItemsWrapper,
        { opacity: menuOpacity, paddingBottom: isMobile ? 120 : 300 },
      ]}>
      <View style={[styles.categoryBox, { paddingHorizontal: 0 }]}>
        <TitleText style={styles.categoryName} spacing={0.5} center={true}>
          COMBOS
        </TitleText>
        <BodyText fontSize={16} mt={4} color='midDark' center={true}>
          Try fan favorite tasty combinations.
        </BodyText>
        <FlatList
          data={combos}
          horizontal={true}
          showsHorizontalScrollIndicator={true}
          style={{ width: '100%' }}
          renderItem={({ item }) => {
            return (
              <ComboBox
                combo={item}
                clickCombo={() => clickCombo(item)}
                productLibrary={productLibrary}
              />
            );
          }}
        />
      </View>

      <MenuToggle menuView={menuView} setMenuView={setMenuView} />

      {categories.map((category, cIdx) => {
        const products = category.products
          .map((p) => productLibrary[p])
          .filter(Boolean);
        const sortedProducts = sortBy(products, (p) => !p.inStock);
        return (
          <View
            style={styles.categoryBox}
            ref={categoryRefs[cIdx]}
            onLayout={(event) => {
              const offset = event?.nativeEvent?.layout?.y;
              if (offset >= 0) updateCategoryLocation(cIdx, offset);
            }}
            key={category.id}>
            <TitleText style={styles.categoryName} spacing={0.5}>
              {category.name.toUpperCase()}
            </TitleText>
            <BodyText style={styles.categoryDescription}>
              {category.description}
            </BodyText>
            <View style={[styles.productGrid, isMobile && styles.mobileGrid]}>
              {sortedProducts.map((p) => (
                <Pressable
                  onPress={() => clickProduct(p)}
                  style={[
                    styles.productContainer,
                    isMobile && styles.mobileProductContainer,
                  ]}
                  disabled={!p.inStock && !STOCK_TESTING}
                  testID={`${category.name.toLowerCase()}-menu-item`}
                  key={p.id}>
                  {/* @ts-ignore */}
                  {({ hovered }) => (
                    <View
                      style={[
                        styles.productBox,
                        !p.inStock && styles.outOfStockProduct,
                        {
                          backgroundColor: hovered
                            ? colors.maroon
                            : colors.white,
                        },
                      ]}
                      key={p.id}>
                      <View style={styles.imageBox}>
                        {(() => {
                          const productImage = getProductImage(p);
                          return (
                            <Image
                              source={productImage}
                              resizeMode='contain'
                              style={{ width: 60, height: 50 }}
                            />
                          );
                        })()}
                      </View>
                      <View style={styles.textBox}>
                        <View style={styles.nameBox}>
                          <TitleText
                            style={[
                              styles.productName,
                              isMobile && styles.mobileProductName,
                            ]}
                            hovered={hovered}
                            onLayout={isWeb ? measureName : undefined}
                            disabled={!p.inStock}
                            color='maroon'
                            hoverColor='white'
                            testID='menu-item-product-name'>
                            {p.name.toUpperCase()}
                            {!p.inStock && (
                              <View style={styles.stockImage}>
                                <YellowSoldOut width={45} />
                              </View>
                            )}
                          </TitleText>
                        </View>
                        <BodyText
                          style={styles.productDescription}
                          hovered={hovered}
                          color='textDark'
                          hoverColor='text'>
                          {p.inStock ? p.description : ''}
                        </BodyText>
                      </View>

                      <BodyText
                        style={styles.productPrice}
                        spacing={0.5}
                        disabled={!p.inStock}
                        testID='menu-item-product-price'>
                        {convertMenuPrice(p)}
                      </BodyText>
                    </View>
                  )}
                </Pressable>
              ))}
            </View>
          </View>
        );
      })}
    </Animated.View>
  );
};

export default MenuItems;

import { ConfigType } from '../helpers/fetch';

import { uuid } from '../helpers';

import { mockStores } from './storeMock';
import { mockCustomer, pastOrders, mockUserCouponsResponse } from './userMock';
import { mockProducts } from './productsMock';

const productCatalog = mockProducts();

const getOrderInfo = (payload: any) => {
  const store = mockStores().find((s) => s.id === payload.store);
  const tax = payload.subtotal * parseFloat(store?.tax_rate || '0');
  const waitTime = store?.estimated_wait_time_minutes || 7;
  const readyTime = new Date(Date.now() + waitTime * 60000).toISOString();

  return { store, tax, waitTime, readyTime };
};
export const mockValidationResponse = ({ body }: ConfigType) => {
  const payload = body ? JSON.parse(body) : {};

  const { tax, waitTime, readyTime } = getOrderInfo(payload);

  const { coupons } = mockUserCouponsResponse();

  const usedCoupon = coupons.find((c) => c.coupon === payload.coupon);
  const couponDescription = usedCoupon
    ? usedCoupon.coupon_description
    : 'Welcome to Stellar Pizza! Order one cheese or pepperoni pizza for $1! Limit one per day.';

  return {
    coupon: payload.coupon || null,
    customer: mockCustomer.id,
    handoff: 'pickup',
    estimated_ready_for_handoff_at: readyTime,
    initial_estimated_ready_for_handoff_at: readyTime,
    order_lines: payload.order_lines,
    source: 'customer/web',
    store: payload.store,
    discount: payload.coupon ? payload.subtotal : 0,
    subtotal: payload.coupon ? 0 : payload.subtotal,
    tax: payload.coupon ? 0 : tax,
    total: payload.coupon ? 0 : tax + payload.subtotal,
    validation_id: uuid(),
    estimated_wait_time_minutes: waitTime,
    coupon_description: couponDescription,
  };
};

export const mockStockErrorResponse = () => ({
  order_lines: [
    {
      modifiers: {
        'c5b379e5-046d-4caf-9b09-5d6dad4f566e': [
          'Number of choices must be 1.',
        ],
      },
      product: ['Product is out of stock.'],
    },
  ],
});

export const mockCreateOrderResponse = ({ body }: ConfigType) => {
  const payload = body ? JSON.parse(body) : {};
  const { tax, readyTime } = getOrderInfo(payload);

  const currentTimestamp = new Date().toISOString();

  const products = payload.order_lines.map((ol: OrderLineResp) =>
    productCatalog.find((p) => p.id === ol.product)
  );

  const order = {
    id: uuid(),
    store: payload.store || 'd186b3fb-2ccf-4cb0-b2ea-abe0becf5ee1',
    coupon: payload.coupon || null,
    customer: mockCustomer.id,
    handoff: 'pickup',
    pickup_identifier: 'Tester T',
    estimated_ready_for_handoff_at: readyTime,
    initial_estimated_ready_for_handoff_at: readyTime,
    source: 'customer/web',
    discount: payload.coupon ? payload.subtotal : 0,
    subtotal: payload.subtotal,
    tax: payload.coupon ? 0 : tax,
    total: payload.coupon ? 0 : payload.subtotal + tax,
    validation_id: payload.validation_id,
    state: payload.coupon ? 'in_progress' : 'awaiting_payment',
    order_lines: payload.order_lines,
    products,
    refunds: [],
    refund_attempted: false,
    created_at: currentTimestamp,
    modified_at: currentTimestamp,
    in_progress_at: payload.coupon ? currentTimestamp : '',
    ready_for_handoff_at: '',
    canceled_at: null,
    completed_at: '',
    address: '12700 Yukon Ave, Hawthorne CA 90250',
  };

  pastOrders.push(order);
  return order;
};

export const mockCreatePaymentResponse = (config: ConfigType) => {
  const { body } = config;
  const payload = body ? JSON.parse(body) : {};
  const orderId = payload.order;
  const foundOrder = pastOrders.find((o) => o.id === orderId);

  return {
    amount: foundOrder?.total || 83,
    ephemeral_key_secret:
      'ek_test_YWNjdF8xR1hYTkdEdTI5NG15VnZ0LGw2N0M0dmp3bXdHeDRwTUxMb2NtQUI1d2U1SGhBS1c_00LtupVACS',
    id: 'a0e10bf8-1f30-46c4-b7a8-7033cfa07fe7',
    order: foundOrder?.id || 'd526b1b2-4674-4769-be3b-3d6dc3c74c16',
    payment_intent_client_secret:
      'pi_3LlcraDu294myVvt1LiQRKmU_secret_16lbtRDLvjstUFByYjzdFzZbl',
    state: 'pending',
    stripe_customer_id: 'cus_MUc5jGvgqrBfRd',
  };
};

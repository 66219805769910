import {
  mockStoreMenuResponse,
  mockStoresResponse,
  mockSingleStoreResponse,
  mockStoreEventsResponse,
  mockLoadUser,
  mockPatchUser,
  mockDeleteUser,
  mockUserOrdersResponse,
  mockUserCouponsResponse,
  mockValidationResponse,
  mockCreateOrderResponse,
  mockCreatePaymentResponse,
  mockFetchOrderResponse,
  mockFetchCompletedOrderResponse,
  mockGenericMenuResponse,
  mockAppConfigurationResponse,
} from '../../mocks';

import { ConfigType } from '.';

const mockFetch = (url: string, config: ConfigType) => {
  if (url === '/customer/app_configuration/')
    return mockAppConfigurationResponse();
  if (url === '/customer/menus/generic/') return mockGenericMenuResponse();
  if (url.startsWith('/customer/stores/?include[]=store_events.*'))
    return mockStoresResponse();
  if (url === '/customer/events/?include[]=store.*')
    return mockStoreEventsResponse();
  if (url.startsWith('/customer/stores/') && url.endsWith('/menu/'))
    return mockStoreMenuResponse();
  if (isStoreRequest(url)) return mockSingleStoreResponse(url);
  if (url === '/customer/profile/' && config.method === 'GET')
    return mockLoadUser(config);
  if (url === '/customer/profile/' && config.method === 'PATCH')
    return mockPatchUser(config);
  if (url === '/customer/profile/' && config.method === 'DELETE')
    return mockDeleteUser(config);
  if (url === '/customer/orders/validate/')
    return mockValidationResponse(config);
  if (
    url ===
    '/customer/orders/?include[]=products.*&include[]=store.*&sort[]=-created_at'
  )
    return mockUserOrdersResponse();
  if (url.startsWith('/customer/orders/') && config.method === 'GET') {
    if (url.endsWith('/?include[]=products.*&include[]=store.*'))
      return mockFetchCompletedOrderResponse(url);
    return mockFetchOrderResponse(url);
  }
  if (url === '/customer/coupons/') return mockUserCouponsResponse();
  if (url === '/customer/orders/' && config.method === 'POST')
    return mockCreateOrderResponse(config);
  if (url === '/customer/payments/') return mockCreatePaymentResponse(config);
};

const isStoreRequest = (url: string) => {
  if (
    !url.startsWith('/customer/stores/') ||
    !url.endsWith('?include[]=store_events.*')
  )
    return false;
  if (url.length !== 79) return false;
  return true;
};

export default mockFetch;

import { sample, cloneDeep } from 'lodash';

import mockStoreConfig from './mockStoreConfig';
import { mockStores } from './storeMock';

import {
  getEventTimes,
  getOpenTimes,
  getOpeningSoonTimes,
  getClosedTimes,
} from './mockTimes';
import { uuid } from '../helpers/general';

const createMockStoreEvents = () => {
  const mockEvents: StoreEventResp[] = [];

  const now = Date.now();
  const oneDay = 1000 * 60 * 60 * 24;

  const stores = cloneDeep(mockStoreConfig);

  stores.forEach((store) => {
    [...Array(7)].forEach((_, idx) => {
      const date = new Date(now + idx * oneDay);

      let eventTimes = getEventTimes(date);
      if (idx === 0) {
        if (store.closed) eventTimes = getClosedTimes();
        else if (store.opening) eventTimes = getOpeningSoonTimes();
        else eventTimes = getOpenTimes();
      }

      const storeEvent = {
        id: uuid(),
        store: store.id,
        temporary_name: store.mobile ? 'Hollywood Bowl' : '',
        address: store.address,
        public: true,
        ...eventTimes,
      };
      mockEvents.push(storeEvent);
    });
  });

  return mockEvents;
};

const mockedEvents = createMockStoreEvents();

export const mockStoreEvents = () => mockedEvents;

export const mockStoreEventsResponse = () => {
  const events = mockStoreEvents();
  const stores = mockStores();
  return { events, stores };
};

import React from 'react';
import { View, Image, Pressable } from 'react-native';

import { getProductImage, getProductPrice } from '../../../../helpers';

import { BodyText, CustomView, Close } from '../../..';

import { CircleAdd } from '../../../../assets';

import styles from './styles';

interface Props {
  item: string;
  index: number;
  addItem: any;
  removeItem: (product: ProductType) => void;
  productLibrary: LibraryType;
  added: boolean;
  inCart?: ProductType;
}

const UpsellItem = ({
  item,
  index,
  addItem,
  removeItem,
  productLibrary,
  added,
  inCart,
}: Props) => {
  const product = productLibrary[item];
  if (!product) return null;
  const productImage = getProductImage(product);
  const shortName = product.name.length < 10;
  const isDrink = product?.category === 'Drinks';

  return (
    <Pressable
      style={[styles.upsellItem, added && styles.addedItem]}
      onPress={() => !added && addItem(product)}>
      <CustomView
        fadeIn={true}
        delay={index * 20}
        duration={400 + index * 100}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        {!!added && (
          <Pressable
            style={styles.closeBox}
            onPress={() => removeItem(product)}>
            <Close width={20} color='white' />
          </Pressable>
        )}

        <View style={styles.textBox}>
          <BodyText
            color={added ? 'white' : 'maroon'}
            bold={true}
            uppercase={true}
            fontSize={shortName ? 16 : 14}
            lineHeight={shortName ? 16 : 16}>
            {product.name}
          </BodyText>
          {!!inCart && (
            <BodyText
              color={added ? 'white' : 'maroon'}
              bold={true}
              uppercase={false}
              fontSize={12}
              lineHeight={16}>
              {inCart?.quantity} in cart
            </BodyText>
          )}
        </View>
        <View style={styles.upsellPriceRow}>
          <BodyText color={added ? 'white' : 'midDark'} bold={true}>
            {getProductPrice(product, { trim: true })}
          </BodyText>
          <Image
            source={productImage}
            style={[styles.upsellImage, isDrink && styles.drinkImage]}
            resizeMode='contain'
          />
          {
            <CircleAdd
              width={32}
              height={32}
              style={{ opacity: added ? 0 : 1 }}
            />
          }
        </View>
      </CustomView>
    </Pressable>
  );
};

export default UpsellItem;

import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  createRef,
} from 'react';
import { View, ScrollView } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-native';

import { logError } from '../../helpers';

import {
  readStoreMenu,
  loadMenu,
  loadStore,
  setActiveProduct,
  setActiveCombo,
  applyCoupon,
  readCart,
} from '../../globalStore';

import {
  MenuItems,
  ImageMenuItems,
  MenuCategoryHeader,
  CouponDisplay,
} from '../../components';

import StoreHeader from './StoreHeader';

import styles from './styles';

const StoreMenu = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();

  const navigate = useNavigate();

  const storeCart = useSelector((state: RootState) => readCart(state, storeId));

  const isMobile = useSelector((state: RootState) => state.appInfo.isMobile);

  const activeMenu = useSelector((state: RootState) =>
    readStoreMenu(state, storeId || '')
  );

  const selectedCoupon = useSelector(
    (state: RootState) => state.userInfo.selectedCoupon
  );
  useEffect(() => {
    if (
      !!selectedCoupon &&
      (!storeCart?.appliedCoupon ||
        selectedCoupon.slug !== storeCart?.appliedCoupon.slug)
    ) {
      const { slug, description } = selectedCoupon;
      dispatch(applyCoupon({ storeId, coupon: { slug, description } }));
    }
  }, [selectedCoupon]);

  const activeStore = useSelector((state: RootState) => {
    const stores = state.storeInfo.stores;
    const store = stores.find((s) => s.id === storeId);
    return store;
  });

  const productLibrary = activeMenu?.productLibrary || {};

  const [scrollY, setScrollY] = useState(0);
  const [categoryScroll, setCategoryScroll] = useState(0);
  const [categoryLocations, setCategoryLocations] = useState<number[]>([]);
  const [menuView, setMenuView] = useState<0 | 1>(0);

  const scrollRef: React.RefObject<any> = useRef(createRef());

  const updateCategoryLocation = useCallback(
    (idx: number, location: number) => {
      setCategoryLocations((prevLocations) => {
        const newLocations = [...prevLocations];
        newLocations[idx] = location;
        return newLocations;
      });
    },
    [setCategoryLocations]
  );

  const clickCategory = useCallback(
    (categoryIdx: number) => {
      if (!categoryLocations) return;
      if (categoryIdx === 0) return scrollRef.current.scrollTo({ y: 0 });
      const scrollLocation = categoryLocations[categoryIdx];
      if (scrollLocation !== null)
        scrollRef.current.scrollTo({ y: scrollLocation });
    },
    [categoryLocations]
  );

  useEffect(() => {
    const getMenu = async () => {
      if (activeMenu && activeStore) return;

      let menuResp: any;
      if (storeId) menuResp = await dispatch(loadMenu(storeId));

      if (
        (!menuResp.payload ||
          !menuResp.payload.menu ||
          !menuResp.payload.menu.id) &&
        !activeMenu
      ) {
        const error = Error(`Menu not present for store - ${storeId}`);
        logError(error);
        navigate('/');
      }

      if (!activeStore) {
        const storeResp: any = await dispatch(loadStore(storeId));
        if (
          !storeResp.payload ||
          !storeResp.payload.store ||
          !storeResp.payload.store.id
        ) {
          const error = Error(`Store not present - ${storeId}`);
          logError(error);
          navigate('/');
        }
      }
    };

    getMenu();
  }, [storeId, activeStore]);

  const checkScroll = useCallback(
    (e: any) => {
      const scrollY = e.nativeEvent.contentOffset.y;
      setScrollY(scrollY);
    },
    [setScrollY]
  );

  if (!activeMenu?.categories) return null;
  const { categories } = activeMenu;

  const clickProduct = (product: ProductType) => {
    dispatch(setActiveProduct(product));
  };
  const clickCombo = (combo: ComboType) => {
    dispatch(setActiveCombo(combo));
  };

  return (
    <>
      <StoreHeader />

      <View style={[styles.borderLine, { marginVertical: isMobile ? 0 : 0 }]} />

      <MenuCategoryHeader
        categories={categories}
        categoryScroll={categoryScroll}
        clickCategory={clickCategory}
      />

      <ScrollView
        onScroll={checkScroll}
        scrollEventThrottle={16}
        ref={scrollRef}>
        <View style={[styles.pageWrapper, isMobile && styles.mobileWrapper]}>
          <View style={[styles.menuWrapper]}>
            <CouponDisplay
              storeId={storeId}
              mb={40}
              showAppliedMessage={true}
              removeCoupon={true}
            />

            {menuView === 0 ? (
              <ImageMenuItems
                categories={categories}
                productLibrary={productLibrary}
                clickProduct={clickProduct}
                clickCombo={clickCombo}
                scrollY={scrollY}
                setCategoryScroll={(categoryIdx: number) =>
                  setCategoryScroll(categoryIdx)
                }
                updateCategoryLocation={updateCategoryLocation}
                menuView={menuView}
                setMenuView={setMenuView}
              />
            ) : (
              <MenuItems
                categories={categories}
                productLibrary={productLibrary}
                clickProduct={clickProduct}
                clickCombo={clickCombo}
                scrollY={scrollY}
                setCategoryScroll={(categoryIdx) =>
                  setCategoryScroll(categoryIdx)
                }
                updateCategoryLocation={updateCategoryLocation}
                menuView={menuView}
                setMenuView={setMenuView}
              />
            )}
          </View>
        </View>
      </ScrollView>
    </>
  );
};

export default StoreMenu;

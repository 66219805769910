import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-native';
import { MOCK_TESTING } from '@app/core';

import {
  colors,
  globalStyles,
  isIos,
  useAuth,
  wipeAllStorage,
} from '../../helpers';
import {
  setAccountDeletionModal,
  removeUser,
  clearUserData,
  clearAllCarts,
} from '../../globalStore';

import { BodyText, TitleText, Button } from '..';

import BaseModal from './BaseModal';

// import { clearGlobalMockMode } from '../../App';
import { clearMockOrders } from '../../mocks';

import { Stars, MascotBolt, Close } from '../../assets';

const AccountDeletionModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logoutUser } = useAuth();

  const [submitting, setSubmitting] = useState(false);
  const [requested, setRequested] = useState(false);

  const clearModal = useCallback(() => {
    dispatch(setAccountDeletionModal(false));
  }, []);

  const softDelete = useCallback(() => {
    setRequested(true);
  }, []);

  const deleteAccount = useCallback(async () => {
    setSubmitting(true);
    await dispatch(removeUser());
    wipeAllStorage();
    logoutUser();

    if (MOCK_TESTING || isIos) {
      dispatch(clearUserData());
      dispatch(clearAllCarts());
      clearMockOrders();
      navigate('/');
      clearModal();
      // if (MOCK_TESTING) clearGlobalMockMode();
    }
  }, []);

  return (
    <BaseModal onBackdropPress={clearModal} testID='account-deletion-modal'>
      <View style={styles.modalBox}>
        <View style={styles.header}>
          <Stars width={214} height={130} />
        </View>

        <Pressable style={styles.closeButton} onPress={clearModal}>
          <Close width={100} height={14} />
        </Pressable>

        <View style={styles.body}>
          <View style={styles.background} />
          <View style={styles.content}>
            <View style={styles.boltBox}>
              <MascotBolt width={100} />
            </View>

            <TitleText fontSize={24} color='primary'>
              FEELING FULL?
            </TitleText>
            {requested ? (
              <>
                <BodyText mt={40} color='textDark' center={true}>
                  Are you sure or looking to get more coupons ;) This will clear
                  all of your stellar progress and past orders, but you're
                  welcome to start fresh anytime you like.
                </BodyText>

                <Button
                  mt={40}
                  onClick={clearModal}
                  color='secondary'
                  label='Nevermind more pizza please'
                />
                <Button
                  mt={30}
                  submitting={submitting}
                  onClick={deleteAccount}
                  label='Yes Delete My Account'
                />
              </>
            ) : (
              <>
                <BodyText mt={40} color='textDark' center={true}>
                  While we'd love to keep serving you hot pizza, we all need
                  breaks once in a while!
                </BodyText>
                <BodyText mt={20} color='textDark' center={true}>
                  We do not track or share any sensitive data, but also happy to
                  delete your account and remove any personal identifiers.
                </BodyText>
                <Button
                  mt={40}
                  submitting={submitting}
                  onClick={softDelete}
                  label='Delete Account'
                />
              </>
            )}

            <BodyText center={true} color='textDark' mt={50} mb={7}>
              Keep in touch!
            </BodyText>
            <View style={globalStyles.flexRow}>
              <BodyText
                underline={true}
                color='primary'
                link='https://www.instagram.com/eatstellarpizza/'>
                Instagrams
              </BodyText>
              <BodyText
                ml={14}
                underline={true}
                color='primary'
                link='https://www.tiktok.com/@eatstellarpizza'>
                TikTok
              </BodyText>
            </View>
          </View>
        </View>
      </View>
    </BaseModal>
  );
};

export default AccountDeletionModal;

const styles = StyleSheet.create({
  modalBox: {
    width: 368,
    minHeight: 540,
    borderRadius: 24,
    backgroundColor: colors.secondary,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 20,
  },
  header: {
    ...globalStyles.flexRow,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    elevation: 2,
  },
  body: {
    // flex: 1,
    position: 'relative',
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.body,
  },
  content: {
    ...globalStyles.flexColumn,
    // flex: 1,
    paddingTop: 80,
    paddingBottom: 40,
  },
  boltBox: {
    ...globalStyles.flexRow,
    position: 'absolute',
    width: '100%',
    top: -80,
    paddingLeft: 20,
  },
});

type slot = {
  hours: number;
  minutes: number;
  slices: number;
  ampm: string;
};

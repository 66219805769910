import { calculatePrice } from './price';

type ModifiersType = {
  [id: string]: {
    [id: string]: number;
  };
};
const getModifiers = (product: ProductType) => {
  const modifiers: ModifiersType = {};

  product.modifiers.forEach((m) => {
    modifiers[m.id] = {};
    m.choices.forEach((choice) => {
      if (choice.selected) modifiers[m.id][choice.id] = 1;
    });
  });

  return modifiers;
};

const getOrderLines = (cart: CartType) => {
  const orderLines = cart.products.map((product) => ({
    price: calculatePrice(product, true),
    product: product.id,
    quantity: product.quantity,
    modifiers: getModifiers(product),
  }));
  return orderLines;
};

export default getOrderLines;

export const calculatePrice = (
  product: ProductType | null,
  ignoreQuantity = false
) => {
  if (!product) return 0;
  let basePrice = product.price;
  product.modifiers.forEach((m) => {
    m.choices.forEach((c) => {
      if (!!c.price && c.selected) basePrice += c.price;
    });
  });
  if (product.priceMax && basePrice > product.priceMax)
    basePrice = product.priceMax;

  if (product.quantity && !ignoreQuantity) basePrice *= product.quantity;

  return basePrice;
};

type ConfigOptions = {
  trim?: boolean;
  inDollars?: boolean;
};

export const formatPrice = (price: number, config: ConfigOptions = {}) => {
  const { trim, inDollars } = config;
  if (price < 100 && !inDollars) return `${price}¢`;
  const dollars = price / 100;

  if (trim) {
    const noCents = dollars === Math.round(dollars);
    if (noCents) return `$${dollars}`;
  }

  return `$${dollars.toFixed(2)}`;
};

export const getProductPrice = (
  product: ProductType | null,
  config: ConfigOptions = {}
): string => {
  if (!product) return '$0';
  return formatPrice(calculatePrice(product), config);
};

const hasPricedModifiers = (product: ProductType) =>
  product.modifiers.some((m) => m.choices.some((c) => !!c.price));

export const convertMenuPrice = (product: ProductType) => {
  const formattedPrice = formatPrice(product.price);

  if (hasPricedModifiers(product)) return formattedPrice + '+';
  return formattedPrice;
};

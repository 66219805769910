import { createAsyncThunk } from '@reduxjs/toolkit';
import { throttle } from 'lodash';

export const createThrottledThunk = (
  name: string,
  callback: any,
  duration = 5000
) => {
  const throttledHandler = throttle(callback, duration, {
    leading: true,
    trailing: true,
  });

  const throttledName = 'throttled' + name[0].toUpperCase + name.slice(1);

  return [
    createAsyncThunk(`user/${name}`, callback),
    createAsyncThunk(`user/${throttledName}`, throttledHandler),
  ];
};

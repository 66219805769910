import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';

import { globalStyles, colors } from '../helpers';

import { BodyText } from '.';

interface Props {
  setMenuView: (view: 0 | 1) => void;
  menuView: 0 | 1;
}

const MenuToggle = ({ setMenuView, menuView }: Props) => {
  const isTiles = menuView === 0;
  const isList = menuView === 1;
  return (
    <View style={styles.wrapper}>
      <Pressable
        onPress={() => setMenuView(0)}
        style={[
          styles.toggleButton,
          styles.leftToggle,
          isTiles && styles.activeToggle,
        ]}>
        {[...Array(4)].map((_, idx) => (
          <View
            style={[styles.square, !isTiles && styles.darkSquare]}
            key={idx}
          />
        ))}
        {/* <View style={styles.square} />
        <View style={styles.square} />
        <View style={styles.square} /> */}
        {/* <BodyText color='white'>Tiles</BodyText> */}
      </Pressable>
      <Pressable
        onPress={() => setMenuView(1)}
        style={[
          styles.toggleButton,
          styles.rightToggle,
          menuView === 1 && styles.activeToggle,
        ]}>
        {[...Array(4)].map((_, idx) => (
          <View style={[styles.line, !isList && styles.darkLine]} key={idx} />
        ))}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 10,
    width: 200,
  },
  toggleButton: {
    width: 45,
    height: 30,
    backgroundColor: colors.midLight,
    alignSelf: 'flex-start',
    marginBottom: 10,
    overflow: 'hidden',
  },
  leftToggle: {
    ...globalStyles.flexRow,
    flexWrap: 'wrap',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  rightToggle: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',

    borderLeftWidth: 1,
    borderLeftColor: colors.midDark,
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
  activeToggle: {
    backgroundColor: colors.primary,
  },
  square: {
    width: '28%',
    height: '30%',
    margin: '3%',
    borderWidth: 1,
    borderColor: colors.white,
  },
  darkSquare: {
    borderColor: colors.textDark,
  },
  line: {
    width: '50%',
    height: 1,
    margin: 2,
    borderBottomWidth: 1,
    borderBottomColor: colors.white,
  },
  darkLine: {
    borderBottomColor: colors.textDark,
  },
});

export default MenuToggle;

import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../helpers';

const styles = StyleSheet.create({
  couponInfoDisplay: {
    ...globalStyles.flexColumn,
    width: '100%',
    paddingHorizontal: 10,
  },
  couponInfoBox: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    width: 700,
    maxWidth: '100%',
    // minHeight: 50,
    padding: 10,
    paddingHorizontal: 20,
    // borderWidth: 1,
    // borderColor: colors.secondary,
    // backgroundColor: colors.yellow,
    backgroundColor: colors.secondary,
    borderRadius: 10,
  },
  activeBox: {
    backgroundColor: colors.yellow,
  },
  couponDescriptionBox: {
    ...globalStyles.flexRow,
    width: '100%',
    marginTop: 8,
    overflow: 'hidden',
  },
  couponList: {
    ...globalStyles.flexRow,
    marginTop: 10,
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'hidden',
    // justifyContent: 'flex-start',
    // borderWidth: 1,
  },
});

export default styles;

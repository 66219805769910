import React, { useCallback } from 'react';
import { View, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-native';

import { selectCoupon, setReferralModal } from '../../../globalStore';

import {
  CouponTag,
  BodyText,
  TitleText,
  CouponList,
} from '../../../components';

import styles from './styles';

const Coupons = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userCoupons, userOrders, registerReferralEnabled } = useSelector(
    (state: RootState) => {
      const userCoupons = state.userInfo.userCoupons;
      const userOrders = state.userInfo.userOrders;
      const registerReferralEnabled =
        state.appInfo.appConfig?.registerReferralEnabled;
      return {
        userCoupons,
        userOrders,
        registerReferralEnabled,
      };
    }
  );

  const clickCoupon = useCallback((coupon: UserCouponType) => {
    dispatch(selectCoupon(coupon));
    navigate('/stores');
  }, []);

  const plural = !!userCoupons && userCoupons?.length > 1;

  const hasCoupons = !!userCoupons && userCoupons?.length > 0;

  const canRefer = userOrders?.length > 0 && !!registerReferralEnabled;

  const message = hasCoupons
    ? `Select ${plural ? 'a ' : ''}coupon to apply to a new order!`
    : canRefer
    ? 'You can earn a coupon by referring a friend!'
    : 'No coupons at the moment.';

  const showReferralModal = () => dispatch(setReferralModal(true));

  return (
    <View style={[styles.couponContainer]}>
      {!hasCoupons && (
        <BodyText
          center={true}
          fontSize={16}
          style={{ width: '90%' }}
          mb={40}
          color={hasCoupons ? 'secondary' : 'textDark'}
          bold={true}>
          {message}
        </BodyText>
      )}
      {hasCoupons && !!userCoupons && (
        <CouponList
          coupons={userCoupons}
          message={message}
          clickCoupon={(coupon: UserCouponType) => clickCoupon(coupon)}
        />
      )}

      {!hasCoupons && !!canRefer && (
        <Pressable onPress={showReferralModal} style={styles.referralButton}>
          <TitleText fontSize={22} spacing={0.4} color='white' center={true}>
            REFER A FRIEND
          </TitleText>
          <BodyText color='white'>(Get a pizza coupon)</BodyText>
        </Pressable>
      )}
    </View>
  );
};

export default Coupons;

import React, { useState, useEffect } from 'react';
import { Image, View, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { readGenericMenu } from '../globalStore';

import { getProductImage } from '../helpers';

const WebImageLoader = () => {
  const menu = useSelector(readGenericMenu);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => setLoad(true), 1000);
  }, []);

  if (!menu || !load) return null;

  const { productLibrary } = menu;
  return (
    <View style={styles.wrapper}>
      {Object.values(productLibrary).map((product) => (
        <Image
          source={getProductImage(product)}
          style={styles.productImage}
          key={product.id}
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    left: -200,
    top: -200,
    overflow: 'hidden',
    width: 50,
    height: 50,
    zIndex: -1,
  },
  productImage: {
    width: 1,
    height: 1,
  },
});

export default WebImageLoader;

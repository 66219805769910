import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../helpers';

const styles = StyleSheet.create({
  menuItemsWrapper: {
    width: '100%',
    minWidth: 200,
  },
  categoryBox: {
    // ...globalStyles.flexColumn,
    width: '100%',
    minHeight: 100,
    marginBottom: 20,
  },
  categoryName: {
    fontSize: 28,
    lineHeight: 30,
    fontWeight: '800',
    color: '#E10000',
    marginBottom: 0,
  },
  categoryDescription: {
    fontSize: 18,
    color: colors.midDark,
  },
  desktopProductGrid: {
    width: '100%',
    maxWidth: 1600,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 18,
  },
});

export default styles;

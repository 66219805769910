import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../../helpers';

const styles = StyleSheet.create({
  eventWrapper: {
    width: '100%',
    maxWidth: 700,
  },
  fullPageWrapper: {
    height: '95%',
  },

  eventContainer: {
    marginTop: 80,
    width: '100%',
  },
  fullPageContainer: {
    marginTop: 0,
    paddingTop: 40,
    paddingBottom: 150,
  },
  dayRow: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderColor: colors.midLight,
    width: '100%',
    // paddingHorizontal: 7,
    paddingVertical: 20,
    // paddingTop: 0,
    // paddingBottom: 8,
    // marginBottom: 10,
  },
  dateBox: {
    // ...globalStyles.flexRow,
    width: 45,
    marginLeft: 8,
  },
  date: {
    color: colors.black,
    fontWeight: '800',
  },
  eventsContainer: {
    flexGrow: 1,
    // width: '85%',
    // borderWidth: 1,
  },
  eventHeader: {
    ...globalStyles.flexRow,
    width: '100%',
    height: 20,
    marginBottom: 20,
    // paddingHorizontal: '5%',
    // marginHorizontal: '1%',
    // marginLeft: 5,
    // backgroundColor: colors.maroon,
  },
  headerContent: {
    ...globalStyles.flexRow,
    justifyContent: 'space-between',
    paddingHorizontal: '5%',
    width: '100%',
    height: '100%',
  },
  line: {
    position: 'absolute',
    width: '100%',
    height: '7%',
    bottom: 0,
    left: 0,
    borderRadius: 2,
    overflow: 'hidden',
  },
  eventBox: {
    alignSelf: 'flex-start',
    minHeight: 43,
    marginVertical: 5,
    width: '100%',
    maxWidth: '100%',
  },
  expandedBox: {
    marginVertical: 10,
  },
  eventPill: {
    justifyContent: 'center',
    alignSelf: 'flex-start',
    minHeight: 42,
    maxWidth: '100%',
    borderRadius: 25,
    backgroundColor: colors.primary,
    overflow: 'hidden',
  },

  eventDetails: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    paddingVertical: 6,
  },
  storeName: {
    maxWidth: '90%',
  },
  locationButton: {
    ...globalStyles.flexRow,
    width: '100%',
    height: 40,
    backgroundColor: colors.barnRed,
    paddingRight: 5,
  },
  storeButton: {
    marginTop: 50,
    paddingVertical: 14,
    paddingHorizontal: 46,
    borderRadius: 4,
    backgroundColor: colors.secondary,
    alignSelf: 'center',
  },
});

export default styles;

// import React from 'react';
// import ReactDOM from 'react-dom/client';
import { AppRegistry } from 'react-native';

import './index.css';
import TempApp from './App';
import { App } from '@app/core';
// import { App, Backdrop, Splash, ClassApp } from '@app/core';
// import App from './Trial';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//     {/* <ClassApp /> */}
//     {/* {<Splash />} */}
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.info))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

AppRegistry.registerComponent('main', () => App);
AppRegistry.runApplication('main', {
  rootTag: document.getElementById('root'),
});

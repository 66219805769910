import { ImageStyle } from 'react-native';

type StyleType = { [id: string]: ImageStyle };

const noOutline: any = { outlineStyle: 'none', outlineWidth: 0 };
const defaultCursor: any = { cursor: 'default' };

const globalStyles: StyleType = {
  pageWrapper: {
    minHeight: '100%',
    width: '100%',
    backgroundColor: '#FBF3EB',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 80,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  noOutline,
  defaultCursor,
};

export default globalStyles;

import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  selectionBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 24,
  },
});

export default styles;

import React from 'react';
import {
  View,
  ScrollView,
  StatusBar,
  Dimensions,
  Animated,
} from 'react-native';
import { connect } from 'react-redux';

import {
  isWeb,
  withNavigate,
  readStorage,
  writeStorage,
} from '@app/core/helpers';

import {
  readUserOrders,
  setLoginModal,
  throttledLoadUserItems,
} from '../../globalStore';

import { BodyText, Block, SocialIcon } from '@app/core/components';

import { getOpenOrders } from './recentOrders';

import HomeContent from './HomeContent';

import PizzaPal from './PizzaPal';

import { Logo, ClearDog, Star } from '@app/core/assets';

import Stars from './Stars';

import styles from './styles';

const now = new Date();
const year = now.getFullYear();

interface Props {
  screenSize: { width: number; height: number };
  userOrders: FilledOrderType[];
  userCoupons: UserCouponType[];
  navigate: (to: string) => void;
  referrerId: string | null;
  registerReferralEnabled: boolean;
  setLoginModal: (open: boolean) => void;
  selectedCoupon: UserCouponType;
  throttledLoadUserItems: (payload: UserItemsPayload) => void;
  userPublicId: string;
  stores: StoreType[];
}

interface State {
  blockHeight: number;
  backgroundHeight: number;
  showPhantom: boolean;
  openStoreOrders: FilledOrderType[];
  initialOrders: FilledOrderType[] | null;
  ordersReady: boolean;
  orderHeight: number;
  referralAvailable: boolean;
}

class NewHome extends React.Component<Props, State> {
  state: State = {
    blockHeight: 0,
    backgroundHeight: 0,
    showPhantom: false,
    openStoreOrders: [],
    initialOrders: null,
    ordersReady: false,
    orderHeight: 0,
    referralAvailable: false,
  };

  footerHeightAnim: Animated.Value = (() => {
    const windowHeight = Dimensions.get('window').height;
    const height = windowHeight > 800 ? 0.4 * windowHeight : 0;
    return new Animated.Value(height);
  })();

  decrementInterval: ReturnType<typeof setInterval> | null = null;

  componentDidMount() {
    this.loadReferralConfig();
    this.getInitialOrders();
    this.props.throttledLoadUserItems(['coupons', 'orders']);
  }

  componentWillUnmount() {
    if (this.decrementInterval) clearInterval(this.decrementInterval);
  }

  getInitialOrders = async () => {
    const { userOrders } = this.props;
    const initialOrders = await getOpenOrders(userOrders);
    this.setState({ initialOrders, openStoreOrders: initialOrders });
  };

  loadReferralConfig = async () => {
    const referralConfig = await readStorage('referralConfig');

    const { timeStamp, referralAvailable } = referralConfig;

    const now = Date.now();
    const oneDay = 1000 * 60 * 60 * 24;
    if (now - timeStamp < oneDay && !!referralAvailable)
      this.setState({ referralAvailable });
  };

  saveReferralConfig = async () => {
    const { userOrders, registerReferralEnabled, userPublicId, stores } =
      this.props;

    if (!stores.length || !userOrders.length) return;

    const lastStore = stores.find((s) => s.id === userOrders[0].storeId);
    if (!lastStore) return;

    const referralAvailable =
      userOrders.length > 0 &&
      registerReferralEnabled &&
      !!userPublicId &&
      lastStore.showReferrals;

    const referralConfig = { referralAvailable, timeStamp: Date.now() };
    writeStorage('referralConfig', referralConfig);
    this.setState({ referralAvailable });
  };

  setOpenStoreOrders = (openStoreOrders: FilledOrderType[]) => {
    this.setState({ openStoreOrders, ordersReady: true });
    this.saveReferralConfig();
  };

  onScroll = (event: any) => {
    const { blockHeight, backgroundHeight } = this.state;
    const height = Dimensions.get('window').height;
    const offset = event?.nativeEvent?.contentOffset?.y;
    const bottom = blockHeight + backgroundHeight - offset;
    const socialBar = height - 160;
    const showPhantom = bottom < socialBar * 1.05;
    if (showPhantom !== this.state.showPhantom) this.setState({ showPhantom });
    //  setShowPhantom(bottom < socialBar * 1.05);

    const extraHeight = height > 800 ? 0.4 * height : 0;
    const footerHeight = Math.max(0, extraHeight + offset * 0.9);
    this.footerHeightAnim.setValue(footerHeight);
  };

  interact = () => undefined;

  render() {
    const {
      screenSize: { width, height },
      userCoupons,
    } = this.props;
    const {
      blockHeight,
      initialOrders,
      openStoreOrders,
      ordersReady,
      showPhantom,
      orderHeight,
      referralAvailable,
    } = this.state;
    const isMobile = width < 900;

    const backgroundHeight = (isMobile ? 710 : height * 0.8) + orderHeight;

    return (
      <>
        <StatusBar hidden={true} barStyle='dark-content' />
        <View style={styles.redFiller} />

        <View style={styles.header}>
          <View style={styles.starryNight}>
            <Stars isMobile={isMobile} />
          </View>

          <View style={[styles.logoBox, { transform: [{ scale: 2 }] }]}>
            <Logo width={200} height={40} />
          </View>
        </View>
        <ScrollView
          style={styles.homeWrapper}
          onScroll={this.onScroll}
          scrollEventThrottle={30}>
          <View
            style={{ borderWidth: 0, borderColor: 'black' }}
            onLayout={(event) => {
              this.setState({ blockHeight: event.nativeEvent.layout.height });
            }}>
            <Block height={isMobile ? 320 : 400} />
          </View>
          <View
            style={[
              styles.background,
              {
                top: blockHeight,
                borderTopRightRadius: width / 2,
                borderTopLeftRadius: width / 2,
                borderBottomRightRadius: width / 2,
                borderBottomLeftRadius: width / 2,
                height: backgroundHeight,
                transform: [{ scaleX: isMobile ? 2.4 : 1.6 }],
              },
            ]}
            onLayout={(event) => {
              this.setState({
                backgroundHeight: event.nativeEvent.layout.height,
              });
            }}
          />

          <View style={styles.welcomeBox}>
            {initialOrders !== null && (
              <PizzaPal
                interact={this.interact}
                openStoreOrders={openStoreOrders}
              />
            )}
          </View>

          {initialOrders !== null && (
            <HomeContent
              referralAvailable={referralAvailable}
              userCoupons={userCoupons}
              initialOrders={initialOrders}
              ordersReady={ordersReady}
              openStoreOrders={openStoreOrders}
              setOpenStoreOrders={this.setOpenStoreOrders}
              setOrderHeight={(height: number) => {
                this.setState({ orderHeight: height });
              }}
              backgroundHeight={backgroundHeight}
            />
          )}

          <Block
            height={
              isMobile
                ? isWeb
                  ? 460
                  : Math.max(height * 0.5, 410)
                : height * 0.7
            }
          />
        </ScrollView>

        <Animated.View
          style={[
            styles.footerWrapper,
            {
              height: this.footerHeightAnim,
            },
          ]}>
          <View style={[styles.footer, isMobile && styles.mobileFooter]}>
            <View
              style={[styles.puppy, { top: isMobile ? -250 : -height * 0.3 }]}>
              <Star
                width={16}
                height={16}
                style={{ position: 'absolute', left: -40, top: -20 }}
              />
              <Star
                width={12}
                height={12}
                style={{ position: 'absolute', left: 5, bottom: -5 }}
              />
              <Star
                width={12}
                height={12}
                style={{ position: 'absolute', right: -10, top: 20 }}
              />
              <ClearDog width={120} height={120} />
            </View>
            <View style={styles.footerBackground} />
            <View
              style={[styles.socialBox, isMobile && styles.mobileSocialBox]}>
              {!!navigator?.share && (
                <SocialIcon
                  name='share'
                  iconSize='48%'
                  size={isMobile ? 56 : 70}
                  iconColor='secondary'
                  background='body'
                  mr={isMobile ? 30 : 50}
                />
              )}

              <SocialIcon
                name='tiktok'
                size={isMobile ? 56 : 70}
                iconSize='54%'
                iconColor='secondary'
                background='body'
                mr={isMobile ? 30 : 50}
              />
              <SocialIcon
                name='instagram'
                size={isMobile ? 56 : 70}
                iconSize='54%'
                iconColor='secondary'
                background='body'
              />
            </View>

            <View style={styles.copy}>
              <BodyText color='white' fontSize={16}>
                © {year} STELLAR PIZZA
              </BodyText>
            </View>
          </View>
        </Animated.View>

        {showPhantom && (
          <View style={styles.phantomBox}>
            <View
              style={[styles.socialBox, isMobile && styles.mobileSocialBox]}>
              {!!navigator?.share && (
                <SocialIcon
                  name='share'
                  iconSize='48%'
                  size={isMobile ? 56 : 70}
                  iconColor='secondary'
                  background='body'
                  mr={isMobile ? 30 : 50}
                />
              )}

              <SocialIcon
                name='tiktok'
                size={isMobile ? 56 : 70}
                iconSize='54%'
                iconColor='secondary'
                background='body'
                mr={isMobile ? 30 : 50}
              />
              <SocialIcon
                name='instagram'
                size={isMobile ? 56 : 70}
                iconSize='54%'
                iconColor='secondary'
                background='body'
              />
            </View>
          </View>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  userOrders: readUserOrders(state),
  userCoupons: state.userInfo.userCoupons,
  screenSize: state.appInfo.screenSize,
  registerReferralEnabled: state.appInfo.appConfig?.registerReferralEnabled,
  referrerId: state.userInfo.referrerId,
  selectedCoupon: state.userInfo.selectedCoupon,
  userPublicId: state.userInfo.user.publicId,
  stores: state.storeInfo.stores,
});

const mapDispatchToProps = {
  setLoginModal,
  throttledLoadUserItems,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigate(NewHome));

import React from 'react';
import {
  View,
  SafeAreaView,
  useWindowDimensions,
  Platform,
} from 'react-native';
import { Route, Routes, Outlet, Navigate } from 'react-router-native';

import Home from './pages/Home';
import Stores from './pages/Stores';
import UpcomingSchedule from './pages/Stores/UpcomingSchedule';
import StoreMenu from './pages/StoreMenu';
import ProfileLayout from './pages/ProfileLayout';
import Profile from './pages/ProfileLayout/Profile';
import Orders from './pages/ProfileLayout/Orders';
import OrderSummary from './pages/ProfileLayout/OrderSummary';
import Coupons from './pages/ProfileLayout/Coupons';
import GenericMenu from './pages/GenericMenu';

import LoginRedirect, { LogoutRedirect } from './pages/LoginRedirect';

import Nav from './components/Nav';
import Cart from './components/Cart';
import Modals from './components/Modals';

const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

const Layout = () => {
  const { height } = useWindowDimensions();

  const ContentView = isNative ? View : SafeAreaView;

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}>
      <ContentView style={{ flex: 1, height }}>
        <Nav />
        <Modals />
        <Outlet />
      </ContentView>
      <Cart />
    </View>
  );
};

const AppRoutes = () => (
  <Routes>
    <Route element={<Layout />}>
      <Route index element={<Home />} />
      <Route path='/stores' element={<Stores />} />
      <Route path='/stores/:storeId' element={<Stores />} />
      <Route path='/schedule' element={<UpcomingSchedule fullPage={true} />} />

      <Route path='/menu' element={<GenericMenu />} />
      <Route path='/login' element={<LoginRedirect />} />
      <Route path='/logout' element={<LogoutRedirect />} />
      <Route path='store/:storeId' element={<StoreMenu />} />
      <Route path='profile' element={<ProfileLayout />}>
        <Route index element={<Profile />} />
        <Route path='orders' element={<Orders />} />
        <Route path='orders/:orderId' element={<OrderSummary />} />
        <Route path='coupons' element={<Coupons />} />
      </Route>
      <Route path='*' element={<Navigate to='/' replace />} />
    </Route>
  </Routes>
);

export default AppRoutes;

import React from 'react';
import { View, ViewStyle } from 'react-native';
import Svg, { Path, G } from 'react-native-svg';

import { colors, globalStyles } from '../helpers';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const CouponIcon = ({ width, height, color }: Props) => {
  let fillColor = colors.white;
  if (color && colors[color]) fillColor = colors[color];

  return (
    <Svg
      width={width || 16}
      height={height || width || 16}
      viewBox='0 0 150 90'
      fill='none'>
      <G
        id='Page-1'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'>
        <G id='voucher' fill={fillColor} fillRule='nonzero'>
          <Path
            d='M145.673145,31.7305664 C148.062891,31.7305664 150,29.7931641 150,27.4037109 L150,4.32685547 C150,1.93710938 148.062598,0 145.673145,0 L50.4808594,0 L50.4808594,21.6345703 C50.4808594,24.0243164 48.543457,25.9614258 46.1540039,25.9614258 C43.7645508,25.9614258 41.8271484,24.0240234 41.8271484,21.6345703 L41.8271484,0 L4.32714844,0 C1.93740234,0 0,1.93710938 0,4.32685547 L0,27.4037109 C0,29.793457 1.93740234,31.7305664 4.32685547,31.7305664 C11.484375,31.7305664 17.3077148,37.5536133 17.3077148,44.7114258 C17.3077148,51.8692383 11.484668,57.6922852 4.32685547,57.6922852 C1.93740234,57.6922852 0,59.6293945 0,62.0191406 L0,85.0959961 C0,87.4857422 1.93740234,89.4228516 4.32685547,89.4228516 L41.8268555,89.4228516 L41.8268555,67.7882812 C41.8268555,65.3985352 43.7642578,63.4614258 46.1537109,63.4614258 C48.5431641,63.4614258 50.4805664,65.3988281 50.4805664,67.7882812 L50.4805664,89.4228516 L145.672852,89.4228516 C148.062598,89.4228516 150,87.4854492 150,85.0959961 L150,62.0191406 C150,59.6293945 148.062305,57.6922852 145.672852,57.6922852 C138.515332,57.6922852 132.691992,51.8692383 132.691992,44.7114258 C132.691992,37.5536133 138.515332,31.7305664 145.673145,31.7305664 Z M50.4808594,50.4805664 C50.4808594,52.8703125 48.543457,54.8074219 46.1540039,54.8074219 C43.7645508,54.8074219 41.8271484,52.8700195 41.8271484,50.4805664 L41.8271484,38.9419922 C41.8271484,36.5522461 43.7645508,34.6151367 46.1540039,34.6151367 C48.543457,34.6151367 50.4808594,36.5525391 50.4808594,38.9419922 L50.4808594,50.4805664 Z'
            id='Shape'
          />
        </G>
      </G>
    </Svg>
  );
};

export default CouponIcon;

import React, { useEffect, useRef } from 'react';
import { Pressable, StyleSheet, Animated } from 'react-native';

import { colors, animate } from '../helpers';

interface Props {
  value: boolean;
  toggleValue: () => void;
}

const Toggle = ({ value, toggleValue }: Props) => {
  const fadeAnim = useRef(new Animated.Value(value ? 1 : 0)).current;
  const leftAnim = useRef(new Animated.Value(value ? 24 : 0)).current;

  useEffect(() => {
    if (value)
      Animated.parallel([
        animate(fadeAnim, {
          toValue: 1,
          duration: 400,
        }),
        animate(leftAnim, {
          toValue: 24,
          duration: 400,
        }),
      ]).start();
    else
      Animated.parallel([
        animate(fadeAnim, {
          toValue: 0,
          duration: 400,
        }),
        animate(leftAnim, {
          toValue: 0,
          duration: 400,
        }),
      ]).start();
  }, [value]);

  return (
    <Pressable onPress={toggleValue} style={styles.toggleBox}>
      <Animated.View
        style={[styles.highlightBackground, { opacity: fadeAnim }]}
      />
      <Animated.View
        style={[styles.switch, { transform: [{ translateX: leftAnim }] }]}
      />
    </Pressable>
  );
};

export default Toggle;

const styles = StyleSheet.create({
  toggleBox: {
    width: 60,
    height: 34,
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.midLight,
    overflow: 'hidden',
  },
  highlightBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: colors.blue,
  },
  switch: {
    backgroundColor: colors.white,
    width: 26,
    height: 26,
    borderRadius: 15,
    marginLeft: 5,
  },
});

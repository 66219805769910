import { useEffect } from 'react';
import { useNavigate } from 'react-router-native';

import { useAuth, writeStorage, readStorage } from '../helpers';

const LoginRedirect = () => {
  const navigate = useNavigate();
  const { parseCode } = useAuth();
  window.isNewUser = true;

  useEffect(() => {
    const loginRedirect = localStorage.getItem('loginRedirect') || '/';

    parseCode();
    navigate(loginRedirect);
  }, []);
  return null;
};

export const setLogoutRedirect = async (pathname: string) => {
  let logoutRedirect = '/';
  if (pathname.startsWith('/store') || pathname.startsWith('/menu'))
    logoutRedirect = pathname;
  writeStorage('logoutRedirect', logoutRedirect);
};

export const LogoutRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = async () => {
      const logoutRedirect = (await readStorage('logoutRedirect')) || '/';
      navigate(logoutRedirect);
    };

    redirect();
  }, []);
  return null;
};

export default LoginRedirect;

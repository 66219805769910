export const mockProducts = () => [
  {
    description: 'Rocket fuel in a can.',
    id: 'f6700829-3b5b-4274-a2c1-ab8e4b67e3f4',
    modifiers: [],
    name: 'Red Bull',
    requires_kitchen: false,
    slug: 'red-bull',
    version: 1,
    in_stock: true,
  },
  {
    description: 'Lemon+Lime.',
    id: '19df866a-fb1e-4b3f-81ff-e308122e525e',
    modifiers: [],
    name: 'Sprite',
    requires_kitchen: false,
    slug: 'sprite',
    version: 2,
    in_stock: true,
  },
  {
    description: 'Avoid the dairy drama!',
    id: 'c4e5ab87-07cb-4631-ae61-7cfa869a67cd',
    modifiers: [],
    name: 'Lactaid Pills',
    requires_kitchen: false,
    slug: 'lactaid-caplets',
    version: 3,
    in_stock: false,
  },
  {
    description: 'A funky good time.',
    id: '823bdb04-6905-49ed-afbf-1fba7baf7b2e',
    modifiers: [],
    name: 'Bleu Cheese',
    requires_kitchen: false,
    slug: 'bleu-cheese',
    version: 3,
    in_stock: true,
  },
  {
    description: 'Is it getting warm in here?',
    id: '0a70f730-8d15-4fdd-9b80-81aa69a5a713',
    modifiers: [],
    name: 'Buffalo Sauce',
    requires_kitchen: false,
    slug: 'buffalo-sauce',
    version: 3,
    in_stock: true,
  },
  {
    description: 'Zero calories and zero sugar!',
    id: '242778b9-acdd-42dc-ac1d-879adc4032b7',
    modifiers: [],
    name: 'Coke Zero',
    requires_kitchen: false,
    slug: 'coke-zero',
    version: 4,
    in_stock: true,
  },
  {
    description: 'Zero calories and zero sugar with a lighter taste.',
    id: 'ecf16728-2af0-483b-8fb0-1306e0d8119b',
    modifiers: [],
    name: 'Diet Coke',
    requires_kitchen: false,
    slug: 'diet-coke',
    version: 3,
    in_stock: true,
  },
  {
    description: 'Bubbly fresh citrus straight from Italy.',
    id: 'f5ded82e-4ce4-4ef5-9edf-0220aedadf92',
    modifiers: [],
    name: 'San Pellegrino Sparkling Orange',
    requires_kitchen: false,
    slug: 'san-pellegrino-sparkling-orange',
    version: 7,
    in_stock: true,
  },
  {
    description: 'Classic BBQ flavor with a kiss of heat.',
    id: '3b6dc514-0143-4ccb-8eb1-5fab56cdf82d',
    modifiers: [],
    name: 'BBQ',
    requires_kitchen: false,
    slug: 'bbq',
    version: 3,
    in_stock: true,
  },
  {
    description: 'Coca-cola.',
    id: '95faefbf-ea3d-4bb4-81a3-d94be73c0d5b',
    modifiers: [],
    name: 'Coke',
    requires_kitchen: false,
    slug: 'coke',
    version: 4,
    in_stock: true,
  },
  {
    description: 'A twist on the classic American flavor.',
    id: '985795db-7ef2-48be-8383-866e062ce836',
    modifiers: [],
    name: 'Jalapeño Ranch',
    requires_kitchen: false,
    slug: 'jalapeno-ranch',
    version: 2,
    in_stock: true,
  },
  {
    description: 'It’s sweet and a bit spicy.',
    id: 'bfbd1d15-dbe9-4cfe-9e62-a14869b2e47e',
    modifiers: [],
    name: "Mike's Hot Honey®",
    requires_kitchen: false,
    slug: 'mikes-hot-honey',
    version: 3,
    in_stock: true,
  },
  {
    description: "Where's the valley? Good luck.",
    id: 'd3ed9cd4-3595-4086-be8d-317187fdb599',
    modifiers: [],
    name: 'Ranch',
    requires_kitchen: false,
    slug: 'ranch',
    version: 2,
    in_stock: true,
  },
  {
    description: 'Name a more iconic duo.',
    id: '662ff615-e94f-4a17-9d01-a13e79d73cb8',
    modifiers: [
      {
        id: '7795b38a-e5a4-42de-a86b-51f125cc27bc',
        name: 'Crust',
        hidden: true,
        choices: [
          {
            id: '545050db-e885-45a2-a894-a7addc7ea9fb',
            name: 'Original Crust',
            default: true,
            ingredients: { '2cb472ad-238a-432a-ab1a-aa89d0252f28': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '64a9d1b7-3b84-4718-a2e4-3169daf4b21f',
        name: 'Sauce',
        hidden: false,
        choices: [
          {
            id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
            name: 'Normal Sauce',
            default: true,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 75 },
            in_stock: true,
          },
          {
            id: 'f977688c-1180-4374-9b9a-73232530c4af',
            name: 'Extra Sauce',
            default: false,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 95 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd54171da-a22e-4976-a321-60134c2c5be3',
        name: 'Cheese',
        hidden: false,
        choices: [
          {
            id: '8d56ea2f-80cc-43e8-a4fe-891f5b29089e',
            name: 'Normal Cheese',
            default: true,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 80 },
            in_stock: true,
          },
          {
            id: '13c71400-e45a-4e97-8b25-1e34d17b28d0',
            name: 'Extra Cheese',
            default: false,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 100 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '2d73b487-d181-4d5e-8fac-35614cfee8ff',
        name: 'Toppings',
        hidden: false,
        choices: [
          {
            id: 'd66841d9-f518-4c8e-bbd2-4e38efbe3fb0',
            name: 'Pepperoni',
            default: true,
            ingredients: { 'cf1287df-4c3c-4d0c-bc56-684aed17888c': 1 },
            in_stock: true,
          },
          {
            id: '82f158b8-7bad-42c2-b64b-8eed7d8a2a41',
            name: 'Sausage',
            default: true,
            ingredients: { '6e381590-79df-47b7-9604-d32bea249c84': 1 },
            in_stock: true,
          },
        ],
        max_choices: 2,
        min_choices: 2,
        max_quantity: 1,
        min_quantity: 1,
      },
    ],
    name: 'Sausage and Pepperoni Pizza',
    requires_kitchen: true,
    slug: 'sausage-and-pepperoni-pizza',
    version: 7,
    in_stock: true,
  },
  {
    description: 'Drinking water from the Alps.',
    id: '86efd9b8-6c56-4fa0-ac58-d3e6438f31ad',
    modifiers: [],
    name: 'Liquid Death Mountain Water',
    requires_kitchen: false,
    slug: 'liquid-death-mountain-water',
    version: 2,
    in_stock: true,
  },
  {
    description: 'Carbonated drinking water from the Alps.',
    id: '989c1c3d-c927-49eb-825c-84ff1d03f9a0',
    modifiers: [],
    name: 'Liquid Death Sparkling Water',
    requires_kitchen: false,
    slug: 'liquid-death-sparking-water',
    version: 4,
    in_stock: true,
  },
  {
    description: 'All pizzas start out like this.',
    id: '6fc79191-74a9-49e3-9442-3f7941c89665',
    modifiers: [
      {
        id: '07c0026f-e5bb-4b3f-99e0-6363c452d2fb',
        name: 'Crust',
        hidden: true,
        choices: [
          {
            id: 'fc4f6588-9ca4-42fc-ab37-2b91e38397d3',
            name: 'Original Crust',
            default: true,
            ingredients: { '2cb472ad-238a-432a-ab1a-aa89d0252f28': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'a9200d7f-583d-47aa-9474-0f86eb815009',
        name: 'Sauce',
        hidden: false,
        choices: [
          {
            id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
            name: 'Normal Sauce',
            default: true,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 75 },
            in_stock: true,
          },
          {
            id: 'f0107015-ff6a-4883-a927-5622e4deb382',
            name: 'Extra Sauce',
            default: false,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 95 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd54171da-a22e-4976-a321-60134c2c5be3',
        name: 'Cheese',
        hidden: false,
        choices: [
          {
            id: 'a8be53ec-b9ae-4739-b09c-0935be89e3f5',
            name: 'Normal Cheese',
            default: true,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 80 },
            in_stock: true,
          },
          {
            id: 'e7b2bb2d-03b4-4317-a7d1-ca3709eb8bc9',
            name: 'Extra Cheese',
            default: false,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 100 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
    ],
    name: 'Cheese Pizza',
    requires_kitchen: true,
    slug: 'cheese-pizza',
    version: 6,
    in_stock: true,
  },
  {
    description: 'Sausage, Pepperoni and Bacon.',
    id: '942454ab-5cc3-43ee-9d78-a8e83a5d4e0b',
    modifiers: [
      {
        id: '7795b38a-e5a4-42de-a86b-51f125cc27bc',
        name: 'Crust',
        hidden: true,
        choices: [
          {
            id: '545050db-e885-45a2-a894-a7addc7ea9fb',
            name: 'Original Crust',
            default: true,
            ingredients: { '2cb472ad-238a-432a-ab1a-aa89d0252f28': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '64a9d1b7-3b84-4718-a2e4-3169daf4b21f',
        name: 'Sauce',
        hidden: false,
        choices: [
          {
            id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
            name: 'Normal Sauce',
            default: true,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 75 },
            in_stock: true,
          },
          {
            id: 'f977688c-1180-4374-9b9a-73232530c4af',
            name: 'Extra Sauce',
            default: false,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 95 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd54171da-a22e-4976-a321-60134c2c5be3',
        name: 'Cheese',
        hidden: false,
        choices: [
          {
            id: '8d56ea2f-80cc-43e8-a4fe-891f5b29089e',
            name: 'Normal Cheese',
            default: true,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 80 },
            in_stock: true,
          },
          {
            id: '13c71400-e45a-4e97-8b25-1e34d17b28d0',
            name: 'Extra Cheese',
            default: false,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 100 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '2d73b487-d181-4d5e-8fac-35614cfee8ff',
        name: 'Toppings',
        hidden: false,
        choices: [
          {
            id: 'd66841d9-f518-4c8e-bbd2-4e38efbe3fb0',
            name: 'Pepperoni',
            default: true,
            ingredients: { 'cf1287df-4c3c-4d0c-bc56-684aed17888c': 1 },
            in_stock: true,
          },
          {
            id: '82f158b8-7bad-42c2-b64b-8eed7d8a2a41',
            name: 'Sausage',
            default: true,
            ingredients: { '6e381590-79df-47b7-9604-d32bea249c84': 1 },
            in_stock: true,
          },
          {
            id: '96000227-ce7b-49e2-adc4-1585c3cf528a',
            name: 'Bacon',
            default: true,
            ingredients: { '8a973b86-31a3-4bd4-a1f8-108b245f7e5b': 1 },
            in_stock: true,
          },
        ],
        max_choices: 3,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
    ],
    name: 'Meat Lovers Pizza',
    requires_kitchen: true,
    slug: 'meat-lovers-pizza',
    version: 9,
    in_stock: true,
  },
  {
    description: "You've never had pepperoni this good.",
    id: 'a9331100-65c2-4edf-8189-a80705e52779',
    modifiers: [
      {
        id: '2c72b458-da27-43fd-a42f-6bc1d7175839',
        name: 'Crust',
        hidden: true,
        choices: [
          {
            id: 'df558bf0-e548-4788-b661-02514efb1d30',
            name: 'Original Crust',
            default: true,
            ingredients: { '2cb472ad-238a-432a-ab1a-aa89d0252f28': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'c5b379e5-046d-4caf-9b09-5d6dad4f566e',
        name: 'Sauce',
        hidden: false,
        choices: [
          {
            id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
            name: 'Normal Sauce',
            default: true,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 75 },
            in_stock: true,
          },
          {
            id: '05a5ab64-4026-4f51-81e5-2ec78a3401a9',
            name: 'Extra Sauce',
            default: false,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 95 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd54171da-a22e-4976-a321-60134c2c5be3',
        name: 'Cheese',
        hidden: false,
        choices: [
          {
            id: 'bdd8f946-2a73-4304-850a-1f36b209ac8f',
            name: 'Normal Cheese',
            default: true,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 80 },
            in_stock: true,
          },
          {
            id: '92898913-dbe8-43e7-9c84-6cf6a3d43f78',
            name: 'Extra Cheese',
            default: false,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 100 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd7547c99-a0a2-4051-8b1b-798827994d6b',
        name: 'Toppings',
        hidden: false,
        choices: [
          {
            id: '26232c64-d912-4a97-8173-ceb8bdb4735c',
            name: 'Pepperoni',
            default: true,
            ingredients: { 'cf1287df-4c3c-4d0c-bc56-684aed17888c': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
    ],
    name: 'Pepperoni Pizza',
    requires_kitchen: true,
    slug: 'pepperoni-pizza',
    version: 8,
    in_stock: true,
  },
  {
    description: 'Pepperoni, Sausage, Onions, Green Peppers and Black Olives.',
    id: 'b436b7a2-3522-4d11-a19c-9ae2d5c2ae81',
    modifiers: [
      {
        id: '564e8532-bd19-4b7a-8c3a-8ea9069fb4de',
        name: 'Crust',
        hidden: true,
        choices: [
          {
            id: '7df1695f-fd55-4cfb-b98b-dad70f7e9133',
            name: 'Original Crust',
            default: true,
            ingredients: { '2cb472ad-238a-432a-ab1a-aa89d0252f28': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'fdc37c9a-8f27-43f6-9c6b-788bb73a916f',
        name: 'Sauce',
        hidden: false,
        choices: [
          {
            id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
            name: 'Normal Sauce',
            default: true,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 75 },
            in_stock: true,
          },
          {
            id: 'f467499d-f586-4b8f-9eff-d613c7b8ff70',
            name: 'Extra Sauce',
            default: false,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 95 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd54171da-a22e-4976-a321-60134c2c5be3',
        name: 'Cheese',
        hidden: false,
        choices: [
          {
            id: '765ace0e-3a7e-48e8-8db2-96f3adee1c77',
            name: 'Normal Cheese',
            default: true,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 80 },
            in_stock: true,
          },
          {
            id: '4c291293-3013-457a-9820-77fbacfb054e',
            name: 'Extra Cheese',
            default: false,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 100 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '54dbe5b3-0a1f-4388-89de-8c375b478f18',
        name: 'Toppings',
        hidden: false,
        choices: [
          {
            id: 'c294f0e1-c7d8-4e47-8fa8-9580bfb19e3e',
            name: 'Pepperoni',
            default: true,
            ingredients: { 'cf1287df-4c3c-4d0c-bc56-684aed17888c': 1 },
            in_stock: true,
          },
          {
            id: '27d3266c-81d8-4f0e-a53a-df84d72c7b53',
            name: 'Sausage',
            default: true,
            ingredients: { '6e381590-79df-47b7-9604-d32bea249c84': 1 },
            in_stock: true,
          },
          {
            id: '226d34d7-0b17-4c08-8100-b15aae4fd876',
            name: 'Onions',
            default: true,
            ingredients: { 'a5b735cb-c390-4017-9848-8d0b62b1cfb2': 1 },
            in_stock: true,
          },
          {
            id: 'cf474a6c-5ea2-40b2-a8d1-0d0d0552df5a',
            name: 'Green Peppers',
            default: true,
            ingredients: { '99c0a0b9-14b1-47ff-bba5-c74971d80148': 1 },
            in_stock: true,
          },
          {
            id: '675af097-782b-47dd-8bcb-89c412e24a87',
            name: 'Black Olives',
            default: true,
            ingredients: { 'f09746fe-c06e-4fba-8155-a9b92da47fdc': 1 },
            in_stock: true,
          },
        ],
        max_choices: 5,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
    ],
    name: 'Supreme Pizza',
    requires_kitchen: true,
    slug: 'supreme-pizza',
    version: 13,
    in_stock: true,
  },
  {
    description: 'Onions, Green Peppers and Black Olives.',
    id: '0d0eac1f-213a-473b-93d3-bcde502d49e9',
    modifiers: [
      {
        id: 'f01c11de-58f7-4301-b3d1-828262fdf9f5',
        name: 'Crust',
        hidden: true,
        choices: [
          {
            id: '7c550c8d-47b7-4902-abda-252f7c7d2d45',
            name: 'Original Crust',
            default: true,
            ingredients: { '2cb472ad-238a-432a-ab1a-aa89d0252f28': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '71f88173-2ebc-4a4a-a011-431c947176ef',
        name: 'Sauce',
        hidden: false,
        choices: [
          {
            id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
            name: 'Normal Sauce',
            default: true,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 75 },
            in_stock: true,
          },
          {
            id: '8e3d2b9b-0b0c-44a3-9006-3a26081bed7b',
            name: 'Extra Sauce',
            default: false,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 95 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd54171da-a22e-4976-a321-60134c2c5be3',
        name: 'Cheese',
        hidden: false,
        choices: [
          {
            id: '43efeee3-c72f-46e6-9fb4-465410ddf6f0',
            name: 'Normal Cheese',
            default: true,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 80 },
            in_stock: true,
          },
          {
            id: 'db61efaa-eb7e-4638-bb07-a2b617f8f57f',
            name: 'Extra Cheese',
            default: false,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 100 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '6e3a1dd1-9d7d-4d2b-9c1d-47509573784c',
        name: 'Toppings',
        hidden: false,
        choices: [
          {
            id: '64eaa61d-ba1a-4e43-b500-6d52ed4b7c59',
            name: 'Onions',
            default: true,
            ingredients: { 'a5b735cb-c390-4017-9848-8d0b62b1cfb2': 1 },
            in_stock: true,
          },
          {
            id: 'ee1341a8-491d-44c9-8c0f-2370838bd888',
            name: 'Green Peppers',
            default: true,
            ingredients: { '99c0a0b9-14b1-47ff-bba5-c74971d80148': 1 },
            in_stock: true,
          },
          {
            id: '94df4d9a-5e97-44bd-b62b-86adf5ee9923',
            name: 'Black Olives',
            default: true,
            ingredients: { 'f09746fe-c06e-4fba-8155-a9b92da47fdc': 1 },
            in_stock: true,
          },
        ],
        max_choices: 3,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
    ],
    name: 'Veggie Pizza',
    requires_kitchen: true,
    slug: 'veggie-pizza',
    version: 11,
    in_stock: true,
  },
  {
    description: 'Everything tastes better when you make it yourself.',
    id: '5352052d-9655-4ed6-89dc-819a900991bb',
    modifiers: [
      {
        id: '8dcd51e1-a965-4d5c-ab33-e711e2161b98',
        name: 'Crust',
        hidden: true,
        choices: [
          {
            id: '26aa5cd8-53cd-4383-bb78-d24c67f7704f',
            name: 'Original Crust',
            default: true,
            ingredients: { '2cb472ad-238a-432a-ab1a-aa89d0252f28': 1 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '4fef18f6-024d-47f1-859f-c01b9c3924ce',
        name: 'Sauce',
        hidden: false,
        choices: [
          {
            id: '9e95529f-4a11-4d3d-ba45-8a84c3c392f6',
            name: 'Normal Sauce',
            default: true,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 75 },
            in_stock: true,
          },
          {
            id: 'ebeac286-e04b-4812-a2ff-662eceac4f07',
            name: 'Extra Sauce',
            default: false,
            ingredients: { '92387963-ca8f-49b4-a4a1-2e662d807ca3': 95 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: 'd54171da-a22e-4976-a321-60134c2c5be3',
        name: 'Cheese',
        hidden: false,
        choices: [
          {
            id: '34ceb2ab-6658-4a21-a44d-a1a54d9c8bdd',
            name: 'Normal Cheese',
            default: true,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 80 },
            in_stock: true,
          },
          {
            id: '9217546e-918b-4fac-a477-fb13a60ec0bc',
            name: 'Extra Cheese',
            default: false,
            ingredients: { '04751992-345e-4711-8298-87b9800d7c0a': 100 },
            in_stock: true,
          },
        ],
        max_choices: 1,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
      {
        id: '287aa293-6cfd-4794-8e12-10a4187bbcd9',
        name: 'Toppings',
        hidden: false,
        choices: [
          {
            id: 'fc9839bf-b3f8-4270-9878-a72310556163',
            name: 'Pepperoni',
            default: false,
            ingredients: { 'cf1287df-4c3c-4d0c-bc56-684aed17888c': 1 },
            in_stock: true,
          },
          {
            id: 'bcb8ea09-aaa2-48bc-bd65-05243afa0397',
            name: 'Sausage',
            default: false,
            ingredients: { '6e381590-79df-47b7-9604-d32bea249c84': 1 },
            in_stock: true,
          },
          {
            id: '4d0ff327-0707-4300-8d0d-9e7985c95787',
            name: 'Bacon',
            default: false,
            ingredients: { '8a973b86-31a3-4bd4-a1f8-108b245f7e5b': 1 },
            in_stock: true,
          },
          {
            id: '8591841d-a0bb-442e-8f27-ce83eaf445bd',
            name: 'Onions',
            default: false,
            ingredients: { 'a5b735cb-c390-4017-9848-8d0b62b1cfb2': 1 },
            in_stock: true,
          },
          {
            id: 'b7ffd1c4-103a-4f9b-9820-106ff800f799',
            name: 'Green Peppers',
            default: false,
            ingredients: { '99c0a0b9-14b1-47ff-bba5-c74971d80148': 1 },
            in_stock: true,
          },
          {
            id: '679caf16-0a18-4885-8a41-1b16047511ab',
            name: 'Black Olives',
            default: false,
            ingredients: { 'f09746fe-c06e-4fba-8155-a9b92da47fdc': 1 },
            in_stock: true,
          },
          {
            id: 'fda7ee4a-acde-49f1-8c55-054b9324f723',
            name: 'Mushrooms',
            default: false,
            ingredients: { '24647572-a77a-4980-ad77-a589a152341b': 1 },
            in_stock: false,
          },
        ],
        max_choices: 7,
        min_choices: 1,
        max_quantity: 1,
        min_quantity: 1,
      },
    ],
    name: 'Build Your Own Pizza',
    requires_kitchen: true,
    slug: 'build-your-own-pizza',
    version: 23,
    in_stock: true,
  },
];

import React, { useCallback, useState, useEffect } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';

import { colors, globalStyles } from '../../helpers';
import { setReferralModal } from '../../globalStore';

import { BodyText, TitleText, ReferralSection, Block } from '..';

import BaseModal from './BaseModal';

import { Stars, MascotBolt, Close } from '../../assets';

const ScheduleUpsellModal = () => {
  const dispatch = useDispatch();

  const clearModal = useCallback(() => {
    dispatch(setReferralModal(false));
  }, []);

  return (
    <BaseModal onBackdropPress={clearModal}>
      <View style={styles.modalBox}>
        <View style={styles.header}>
          <Stars width={214} height={130} />
        </View>

        <Pressable style={styles.closeButton} onPress={clearModal}>
          <Close width={100} height={14} />
        </Pressable>

        <View style={styles.body}>
          <View style={styles.background} />
          <View style={styles.content}>
            <View style={styles.boltBox}>
              <MascotBolt width={100} />
            </View>
            <Block height={20} />
            <ReferralSection />
          </View>
        </View>
      </View>
    </BaseModal>
  );
};

export default ScheduleUpsellModal;

const styles = StyleSheet.create({
  modalBox: {
    width: 368,
    height: 500,
    borderRadius: 24,
    backgroundColor: colors.secondary,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 20,
  },
  header: {
    ...globalStyles.flexRow,
    paddingLeft: 15,
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: 16,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    elevation: 2,
  },
  body: {
    flex: 1,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderTopLeftRadius: 200,
    borderTopRightRadius: 200,
    transform: [{ scaleX: 2 }],
    backgroundColor: colors.body,
  },
  content: {
    ...globalStyles.flexColumn,
    flex: 1,
    bottom: 88,
  },
  boltBox: {
    left: 18,
    marginBottom: 30,
  },
});

import React, {
  useRef,
  createRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-native';

import { globalStyles, colors } from '../../helpers';

import { readUser, throttledLoadUserItems } from '../../globalStore';

import { NavLink, TitleText } from '../../components';

import { ProfilePicture } from '../../assets';

const ProfileLayout = () => {
  const dispatch = useDispatch();

  const user = useSelector(readUser);
  const { pathname } = useLocation();

  const isMobile = useSelector((state: RootState) => state.appInfo.isMobile);
  const screenSize = useSelector(
    (state: RootState) => state.appInfo.screenSize
  );

  useEffect(() => {
    dispatch(throttledLoadUserItems(['orders', 'coupons']));
  }, []);

  const scrollRef = useRef(createRef()).current as React.RefObject<ScrollView>;
  const [scrollY, setScrollY] = useState(0);
  const shorty = screenSize.height < 900;

  const TopElement = shorty ? ScrollView : View;
  const ContentElement = shorty ? View : ScrollView;

  useEffect(() => {
    scrollUp();
  }, [pathname]);

  const scrollUp = useCallback(
    (animated = false) => {
      scrollRef.current?.scrollTo({ y: 0, animated });
    },
    [scrollRef]
  );

  const onScroll = useCallback(
    (event: any) => {
      const offset = event?.nativeEvent?.contentOffset?.y;
      if (offset) setScrollY(offset);
    },
    [setScrollY]
  );

  const backgroundColor =
    scrollY < 0.01 * screenSize.height ? colors.primary : colors.body;

  return (
    <TopElement
      style={[styles.wrapper, { backgroundColor }]}
      onScroll={onScroll}
      scrollEventThrottle={30}
      ref={shorty ? scrollRef : null}>
      <View style={[styles.header, !!isMobile && styles.mobileHeader]}>
        <ProfilePicture width={100} />
      </View>

      <View style={styles.layout}>
        <TitleText color='primary' uppercase={true} fontSize={24}>
          {user.name}
        </TitleText>
        <View style={[styles.flexBox, !!isMobile && styles.mobileFlexBox]}>
          <View style={[styles.linkBox, !!isMobile && styles.mobileLinkBox]}>
            <NavLink
              to='/profile'
              label='PROFILE'
              fontSize={18}
              underlineActive={true}
              style={styles.navLink}
            />
            <NavLink
              to='/profile/orders'
              activeChildren={true}
              underlineActive={true}
              label='ORDERS'
              ml={!!isMobile ? 30 : 0}
              mt={isMobile ? 0 : 30}
              style={styles.navLink}
              fontSize={18}
            />
            <NavLink
              to='/profile/coupons'
              label='COUPONS'
              fontSize={18}
              underlineActive={true}
              ml={!!isMobile ? 30 : 0}
              mt={isMobile ? 0 : 30}
              style={styles.navLink}
            />
          </View>
          <ContentElement
            onScroll={onScroll}
            scrollEventThrottle={30}
            style={[
              styles.content,
              { maxHeight: shorty ? 'auto' : screenSize.height - 200 },
            ]}
            ref={!shorty ? scrollRef : null}>
            <Outlet context={scrollUp} />
          </ContentElement>
        </View>
      </View>
    </TopElement>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...globalStyles.pageWrapper,
    alignItems: undefined,
    minHeight: 0,
    flex: 1,
    paddingTop: 0,
    backgroundColor: colors.yellow,
  },
  header: {
    ...globalStyles.flexRow,
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 80,
    paddingTop: 80,
    borderTopWidth: 1,
    borderTopColor: colors.white,
    backgroundColor: colors.primary,
    zIndex: 10,
  },
  mobileHeader: {
    borderTopWidth: 0,
  },
  layout: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
    minHeight: '100%',
    alignItems: 'center',
    paddingTop: 160,
    backgroundColor: colors.body,
    borderTopColor: colors.midLight,
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start',
    flex: 1,
    paddingTop: 40,
    marginTop: 30,
    marginRight: 180,
    width: 800,
    maxWidth: '95%',

    overflow: 'hidden',
  },
  mobileFlexBox: {
    flexDirection: 'column',
    marginRight: 0,
    paddingTop: 20,
  },
  linkBox: {
    width: 180,
    marginTop: 20,
  },
  mobileLinkBox: {
    ...globalStyles.flexRow,
    width: '100%',
    marginBottom: 30,
    marginTop: 0,
  },
  navLink: {
    alignSelf: 'flex-start',
  },
  content: {
    width: 800,
    maxWidth: '100%',
  },
});

export default ProfileLayout;

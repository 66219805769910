import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  AsyncThunkPayloadCreator,
} from '@reduxjs/toolkit';

import { sleep, logMessage, logError, writeStorage } from '../../helpers';

import { removeCartCoupon, setReferralRedeemedModal } from '..';

import {
  fetchUser,
  patchUser,
  deleteUser,
  fetchUserOrders,
  fetchUserCoupons,
  fetchOrder,
  fetchCompletedOrder,
  writeUserStorage,
  readUserStorage,
  postRegisterReferral,
} from './actions';

import { createThrottledThunk } from './throttleThunkFactory';

export type UserState = {
  user: UserType;
  userOrders: FilledOrderType[];
  userCoupons: UserCouponType[] | null;
  selectedCoupon: UserCouponType | CouponType | null;
  error: string;
  referrerId: string | null;
};

const emptyUser = (loaded = false) => ({ token: '', loaded, updating: false });

const initialState = (loaded = false): UserState => ({
  user: emptyUser(loaded),
  userOrders: [],
  userCoupons: null,
  selectedCoupon: null,
  error: '',
  referrerId: null,
});

const userSlice = createSlice({
  name: 'userInfo',
  initialState: initialState(),
  reducers: {
    setUserToken: (state, { payload }) => {
      state.user = { loaded: true, updating: false, token: payload };
    },
    setUserUpdating: (state) => {
      state.user.updating = true;
    },
    clearUserError: (state) => {
      state.error = '';
    },
    clearUserData: () => {
      const newState = initialState(true);
      writeUserStorage(newState);
      return newState;
    },
    selectCoupon: (state, { payload }: { payload: UserCouponType }) => {
      state.selectedCoupon = payload || state.selectedCoupon;
      writeUserStorage(state);
    },
    setReferrerId: (state, { payload }: { payload: string }) => {
      state.referrerId = payload || state.referrerId;
      if (!!payload) writeStorage('referrerId', payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(loadUser.fulfilled, (state, { payload }) => {
      state.user = payload.user;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.user = payload.user || state.user;
      state.error = '';
      state.user.updating = false;
    });
    builder.addCase(removeUser.fulfilled, (state, {}) => {
      const newState = initialState(true);
      writeUserStorage(newState);
      return newState;
    });
    builder.addCase(updateUser.rejected, (state, { payload }: any) => {
      state.user.updating = false;
      state.error = payload.error || 'Unable to update user';
    });
    builder.addCase(loadUserOrders.fulfilled, (state, { payload }) => {
      state.userOrders = payload.userOrders || state.userOrders;
    });
    builder.addCase(setUserOrder.fulfilled, (state, { payload }) => {
      if (payload?.order && payload.order) {
        const newOrders = [...state.userOrders].filter(
          (o) => o.id !== payload?.order.id
        );
        newOrders.push(payload.order);
        newOrders.sort((a, b) => b.createdTime - a.createdTime);
        state.userOrders = newOrders;
      }
    });
    builder.addCase(loadUserCoupons.fulfilled, (state, { payload }) => {
      if (payload.desiredUserCoupon) {
        state.selectedCoupon = payload.desiredUserCoupon;
        // We are now setting desiredUserCoupon regardless if it matches an existing customer coupon
        // Leaving this for ref
        // const desiredUserCoupon = payload.userCoupons?.find(
        //   (c: UserCouponType) => c.id === payload.desiredUserCoupon.id
        // );
        // if (desiredUserCoupon) state.selectedCoupon = desiredUserCoupon;
      }
      state.userCoupons = payload.userCoupons || state.userCoupons;
      writeUserStorage(state);
    });
    builder.addCase(unselectCoupon.fulfilled, (state) => {
      state.selectedCoupon = null;
      writeUserStorage(state);
    });
    builder.addCase(registerReferral.fulfilled, (state, { payload }) => {
      if (payload?.id) {
        const currentCoupons = state.userCoupons || [];
        const newCoupons = [...currentCoupons].filter(
          (c) => c.id !== payload?.id
        );
        newCoupons.push(payload);
        state.userCoupons = newCoupons;
        state.referrerId = null;
        state.selectedCoupon = payload || state.selectedCoupon;
        writeUserStorage(state);
      }
    });
    builder.addCase(registerReferral.rejected, (state) => {
      state.referrerId = null;
    });
  },
});

export const unselectCoupon = createAsyncThunk(
  'user/unselectCoupon',
  async (storeId: string, { dispatch }) => {
    if (storeId) dispatch(removeCartCoupon({ storeId }));
    return;
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (user: UserType, { rejectWithValue }) => {
    if (!user.loaded || !user.token) {
      logMessage('No authenticated user present');
      return rejectWithValue({ error: 'no user present' });
    }

    const { updatedUser, error } = await patchUser(user);
    if (error) return rejectWithValue({ error });
    if (updatedUser) return { user: updatedUser };

    return rejectWithValue({ error: 'user not updated' });
  }
);

export const loadUser = createAsyncThunk(
  'user/loadUser',
  async (_, { getState, rejectWithValue }) => {
    const storeState = getState() as RootState;
    const { user } = storeState.userInfo;
    if (!user.loaded || !user.token) {
      logMessage('No authenticated user present');
      return rejectWithValue({ error: 'no user present' });
    }

    const loadedUser = await fetchUser(user);
    if (!loadedUser) return rejectWithValue({ error: 'failed' });
    return { user: loadedUser };
  }
);

export const removeUser = createAsyncThunk(
  'user/deleteUser',
  async (_, { getState, rejectWithValue }) => {
    const storeState = getState() as RootState;
    const { user } = storeState.userInfo;
    if (!user.loaded || !user.token) {
      logMessage('No authenticated user present');
      return rejectWithValue({ error: 'no user present' });
    }

    const response = await deleteUser(user);
    if (response.status !== 204)
      logError(`Error deleting user account - ${response}`);
    return;
  }
);

const loadUserItemsHandler: AsyncThunkPayloadCreator<
  any,
  UserItemsPayload,
  Record<string, unknown>
> = async (
  payload: UserItemsPayload,
  { getState, dispatch, rejectWithValue }
) => {
  const storeState = getState() as RootState;
  const { user } = storeState.userInfo;
  if (!user.loaded || !user.token)
    return rejectWithValue({ error: 'no user present' });
  if (payload?.includes('orders')) dispatch(loadUserOrders());
  if (payload?.includes('coupons')) dispatch(loadUserCoupons());
};

export const [loadUserItems, throttledLoadUserItems] = createThrottledThunk(
  'loadUserItems',
  loadUserItemsHandler,
  3000
);

export const loadUserOrders = createAsyncThunk(
  'user/loadUserOrders',
  async (_, { getState, rejectWithValue }) => {
    const storeState = getState() as RootState;
    const { user } = storeState.userInfo;
    if (!user.loaded || !user.token)
      return rejectWithValue({ error: 'no user present' });
    const { error, filledOrders } = await fetchUserOrders(user);
    if (error) return rejectWithValue({ error });

    return { userOrders: filledOrders };
  }
);

export const loadUserCoupons = createAsyncThunk(
  'user/loadUserCoupons',
  async (_, { getState, rejectWithValue }) => {
    const storeState = getState() as RootState;
    const { user } = storeState.userInfo;
    if (!user.loaded || !user.token)
      return rejectWithValue({ error: 'no user present' });
    const coupons = await fetchUserCoupons(user);
    const { desiredUserCoupon } = await readUserStorage();
    return { userCoupons: coupons, desiredUserCoupon };
  }
);

export const refreshUser = createAsyncThunk(
  'user/refreshUser',
  async (_, { dispatch }) => {
    await dispatch(loadUser());
    dispatch(loadUserOrders());
  }
);

export const setUserTokenAndLoad = createAsyncThunk(
  'user/setUserTokenAndLoad',
  async (payload: string, { dispatch }) => {
    const token = payload;
    if (!token) return;
    const { setUserToken } = userSlice.actions;
    dispatch(setUserToken(token));
    dispatch(loadUser());
  }
);

const setUserOrder = createAsyncThunk(
  'user/setUserOrder',
  async (payload: FilledOrderType, {}) => {
    const order = payload;
    if (order && order.id) return { order };
  }
);

type CompletedOrderType = {
  orderId: string;
  attemptsLeft: number;
};

export const loadCompletedOrder: AsyncThunk<any, CompletedOrderType, any> =
  createAsyncThunk(
    'user/loadCompletedOrder',
    async (payload: CompletedOrderType, { getState, dispatch }) => {
      const { orderId, attemptsLeft } = payload;

      const globalState = getState() as RootState;
      const { user } = globalState.userInfo;

      if (user.token && orderId) {
        const order = await fetchCompletedOrder(orderId, user.token);
        dispatch(setUserOrder(order));
        if (order.state === 'awaiting_payment') {
          await sleep(2);
          const newAttempts = attemptsLeft - 1;
          if (newAttempts > 0)
            return dispatch(
              loadCompletedOrder({ orderId, attemptsLeft: newAttempts })
            );
        }
        return { order };
      }
    }
  );

type GetOrderType = {
  storeId: string;
  orderId: string;
};

export const getOrder = createAsyncThunk(
  'user/getOrder',
  async (payload: GetOrderType, { getState }) => {
    const { storeId, orderId } = payload;

    const globalState = getState() as RootState;
    const { user } = globalState.userInfo;

    if (user.token && orderId) {
      const order = await fetchOrder(orderId, user.token);
      if (!order || !order.id) return { error: 'Order not found' };
      order.storeId = storeId;
      return { order, storeId };
    }
  }
);

export const registerReferral = createAsyncThunk(
  'user/registerReferral',
  async (referrerId: string, { getState, rejectWithValue, dispatch }) => {
    const globalState = getState() as RootState;
    const { user } = globalState.userInfo;

    if (user.token && referrerId) {
      const { coupon, error } = await postRegisterReferral(user, referrerId);
      if (coupon) {
        dispatch(setReferralRedeemedModal(coupon));
        return coupon;
      } else return rejectWithValue(error);
    }
  }
);

export default userSlice;

import React, { useRef, useEffect } from 'react';
import { Animated, Image, Platform, Text } from 'react-native';
import { useDispatch } from 'react-redux';

import { MOCK_TESTING, JEST_TEST } from '@app/core';

import { animate, readStorage, writeStorage } from '../../helpers';

import { setUpdateRequiredModal } from '../../globalStore';
import { Logo, dog } from '@app/core/assets';

import styles from './styles';

export const shouldShowBackdrop = async () => {
  if (window.location.pathname === '/login') return false;
  if (Platform.OS === 'ios') return true;

  let showBackdrop = true;
  const now = new Date().getTime();
  const lastShowed = await readStorage('backdropLastShowed');
  if (lastShowed) showBackdrop = now - lastShowed > 3600000;
  return showBackdrop;
};

interface Props {
  showContent: () => void;
}

export let setUpdateRequired: (versions: VersionType) => void;

const Backdrop = ({ showContent }: Props) => {
  const dispatch = useDispatch();
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const slideAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    setUpdateRequired = (versions) =>
      dispatch(setUpdateRequiredModal(versions));

    const backDropInit = async () => {
      const showBackdrop = await shouldShowBackdrop();
      let showOpacity = 0;
      let slideDuration = 0;
      let staggerDuration = 0;
      let fadeDuration = 0;
      if (showBackdrop) {
        showOpacity = 1;
        fadeDuration = 700;
        slideDuration = 1000;
        staggerDuration = 1400;
        const now = Date.now();
        writeStorage('backdropLastShowed', now);
      }

      if (!showBackdrop) showContent();
      else setTimeout(showContent, 1000);

      Animated.stagger(staggerDuration, [
        animate(fadeAnim, {
          toValue: showOpacity,
          duration: fadeDuration,
        }),
        animate(slideAnim, {
          toValue: -1500,
          duration: slideDuration,
        }),
      ]).start();
    };

    backDropInit();
  }, []);

  // Getting a warning about passing accessibilityLabel to DOM, only needed for tests currently
  const logoProps: any = {
    width: 300,
    height: 100,
    preserveAspectRatio: 'xMidYMid',
  };
  if (JEST_TEST) logoProps.accessibilityLabel = 'stellar logo';

  return (
    <Animated.View
      style={[
        styles.backdrop,
        {
          opacity: fadeAnim,
          transform: [{ translateY: slideAnim }],
        },
      ]}>
      <Logo {...logoProps} />
      <Image
        source={dog}
        style={styles.puppy}
        resizeMode='contain'
        accessibilityLabel='stellar space dog'
      />
      {/* <Text style={styles.copy}>
        {Platform.OS !== 'web' && `${Platform.OS} ${Platform.Version}`}
      </Text> */}
    </Animated.View>
  );
};

export default Backdrop;

import React from 'react';
import { View, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-native';

import { BodyText } from '../../../components';
import Clock from '../../../assets/clock.svg';

import { MaroonLocationPin } from '../../../assets';

import { setScheduleUpsellModal } from '../../../globalStore';

import { getAbsoluteWaitTime } from '../../../helpers';

import styles from './styles';

const minFixedHeight = 58;

const StoreHeader = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const navigate = useNavigate();

  const isMobile = useSelector((state: RootState) => state.appInfo.isMobile);
  const activeStore = useSelector((state: RootState) => {
    const stores = state.storeInfo.stores;
    const store = stores.find((s) => s.id === storeId);
    return store;
  });

  const longStoreName = activeStore?.name && activeStore?.name.length > 20;

  return (
    <View
      style={[
        styles.infoBox,
        isMobile && styles.mobileInfoBox,
        { height: minFixedHeight },
      ]}>
      {!!activeStore && (
        <View style={[styles.infoRow, isMobile && styles.mobileRow]}>
          <Pressable
            onPress={() => navigate('/stores')}
            style={[
              styles.headerButton,
              isMobile && styles.mobileHeaderButton,
            ]}>
            <View>
              <MaroonLocationPin width={isMobile ? 24 : 14} height={20} />
            </View>
            <View
              style={[styles.detailBox, { marginLeft: longStoreName ? 0 : 4 }]}>
              <BodyText style={styles.detailHeader}>PICKUP FROM</BodyText>
              <BodyText
                color='secondary'
                bold={true}
                fontSize={longStoreName ? 12 : 14}
                lineHeight={longStoreName ? 16 : 18}
                numberOfLines={1}
                ml={1}>
                {activeStore?.name}
              </BodyText>
            </View>
          </Pressable>

          <View style={styles.spaceBlock} />

          {activeStore?.open ? (
            <Pressable
              onPress={() => dispatch(setScheduleUpsellModal(true))}
              style={[
                styles.headerButton,
                isMobile && styles.mobileHeaderButton,
              ]}>
              <View>
                <Clock width={isMobile ? 30 : 22} height={isMobile ? 20 : 17} />
              </View>
              <View style={[styles.detailBox, { marginLeft: 4 }]}>
                <BodyText style={styles.detailHeader}>TIME: ASAP</BodyText>
                <BodyText
                  color='secondary'
                  bold={true}
                  fontSize={longStoreName ? 12 : 14}
                  lineHeight={longStoreName ? 16 : 18}>
                  {activeStore?.waitTime > 50
                    ? `Ready at ${getAbsoluteWaitTime(activeStore?.waitTime)}`
                    : ` Ready in ${activeStore?.waitTime} mins`}
                </BodyText>
              </View>
            </Pressable>
          ) : (
            <View
              style={[
                styles.headerButton,
                !!isMobile && styles.mobileHeaderButton,
                styles.pillBox,
              ]}>
              <BodyText style={[styles.pillInfo]}>Store Closed</BodyText>
            </View>
          )}
        </View>
      )}
    </View>
  );
};
export default StoreHeader;

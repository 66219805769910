import React from 'react';
import { Pressable, View, Animated } from 'react-native';

import { colors, getTrimTime, linkToMap } from '../../helpers';

import { BodyText, Block } from '../../components';

import StoreHours from './StoreHours';

import { RedLocationPin, MaroonLocationPin } from '../../assets';

import styles from './styles';

interface Props {
  store: StoreType;
  expandHours?: boolean;
}

const OpeningStoreBox = ({ store, expandHours }: Props) => {
  const openingTime = getOpeningTime(store);

  return (
    <View style={{ maxWidth: '94%' }} testID='opening-store-container'>
      <View
        style={[styles.storeBox, styles.closedStoreBox, styles.openingStoreBox]}
        testID='opening-store-box'
        key={store.id}>
        <View style={styles.openingContent}>
          <BodyText style={styles.label} color='secondary'>
            {store.name}
          </BodyText>
          <BodyText
            style={[styles.sublabel, { marginTop: 2, marginBottom: 8 }]}>
            {store.address}
          </BodyText>

          <View style={styles.infoRow}>
            {/* <Animated.View style={[styles.pill, styles.opening, { right: 2 }]}> */}
            <BodyText color='green' fontSize={14} bold={true}>
              {openingTime || ''}
            </BodyText>
            {/* </Animated.View> */}
          </View>
          <Block height={10} />
          <StoreHours store={store} expandHours={expandHours} />
        </View>
        <Block height={14} />
        <Pressable onPress={() => linkToMap(store)}>
          {/* @ts-ignore */}
          {({ hovered }) => (
            <View
              style={[
                styles.mapButton,
                {
                  backgroundColor: hovered ? colors.secondary : '',
                },
              ]}>
              {hovered ? (
                <RedLocationPin width={16} height={16} />
              ) : (
                <MaroonLocationPin width={16} height={16} />
              )}

              <BodyText ml={6} mr={2} color={hovered ? 'white' : 'textDark'}>
                View Location
              </BodyText>
            </View>
          )}
        </Pressable>
      </View>
    </View>
  );
};

export default OpeningStoreBox;

export const getOpeningTime = (store: StoreType) => {
  const now = new Date();

  if (store.preorderAt && new Date(store.preorderAt) > now)
    return `Orders open at ${getTrimTime(store.preorderAt)}`
      .replace('12am', 'Midnight')
      .replace('12pm', 'Noon');

  if (store.openAt && new Date(store.openAt) > now)
    return `Opening at ${getTrimTime(store.openAt)}`;

  return null;
};

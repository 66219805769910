import React, { useState } from 'react';
import { TextInput, TextInputProps, TextStyle, StyleSheet } from 'react-native';

import { BodyText } from './';

import { colors } from '../helpers';

interface Props extends TextInputProps {
  focusStyle?: TextStyle;
  label?: string;
  labelLeft?: boolean;
  labelMargin?: number;
  labelUppercase?: boolean;
  labelBold?: boolean;
  disabled?: boolean;
  inputRef?: React.RefObject<TextInput>;
  hasError?: boolean;
  testID?: string;
}

const CustomTextInput = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const {
    focusStyle,
    label,
    labelLeft,
    labelMargin,
    labelUppercase,
    labelBold,
    disabled,
    inputRef,
    hasError,
    testID,
  } = props;

  const labelStyle: TextStyle = {};

  if (labelLeft) labelStyle.alignSelf = 'flex-start';

  return (
    <>
      {label && (
        <BodyText
          uppercase={!!labelUppercase}
          color='textDark'
          bold={!!labelBold}
          mb={labelMargin || 10}
          style={[labelStyle, { opacity: disabled ? 0.8 : 1 }]}>
          {label}
        </BodyText>
      )}
      <TextInput
        {...props}
        ref={inputRef}
        style={[
          props.style,
          isFocused && focusStyle,
          hasError && styles.errorInput,
        ]}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        selectTextOnFocus={disabled ? false : true}
        editable={disabled ? false : true}
        testID={testID}
      />
    </>
  );
};

const styles = StyleSheet.create({
  errorInput: {
    borderColor: colors.error,
  },
});
export default CustomTextInput;

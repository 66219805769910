import { Animated, Platform, EasingFunction } from 'react-native';

type OptionsType = {
  toValue: number;
  duration?: number;
  delay?: number;
  easing?: EasingFunction;
  useNativeDriver?: boolean;
};

export const animate = (value: Animated.Value, options: OptionsType) => {
  const { toValue, duration, delay, easing, useNativeDriver } = options;
  const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

  return Animated.timing(value, {
    toValue,
    duration: duration !== undefined ? duration : 1000,
    delay,
    easing,
    useNativeDriver:
      typeof useNativeDriver !== undefined ? !!useNativeDriver : !!isNative,
  });
};

import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-native';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData, StripeElements } from '@stripe/stripe-js';
import { MOCK_TESTING } from '@app/core';

import {
  readOrder,
  getOrder,
  loadCompletedOrder,
  setOrderSuccessModal,
  clearCart,
  unselectCoupon,
  throttledLoadUserItems,
} from '../../../../globalStore';

import { getStripe, formatPrice, colors, logError } from '../../../../helpers';

import {
  BodyText,
  Block,
  Button,
  StarLoader,
  TitleText,
  TextInput,
} from '../../..';

import SavedPaymentMethods from '../SavedPaymentMethods';
import WebPaymentRequestField from './WebPaymentRequestField';

import styles from '../styles';

const WebPaymentFields = () => {
  const dispatch = useDispatch();

  const { storeId } = useParams();
  const order = useSelector((state: RootState) => readOrder(state, storeId));
  const stripe = useStripe();
  const elements: StripeElements | null = useElements();

  const [errorMessage, setErrorMessage] = useState('');
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [userName, setUserName] = useState('');
  const [nameError, setNameError] = useState('');

  const paymentMethods = order?.paymentMethods || [];

  const hasPaymentMethods = paymentMethods.length > 0;
  const [selectedPaymentMethod, setPaymentMethod] = useState(
    hasPaymentMethods ? paymentMethods[0].id : ''
  );

  const submitPayment = useCallback(async () => {
    if (!stripe || !elements || !order?.payment || !!processing) return;

    let paymentMethod: string | CreatePaymentMethodCardData;

    const orderId = order.id;
    const orderResult: any = await dispatch(getOrder({ storeId, orderId }));
    const pendingOrder = orderResult?.payload?.order;

    if (
      !pendingOrder.id ||
      !pendingOrder.state.toLowerCase ||
      pendingOrder.state.toLowerCase() !== 'awaiting_payment'
    ) {
      logError(
        `Order from platform not in correct state to submit payment - ${orderId}`
      );
      return setErrorMessage(
        'Order no longer available for payment, close cart and start again.'
      );
    }

    if (selectedPaymentMethod) {
      setProcessing(true);
      paymentMethod = selectedPaymentMethod;
    } else {
      if (errorMessage) {
        elements.getElement('card')?.focus();
        return;
      }

      if (!userName) return setNameError('Name cannot be empty');
      else setNameError('');

      if (cardComplete) setProcessing(true);

      const cardElement = elements?.getElement('card');

      const billingDetails = { name: userName };

      paymentMethod = {
        card: cardElement || null,
        billing_details: billingDetails,
      } as CreatePaymentMethodCardData;
    }
    if (MOCK_TESTING) return celebrateOrderSuccess();

    const result = await stripe.confirmCardPayment(order.payment.clientSecret, {
      payment_method: paymentMethod,
      setup_future_usage: 'off_session',
    });

    if (result.error) {
      setErrorMessage(result.error.message || 'An unknown error occurred');
      setProcessing(false);
    } else if (result.paymentIntent.status === 'succeeded') {
      celebrateOrderSuccess();
    }
  }, [
    stripe,
    elements,
    cardComplete,
    userName,
    order?.payment?.clientSecret,
    selectedPaymentMethod,
  ]);

  const celebrateOrderSuccess = useCallback(async () => {
    if (!order) return;

    const orderId = order.id;

    const completedResult: any = await dispatch(
      loadCompletedOrder({ orderId, attemptsLeft: 1 })
    );

    let completedOrder;
    if (completedResult?.payload?.order)
      completedOrder = completedResult?.payload?.order;
    else {
      const result: any = await dispatch(getOrder({ storeId, orderId }));
      completedOrder = result?.payload?.order;
    }

    if (completedOrder) dispatch(setOrderSuccessModal(completedOrder));
    else {
      setErrorMessage(
        'Order not confirmed, please refresh and check your recent orders.'
      );
      logError(`Order not read after completing payment - ${orderId}`);
    }
    setProcessing(false);
    dispatch(unselectCoupon(''));
    dispatch(throttledLoadUserItems(['coupons']));
    return dispatch(clearCart(storeId));
  }, [order, setOrderSuccessModal, setProcessing]);

  return (
    <>
      <TitleText color='secondary' fontSize={16} mb={26} spacing={0.4}>
        CHECKOUT
      </TitleText>

      {!!order && (
        <WebPaymentRequestField
          order={order}
          setErrorMessage={setErrorMessage}
          orderSuccess={celebrateOrderSuccess}
        />
      )}

      {!!hasPaymentMethods && (
        <SavedPaymentMethods
          paymentMethods={paymentMethods}
          selectedPaymentMethod={selectedPaymentMethod}
          setPaymentMethod={setPaymentMethod}
        />
      )}

      {!selectedPaymentMethod && (
        <>
          <TextInput
            value={userName}
            onChangeText={setUserName}
            style={styles.fieldInput}
            label='Name on card'
            labelLeft={true}
            labelMargin={2}
            testID='payment-cart-name-input'
          />
          {!!nameError && (
            <BodyText color='error' style={styles.nameError}>
              {nameError}
            </BodyText>
          )}

          <BodyText style={styles.cardLabel}>Card information</BodyText>
          <View style={styles.fieldInput}>
            <CardElement
              options={{
                style: {
                  base: {
                    color: colors.textDark,
                    fontSize: '16px',
                    lineHeight: '2',
                  },
                },
              }}
              onChange={(e) => {
                if (e.error)
                  setErrorMessage(
                    e.error.message || 'An unknown error occurred'
                  );
                else setErrorMessage('');
                setCardComplete(e.complete);
              }}
            />
          </View>
        </>
      )}

      {!!errorMessage && (
        <BodyText color='error' style={styles.errorMessage} center={true}>
          {errorMessage}
        </BodyText>
      )}

      <Block grow={true} height={30} />
      <Button
        onClick={submitPayment}
        label={`Pay ${formatPrice(order?.payment?.amount || 0)}`}
        style={styles.payButton}
        submitting={!!processing}
        testID='payment-submit-button'></Button>
    </>
  );
};

const PaymentFieldWrapper = () => {
  return (
    <Elements stripe={getStripe()}>
      <WebPaymentFields />
    </Elements>
  );
};

export default PaymentFieldWrapper;

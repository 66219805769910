import React from 'react';
import {
  View,
  StyleSheet,
  Image,
  Pressable,
  PanResponder,
  Animated,
  GestureResponderHandlers,
} from 'react-native';
import MapView, { Marker } from 'react-native-maps';

import { StarLoader, CustomView, BodyText } from '..';

import { animate, getEnvVariable, getCoordinates } from '../../helpers';

import { truckMarker, RefreshGPS } from '../../assets';

import styles from './styles';

interface Props {
  store: StoreType;
  setMapError?: (hasError: boolean) => void;
  disabled?: boolean;
  zoomedIn?: boolean;
}

interface State {
  appear: boolean;
  offscreenDirection: number[];
  touches: number;
  scrollEnabled: boolean;
  panHandlers: GestureResponderHandlers;
  coordinates: { lat: number; lng: number };
}

export default class NativeMapPreview extends React.Component<Props, State> {
  state: State = {
    appear: false,
    offscreenDirection: [0, 0],
    touches: 0,
    scrollEnabled: false,
    panHandlers: {},
    coordinates: {
      lat: this.props.store.latitude || 0,
      lng: this.props.store.longitude || 0,
    },
  };

  moveTimestamp = 0;
  messageTimer = setTimeout(() => undefined, 1);
  disableTimer = setTimeout(() => undefined, 1);
  checkBlock = true;

  mapRef: MapView | null = null;

  viewOpacity = new Animated.Value(0);

  componentDidMount() {
    this.loadMap();
  }

  loadMap = () => {
    const { coordinates } = this.state;
    if (!coordinates.lat || !coordinates.lng) this.fetchLatLng();
    this.setPanHandlers();
  };

  catchMapError = () => {
    const { setMapError } = this.props;
    if (setMapError) setMapError(true);
  };

  fetchLatLng = async () => {
    const { store } = this.props;
    const coordinates = await getCoordinates(store.address);

    if (!coordinates) return this.catchMapError();
    this.setState({ coordinates });
  };

  setPanHandlers = () => {
    const panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponder: () => true,
      onPanResponderMove: (event, gesture) => {
        if (this.props.disabled) return;

        if (gesture.numberActiveTouches === 1 && this.checkBlock) {
          this.setState({ touches: gesture.numberActiveTouches });
          clearTimeout(this.messageTimer);
          animate(this.viewOpacity, { toValue: 0.6, duration: 600 }).start();
          this.messageTimer = setTimeout(
            () =>
              animate(this.viewOpacity, { toValue: 0, duration: 300 }).start(),
            1500
          );
        } else if (gesture.numberActiveTouches === 2) {
          this.setState({
            touches: gesture.numberActiveTouches,
            scrollEnabled: true,
          });
          this.checkBlock = false;

          this.resetDisableTimer();
        }
      },
    });
    this.setState({ panHandlers: panResponder.panHandlers });
  };

  disableMap = () => {
    setTimeout(() => {
      this.checkBlock = true;
    }, 100);
    this.setState({ scrollEnabled: false, touches: 0 });
    animate(this.viewOpacity, { toValue: 0, duration: 300 }).start();
  };

  resetDisableTimer = () => {
    clearTimeout(this.disableTimer);
    this.disableTimer = setTimeout(this.disableMap, 1500);
  };

  centerMap = () => {
    const { zoomedIn } = this.props;
    const { coordinates } = this.state;
    if (!coordinates.lat || !coordinates.lng) return;
    this.mapRef?.animateToRegion({
      latitude: coordinates.lat,
      longitude: coordinates.lng,
      latitudeDelta: zoomedIn ? 0.0015 : 0.003,
      longitudeDelta: zoomedIn ? 0.0025 : 0.005,
    });
    this.disableMap();
  };

  detectMapMove = (event: any) => {
    const { zoomedIn } = this.props;
    const { coordinates } = this.state;
    this.resetDisableTimer();
    const now = Date.now();
    if (now - this.moveTimestamp < 300) return;
    this.moveTimestamp = Date.now();

    if (!coordinates.lat || !coordinates.lng) return;
    const latDiff = event.latitude - coordinates.lat;
    const longDiff = event.longitude - coordinates.lng;
    // const latChange = Math.abs(latDiff - 1.003);

    // const testLat =
    //  coordinates.lat < event.latitude - event.latitudeDelta ||
    //  coordinates.lat > event.latitude + event.latitudeDelta;

    // const testLong =
    //   coordinates.lng < event.longitude - event.longitudeDelta ||
    //   coordinates.lng > event.longitude + event.longitudeDelta;

    const latLimit = zoomedIn ? 0.001 : 0.002;
    const longLimit = zoomedIn ? 0.002 : 0.0045;

    let latChange =
      Math.abs(latDiff) > latLimit ? latDiff / Math.abs(latDiff) : 0;
    let longChange =
      Math.abs(longDiff) > longLimit ? longDiff / Math.abs(longDiff) : 0;

    if (event.latitudeDelta < latLimit) {
      latChange = 1;
      longChange = 1;
    }

    const changes = [latChange, longChange];
    if (changes !== this.state.offscreenDirection)
      this.setState({ offscreenDirection: changes });
  };

  render() {
    const { disabled, zoomedIn } = this.props;
    const {
      offscreenDirection,
      panHandlers,
      appear,
      scrollEnabled,
      coordinates,
    } = this.state;
    if (!coordinates.lat || !coordinates.lng) return;

    const showCenterButton =
      !disabled && (!!offscreenDirection[0] || !!offscreenDirection[1]);

    return (
      <View style={styles.mask}>
        <View style={styles.loadingFrame}>
          <StarLoader color='primary' />
        </View>
        <CustomView style={styles.mapBox} fadeIn={true} appear={appear}>
          <View {...panHandlers} style={StyleSheet.absoluteFillObject}>
            <MapView
              style={StyleSheet.absoluteFillObject}
              userInterfaceStyle='light'
              // initialRegion={{
              //   latitude: coordinates.lat,
              //   longitude: coordinates.lng,
              //   latitudeDelta: zoomedIn ? 0.0015 : 0.003,
              //   longitudeDelta: zoomedIn ? 0.0025 : 0.005,
              // }}
              initialCamera={{
                center: {
                  latitude: coordinates.lat,
                  longitude: coordinates.lng,
                },
                heading: zoomedIn ? 5 : 0,
                pitch: zoomedIn ? 30 : 0,
                altitude: zoomedIn ? 200 : 600,
              }}
              onMapReady={() => this.setState({ appear: true })}
              onRegionChange={(event) => this.detectMapMove(event)}
              showsPointsOfInterest={false}
              scrollEnabled={scrollEnabled && !disabled}
              mapType='mutedStandard'
              ref={(r) => (this.mapRef = r)}
              onLayout={() => {
                // this.mapRef.animateToBearing(125);
                // this.mapRef.animateToViewingAngle(45);
              }}>
              <Marker
                coordinate={{
                  latitude: coordinates.lat,
                  longitude: coordinates.lng,
                }}
                // title={store.name}
                // description='wtf'
                centerOffset={{ x: 0, y: -28 }}>
                <Image
                  source={truckMarker}
                  style={{ width: 30, height: 40 }}
                  resizeMode='contain'
                />
              </Marker>
            </MapView>
            {!scrollEnabled && !disabled && (
              <Animated.View
                style={[
                  styles.mapCover,
                  {
                    opacity: this.viewOpacity,
                  },
                ]}>
                <BodyText color='white' fontSize={18} center={true}>
                  Use two fingers to move the map
                </BodyText>
              </Animated.View>
            )}
          </View>

          {!!showCenterButton && (
            <Pressable
              style={[styles.centerButtonBox]}
              onPress={this.centerMap}>
              <RefreshGPS width={35} height={40} />
            </Pressable>
          )}

          {/* <View style={{ position: 'absolute', top: 5, left: 5 }}>
          <BodyText color='blue' fontSize={22}>
            {touches} {JSON.stringify(scrollEnabled)}
          </BodyText>
        </View> */}
        </CustomView>
      </View>
    );
  }
}

import React from 'react';
import { View, Pressable } from 'react-native';

import { BodyText } from '..';

import { Plus, Minus } from '../../assets';

import styles from './styles';

interface Props {
  increaseCount: () => void;
  decreaseCount: () => void;
  count: number;
}

const Counter = ({ decreaseCount, increaseCount, count }: Props) => {
  return (
    <View style={styles.counterBox}>
      <Pressable
        style={[styles.buttonBox, { paddingLeft: 5 }]}
        onPress={decreaseCount}>
        <Minus width={14} />
      </Pressable>
      <BodyText style={styles.count}>{count}</BodyText>
      <Pressable
        style={[styles.buttonBox, { paddingRight: 5 }]}
        onPress={increaseCount}>
        <Plus width={14} />
      </Pressable>
    </View>
  );
};

export default Counter;

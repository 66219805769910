import { Platform, AppState, NativeEventSubscription } from 'react-native';

type ListenerType = {
  lastState: string;
  subscription: NativeEventSubscription;
};

const listenerObjects: ListenerType[] = [];

const createListenerObject = (subscription: NativeEventSubscription) => ({
  lastState: '',
  subscription,
});

export const addFocusListener = (cb: () => void) => {
  const isWeb = Platform.OS === 'web';
  if (isWeb) window.addEventListener('focus', cb);
  else {
    const idx = listenerObjects.length;
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      const activeListener = listenerObjects[idx];
      const { lastState } = activeListener;
      if (lastState !== 'active' && nextAppState === 'active') cb();
      activeListener.lastState = nextAppState;
    });
    const listener = createListenerObject(subscription);

    listenerObjects.push(listener);
    return idx;
  }
  return 0;
};

export const removeFocusListener = (listenerIdx: number, cb: () => void) => {
  const isWeb = Platform.OS === 'web';
  if (isWeb) window.removeEventListener('focus', cb);
  else {
    const listener = listenerObjects[listenerIdx];
    listener?.subscription?.remove();
  }
};

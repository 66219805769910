import React from 'react';
import { View } from 'react-native';

import { PillBox } from '..';

import styles from './styles';

interface Props {
  choices: ChoiceType[];
  toggleChoice: (choiceId: string) => void;
  disabled?: boolean;
}

const ChoicesSelection = ({ choices, toggleChoice, disabled }: Props) => {
  return (
    <View style={styles.selectionBox}>
      {choices.map((c, idx) => (
        <PillBox
          choice={c}
          onPress={toggleChoice}
          disabled={disabled}
          marginRight={idx % 2 === 0 ? 10 : 0}
          fullWidth={choices.length === 1}
          key={c.id}
        />
      ))}
    </View>
  );
};

export default ChoicesSelection;

import { shuffle, cloneDeep, isString, isArray, isPlainObject } from 'lodash';

export const flattenArray = (arr: any[]): string[] =>
  arr.flatMap((item) => {
    if (isString(item)) return item;
    if (isArray(item)) return flattenArray(item);
    return '';
  });

export const flattenObjectErrors = (obj: { [key: string]: any }): string[] => {
  let errors: string[] = [];
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (isString(value)) errors.push(value);
    else if (isArray(value)) {
      const arrayErrors = flattenArray(value);
      errors = [...errors, ...arrayErrors];
    } else if (isPlainObject(value)) {
      const objectErrors = flattenObjectErrors(value);
      errors = [...errors, ...objectErrors];
    }
  });
  return errors.filter(Boolean);
};
